import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { FaRegEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { FaEye } from "react-icons/fa";
import { IoMdAdd } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
import { IoIosArrowBack } from "react-icons/io";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { FaSearch } from "react-icons/fa";
import company from "../../assets/img/icons/building.png";
import Swal from 'sweetalert2';
import { IoMdPersonAdd } from "react-icons/io";
import { MdCurrencyRupee } from "react-icons/md";
import { FaRupeeSign } from "react-icons/fa";
export default function Customermanagement() {
  const [data, setData] = useState([]);
  const [baseurl, setbaseurl] = useState();
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [AllBusinesesCustomers, setBusinesesCustomers] = useState();
  const [ActiveBusinesesCustomers, setActiveBusinesesCustomers] = useState();
  const [InactiveBusinesesCustomers, setInactiveBusinesesCustomers] = useState();
  const navigate = useNavigate();
  const custumerdetails_id = localStorage.getItem("custumerdetails");
  const company_id_BCustumer = localStorage.getItem("company_id_BCustumer");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const broker_id = localStorage.getItem("broker_id");
  const token = localStorage.getItem("bear_token");
  const fetchData = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem("bear_token");
      const broker_id = localStorage.getItem("broker_id");

      const formData = new FormData();
      formData.append("smalladmins_id", broker_id);

      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}small-admins-business-customer-lists`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      };

      const response = await axios(config);
      const data = response.data.data;
      const data1 = response.data.image_url;

      const filteredData = data.filter(item => item.company_id == company_id_BCustumer);

      const totalFilteredEmp = filteredData.length;
      setBusinesesCustomers(totalFilteredEmp);

      const activeFilteredEmp = filteredData.filter(item => item.status === 1).length;
      setActiveBusinesesCustomers(activeFilteredEmp);

      const inactiveFilteredEmp = filteredData.filter(item => item.status === 0).length;
      setInactiveBusinesesCustomers(inactiveFilteredEmp);

      setbaseurl(data1);
      setData(data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);


  const Delete = (id) => {
    localStorage.setItem("b_id", id);
    navigate("/Add-Bussiness-Transaction");
  };
  // const Delete = async (business_id) => {
  //   try {
  //     const result = await Swal.fire({
  //       title: "Are you sure?",
  //       text: "You won't be able to revert this!",
  //       icon: "warning",
  //       showCancelButton: true,
  //       confirmButtonColor: "#3085d6",
  //       cancelButtonColor: "#d33",
  //       confirmButtonText: "Yes, delete it!",
  //     });
  //     if (result.isConfirmed) {
  //       setLoading(true);
  //       const formData = new FormData();
  //       formData.append("smalladmins_id", broker_id);
  //       formData.append("business_cust_id", business_id);

  //       const config = {
  //         method: "post",
  //         url: `${process.env.REACT_APP_API_URL}delete-small-admins-business-customer`,
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //           "Content-Type": "multipart/form-data",
  //         },
  //         data: formData,
  //       };

  //       const response = await axios(config);
  //       const data = response.data.status;
  //       if (data === 1) {
  //         Swal.fire({
  //           icon: 'success',
  //           title: 'Success',
  //           text: response.data.message,
  //           // timer: 1500
  //         });
         
  //         fetchData();
  //       } else {
  //         Swal.fire({
  //           icon: 'error',
  //           title: 'Error',
  //           text: response.data.message,
  //           // timer: 2000
  //         });
  //       }
  //       setLoading(false);
  //     }
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //     setLoading(false);
  //   }
  // };
  const handleStatusChange = async (business_id, currentStatus) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, change status!",
      });

      if (result.isConfirmed) {
        setLoading(true);

        const formData = new FormData();
        formData.append("smalladmins_id", broker_id);
        formData.append("business_cust_id", business_id);
        formData.append("status", currentStatus === 1 ? 0 : 1); 

        const config = {
          method: "post",
          url: `${process.env.REACT_APP_API_URL}business-customer-small-admins-update-status`,
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
          data: formData,
        };

        const response = await axios(config);
        const data = response.data.status;

        if (data === 1) {
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: response.data.message,
            timer: 1500
          });
          fetchData(); // Refresh data after status change
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: response.data.message,
            // timer: 2000
          });
      
        }

        setLoading(false);
      }
    } catch (error) {
      console.error("Error changing status:", error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: "There was an error changing the status.",
        // timer: 2000
      });

      setLoading(false);
    }
  };
  const filteredData = data ? data.filter((item) =>   item.company_id == company_id_BCustumer
  && item.business_name.toLowerCase().includes(searchTerm.toLowerCase())
):[];

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedData = filteredData.slice(startIndex, endIndex);


  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const handleClick = async (id, companyName, company_id, status,user_credit) => {
    localStorage.setItem("bid", id);
    localStorage.setItem("bidwallet", id);
    console.warn("id, companyName", id, company_id);
    localStorage.setItem("company_id", company_id);
    localStorage.setItem("user_credit", user_credit);
    if (companyName === "Not Assign" || companyName === "NA") {
      const result = await Swal.fire({
        title: "Alert",
        text: "Please assign a company first!",
        icon: "error",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Assign Company!",
      });
  
      if (result.isConfirmed) {
        localStorage.setItem('customerBusinesset_id', id);
        navigate("/Business-edit");
      }
    } else if (status === undefined || status === null || status === 0) {
      await Swal.fire({
        title: "Alert",
        text: "Please Update Status!",
        icon: "error",
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });
    } else {
      localStorage.setItem("Addplan_id", id);
      navigate("/Add-B-More-plan");
    }
  };
  
  // const handleClick = (id) => {
  //   localStorage.setItem("Addplan_id", id);
  //   navigate("/Add-B-More-plan");
  // };
  const handleViveClick = (id) => {
    localStorage.setItem("customerBusinesset_id", id);
    navigate("/Businesset-View");
  };
  const handleClickadd = (id) => {
    navigate("/Addbusiness");
  };
  const handlecust_idClick = (business_cust_id) => {
    localStorage.setItem('business_cust_id',business_cust_id)
    navigate("/Business-edit");
  };
  return (
    <>
      <div className="card mb-3">
        <div
          className="bg-holder d-none d-lg-block bg-card"
          style={{
            backgroundImage:
              "url(../../assets/img/icons/spot-illustrations/corner-4.png)"
          }}
        />
        {/*/.bg-holder*/}
        <div className="card-body position-relative">
          <div className="row">
            <div className="col-lg-8">
              <h3 className="mb-0">Business Customer Analytics</h3>
            </div>
            <div className="col-lg-4 text-end">
              <Link to="/CompanyView" className="">
                <button className="btn btn-success">Back</button>
              </Link>
            </div>

          </div>
        </div>

      </div>
      <div className="row g-3 mb-3">
        <div className="col-md-4 col-xxl-3">
        <Link to='/Company-Bussiness-Customer' className="linkWithoutUnderline active">
          <div className="card h-md-100 ecommerce-card-min-width">
            <div className="card-header pb-0">
              <h5 className="mb-0 mt-2 d-flex align-items-center">
                Total 
              </h5>
            </div>
            <div className="card-body d-flex flex-column justify-content-end">
              <div className="row align-items-center">
                <div className="col pe-0">
                  <IoMdPersonAdd className="fs-4 text-primary" />
                </div>
                <div className="col ps-0">
                  <p className="font-sans-serif lh-1 mb-1 fs-5 text-primary">
                    {AllBusinesesCustomers}
                  </p>
                </div>
                <div className="col-auto ps-0">
                  <div className="echart-bar-weekly-sales h-100" />
                </div>
              </div>
            </div>
          </div>
          </Link>
        </div>
        <div className="col-md-4 col-xxl-3">
        <Link to='/Company-Active-Bussiness-Customer' className="linkWithoutUnderline ">
          <div className="card h-md-100">
            <div className="card-header pb-0">
              <h5 className="mb-0 mt-2"> Active </h5>
            </div>
            <div className="card-body d-flex flex-column justify-content-end">
              <div className="row justify-content-between">
                <div className="col pe-0">
                  <IoMdPersonAdd className="fs-4 text-success" />
                </div>
                <div className="col ps-0">
                  <div className="fs-5 fw-normal font-sans-serif text-700 lh-1 mb-1 ">
                    <p className="font-sans-serif lh-1 mb-1 fs-5 text-success">
                      {ActiveBusinesesCustomers}
                    </p>
                  </div>
                </div>
                <div className="col-auto ps-0 mt-n4">
                  <div
                    className="echart-default-total-order"
                    data-echarts='{"tooltip":{"trigger":"axis","formatter":"{b0} : {c0}"},"xAxis":{"data":["Week 4","Week 5","Week 6","Week 7"]},"series":[{"type":"line","data":[20,40,100,120],"smooth":true,"lineStyle":{"width":3}}],"grid":{"bottom":"2%","top":"2%","right":"10px","left":"10px"}}'
                    data-echart-responsive="true"
                  />
                </div>
              </div>
            </div>
          </div>
          </Link>
        </div>
        <div className="col-md-4 col-xxl-3">
        <Link to='/Company-Inactive-Bussiness-Customer' className="linkWithoutUnderline">
          <div className="card h-md-100">
            <div className="card-header pb-0">
              <h5 className="mb-0 mt-2"> Inactive </h5>
            </div>
            <div className="card-body d-flex flex-column justify-content-end">
              <div className="row justify-content-between">
                <div className="col pe-0">
                  <IoMdPersonAdd className="fs-4 text-danger" />
                </div>
                <div className="col ps-0">
                  <div className="fs-5 fw-normal font-sans-serif text-700 lh-1 mb-1">
                    <p className="font-sans-serif lh-1 mb-1 fs-5  text-danger">
                      {InactiveBusinesesCustomers}
                    </p>
                  </div>
                </div>
                <div className="col-auto ps-0 mt-n4">
                  <div
                    className="echart-default-total-order"
                    data-echarts='{"tooltip":{"trigger":"axis","formatter":"{b0} : {c0}"},"xAxis":{"data":["Week 4","Week 5","Week 6","Week 7"]},"series":[{"type":"line","data":[20,40,100,120],"smooth":true,"lineStyle":{"width":3}}],"grid":{"bottom":"2%","top":"2%","right":"10px","left":"10px"}}'
                    data-echart-responsive="true"
                  />
                </div>
              </div>
            </div>
          </div>
          </Link>
        </div>
      </div>
      <div className="card mb-3">
        <div
          className="bg-holder d-none d-lg-block bg-card"
          style={{
            backgroundImage:
              "url(../../assets/img/icons/spot-illustrations/corner-4.png)",
          }}
        />
        {/*/.bg-holder*/}
        <div className="card-body position-relative">
          <div className="row">
            <div className="col-lg-8">
              <h3 className="mb-0">Bussiness Customer List</h3>
            </div>
            <div className="col md-4 d-flex justify-content-end border-0 shadow-none">
              <button
                type="button"
                className="btn btn-sm btn-success"
                onClick={handleClickadd}
              >

                <IoMdAdd className="me-1 fs--2" />  Add Business Customer
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="card"
        id="ticketsTable"
        data-list='{"valueNames":["client","subject","status","priority","agent"],"page":11,"pagination":true,"fallback":"tickets-table-fallback"}'
      >
        <div className="card-header justify-content-between d-flex border-bottom border-200">
          <div className="justify-content-end d-flex w-100">
            <form>
              <div className="input-group input-search-width">
                <input
                  className="form-control form-control-sm shadow-none search"
                  type="search"
                  placeholder="Search by name"
                  aria-label="search"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
            </form>
          </div>
        </div>
        {loading && (
          <div id="preloader">
            <div id="loading-center">
              <div className="loader">
                <div className="waviy">
                  <img
                    src="https://swaninvestment.in/frontant/assets/img/loader.png"
                    alt="Loader image"
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="card-body p-0">
          <div className="table-responsive scrollbar">
            <table className="table table-sm mb-0 fs-10 table-view-tickets">
              <thead className="bg-body-tertiary">
                <tr>
                  <th className="text-800 sort align-middle" >
                    Sr.
                  </th>
                  <th className="text-800 sort align-middle">Business Customer Name</th>
                  {/* <th className="text-800 sort align-middle">Sub Admin Name</th> */}
                  <th className="text-800 sort align-middle">Company Name</th>
                  <th className="text-800 sort align-middle">Sub Admin Email</th>
                  <th className="text-800 sort align-middle">Total Amount</th>
                  <th className="text-800 sort align-middle">Status</th>
                  <th className="text-800 sort align-middle">Action</th>
                </tr>
              </thead>
              <tbody className="list " id="table-ticket-body">
                {paginatedData && paginatedData.length > 0 ? (
                  paginatedData.map((item, index) => (
                    item.company_id == company_id_BCustumer &&
                    <tr>
                      <td>{startIndex + index + 1}</td>
                      {/* <td>{item.id}</td> */}
                      <td className="capitalize">{item.business_name}</td>
                      {/* <td>{item.sub_admin_name}</td> */}
                      <td className="capitalize">{item.company_name}</td>

                      <td>
                      {item.sub_admin_email ? (
                        <a href={`mailto:${item.sub_admin_email}`}>{item.sub_admin_email}</a>
                      ) : (
                        "NA"
                      )}
                    </td>
                      <td className="capitalize"><MdCurrencyRupee /> {item.user_credit} </td>
                 
                      <td>
                        <div className="form-check form-switch">
                          <input
                            className="form-check-input toggle_btn"
                            type="checkbox"
                            role="switch"
                            id={`flexSwitchCheckDefault-${item.business_cust_id}`}
                            checked={item.status === 1}
                            onChange={() => handleStatusChange(item.business_cust_id, item.status)}
                            disabled={loading}
                          />
                        </div>
                      </td>
                      <td>
                        <div className="d-flex gap-1 action_button">
                          <OverlayTrigger
                            key="Edit"
                            placement="top"
                            overlay={<Tooltip id={`Edit`}>Edit</Tooltip>}
                          >
                            <button
                              onClick={() => handlecust_idClick(item.business_cust_id)}
                              style={{ "--i": "#2c7be5" }}
                            >
                              {" "}
                              <FaRegEdit className="color_link" />
                            </button>
                          </OverlayTrigger>

                          <OverlayTrigger
                            key="View"
                            placement="top"
                            overlay={<Tooltip id={`View`}>View</Tooltip>}
                          >
                            <button
                              onClick={() => handleViveClick(item.business_cust_id)}
                              style={{ "--i": "#27bcfd" }}
                            >
                              <FaEye className="color_link" />
                            </button>
                          </OverlayTrigger>
                          <OverlayTrigger
                            key="Update"
                            placement="top"
                            overlay={
                              <Tooltip id={`Update`}>Add More Plan</Tooltip>
                            }
                          >
                            <button
                              type="button"
                              style={{ "--i": "#07bc0c" }}
                              onClick={() => handleClick(item.business_cust_id, item.company_name,item.company_id,item.user_credit)}                 
                        
                            >
                              <IoMdAdd className="color_link" />
                            </button>
                          </OverlayTrigger>
                          <OverlayTrigger
                            key="Delete"
                            placement="top"
                            overlay={<Tooltip id={`Delete`}>Add Fund</Tooltip>}
                          >
                            <button
                              style={{ "--i": "#444" }}
                              onClick={() => Delete(item.business_cust_id)}
                            >
                              {" "}
                              <MdDelete className="color_link" />
                            </button>
                          </OverlayTrigger>
                        </div>
                      </td>
                      
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="12" className="text-center py-4">
                      No data found.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <div className="text-center d-none" id="tickets-table-fallback">
              <p className="fw-bold fs-8 mt-3">No ticket found</p>
            </div>
            {totalPages > 1 && (
              <div className="pagination mt-3 mb-3 mx-3">
                <button
                  className="btn btn-sm btn-light"
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                >
                  <IoIosArrowBack />
                </button>
                {[...Array(totalPages).keys()].map((pageNumber) => (
                  <button
                    key={pageNumber + 1}
                    className={`btn btn-sm mx-1 ${currentPage === pageNumber + 1
                        ? "btn-light text-primary"
                        : "btn-light"
                      }`}
                    onClick={() => handlePageChange(pageNumber + 1)}
                  >
                    {pageNumber + 1}
                  </button>
                ))}
                <button
                  className="btn btn-sm btn-light"
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalPages}
                >
                  <IoIosArrowForward />
                </button>
              </div>
            )}
          </div>
        </div>
        
      </div>

    </>
  );
}
