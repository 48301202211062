import React, { useState, useEffect } from "react";
import axios from "axios";
import { Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { FaUserAlt,  FaHourglassHalf} from 'react-icons/fa';
import { FaChartBar,FaChartLine } from 'react-icons/fa';
import { SiSymbolab } from "react-icons/si";
export default function Veissymbol() {
  const broker_id = localStorage.getItem("broker_id");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [newImage, setNewImage] = useState(null);
  const [fileimage, setFileimage] = useState(null);
  const [errors, setErrors] = useState({});
  const [data, setData] = useState([]);
  const [dataplan, setDataplan] = useState([]);
  const [baseurl, setBaseUrl] = useState("");
  const [formData, setFormData] = useState({
    name: "",
    strategies: "",
    smalladmins_name: "",
    strategy_type: "",
   

   
  });

  useEffect(() => {
    fetchData1();
  }, []);
  const fetchData1 = async () => {
    try {
      const token = localStorage.getItem("bear_token");

      const customer_id = localStorage.getItem("Stratiesid");



      const formData = new FormData();
      formData.append("smalladmins_id", broker_id);
      formData.append("id", customer_id);
      formData.append("status", "1");

      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}small-admins-view-symbols`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      };

      const response = await axios(config);
      const data = response.data.data;
      setFormData(data[0]);
      const dataurl = response.data.url;
      setBaseUrl(dataurl);
    } catch (error) {
      console.error("Error fetching user broker:", error);
    }
  };

  return (
    <div>
      <div className="card mb-3">
        <div
          className="bg-holder d-none d-lg-block bg-card"
          style={{
            backgroundImage:
              "url(../../assets/img/icons/spot-illustrations/corner-4.png)",
          }}
        />
        <div className="card-body position-relative">
          <div className="row ">
            <div className="col-lg-8">
              <h3 className="mb-0"> Symbol Details</h3>
            </div>
            <div className="col-lg-4 text-end">
            {/* <Link to="/Edidsymbol" className="">
                <button className="btn btn-primary">Update</button>
              </Link> */}
              <Link to="/Addsymbol" className="mx-1">
                <button className="btn btn-success">Back</button>
              </Link>
             
            </div>
          </div>
        </div>
      </div>

      <div className="col-lg-12 mb-3">
          <div className="card">
            <div className="card-header">
              <h5 className="card-title mb-0">Symbol Details</h5>
            </div>
            <div className="card-body">
              <table className="table">
                <tbody>
                  <tr>
                    <th scope="row">Symbol  ID</th>
                    <td style={{ color: 'green', fontWeight: 'bold' }}># {formData.id }</td>
                  </tr>
                  <tr>
                    <th scope="row">Symbol  Name</th>
                    <td style={{ color: 'blue', fontWeight: 'bold' }}className='capitalize'><SiSymbolab className="me-2" /> {formData.name}</td>
                  </tr>
                  <tr>
                    <th scope="row">Strategies Name</th>
                    <td className='capitalize' style={{ color: 'purple', fontWeight: 'bold' }}><FaHourglassHalf className="me-2" /> {formData.strategies_name}</td>
                  </tr>
                  <tr>
                    <th scope="row">Type</th>
                    <td className='capitalize' style={{ color: 'purple', fontWeight: 'bold' }}>{formData.strategy_type === "positional" ? 
                    
                    <FaChartLine className="me-2" />
                    :
                    <FaChartBar className="me-2" />
                  }{formData.strategy_type}</td>
                  </tr>
                 
                </tbody>
              </table>
            </div>
          </div>
        </div>

 

    </div>
  );
}
