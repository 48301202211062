import React, { useEffect, useState } from "react";
import axios from "axios";
import { Container, Row, Col, Spinner, Form } from "react-bootstrap";
import { useParams, useNavigate, Link } from 'react-router-dom';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { GrTransaction } from "react-icons/gr";
import { FaDiagramSuccessor } from "react-icons/fa6";
import Swal from 'sweetalert2';
import { IoMdPersonAdd } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
import { IoIosArrowBack } from "react-icons/io";
import { MdOutlineMarkEmailRead } from "react-icons/md";
import { IoPersonSharp } from "react-icons/io5";
import { FaUserAlt, FaRegEnvelope, FaPhoneAlt, FaRegBuilding, FaRegAddressCard, FaCalendarAlt } from 'react-icons/fa';
import { PiAddressBook } from "react-icons/pi";
import { BsPeople } from "react-icons/bs";
import { MdOutlineAccountBalanceWallet, MdPermIdentity } from "react-icons/md";

export default function ViewEmployee() {
  const navigate = useNavigate();
  const { emp_id } = useParams();

  const [baseurl, setBaseUrl] = useState();
  const [loading, setLoading] = useState(true);
  const [employeeDetails, setEmployeeDetails] = useState([]);
  const employee_id = localStorage.getItem("employee_id");
  const [AllEmployee, setAllEmployee] = useState(''); // State to hold the search term
  const [ActiveEmployee, setActiveEmployee] = useState(''); // State to hold the search term
  const [InactiveEmployee, setInactiveEmployee] = useState(''); // State to hold the search term

  useEffect(() => {
    const fetchEmployeeDetails = async () => {
      try {
        const broker_id = localStorage.getItem("broker_id");
        const token = localStorage.getItem("bear_token");

        const response = await axios.post(
          // "https://broker.swaninvestment.in/API/public/api/view-small-admins-emp",
          `${process.env.REACT_APP_API_URL}view-small-admins-emp`,
          {
            smalladmins_id: broker_id,
            emp_id: employee_id,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        const totalEmp = response.data.TotalEMPTransaction;
        const ActiveEmployee = response.data.TotalEmployeeSalarySlip;
        setAllEmployee(totalEmp)
        setActiveEmployee(ActiveEmployee)
        const baseurl = response.data.url;
        console.warn("hjdfghjdfhgj", baseurl);
        setBaseUrl(baseurl);
        if (response.data.status === 1) {
          setEmployeeDetails(response.data.data);
          console.warn("response.data.data", response.data.data)
        } else {
          // toast.error(response.data.message);
        }
      } catch (error) {
        console.error("Error fetching employee details:", error);
        // toast.error("An error occurred while fetching the employee details.");
      } finally {
        setLoading(false);
      }
    };

    fetchEmployeeDetails();

  }, [employee_id]);
  useEffect(() => {
    fetchData();
  }, [])
  const fetchData = async () => {
    try {
      setLoading(true);

      // Fetch data from API
      const token = localStorage.getItem("bear_token");
      const broker_id = localStorage.getItem("broker_id");

      const formData = new FormData();
      formData.append("smalladmins_id", broker_id);
      formData.append("status", '1');

      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}small-admins-emp-lists`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data"
        },
        data: formData
      };

      const response = await axios(config);



      // Set fetched data
      setLoading(false);

    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };
  if (loading) {
    return (
      <div className="text-center mt-5">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  return (
    <Container>
      <div className="card mb-3">
        <div
          className="bg-holder d-none d-lg-block bg-card"
          style={{
            backgroundImage:
              "url(../../assets/img/icons/spot-illustrations/corner-4.png)"
          }}
        />
        <div className="card-body position-relative">
          <div className="row">
            <div className="col-lg-8">
              <h2 className="mb-0">Employee Analytics</h2>
            </div>
            <div className="col-lg-4 text-end">
              <Link to='/Employeeedit' className="">
                <button className="btn btn-primary">Update</button>
              </Link>
              <Link to='/Employeemanagement' className="mx-1">
                <button className="btn btn-success">Back</button>
              </Link>
            </div>
          </div>
        </div>

      </div>
      <div className="card mb-3">
        {/* <div
          className="bg-holder d-none d-lg-block bg-card"
          style={{
            backgroundImage:
              "url(../../assets/img/icons/spot-illustrations/corner-4.png)"
          }}
        />
        <div className="card-body position-relative">
          <div className="row">
            <div className="col-lg-4">
            <Link to="/Employee-Transaction-details" className="linkWithoutUnderline">
              <h4 className="mb-0 btn btn-primary w-100">Transactions</h4>
            </Link>
            </div>
            <div className="col-lg-4">
            
            <Link to="/Employee-Salary-Shlip-details" className="linkWithoutUnderline">
              <h4 className="mb-0 btn btn-danger w-100">Salary Ship</h4>
            </Link>
            </div>
             <div className="col-lg-4">
              <h4 className="mb-0 btn btn-warning w-100">Send Message</h4>
            </div> 

          </div>
        </div> */}

      </div>
      <div className="card mb-3">
        <div
          className="bg-holder d-none d-lg-block bg-card"
          style={{
            backgroundImage:
              "url(../../assets/img/icons/spot-illustrations/corner-4.png)"
          }}
        />
        {/*/.bg-holder*/}


      </div>
      <div className="row g-3 mb-3">
        <div className="col-md-6 col-xxl-3">
          <Link to='/Employee-Transaction-details' className="linkWithoutUnderline">
            <div className="card h-md-100 ecommerce-card-min-width">
              <div className="card-header pb-0">
                <h5 className="mb-0 mt-2 d-flex align-items-center">
                  Total Transactions
                </h5>
              </div>
              <div className="card-body d-flex flex-column justify-content-end">
                <div className="row align-items-center">
                  <div className="col pe-0">
                    <GrTransaction className="fs-4 text-primary" />
                    {/* <img src={company} alt="company" className="img-fluid"/> */}
                  </div>
                  <div className="col ps-0">
                    <p className="font-sans-serif lh-1 mb-1 fs-5 text-primary">
                      {AllEmployee}
                    </p>
                    {/* <span className="badge badge-subtle-success rounded-pill fs-11">
                +3.5%
              </span> */}
                  </div>
                  <div className="col-auto ps-0">
                    <div className="echart-bar-weekly-sales h-100" />
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div>
        <div className="col-md-6 col-xxl-3">
          <Link to='/Employee-Salary-Shlip-details' className="linkWithoutUnderline">
            <div className="card h-md-100">
              <div className="card-header pb-0">
                <h5 className="mb-0 mt-2">Total Salary Pay Ship </h5>
              </div>
              <div className="card-body d-flex flex-column justify-content-end">
                <div className="row justify-content-between">
                  <div className="col pe-0">
                    <FaDiagramSuccessor className="fs-4 text-success" />
                  </div>
                  <div className="col ps-0">
                    <div className="fs-5 fw-normal font-sans-serif text-700 lh-1 mb-1 ">
                      <p className="font-sans-serif lh-1 mb-1 fs-5 text-success">
                        {ActiveEmployee}
                      </p>
                    </div>
                    {/* <span className="badge rounded-pill fs-11 bg-200 text-primary">
                <span className="fas fa-caret-up me-1" />
                13.6%
              </span> */}
                  </div>
                  <div className="col-auto ps-0 mt-n4">
                    <div
                      className="echart-default-total-order"
                      data-echarts='{"tooltip":{"trigger":"axis","formatter":"{b0} : {c0}"},"xAxis":{"data":["Week 4","Week 5","Week 6","Week 7"]},"series":[{"type":"line","data":[20,40,100,120],"smooth":true,"lineStyle":{"width":3}}],"grid":{"bottom":"2%","top":"2%","right":"10px","left":"10px"}}'
                      data-echart-responsive="true"
                    />
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div>

      </div>
      <ToastContainer />

      <div className="card mb-3">
        <div
          className="bg-holder d-none d-lg-block bg-card"
          style={{
            backgroundImage:
              "url(../../assets/img/icons/spot-illustrations/corner-4.png)"
          }}
        />
        <div className="card-body position-relative">
          {employeeDetails && employeeDetails.map((business) => (
            <div className="row">
              <div className="col-lg-4 d-flex align-items-center">
                <h5 className="mb-0 capitalize d-flex align-items-center">
                  {business.profile === null ?

                    <>
                      <IoPersonSharp className="fs-6 text-dark me-1" />
                    </>
                    :

                    <img src={`${baseurl}/${business.profile}`} height={35}
                      width={35} alt="." className="img-fluid rounded-circle fs-6  me-1" />

                  }
                  {business.name}</h5>
              </div>
              <div className="col-lg-4 ">
                <h3 className="mb-0 text-success">  <MdOutlineAccountBalanceWallet className="fs-4 text-success" /> {business.user_credit}</h3>
              </div>
              <div className="col-lg-4 text-end">
                <h5 className="mb-0 text-info">  <MdOutlineMarkEmailRead className="fs-4 text-info" /> {business.email}</h5>
              </div>
            </div>
          ))}
        </div>
      </div>





      <div className="card-body">
        {employeeDetails && employeeDetails.map((employee) => (
          <React.Fragment key={employee.id}>
            {/* Personal Information Card */}
            <div className="card mb-3">
              <div className="card-header">
                <div className="card-title mb-0">Personal Information</div>
              </div>
              <div className="card-body bg-body-tertiary">
                <table className="table">
                  <tbody>
                    <tr>
                      <th scope="row">Name</th>
                      <td className="d-flex align-items-center" style={{ color: 'brown' }}>
                        <FaUserAlt className="me-2" />
                        <span className="fw-bold capitalize">{employee.name || 'N/A'}</span>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Email</th>
                      <td className="d-flex align-items-center" style={{ color: 'blue' }}>
                        <FaRegEnvelope className="me-2" />
                        <span className="fw-bold">{employee.email || 'N/A'}</span>
                        {employee.email && (
                          <a href={`mailto:${employee.email}`} className="ms-2">

                          </a>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Personal Email</th>
                      <td className="d-flex align-items-center" style={{ color: 'green' }}>
                        <FaRegEnvelope className="me-2" />
                        <span className="fw-bold">{employee.personal_email || 'N/A'}</span>
                        {employee.personal_email && (
                          <a href={`mailto:${employee.personal_email}`} className="ms-2">

                          </a>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Mobile</th>
                      <td className="d-flex align-items-center" style={{ color: 'purple' }}>
                        <FaPhoneAlt className="me-2" />
                        {/* <span className="fw-bold">{employee.mobile || 'N/A'}</span> */}
                        {employee.mobile && (
                          <a href={`tel:${employee.mobile}`} className="ms-2">
                            <span className="fw-bold">{employee.mobile || 'N/A'}</span>
                          </a>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Alternate Mobile</th>
                      <td className="d-flex align-items-center" style={{ color: 'teal' }}>
                        <FaPhoneAlt className="me-2" />

                        {employee.alternate_mobile !== "null" ? (
                          <a href={`tel:${employee.alternate_mobile}`} className="ms-2">
                            <span className="fw-bold">{employee.alternate_mobile || 'N/A'}</span>

                          </a>
                        ) : "N/A"}


                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Aadhar Number</th>
                      <td className="d-flex align-items-center" style={{ color: 'teal' }}>
                        <MdPermIdentity className="me-2" />

                        {employee.aadhar_no !== "null" ? (
                          <a href={`tel:${employee.aadhar_no}`} className="ms-2">
                            <span className="fw-bold">{employee.aadhar_no || 'N/A'}</span>

                          </a>
                        ) : "N/A"}


                      </td>
                    </tr>

                    <tr>
                      <th scope="row">Date of Birth</th>
                      <td className="d-flex align-items-center" style={{ color: 'red' }}>
                        <FaCalendarAlt className="me-2" />
                        <span className="fw-bold">{new Date(employee.dob || 'N/A').toLocaleDateString('en-GB', {
                          day: '2-digit',
                          month: '2-digit',
                          year: 'numeric',
                        }).replace(/\//g, '-')}</span>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Gender</th>
                      <td className="d-flex align-items-center">
                        <FaUserAlt className="me-2" style={{ color: 'navy' }} />
                        <span className="fw-bold capitalize">{employee.gender || 'N/A'}</span>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Marital Status</th>
                      <td className="d-flex align-items-center" style={{ color: 'gray' }}>
                        <BsPeople className="me-2" />
                        <span className="fw-bold capitalize">{employee.marital_status || 'N/A'}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className="card mb-3">
              <div className="card-header">
                <div className="card-title mb-0">Work Information</div>
              </div>
              <div className="card-body bg-body-tertiary">
                <table className="table">
                  <tbody>
                    <tr>
                      <th scope="row">Department ID</th>
                      <td className="d-flex align-items-center" style={{ color: 'indigo' }}>
                        {/* <FaRegAddressCard className="me-2"  /> */}
                        # <span className="fw-bold">{employee.dept_id || 'N/A'}</span>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Reporting Manager ID</th>
                      <td className="d-flex align-items-center" style={{ color: 'lime' }}>
                        {/* <FaUserAlt className="me-2"  /> */}
                        # <span className="fw-bold">{employee.reporting_manager_id || 'N/A'}</span>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Total Experience</th>
                      <td className="d-flex align-items-center" style={{ color: 'brown' }}>
                        <FaUserAlt className="me-2" />
                        <span className="fw-bold">{employee.total_experience || 'N/A'}</span>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Confirmation Date</th>
                      <td className="d-flex align-items-center" style={{ color: 'purple' }}>
                        <FaCalendarAlt className="me-2" />
                        <span className="fw-bold">{employee.confirmation_date || 'N/A'}</span>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Pre Work Experience</th>
                      <td className="d-flex align-items-center" style={{ color: 'teal' }}>
                        <FaUserAlt className="me-2" />
                        <span className="fw-bold">{employee.pre_work_exp || 'N/A'}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className="card mb-3">
              <div className="card-header">
                <div className="card-title mb-0">Bank Information</div>
              </div>
              <div className="card-body bg-body-tertiary">
                <table className="table">
                  <tbody>
                    <tr>
                      <th scope="row">Bank Account Number</th>
                      <td className="d-flex align-items-center" style={{ color: 'blue' }}>
                        <FaRegAddressCard className="me-2" />
                        <span className="fw-bold">{employee.bank_ac_no || 'N/A'}</span>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Account Holder Name</th>
                      <td className="d-flex align-items-center" style={{ color: 'navy' }}>
                        <FaUserAlt className="me-2" />
                        <span className="fw-bold capitalize">{employee.acc_holder_name || 'N/A'}</span>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Bank Branch</th>
                      <td className="d-flex align-items-center" style={{ color: 'teal' }}>
                        <PiAddressBook className="me-2" />
                        <span className="fw-bold capitalize">{employee.bank_branch || 'N/A'}</span>
                      </td>
                    </tr>
                    <tr>
                    <th scope="row">Payment Mode Type</th>
                    <td style={{ color: 'green', fontWeight: 'bold' }} className='capitalizeall'>{employee.payment_mode_type || 'N/A'}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </React.Fragment>
        ))}
      </div>




    </Container>
  );
}
