import React, { useState, useEffect } from "react";
import axios from "axios";
import { Container, Row, Col, Spinner } from "react-bootstrap";
import {Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { MdOutlineAccountBalanceWallet } from "react-icons/md";
export default function Addplan() {
  const broker_id = localStorage.getItem("broker_id");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const Addplan_id = localStorage.getItem("Addplan_id");
  const bid = localStorage.getItem("bid");
  const bidwallet = localStorage.getItem("bidwallet");

  const [newImage, setNewImage] = useState();
  const [user_credit, setuser_credit] = useState();
  const [fileimage, setFileimage] = useState();
  const [errors, setErrors] = useState({});
  const [data, setData] = useState([]);
  const [datacom, setDatacompny] = useState([]);
  const [datatype, setDatatype] = useState([]);
  const [dataplan, setDataplan] = useState([]);

  const [formData, setFormData] = useState({
    description: "",
    plan_duration: "",
    plan_duration_type: "",
    company_id: "",
    invested_amount: "",
    invest_plain_id: "",
  });

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setNewImage(file);
    setFileimage(URL.createObjectURL(file));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;



    if (name === "invested_amount" && (value.length > 15 || isNaN(value))) {
      return;
    }
    setFormData({ ...formData, [name]: value });
  };

  useEffect(() => {
    fetchData();
    fetchDatacompny();
    fetchData1();
    fetchDataine_plan();
   
  }, []);

  const fetchDataine_plan = async () => {
    try {
      const token = localStorage.getItem("bear_token");

      const formData = new FormData();
      formData.append("smalladmins_id", broker_id);

      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}small-admins-investment-active-plans-lists`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      };

      const response = await axios(config);
      setDataplan(response.data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchDatacompny = async () => {
    try {
      const token = localStorage.getItem("bear_token");

      const formData = new FormData();
      formData.append("smalladmins_id", broker_id);
      formData.append("status", "1");

      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}small-admins-company-lists`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      };

      const response = await axios(config);
      setDatacompny(response.data.data);
      
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchData = async () => {
    const token = localStorage.getItem("bear_token");

    try {
      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}small-admins-paln-duration`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      };

      const response = await axios(config);
      const rawData = response.data.data;

      // Transform the data into the expected format
      const transformedData = rawData.map((item) => ({
        id: item,
        name: `${item}`, // Assuming you want to display "Duration <number>"
      }));

      console.warn(transformedData);

      // Set transformed data
      setData(transformedData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchData1 = async () => {
    const token = localStorage.getItem("bear_token");

    try {
      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}small-admins-plan-duration-type`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      };

      const response = await axios(config);
      const rawData = response.data.data;

      const transformedData = rawData.map((item) => ({
        id: item,
        name: `${item}`,
      }));

      console.warn(transformedData);

      // Set transformed data
      setDatatype(transformedData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchData2 = async () => {
    try {
 

      // Fetch data from API
      const token = localStorage.getItem("bear_token");
      const broker_id = localStorage.getItem("broker_id");
      const customerBusinesset_id = localStorage.getItem("customerBusinesset_id");

      const formData = new FormData();
      formData.append("smalladmins_id", broker_id);
      formData.append("business_cust_id", bidwallet);

      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}view-small-admins-business-customer`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data"
        },
        data: formData
      };

      const response = await axios(config);
      const data = response.data.data;
      const data1 = response.data.url;


      console.warn("data",data[0].user_credit)
      setuser_credit(data[0].user_credit);
  

    } catch (error) {
   
    }
  };
  useEffect(() => {
    fetchData2();
  }, []);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const requiredFields = [
      { field: "invest_plain_id", name: "Invest Plan Name" },
      // { field: "company_id", name: "Company Name" },
      { field: "invested_amount", name: "Invested Amount" },
      // { field: "plan_duration", name: "Plan Duration" },
      // { field: "plan_duration_type", name: "Plan Duration Type" },
    ];

    let validationErrors = {};
    let hasError = false;

    for (const { field, name } of requiredFields) {
      if (!formData[field]) {
        validationErrors[field] = `Please fill in the ${name}.`;
        hasError = true;
      }
    }

    setErrors(validationErrors);

    if (hasError) {
      setLoading(false);
      return;
    }

    try {
      const token = localStorage.getItem("bear_token");
      const updatedFormData = new FormData();
      updatedFormData.append("smalladmins_id", broker_id);
      if(bid){  
        updatedFormData.append("cust_id", bid);
      }else{

        updatedFormData.append("cust_id", Addplan_id);
      }
      updatedFormData.append("status", "1");
      Object.keys(formData).forEach((key) => {
        updatedFormData.append(key, formData[key]);
      });
      updatedFormData.append("Plan_image", newImage);

      const config = {
        method: "post",
        // url: `${process.env.REACT_APP_API_URL}invest-more-small-admins-customers`,
        url: `${process.env.REACT_APP_API_URL}invest-more-small-admins-business-customers`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
        data: updatedFormData,
      };

      const response = await axios(config);
      const data = response.data.status;

      if (data === 1) {
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: response.data.message,
          // timer: 1500
        });
        setTimeout(() => {
          navigate("/Bussiness-Customer-management");
        }, 2000);
      } else {
        console.warn(response.data.message)
        if (user_credit <= formData.invested_amount || response.data.message.trim() === `Sorry you dont have sufficient balance please add amount in your wallet first ${user_credit}`) {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: `Insufficient amount: You only have ${user_credit} available.`,
            showCancelButton: true, 
            confirmButtonText: 'Add Fund',
            cancelButtonText: 'Cancel', 
          
          }).then((result) => {
            if (result.isConfirmed) {
          
              navigate('/Add-Bussiness-Transaction');
            } else if (result.isDismissed) {
       
              console.log('Action canceled');
            }
          });
        }
        
        else{
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: response.data.message,
            // timer: 2000
            
          });
        }
      }
    } catch (error) {
      console.warn("Error add-small-admins-customer:", error);
      if (error.response && error.response.data && error.response.data.error && error.response.data.error.email) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: error.response.data.error.email[0],
          // timer: 2000
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: "Failed to add customer",
          // timer: 2000
        });
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <div className="card mb-3">
        <div
          className="bg-holder d-none d-lg-block bg-card"
          style={{
            backgroundImage:
              "url(../../assets/img/icons/spot-illustrations/corner-4.png)",
          }}
        />
        <div className="card-body position-relative">
          <div className="row">
            <div className="col-lg-8">
              <h3 className="mb-0">Business Customer Investment Plan  <MdOutlineAccountBalanceWallet/>{user_credit}</h3>
            </div>
            <div className="col-lg-4 text-end">
             
             <Link to="/Customermanagement" className="mx-1">
               <button className="btn btn-success">Back</button>
             </Link>
           </div>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-body">
          <Container fluid>
            <Row>
            <div className="col-md-6 form_details mt-3">
                <div className="form-group local-forms">
                  <label className="form-label" htmlFor="invest_plain_id">
                    Invest Plan <span className="login-danger">*</span>
                  </label>
                  <select
                    className={`form-select ${errors.invest_plain_id ? 'input-error' : ''}`}
                    id="invest_plain_id"
                    name="invest_plain_id"
                    value={formData.invest_plain_id}
                    onChange={handleInputChange}
                  >
                    <option value="">Select Invest Plan</option>
                    {dataplan.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                 
                </div>
              </div>
              <div className="col-md-6 form_details mt-3">
                <div className="form-group local-forms ">
                  <label className="form-label" htmlFor="invested_amount">
                    Invested Amount <span className="login-danger">*</span>
                  </label>
                  <input
                    className={`form-control ${errors.invested_amount ? 'input-error' : ''}`}
                    id="invested_amount"
                    name="invested_amount"
                    type="text"
                    value={formData.invested_amount}
                    onChange={handleInputChange}
                  />
              
                </div>
              </div>
              {/* <div className="col-md-6 form_details mt-3">
                <div className="form-group local-forms">
                  <label className="form-label" htmlFor="plan_duration">
                    Plan Duration <span className="login-danger">*</span>
                  </label>
                  <select
                    className={`form-select ${errors.plan_duration ? 'input-error' : ''}`}
                    id="plan_duration"
                    name="plan_duration"
                    value={formData.plan_duration}
                    onChange={handleInputChange}
                  >
                    <option value="">Select Plan Duration</option>
                    {data.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
               
                </div>
              </div> */}
              {/* <div className="col-md-6 form_details mt-3">
                <div className="form-group local-forms">
                  <label className="form-label" htmlFor="plan_duration_type">
                    Plan Duration Type <span className="login-danger">*</span>
                  </label>
                  <select
                    className={`form-select ${errors.plan_duration_type ? 'input-error' : ''}`}
                    id="plan_duration_type"
                    name="plan_duration_type"
                    value={formData.plan_duration_type}
                    onChange={handleInputChange}
                  >
                    <option value="">Select Plan Duration Type</option>
                    {datatype.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                 
                </div>
              </div> */}
              {/* <div className="col-md-6 form_details mt-3">
                <div className="form-group local-forms">
                  <label className="form-label" htmlFor="company_id">
                    Company Name <span className="login-danger">*</span>
                  </label>
                  <select
                    className={`form-select ${errors.company_id ? 'input-error' : ''}`}
                    id="company_id"
                    name="company_id"
                    value={formData.company_id}
                    onChange={handleInputChange}
                  >
                    <option value="">Select Company</option>
                    {datacom.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                 
                </div>
              </div> */}
           
              {/* <div className="col-md-6 form_details mt-3">
                <div className="form-group local-forms">
                  <label className="form-label" htmlFor="Plan_image">
                    Plan Image
                  </label>
                  <input
                    className="form-control"
                    id="Plan_image"
                    name="Plan_image"
                    type="file"
                    onChange={handleImageChange}
                  />
                  {fileimage && <img src={fileimage} alt="Preview" />}
                </div>
              </div> */}
              <Col md={12}>
                <div className="mb-3 d-flex justify-content-end">
                  <button
                    onClick={handleSubmit}
                    disabled={loading}
                    className="btn btn-sm btn-primary"
                    type="submit"
                  >
                    Submit
                    {loading && (
                      <Spinner
                        animation="border"
                        style={{
                          width: "15px",
                          height: "15px",
                          marginLeft: "10px",
                        }}
                      />
                    )}
                  </button>
                </div>
              </Col>
            </Row>
            {/* <Row>
              <Col className="text-center mt-4">
                <button
                  type="submit"
                  className="btn btn-primary"
                  onClick={handleSubmit}
                  disabled={loading}
                >
                  {loading ? <Spinner animation="border" size="sm" /> : "Submit"}
                </button>
              </Col>
            </Row> */}
          </Container>
        </div>
      </div>
    </div>
  );
}
