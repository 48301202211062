import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { GiTakeMyMoney } from "react-icons/gi";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";

export default function CustomerinvestmentsInactive() {

  const [data, setData] = useState([]);
  const [baseurl, setbaseurl] = useState();
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState(''); // State to hold the search term
  const navigate = useNavigate();
  const custumerdetails_id = localStorage.getItem("custumerdetails");
  const [Allcompany, setCompany] = useState();
  const [Active, setActive] = useState();
  const [Inactive, setInactive] = useState();
  const [Expired, setExpired] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  
  const fetchData = async () => {
    try {
      setLoading(true);

      // Fetch data from API
      const token = localStorage.getItem("bear_token");
      const broker_id = localStorage.getItem("broker_id");

      const formData = new FormData();
      formData.append("smalladmins_id", broker_id);
      formData.append("status", '1');

      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}customer-small-admins-investments-inactive-lists`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data"
        },
        data: formData
      };

      const response = await axios(config);
      const data = response.data.data;
      const data1 = response.data.image_url;
      setbaseurl(data1);
      setCompany(response.data.totaluserinvestments);
      setActive(response.data.totalActiveuserinvestments);
      setInactive(response.data.totalInActiveuserinvestments);
      setExpired(response.data.totalexpiredinvestmentplans);
      setData(data);
      setLoading(false);

    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  

  const filteredData = data
  ? data.filter((item) =>
    item.customer_name &&
  item.customer_name.toLowerCase().includes(searchTerm.toLowerCase())
    )
  : [];

  const totalPages = Math.ceil(data.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedData = filteredData.slice(startIndex, endIndex);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const AprovedHandlechange = (cust_id, invest_plain_id, company_id) => {
    handleApproved(cust_id, invest_plain_id, company_id);
    console.warn(cust_id, invest_plain_id, company_id)
  };

  const handleApproved = async (cust_id, invest_plain_id, company_id) => {
    try {
      const token = localStorage.getItem("bear_token");
      const broker_id = localStorage.getItem("broker_id");

      const formData = new FormData();
      formData.append("smalladmins_id", broker_id);
      formData.append("cust_id", cust_id);
      formData.append("invest_plain_id", invest_plain_id);
      formData.append("company_id", company_id);

      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}approve-small-admins-customers-plans`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data"
        },
        data: formData
      };

      const response = await axios(config);
      const data = response.data;

      if (data.error === 1) {
        toast.error(data.message);

      } else if (data.data === 1) {
        toast.success(data.message);
        fetchData();
        navigate('/Customer-investments');
      } else {
        toast.error("Unexpected response from the server.");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.error("An error occurred while processing your request.");
    }
  };

  return (
    <>
       <div className="card mb-3">
        <div
          className="bg-holder d-none d-lg-block bg-card"
          style={{
            backgroundImage:
              "url(../../assets/img/icons/spot-illustrations/corner-4.png)"
          }}
        />
        <div className="card-body position-relative">
          <div className="row">
            <div className="col-lg-8">
              <h3 className="mb-0">Customer Investments Plan Analytics</h3>
            </div>
          </div>
        </div>
      </div>

      <div className="row g-3 mb-3">
        <div className="col-md-3 col-xxl-3">
          <Link to='/Customer-investments'className="linkWithoutUnderline">
            <div className="card h-md-100 ecommerce-card-min-width">
              <div className="card-header pb-0">
                <h5 className="mb-0 mt-2 d-flex align-items-center">Total</h5>
              </div>
              <div className="card-body d-flex flex-column justify-content-end">
                <div className="row align-items-center">
                  <div className="col pe-0">
                    <GiTakeMyMoney className="fs-4 text-primary" />
                  </div>
                  <div className="col ps-0">
                    <h5 className="font-sans-serif lh-1 mb-1 fs-5 text-primary">
                      {Allcompany}
                    </h5>
                  </div>
                  <div className="col-auto ps-0">
                    <div className="echart-bar-weekly-sales h-100" />
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div>
        <div className="col-md-3 col-xxl-3">
          <Link to='/Customer-investments-Active' className="linkWithoutUnderline">
            <div className="card h-md-100">
              <div className="card-header pb-0">
                <h5 className="mb-0 mt-2">Active dfdsf</h5>
              </div>
              <div className="card-body d-flex flex-column justify-content-end">
                <div className="row justify-content-between">
                  <div className="col pe-0">
                    <GiTakeMyMoney className="fs-4 text-success" />
                  </div>
                  <div className="col ps-0">
                    <h5 className="font-sans-serif lh-1 mb-1 fs-5 text-success">
                      {Active}
                    </h5>
                  </div>
                  <div className="col-auto ps-0 mt-n4">
                    <div
                      className="echart-default-total-order"
                      data-echarts='{"tooltip":{"trigger":"axis","formatter":"{b0} : {c0}"},"xAxis":{"data":["Week 4","Week 5","Week 6","Week 7"]},"series":[{"type":"line","data":[20,40,100,120],"smooth":true,"lineStyle":{"width":3}}],"grid":{"bottom":"2%","top":"2%","right":"10px","left":"10px"}}'
                      data-echart-responsive="true"
                    />
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div>
        <div className="col-md-3 col-xxl-3">
        <Link to='/Customer-investments-pending'className="linkWithoutUnderline">
          <div className="card h-md-100">
            <div className="card-header pb-0">
              <h5 className="mb-0 mt-2">Pending</h5>
            </div>
            <div className="card-body d-flex flex-column justify-content-end">
              <div className="row justify-content-between">
                <div className="col pe-0">
                  <GiTakeMyMoney className="fs-4 text-warning" />
                </div>
                <div className="col ps-0">
                  <h5 className="font-sans-serif lh-1 mb-1 fs-5 text-warning">
                    {Inactive}
                  </h5>
                </div>
                <div className="col-auto ps-0 mt-n4">
                  <div
                    className="echart-default-total-order"
                    data-echarts='{"tooltip":{"trigger":"axis","formatter":"{b0} : {c0}"},"xAxis":{"data":["Week 4","Week 5","Week 6","Week 7"]},"series":[{"type":"line","data":[20,40,100,120],"smooth":true,"lineStyle":{"width":3}}],"grid":{"bottom":"2%","top":"2%","right":"10px","left":"10px"}}'
                    data-echart-responsive="true"
                  />
                </div>
              </div>
            </div>
          </div>
          </Link>
        </div>
        {/* <div className="col-md-3 col-xxl-3">
          <Link to='/Customer-investments-Inactive' className="linkWithoutUnderline">
            <div className="card h-md-100">
              <div className="card-header pb-0">
                <h5 className="mb-0 mt-2">Inactive</h5>
              </div>
              <div className="card-body d-flex flex-column justify-content-end">
                <div className="row justify-content-between">
                  <div className="col pe-0">
                    <GiTakeMyMoney className="fs-4 text-secondary" />
                  </div>
                  <div className="col ps-0">
                    <h5 className="font-sans-serif lh-1 mb-1 fs-5 text-secondary">
                      {Active}
                    </h5>
                  </div>
                  <div className="col-auto ps-0 mt-n4">
                    <div
                      className="echart-default-total-order"
                      data-echarts='{"tooltip":{"trigger":"axis","formatter":"{b0} : {c0}"},"xAxis":{"data":["Week 4","Week 5","Week 6","Week 7"]},"series":[{"type":"line","data":[20,40,100,120],"smooth":true,"lineStyle":{"width":3}}],"grid":{"bottom":"2%","top":"2%","right":"10px","left":"10px"}}'
                      data-echart-responsive="true"
                    />
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div> */}
        

        <div className="col-md-3 col-xxl-3">
        <Link to='/Customer-investments-expired'className="linkWithoutUnderline">
          <div className="card h-md-100">
            <div className="card-header pb-0">
              <h5 className="mb-0 mt-2">Expired</h5>
            </div>
            <div className="card-body d-flex flex-column justify-content-end">
              <div className="row justify-content-between">
                <div className="col pe-0">
                  <GiTakeMyMoney className="fs-4 text-danger" />
                </div>
                <div className="col ps-0">
                  <h5 className="font-sans-serif lh-1 mb-1 fs-5 text-danger">
                    {Expired}
                  </h5>
                </div>
                <div className="col-auto ps-0 mt-n4">
                  <div
                    className="echart-default-total-order"
                    data-echarts='{"tooltip":{"trigger":"axis","formatter":"{b0} : {c0}"},"xAxis":{"data":["Week 4","Week 5","Week 6","Week 7"]},"series":[{"type":"line","data":[20,40,100,120],"smooth":true,"lineStyle":{"width":3}}],"grid":{"bottom":"2%","top":"2%","right":"10px","left":"10px"}}'
                    data-echart-responsive="true"
                  />
                </div>
              </div>
            </div>
          </div>
          </Link>
        </div>
      </div >
      
      <div className="card mb-3">
        <div className="card-header">
        <h5 className="mb-0">Normal Customer Investments Plan List</h5>
        </div>
        <div className="card-body bg-light">
          <div className="row">
            <div className="col-lg-6">
              <form className="row gx-2">
                <div className="col-sm-6 mb-3">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search by customer name"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
        
        <div className="card-body p-0">
          <div className="table-responsive">
            <table className="table table-sm mb-0 table-dashboard fs--1">
              <thead className="bg-200 text-900">
                <tr>
                  <th className="sort">#</th>
                  <th className="sort">Customer ID</th>
                  <th className="sort">Customer Name</th>
                  <th className="sort">Company Name</th>
                  <th className="sort">Status</th>
                  <th className="sort">Action</th>
                </tr>
              </thead>
              <tbody className="list" id="table-ticket-body">
                {paginatedData && paginatedData.length > 0 ? (
                  paginatedData.map((item, index) => (
                    <tr key={index}>
                      <td>{startIndex + index + 1}</td>
                      <td>{item.cust_id}</td>
                      <td>{item.customer_name}</td>
                      <td>{item.company_name}</td>
                      <td style={{ color: item.plan_status === 'pending' ? 'orange' : 'green' }}>
                        {item.plan_status === 'pending' ? 'Pending' : 'Approved'}
                      </td>
                      <td>
                     { item.plan_status != 'approve'  ? 
                        <button
                          onClick={() => AprovedHandlechange(item.cust_id, item.invest_plain_id, item.company_id)}
                          className="btn btn-sm btn-info"
                          disabled={item.plan_status === 'approve'}
                        >
                          Activate Now
                        </button>
                        :
                        <button
                         
                          className="btn btn-sm btn-success"
                          disabled
                        >
                          Activated
                        </button>
                       }
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="9" className="text-center py-4">
                      No data found.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          {totalPages > 1 && (
            <div className="pagination mt-3 mb-3 mx-3">
              <button
                className="btn btn-sm btn-light"
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                <IoIosArrowBack />
              </button>
              {[...Array(totalPages).keys()].map((pageNumber) => (
                <button
                  key={pageNumber + 1}
                  className={`btn btn-sm mx-1 ${currentPage === pageNumber + 1
                    ? "btn-light text-primary"
                    : "btn-light"
                    }`}
                  onClick={() => handlePageChange(pageNumber + 1)}
                >
                  {pageNumber + 1}
                </button>
              ))}
              <button
                className="btn btn-sm btn-light"
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                <IoIosArrowForward />
              </button>
            </div>
          )}
        </div>
        
    
      </div>
      <ToastContainer />
    </>
  );
}
