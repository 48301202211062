import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom';
import { FaRegEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { FaEye } from "react-icons/fa";
import { IoMdAdd } from "react-icons/io";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from 'sweetalert2';

export default function Investmentmanagement() {

  const [data, setData] = useState([]);
  const [baseurl, setbaseurl] = useState();
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState(''); // State to hold the search term
  const navigate = useNavigate();
  const custumerdetails_id = localStorage.getItem("custumerdetails");

  const fetchData = async () => {
    try {
      setLoading(true);

      // Fetch data from API
      const token = localStorage.getItem("bear_token");
      const broker_id = localStorage.getItem("broker_id");

      const formData = new FormData();
      formData.append("smalladmins_id", broker_id);
      formData.append("status", '1');

      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}small-admins-business-lists`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data"
        },
        data: formData
      };

      const response = await axios(config);
      const data = response.data.data;
      const data1 = response.data.image_url;
      setbaseurl(data1)
      // Set fetched data
      setData(data);
      setLoading(false);

    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  const Delete = async (id) => {
    const token = localStorage.getItem("bear_token");
    const broker_id = localStorage.getItem("broker_id");
    try {
      const result = await Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        // width:300,
        // height:10,
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      });
      if (result.isConfirmed) {
        setLoading(true);
        const formData = new FormData();
        formData.append("smalladmins_id", broker_id);
        formData.append("business_id", id);
  
        const config = {
          method: "post",
          url: `${process.env.REACT_APP_API_URL}delete-small-admins-business`,
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data"
          },
          data: formData
        };
  
        const response = await axios(config);
        const data = response.data.status;
        if (data === 1) {
          toast.success(response.data.message);
          setLoading(false);
          fetchData();
        } else {
          toast.error(response.data.message);
          setLoading(false);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };
  // const filteredData = data.filter(item =>
  //   item.name.toLowerCase().includes(searchTerm.toLowerCase())
  // );

  const filteredData = data
  ? data.filter((item) =>
    item.transaction_id &&
  item.name.toLowerCase().includes(searchTerm.toLowerCase())
    )
  : [];

  
  const handleClick = (id) => {
    localStorage.setItem('custumerdetails', id,);
    navigate('/Custumerdetails')
  }
  const handleClickadd = (id) => {
    navigate('/Addbusiness')
  }
  const handleClickinvestmentId = (id) => {
    localStorage.setItem('business_id',id)
    navigate('/BusinessetView')
    // alert("jgkjfgj")
  }
  const handleClickinvestmenteditId = (id) => {
    localStorage.setItem('businessedit_id',id)
    navigate('/Businessedit')
    // alert("jgkjfgj")
  }
  return (
    <>
      <div className="card mb-3">
        <div
          className="bg-holder d-none d-lg-block bg-card"
          style={{
            backgroundImage:
              "url(../../assets/img/icons/spot-illustrations/corner-4.png)"
          }}
        />
        {/*/.bg-holder*/}
        <div className="card-body position-relative">
          <div className="row">
            <div className="col-lg-8">
              <h3 className="mb-0">Business Registration</h3>
            </div>
            <div className="col md-4 d-flex justify-content-end border-0 shadow-none">
              <button type="button" className="btn btn-sm btn-success" onClick={handleClickadd}>Add Businesse</button>

            </div>
          </div>
        </div>

      </div>
      <div
        className="card"
        id="ticketsTable"
        data-list='{"valueNames":["client","subject","status","priority","agent"],"page":11,"pagination":true,"fallback":"tickets-table-fallback"}'
      >
        <div className="card-header justify-content-between d-flex border-bottom border-200">
          <div className="justify-content-end d-flex w-100">
            <form>

              <div className="input-group input-search-width">
                <input
                  className="form-control form-control-sm shadow-none search"
                  type="search"
                  placeholder="Search by name"
                  aria-label="search"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
                <button className="btn btn-sm btn-outline-secondary border-300 hover-border-secondary">
                  <span className="fa fa-search fs-10 text-dark" />
                </button>
              </div>
            </form>
          </div>

        </div>
        {loading &&
          <div id="preloader">
            <div id="loading-center">
              <div className="loader">
                <div className="waviy">
                  <img
                    src="https://swaninvestment.in/frontant/assets/img/loader.png"
                    alt="Loader image"
                  />
                </div>
              </div>
            </div>
          </div>}
        <div className="card-body p-0">
          <div className="table-responsive scrollbar">
            <table className="table table-sm mb-0 fs-10 table-view-tickets">
              <thead className="bg-body-tertiary">
                <tr>
                  <th className="text-800 sort align-middle" data-sort="GSTIN">
                    sr.no
                  </th>
                  {/* <th className="text-800 sort align-middle" data-sort="GSTIN">
                  Cust Id
                  </th> */}
                  <th className="text-800 sort align-middle">Name</th>
                  <th className="text-800 sort align-middle">account no</th>
                  <th className="text-800 sort align-middle">ifsc</th>
                  <th className="text-800 sort align-middle">sub email</th>
                  <th className="text-800 sort align-middle">total invested</th>
                  <th className="text-800 sort align-middle">status</th>
                  <th className="text-800 sort align-middle">Action</th>
                </tr>
              </thead>
              <tbody className="list" id="table-ticket-body">
                {data && data.length > 0 ? (
                  data.map((item, index) => (
                    <tr>
                      <td>{index + 1}</td>
                      {/* <td>{item.id}</td> */}
                      <td>{item.ac_holder_name}</td>
                      <td>{item.account_no}</td>
                      <td>{item.ifsc}</td>
                      <td>{item.sub_admin_email}</td>
                      <td>{item.total_money_invested}</td>
                      <td style={{ color: item.status === 1 ? 'green' : 'red' }}>
                        {item.status === 1 ? 'active' : 'inactive'}
                      </td>


                      {/* <td>
                      <small className="badge rounded badge-subtle-success false">
                        Active
                      </small>
                    </td> */}
                      <td>
                        <div className='d-flex gap-1 action_button'>
                          <OverlayTrigger
                            key="Edit"
                            placement="top"
                            overlay={<Tooltip id={`Edit`}>Edit</Tooltip>}
                          >
                            <button onClick={() => handleClickinvestmenteditId(item.id)} style={{ '--i': '#3498db' }}> <FaRegEdit className="color_link" /></button>
                          </OverlayTrigger>


                          <OverlayTrigger
                          key="View"
                          placement="top"
                          overlay={<Tooltip id={`View`}>View</Tooltip>}
                        >
                          <button type="button" onClick={() => handleClickinvestmentId(item.id)} style={{ '--i': '#07bc0c' }}><FaEye className="color_link" />
                          </button>
                        </OverlayTrigger>

                          {/* <OverlayTrigger
                            key="Update"
                            placement="top"
                            overlay={<Tooltip id={`Update`}>Add Plan</Tooltip>}
                          >
                            <button type="button" style={{ '--i': '#07bc0c' }} onClick={handleClick}>
                              <IoMdAdd className="color_link" />
                            </button>
                          </OverlayTrigger> */}
                          <OverlayTrigger

                            key="Delete"
                            placement="top"
                            overlay={<Tooltip id={`Delete`}>Delete</Tooltip>}
                          >
                            <Link style={{ '--i': 'red' }} onClick={() => {
                              Delete(item.id);
                            }}> <MdDelete className="color_link" /></Link>
                          </OverlayTrigger>



                        </div>


                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="12" className="text-center py-4">
                      No data found.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <div className="text-center d-none" id="tickets-table-fallback">
              <p className="fw-bold fs-8 mt-3">No ticket found</p>
            </div>
          </div>
        </div>
        <div className="card-footer">
          <div className="d-flex justify-content-center">
            <button
              className="btn btn-sm btn-falcon-default me-1 disabled"
              type="button"
              title="Previous"
              data-list-pagination="prev"
              disabled=""
            >
              <span className="fas fa-chevron-left" />
            </button>
            <ul className="pagination mb-0">
              <li className="active">
                <button className="page" type="button" data-i={1} data-page={11}>
                  1
                </button>
              </li>
              <li>
                <button className="page" type="button" data-i={2} data-page={11}>
                  2
                </button>
              </li>
              <li>
                <button className="page" type="button" data-i={3} data-page={11}>
                  3
                </button>
              </li>
            </ul>
            <button
              className="btn btn-sm btn-falcon-default ms-1"
              type="button"
              title="Next"
              data-list-pagination="next"
            >
              <span className="fas fa-chevron-right" />
            </button>
          </div>
        </div>
      </div>
      <ToastContainer/>
    </>

  )
}
