import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom';
import Swal from "sweetalert2";

export default function AddStrategies() {
    const navigate = useNavigate();
    const broker_id = localStorage.getItem("broker_id");
    const [fileimage, setFileimage] = useState("");
    const [newImage, setNewImage] = useState(null);
    const [formData, setFormData] = useState({
        smalladmins_id: broker_id,
        name: "",
        smalladmins_name: "",
        status: "1" , 
    });
    const [profile, setProfile] = useState(null);
    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData({ ...formData, [id]: value });
        setErrors((prevErrors) => ({ ...prevErrors, [id]: null }));
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setProfile(file);
        setFileimage(URL.createObjectURL(file));
        setNewImage(file);
    };

    const handleValidation = () => {
        const requiredFields = [
            { field: "name", name: "Company name" },
            { field: "Strategies", name: "Strategies" }
        ];

        let validationErrors = {};
        let hasError = false;

        for (const { field, name } of requiredFields) {
            if (!formData[field]) {
                validationErrors[field] = `${name} is required`;
                hasError = true;
            }
        }

        setErrors(validationErrors);
        return !hasError;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const token = localStorage.getItem("bear_token");

        if (!handleValidation()) {
            return;
        }

        const dataUpdate = new FormData();
        dataUpdate.append("smalladmins_id", broker_id);
        dataUpdate.append("name", formData.name);
        dataUpdate.append("smalladmins_name", formData.smalladmins_name);
        dataUpdate.append("status", formData.status);

        if (newImage) {
            dataUpdate.append("profile", newImage);
        }

        try {
            const config = {
                method: "post",
                url: `${process.env.REACT_APP_API_URL}small-admins-add-strategies`,
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "multipart/form-data",
                },
                data: dataUpdate,
            };

            const response = await axios(config);

            if (response.status === 201) {
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: response.data.message,
                    // timer: 1500
                });

                setTimeout(() => {
                    navigate("/Straties");
                }, 2000);
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: response.data.message,
                    // timer: 2000
                });
            }
        } catch (error) {
            console.error("Error:", error);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: "An error occurred while submitting the form.",
                // timer: 2000
            });
        }
    };

    return (
        <>
            <div className="card mb-3">
                <div
                    className="bg-holder d-none d-lg-block bg-card"
                    style={{
                        backgroundImage:
                            "url(../../assets/img/icons/spot-illustrations/corner-4.png)",
                    }}
                />
                <div className="card-body position-relative">
                    <div className="row">
                        <div className="col-lg-8">
                            <h3 className="mb-0">Add Strategies</h3>
                        </div>
                        <div className="col-lg-4 text-end">
                            <Link to="/Companymangement" className="">
                                <button className="btn btn-success">Back</button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card">
                <div className="card-body">
                    <form className="row g-3" onSubmit={handleSubmit}>
                        <div className="col-md-6 form_details">
                            <div className="form-group local-forms">
                                <label>
                                Strategies  Name <span className="login-danger">*</span>
                                </label>
                                <input
                                    className={`form-control ${errors.name ? "input-error" : ""}`}
                                    id="name"
                                    type="text"
                                    value={formData.name}
                                    onChange={handleChange}
                                />
                                {errors.name && <div className="text-danger">{errors.name}</div>}
                            </div>
                        </div>

                      


                        <div className="col-md-6 form_details">
                            <div className="form-group local-forms">
                                <label className="form-label" htmlFor="smalladmins_name">
                                Small Admins Name <span className="login-danger">*</span>
                                </label>
                                <input
                                    className={`form-control ${errors.smalladmins_name ? "input-error" : ""}`}
                                    id="smalladmins_name"
                                    type="text"
                                    value={formData.smalladmins_name}
                                    onChange={handleChange}
                                />
                                {errors.smalladmins_name && <div className="text-danger">{errors.smalladmins_name}</div>}
                            </div>
                        </div>

                        {/* <div className="col-md-6 form_details">
                            <div className="form-group local-forms">
                                <p className="form-label" htmlFor="profile">
                                    Upload Company Photo (150px X 150px)
                                </p>
                                <input
                                    className="form-control"
                                    id="profile"
                                    name="profile"
                                    type="file"
                                    onChange={handleFileChange}
                                />
                            </div>
                        </div>
                        {fileimage && (
                            <div className="mt-3">
                                <img
                                    src={fileimage}
                                    alt="Selected profile"
                                    style={{ height: "100px" }}
                                />
                            </div>
                        )} */}
                        <div className="col-12 d-flex justify-content-end">
                            <button className="btn btn-primary" type="submit">
                                Submit
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}
