import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';


import { MdOutlineAccountBalanceWallet } from "react-icons/md";

import company from "../../assets/img/icons/building.png"
import { GiReceiveMoney,GiTakeMyMoney ,GiReturnArrow } from "react-icons/gi";
import { IoMdPersonAdd } from "react-icons/io";
import { Link } from "react-router-dom";
import { MdOutlineMarkEmailRead } from "react-icons/md";
import { IoPersonSharp } from "react-icons/io5";
import { FaUserAlt, FaRegEnvelope, FaRegBuilding, FaPhoneAlt, FaRegAddressCard, FaBuilding } from 'react-icons/fa';
import { RiLockPasswordFill } from "react-icons/ri";
export default function InvestmentView() {
  const { id } = useParams(); 
  const navigate = useNavigate();
  const [business, setBusiness] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [baseurl, setbaseurl] = useState();
  const [data, setData] = useState([]);
  const [AllEmployee, setAllEmployee] = useState(''); 
  const [ActiveEmployee, setActiveEmployee] = useState(''); 
  const [InactiveEmployee, setInactiveEmployee] = useState(''); 
  const fetchDatalist = async () => {
    try {
      setLoading(true);


      const token = localStorage.getItem("bear_token");
      const broker_id = localStorage.getItem("broker_id");

      const formData = new FormData();
      formData.append("smalladmins_id", broker_id);
      formData.append("status", "1");

      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}small-admins-customer-lists`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      };

      const response = await axios(config);
   
   
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDatalist();
  }, []);
  const fetchData = async () => {
    try {
      setLoading(true);

      // Fetch data from API
      const token = localStorage.getItem("bear_token");
      const broker_id = localStorage.getItem("broker_id");
      const customer_id = localStorage.getItem("customer_id");

      const formData = new FormData();
      formData.append("smalladmins_id", broker_id);
      formData.append("cust_id", customer_id);

      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}view-small-admins-customers`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data"
        },
        data: formData
      };

      const response = await axios(config);
      const data = response.data.data;
      const totalEmp = response.data.totalNarmalCustomerInvestedPlansAmount;
      const ActiveEmployee = response.data.totalNarmalCustomerInvestedPlansCount;
      const InactiveEmployee = response.data.totalNarmalCustomerInvestedreturnsGeneratedAmount;

setAllEmployee(totalEmp)
setActiveEmployee(ActiveEmployee)
setInactiveEmployee(InactiveEmployee)
      const data1 = response.data.url;
      setbaseurl(data1)
      // Set fetched data
      setData(data);
      console.warn(data)
      setLoading(false);

    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <>
    <div className="card mb-3">
        <div
          className="bg-holder d-none d-lg-block bg-card"
          style={{
            backgroundImage:
              "url(../../assets/img/icons/spot-illustrations/corner-4.png)"
          }}
        />
        <div className="card-body position-relative">
          <div className="row">
            <div className="col-lg-8">
              <h2 className="mb-0">Noramal Customer Analytics</h2>
            </div>
            <div className="col-lg-4 text-end">
            <Link to='/Custumerdetailsedit' className="">
              <button className="btn btn-primary">Update</button>
            </Link>
            <Link to='/Customermanagement' className=" mx-1">
              <button className="btn btn-success">Back</button>
            </Link>
            </div>
          </div>
        </div>

      </div>
    <div className="card mb-3">
        <div
          className="bg-holder d-none d-lg-block bg-card"
          style={{
            backgroundImage:
              "url(../../assets/img/icons/spot-illustrations/corner-4.png)"
          }}
        />
        <div className="card-body position-relative">
          <div className="row">
            <div className="col-lg-4">
            <Link to="/Customer-Transaction-Details" className="linkWithoutUnderline">
              <h4 className="mb-0 btn btn-primary w-100">Transactions</h4>
            </Link>
            </div>
            <div className="col-lg-4">
              <h4 className="mb-0 btn btn-success w-100">Message Histories</h4>
            </div>
            <div className="col-lg-4">
              <h4 className="mb-0 btn btn-warning w-100">Send Message</h4>
            </div>

          </div>
        </div>

      </div>
      
      <div className="row g-3 mb-3">
        <div className="col-md-4 col-xxl-3">
      {/* <Link to='/Customermanagement' className="linkWithoutUnderline"> */}
      {/* <Link to='#' className="linkWithoutUnderline"> */}
          <div className="card h-md-100 ecommerce-card-min-width">
            <div className="card-header pb-0">
              <h5 className="mb-0 mt-2 d-flex align-items-center">
                Total Invested Amount
              </h5>
            </div>
            <div className="card-body d-flex flex-column justify-content-end">
              <div className="row align-items-center">
                <div className="col-2 d-flex gap-2 pe-0">
                  <GiReceiveMoney className="fs-4 text-primary" />
                </div>
                <div className="col-10 ps-0">
                  <p className="font-sans-serif lh-1 mb-1 fs-5 text-primary mx-2">
                    {AllEmployee}
                  </p>
                </div>
                <div className="col-auto ps-0">
                  <div className="echart-bar-weekly-sales h-100" />
                </div>
              </div>
            </div>
          </div>
        {/* </Link> */}
        </div>

        <div className="col-md-4 col-xxl-3">
        <Link to='/Single-Customer-Plan' className="linkWithoutUnderline">
          <div className="card h-md-100">
            <div className="card-header pb-0">
              <h5 className="mb-0 mt-2"> Total Invested Plan</h5>
            </div>
            <div className="card-body d-flex flex-column justify-content-end">
              <div className="row justify-content-between">
                <div className="col-2 d-flex gap-2 pe-0">
                  <GiTakeMyMoney className="fs-4 text-success" />
                </div>
                <div className="col ps-0">
                  <div className="fs-5 fw-normal font-sans-serif text-700 lh-1 mb-1 ">
                    <p className="font-sans-serif lh-1 mb-1 fs-5 text-success mx-2">
                      {ActiveEmployee}
                    </p>
                  </div>           
                </div>
                {/* <div className="col-10 ps-0 mx-1">
                  <div className="fs-5 fw-normal font-sans-serif text-700 lh-1 mb-1 ">
                    <p className="font-sans-serif lh-1 mb-1 fs-5 text-success">
                      {ActiveEmployee}
                    </p>
                  </div>
                </div> */}
                <div className="col-auto ps-0 mt-n4">
                  <div
                    className="echart-default-total-order"
                    data-echarts='{"tooltip":{"trigger":"axis","formatter":"{b0} : {c0}"},"xAxis":{"data":["Week 4","Week 5","Week 6","Week 7"]},"series":[{"type":"line","data":[20,40,100,120],"smooth":true,"lineStyle":{"width":3}}],"grid":{"bottom":"2%","top":"2%","right":"10px","left":"10px"}}'
                    data-echart-responsive="true"
                  />
                </div>
              </div>
            </div>
          </div>
        </Link>
        </div>
        <div className="col-md-4 col-xxl-3">
        {/* <Link to='#' className="linkWithoutUnderline"> */}

          <div className="card h-md-100">
            <div className="card-header pb-0">
              <h5 className="mb-0 mt-2"> Total Return Generated</h5>
            </div>
            <div className="card-body d-flex flex-column justify-content-end">
              <div className="row justify-content-between">
                <div className="col-2 d-flex gap-2 pe-0">
                  <GiReturnArrow  className="fs-4 text-info" />
                </div>
                <div className="col-10 ps-0">
                  <div className="fs-5 fw-normal font-sans-serif text-700 lh-1 mb-1 ">
                    <p className="font-sans-serif lh-1 mb-1 fs-5  text-info mx-2">
                      {InactiveEmployee}
                    </p>
                  </div>
                </div>
                <div className="col-auto ps-0 mt-n4">
                  <div
                    className="echart-default-total-order"
                    data-echarts='{"tooltip":{"trigger":"axis","formatter":"{b0} : {c0}"},"xAxis":{"data":["Week 4","Week 5","Week 6","Week 7"]},"series":[{"type":"line","data":[20,40,100,120],"smooth":true,"lineStyle":{"width":3}}],"grid":{"bottom":"2%","top":"2%","right":"10px","left":"10px"}}'
                    data-echart-responsive="true"
                  />
                </div>
              </div>
            </div>
          </div>
        {/* </Link> */}
        </div>
      </div>
      <div className="card mb-3">
        <div
          className="bg-holder d-none d-lg-block bg-card"
          style={{
            backgroundImage:
              "url(../../assets/img/icons/spot-illustrations/corner-4.png)"
          }}
        />
        <div className="card-body position-relative">
        {data && data.map((business)=>(
          <div className="row">
            <div className="col-lg-4 d-flex align-items-center">
            <h5 className="mb-0 capitalize d-flex align-items-center"> 
            {business.profile_image === null ? 
            
            <>
            <IoPersonSharp className="fs-6 text-dark me-1" />
            </>
            :
            
            <img  src={`${baseurl}/${business.profile_image}`}   height={35}
            width={35} alt="plan_image" className="img-fluid rounded-circle fs-6  me-1"  />
            }  {business.first_name}</h5>
            </div>
            <div className="col-lg-4 ">
           <h3 className="mb-0 text-success">  <MdOutlineAccountBalanceWallet   className="fs-4 text-success" /> {business.user_credit}</h3>
            </div>
            <div className="col-lg-4 text-end">
           <h6 className="mb-0 text-info">  <MdOutlineMarkEmailRead className="fs-4 text-info" /> {business.email}</h6>
            </div>
          </div>
        ))}
        </div>
      </div>

  
  <div className="card-body">
    {data && data.map((business) => (
      <React.Fragment key={business.cust_id}>
        <div className="card mb-3">
          <div className="card-header">
            <div className="card-title mb-0">Customer Details</div>
          </div>
          <div className="card-body bg-body-tertiary">
            <table className="table">
              <tbody>
                <tr>
                  <th scope="row">Customer ID</th>
                  <td className="d-flex align-items-center">
                    <span className="fw-bold">#{business.cust_id}</span>
                  </td>
                </tr>
                <tr>
                  <th scope="row">Full Name</th>
                  <td className="d-flex align-items-center"style={{ color: 'green' }}>
                    <FaUserAlt className="me-2"  />
                    <span className="fw-bold capitalize">{business.first_name}</span>
                  </td>
                </tr>
                <tr>
                  <th scope="row">Email</th>
                  <td className="d-flex align-items-center" style={{ color: 'blue' }}>
                    <FaRegEnvelope className="me-2"  />
                    <span className="fw-bold">{business.email}</span>
                  </td>
                </tr>
                <tr>
                  <th scope="row">Phone Number</th>
                  <td className="d-flex align-items-center"style={{ color: 'purple' }}>
                    <FaPhoneAlt className="me-2"  />
                    <span className="fw-bold">{business.phone}</span>
                  </td>
                </tr>
                <tr>
                  <th scope="row">Alternative Phone Number</th>
                  <td className="d-flex align-items-center" style={{ color: 'teal' }}>
                    <FaPhoneAlt className="me-2" />
                    <span className="fw-bold">{business.altername_phone}</span>
                  </td>
                </tr>
                {/* <tr>
                  <th scope="row">Password</th>
                  <td className="d-flex align-items-center" style={{ color: 'teal' }}>
                    <RiLockPasswordFill className="me-2" />
                    <span className="fw-bold">{business.password}</span>
                  
                  </td>
                </tr> */}
                <tr>
                  <th scope="row">Company Name</th>
                  <td className="d-flex align-items-center" style={{ color: 'red' }}>
                    <FaRegBuilding className="me-2"  />
                    <span className="fw-bold capitalize">{business.company_name}</span>
                  </td>
                </tr>
            
              </tbody>
            </table>
          </div>
        </div>

      </React.Fragment>
    ))}
  </div>


    </>
      
 
  );
}
