import React, { useState, useEffect } from "react";
import axios from "axios";
import { Container, Row, Col, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export default function CustomerPlan() {
    const broker_id = localStorage.getItem("broker_id");
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const custumerdetails_id = localStorage.getItem("custumerdetails");
    const [data, setData] = useState([]);
    const [dataplan, setDataplan] = useState([]);

    const [errors, setErrors] = useState({});

    const [formData, setFormData] = useState({
        company_id: "",
        invest_plain_id: "",   
    });

 


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const fetchData = async () => {
        try {

            // Fetch data from API
            const token = localStorage.getItem("bear_token");
            const broker_id = localStorage.getItem("broker_id");

            const formData = new FormData();
            formData.append("smalladmins_id", broker_id);
            formData.append("status", '1');

            const config = {
                method: "post",
                url: `${process.env.REACT_APP_API_URL}small-admins-company-lists`,
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "multipart/form-data"
                },
                data: formData
            };

            const response = await axios(config);
            const data = response.data.data;

            // Set fetched data
            setData(data);

        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };
    const fetchDataine_plan = async () => {
        try {

            // Fetch data from API
            const token = localStorage.getItem("bear_token");
            const broker_id = localStorage.getItem("broker_id");

            const formData = new FormData();
            formData.append("smalladmins_id", broker_id);
            formData.append("status", '1');

            const config = {
                method: "post",
                url: `${process.env.REACT_APP_API_URL}small-admins-investment-plans-lists`,
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "multipart/form-data"
                },
                data: formData
            };

            const response = await axios(config);
            const data = response.data.data;

            // Set fetched data
            setDataplan(data);

        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        fetchData();
        fetchDataine_plan();
    }, []);
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        const requiredFields = [
            { field: 'company_id', name: 'company Name' },
            { field: 'invest_plain_id', name: 'invest plain Name' },
           
        ];   

        let validationErrors = {};
        let hasError = false;

        for (const { field, name } of requiredFields) {
            if (!formData[field]) {
                validationErrors[field] = `Please fill in the ${name}.`;
                hasError = true;
            }
        }
        setErrors(validationErrors);

        if (hasError) {
            setLoading(false);
            return;
        }

        try {
            const token = localStorage.getItem("bear_token");
            const updatedFormData = new FormData();
            updatedFormData.append("smalladmins_id", broker_id);
            updatedFormData.append("cust_id", custumerdetails_id);
            // updatedFormData.append("status", '1');
            // updatedFormData.append("plan_id", '4');
            Object.keys(formData).forEach(key => {
                updatedFormData.append(key, formData[key]);
            });
        

            const config = {
                method: "post",
                url: `${process.env.REACT_APP_API_URL}invest-more-small-admins-customers`,
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "multipart/form-data"
                },
                data: updatedFormData
            };

            const response = await axios(config);
            const data = response.data.status;

            if (data === 1) {
                toast.success(response.data.message); 
                setTimeout(() => {
                    navigate('/Customermanagement');
                  }, 2000);
                // navigate('/Custumerdetails');
            } else {
                toast.error(response.data.message); 
            }
        } catch (error) {
            console.error("Error add-small-admins-customer:", error);
            // toast.error(response.data.error); 
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            <div className="card mb-3">
                <div className="bg-holder d-none d-lg-block bg-card" style={{ backgroundImage: "url(../../assets/img/icons/spot-illustrations/corner-4.png)" }} />
                <div className="card-body position-relative">
                    <div className="row">
                        <div className="col-lg-8">
                            <h3 className="mb-0"> Customer Plan</h3>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card">
                <div className="card-body">
                    <Container fluid>
                        <Row>
                            {/* <Col md={6}>
                                <div className="mb-3">
                                    <label className="form-label" htmlFor="first_name">First Name</label>
                                    <input className="form-control" id="first_name" type="text" name="first_name" value={formData.first_name} onChange={handleInputChange} />
                                    {errors.first_name && <div className="text-danger">{errors.first_name}</div>}
                                </div>
                            </Col> */}
                            <Col md={6}>
                                <div className="mb-3">
                                    <label className="form-label" htmlFor="company_id">Select Company</label>
                                    <select
                                        className="form-select"
                                        id="company_id"
                                        name="company_id"
                                        value={formData.company_id}
                                        onChange={handleInputChange}
                                    >
                                        <option value="">Select Company Name</option>
                                        {data.map((item) => (
                                            <option key={item.id} value={item.id}>
                                                {item.name}
                                            </option>
                                        ))}
                                    </select>
                                    {errors.company_id && <div className="text-danger">{errors.company_id}</div>}
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="mb-3">
                                    <label className="form-label" htmlFor="invest_plain_id">Invest Plan Name</label>
                                    <select
                                        className="form-select"
                                        id="invest_plain_id"
                                        name="invest_plain_id"
                                        value={formData.invest_plain_id}
                                        onChange={handleInputChange}
                                    >
                                        <option value="">Select Invest Plan Name</option>
                                        {dataplan.map((item) => (
                                            <option key={item.id} value={item.id}>
                                                {item.name}
                                            </option>
                                        ))}
                                    </select>
                                    {errors.invest_plain_id && <div className="text-danger">{errors.invest_plain_id}</div>}
                                </div>
                            </Col>
                            
                            <Col md={12}>
                                <div className="mb-3 d-flex justify-content-end">
                                    <button onClick={handleSubmit} disabled={loading} className="btn btn-sm btn-primary" type="submit">
                                        Submit
                                        {loading && <Spinner animation="border" style={{ width: '15px', height: '15px', marginLeft: '10px' }} />}
                                    </button>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
            <ToastContainer/>
        </div>
    );
}
