import React, { useState, useEffect } from "react";
import axios from "axios";
import { Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import Swal from "sweetalert2";
import { Link } from "react-router-dom";
export default function Addsmalladmins_customer() {
  const broker_id = localStorage.getItem("broker_id");
  const company_id = localStorage.getItem("company_id");
  const company_Name = localStorage.getItem("company_Name");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [newImage, setNewImage] = useState(null);
  const [fileimage, setFileimage] = useState(null);
  const [errors, setErrors] = useState({});
  const [data, setData] = useState([]);
  const [dataplan, setDataplan] = useState([]);

  const [formData, setFormData] = useState({
    first_name: "",
    aadharnumber: "",
    pannumber: "",
    invested_amount: "",
    email: "",
    address: "",
    phone: "",
    altername_phone: "",
    profile_image: null,
    company_id: company_id,
    plan_id: "",
    marital_status: "",
    password: "",
    gender: "",
    profile_image:null,
  });

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setFileimage(URL.createObjectURL(file));
    setNewImage(file);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "phone" && (value.length > 10 || isNaN(value))) {
      return;
    }
    if (name === "altername_phone" && (value.length > 10 || isNaN(value))) {
      return;
    }

    let formattedValue = value;

    if (name === "aadharnumber") {
      formattedValue = value.replace(/\D/g, "").slice(0, 12);
    } else if (name === "pannumber") {
      formattedValue = value
        .toUpperCase()
        .replace(/[^A-Z0-9]/g, "")
        .slice(0, 10);
    }
    if (name === "password") {
      formattedValue = value.slice(0, 18);
    } else if (name === "password") {
    }
    setFormData({ ...formData, [name]: formattedValue });

    setErrors((prevErrors) => ({ ...prevErrors, [name]: null }));
  };
  const fetchData = async () => {
    try {
      const token = localStorage.getItem("bear_token");

      const formData = new FormData();
      formData.append("smalladmins_id", broker_id);
      formData.append("status", "1");

      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}small-admins-active-company-lists`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      };

      const response = await axios(config);
      setData(response.data.data);
      console.warn("response.data.data", response.data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchDataine_plan = async () => {
    try {
      const token = localStorage.getItem("bear_token");

      const formData = new FormData();
      formData.append("smalladmins_id", broker_id);
      formData.append("status", "1");

      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}small-admins-investment-active-plans-lists`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      };

      const response = await axios(config);
      setDataplan(response.data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
    fetchDataine_plan();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
  
    const requiredFields = [
      { field: "first_name", name: "Full Name" },
      { field: "pannumber", name: "Pan Number" },
      { field: "aadharnumber", name: "Aadhar Number" },
      { field: "email", name: "Email" },
      { field: "phone", name: "Phone" },
      { field: "password", name: "password" },
    ];
  
    let validationErrors = {};
    let hasError = false;
  
    for (const { field, name } of requiredFields) {
      if (!formData[field]) {
        validationErrors[field] = true;
        hasError = true;
      }
    }
  
    if (formData.phone.length !== 10) {
      validationErrors.phone = true;
      hasError = true;
    }
  
    setErrors(validationErrors);
  
    if (hasError) {
      setLoading(false);
      return;
    }
  
    try {
      const token = localStorage.getItem("bear_token");
      const updatedFormData = new FormData();
      updatedFormData.append("smalladmins_id", broker_id);
      updatedFormData.append("status", "1");
      Object.keys(formData).forEach((key) => {
        updatedFormData.append(key, formData[key]);
      });
      if (newImage) {
        updatedFormData.append("profile_image", newImage);
      }
  
      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}add-small-admins-customer`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
        data: updatedFormData,
      };
  
      const response = await axios(config);
  
      if (response.data.status === 1) {
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: response.data.message,
          // timer: 1500
        });
        setTimeout(() => {
          navigate("/Customermanagement");
        }, 2000);
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: response.data.message,
          // timer: 2000
        });
      }
    } catch (error) {
      console.warn("Error add-small-admins-customer:", error);
      if (error.response && error.response.data && error.response.data.error && error.response.data.error.email) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: error.response.data.error.email[0],
          // timer: 2000
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: "Failed to add customer",
          // timer: 2000
        });
      }
    } finally {
      setLoading(false);
    }
  };
  

  return (
    <div>
      <div className="card mb-3">
        <div
          className="bg-holder d-none d-lg-block bg-card"
          style={{
            backgroundImage:
              "url(../../assets/img/icons/spot-illustrations/corner-4.png)",
          }}
        />
        <div className="card-body position-relative">
          <div className="row">
            <div className="col-lg-8">
              <h3 className="mb-0">Add Normal Customer</h3>
            </div>
            <div className="col-lg-4 text-end">
              <Link to="/Customermanagement" className="">
                <button className="btn btn-success">Back</button>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="card mb-4">
        <form className="card-body" onSubmit={handleSubmit}>
          <h5>Customer Details</h5>
          <div className="row g-3 mt-2">
            <div className="col-md-6 form_details mt-3">
              <div className="form-group local-forms">
                <label className="form-label" htmlFor="fullname">
                  Full Name <span className="login-danger">*</span>
                </label>
                <input
                  className={`form-control ${
                    errors.first_name ? "input-error" : ""
                  }`}
                  id="fullname"
                  name="first_name"
                  type="text"
                  value={formData.first_name}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="col-md-6 form_details">
              <div className="form-group local-forms">
                <label className="form-label" htmlFor="phone">
                  Mobile Number <span className="login-danger">*</span>
                </label>
                <input
                  className={`form-control ${
                    errors.phone ? "input-error" : ""
                  }`}
                  id="phone"
                  name="phone"
                  type="text"
                  value={formData.phone}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="col-md-6 form_details">
              <div className="form-group local-forms">
                <label className="form-label" htmlFor="email">
                  Email <span className="login-danger">*</span>
                </label>
                <input
                  className={`form-control ${
                    errors.email ? "input-error" : ""
                  }`}
                  id="email"
                  type="text"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="col-md-6 form_details">
              <div className="form-group local-forms">
                <label className="form-label" htmlFor="altername_phone">
                  Alternative Mobile Number{" "}
                  <span className="login-danger"></span>
                </label>
                <input
                  className={`form-control ${
                    errors.altername_phone ? "input-error" : ""
                  }`}
                  id="altername_phone"
                  type="text"
                  name="altername_phone"
                  value={formData.altername_phone}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="col-md-6 form_details">
              <div className="form-group local-forms">
                <label className="form-label" htmlFor="address">
                  Address <span className="login-danger"></span>
                </label>
                <textarea
                  className={`form-control ${
                    errors.address ? "input-error" : ""
                  }`}
                  id="address"
                  type="text"
                  name="address"
                  value={formData.address}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="col-md-6 form_details">
              <div className="form-group local-forms">
                <label className="form-label" htmlFor="pannumber">
                  Pan Number <span className="login-danger">*</span>
                </label>
                <input
                  className={`form-control ${
                    errors.pannumber ? "input-error" : ""
                  }`}
                  id="pannumber"
                  type="text"
                  name="pannumber"
                  value={formData.pannumber}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="col-md-6 form_details">
              <div className="form-group local-forms">
                <label className="form-label" htmlFor="aadharnumber">
                  Aadhar Number <span className="login-danger">*</span>
                </label>
                <input
                  className={`form-control ${
                    errors.aadharnumber ? "input-error" : ""
                  }`}
                  id="aadharnumber"
                  type="text"
                  name="aadharnumber"
                  value={formData.aadharnumber}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="col-md-6 form_details mt-3">
              <div className="form-group local-forms">
                <label className="form-label" htmlFor="gender">
                  Gender <span className="login-danger"></span>
                </label>
                <select
                  className="form-select"
                  id="gender"
                  name="gender"
                  value={formData.gender}
                  onChange={handleInputChange}
                >
                  <option value="">Select</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Other">Other</option>
                </select>
              </div>
            </div>
            <div className="col-md-6 form_details mt-3">
              <div className="form-group local-forms">
                <label className="form-label" htmlFor="marital_status">
                  Marital Status <span className="login-danger"></span>
                </label>
                <select
                  className="form-select"
                  id="marital_status"
                  name="marital_status"
                  value={formData.marital_status}
                  onChange={handleInputChange}
                >
                  <option value="">Select</option>
                  <option value="Single">Single</option>
                  <option value="Married">Married</option>
                  <option value="Other">Other</option>
                </select>
              </div>
            </div>
            <div className="col-md-6 form_details">
              <div className="form-group local-forms">
                <label className="form-label" htmlFor="aadharnumber">
                  Password <span className="login-danger">*</span>
                </label>
                <input
                  className={`form-control ${
                    errors.password ? "input-error" : ""
                  }`}
                  id="password"
                  type="text"
                  name="password"
                  value={formData.password}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="col-md-6 form_details">
              <div className="form-group local-forms">
                <label className="form-label" htmlFor="profile_image">
                  {/* Profile Image <span className="login-danger"></span> */}
                </label>
                <input
                  className="form-control"
                  id="profile_image"
                  type="file"
                  name="profile_image"
                  value={formData.profile_image}
                  onChange={handleImageChange}
                />
                {fileimage && (
                  <img
                    src={fileimage}
                    alt="Profile Preview"
                    style={{
                      width: "100px",
                      height: "100px",
                      marginTop: "10px",
                    }}
                  />
                )}
              </div>
            </div>
          </div>

          <hr className="my-4 mx-n4" />

          <h5>Company Details</h5>

          {company_Name !== null ? (
            <div className="col-md-6 form_details mt-3">
              <div className="form-group local-forms">
                <label className="form-label" htmlFor="company_id">
                  Company <span className="login-danger"></span>
                </label>
                <select
                  className={`form-select `}

                  id="company_id"
                  name="company_id"
                  value={formData.company_id}
                  onChange={handleInputChange}
                >
                    <option key={company_id} value={company_id}>
                      {company_Name}
                    </option>
                  
                </select>
              </div>
            </div>
              ):(
            <div className="col-md-6 form_details mt-3">
              <div className="form-group local-forms">
                <label className="form-label" htmlFor="company_id">
                  Company <span className="login-danger"></span>
                </label>
                <select
                  className={`form-select `}
                  id="company_id"
                  name="company_id"
                  value={formData.company_id}
                  onChange={handleInputChange}
                >
              <option value="">Select Company</option>
                  {data.map((company) => (
                    <option key={company.id} value={company.id}>
                      {company.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            )}
  
          <hr className="my-4 mx-n4" />
{/* 
          <h5>Investment Details</h5>
          <div className="row g-3 mt-1">
            <div className="col-md-6 form_details mt-3">
              <div className="form-group local-forms">
                <label className="form-label" htmlFor="plan_id">
                  Investment Plan <span className="login-danger"></span>
                </label>
                <select
                  className={`form-select`}
                  id="plan_id"
                  name="plan_id"
                  value={formData.plan_id}
                  onChange={handleInputChange}
                >
                  <option value="">Select Plan</option>
                  {dataplan.map((plan) => (
                    <option key={plan.id} value={plan.id}>
                      {plan.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="col-md-6 form_details">
              <div className="form-group local-forms">
                <label className="form-label" htmlFor="invested_amount">
                  Invested Amount <span className="login-danger"></span>
                </label>
                <input
                  className={`form-control`}
                  id="invested_amount"
                  type="text"
                  name="invested_amount"
                  value={formData.invested_amount}
                  onChange={handleInputChange}
                />
              </div>
            </div>
          </div> */}

          <div className="pt-4 w-100 d-flex justify-content-end">
            <button
              type="submit"
              className="btn btn-primary me-sm-3 me-1 waves-effect waves-light"
              disabled={loading}
            >
              {loading ? <Spinner animation="border" size="sm" /> : "Submit"}
            </button>
          </div>
        </form>
      </div>
    
    </div>
  );
}
