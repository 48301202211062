import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { FaRegEdit, FaEye, FaSearch } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import { IoMdAdd } from "react-icons/io";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { MdAutoGraph } from "react-icons/md";
import company from "../../assets/img/icons/building.png";
import { MdBusinessCenter } from "react-icons/md";
import { SiSmartthings } from "react-icons/si";
export default function Straties() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [baseurl, setbaseurl] = useState();
  const [Allcompany, setCompany] = useState();
  const [Activecompany, setActivecompany] = useState();
  const [Inactivecompany, setInactivecompany] = useState();
     console.warn(Inactivecompany)
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);

  const broker_id = localStorage.getItem("broker_id");
  const token = localStorage.getItem("bear_token");



  const [totalSwanStrategies, setTotalSwanStrategies] = useState(0);
//   console.warn(totalSwanStrategies)
  const [totalActiveSwanStrategies, setTotalActiveSwanStrategies] = useState(0);
  const [totalInActiveSwanStrategies, setTotalInActiveSwanStrategies] = useState(0);

  const fetchData = async () => {
    try {
      setLoading(true);
  
      const token = localStorage.getItem("bear_token");
      const broker_id = localStorage.getItem("broker_id");
  
      const params = {
        smalladmins_id: broker_id,
        status: "1",
      };
  
      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}small-admins-strategies-lists`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        params: params,
      };
  
      const response = await axios(config);
      console.warn(response);
  
      setData(response.data.data);
      setTotalSwanStrategies(response.data.totalSwanStrategies);
      setTotalActiveSwanStrategies(response.data.totalActiveSwanStrategies);
      setTotalInActiveSwanStrategies(response.data.totalInActiveSwanStrategies);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };
  

  useEffect(() => {
    fetchData();
  }, []); // Empty dependenc
  const filteredData = data ? data.filter((item) =>
    item.name && item.name.toLowerCase().includes(searchTerm.toLowerCase())
  ):[];

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedData = filteredData.slice(startIndex, endIndex);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const Delete = async (id) => {
    console.warn(id);
    const token = localStorage.getItem("bear_token");
    const broker_id = localStorage.getItem("broker_id");
  
    if (!id) {
      console.error("ID is required");
      return;
    }
  
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      });
  
      if (result.isConfirmed) {
        setLoading(true);
  
        const formData = new FormData();
        formData.append("smalladmins_id", broker_id);
        formData.append("id", id);  // Ensure the id field is appended correctly
        formData.append("status", "1");
  
        const config = {
          method: "post",
          url: `${process.env.REACT_APP_API_URL}small-admins-delete-strategies`,
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
          data: formData,
        };
  
        const response = await axios(config);
        const data = response.data.status;
  
        if (data === 1) {
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: response.data.message,
            // timer: 1500,
          });
  
          fetchData();
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: response.data.message,
            // timer: 2000,
          });
        }
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };
  
  
  
  const handleStatusChange = async (business_id, currentStatus) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, change status!",
      });

      if (result.isConfirmed) {
        setLoading(true);

        const formData = new FormData();
        formData.append("smalladmins_id", broker_id);
        formData.append("id", business_id);
        formData.append("status", currentStatus === 1 ? 0 : 1); 

        const config = {
          method: "post",
          url: `${process.env.REACT_APP_API_URL}small-admins-update-strategies-status`,
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
          data: formData,
        };

        const response = await axios(config);
        const data = response.data.status;

        if (data === 1) {
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: "Status updated successfully!",
            // timer: 1500
          });
      
          
          fetchData(); 
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: response.data.message,
            // timer: 2000
          });
        }

        setLoading(false);
      }
    } catch (error) {
      console.error("Error changing status:", error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: "There was an error changing the status.",
        // timer: 2000
      });
      setLoading(false);
    }
  };

 

  const handleAddbusiness = (id,name) => {
    localStorage.setItem("company_id", id);
    localStorage.setItem("company_Name", name);
    navigate("/Addbusiness");
  };

  const HandleAddadminscustomer = (id,name) => {
    localStorage.setItem("company_id", id);
    localStorage.setItem("company_Name", name);
    navigate("/Add-admins-customer");
  };

  
  const handleClickEditCompanyId = (id) => {
    localStorage.setItem("Stratiesid", id);
    navigate("/EditViesStraties");
  };

  const handleClickCompanyId = (id) => {
    localStorage.setItem("Stratiesid", id);
    navigate("/ViesStraties");
  };
 
  return (
    <>
      <div className="card mb-3">
        <div
          className="bg-holder d-none d-lg-block bg-card"
          style={{
            backgroundImage:
              "url(../../assets/img/icons/spot-illustrations/corner-4.png)"
          }}
        />
        {/*/.bg-holder*/}
        <div className="card-body position-relative">
          <div className="row">
            <div className="col-lg-8">
              <h3 className="mb-0">Strategy Analytics</h3>
            </div>
            <div className="col-lg-4 text-end">
              <Link to="/Home" className="">
                <button className="btn btn-success">Back</button>
              </Link>
            </div>
          </div>
        </div>

      </div>
      <div className="row g-3 mb-3">
        <div className="col-md-4 col-xxl-3">
      <Link to='#'     
            className={`linkWithoutUnderline active`}>
          <div className="card h-md-100 ecommerce-card-min-width">
            <div className="card-header pb-0">
              <h5 className="mb-0 mt-2 d-flex align-items-center">
                Total
              </h5>
            </div>
            <div className="card-body d-flex flex-column justify-content-end">
              <div className="row align-items-center">
                <div className="col pe-0">
                  <SiSmartthings className="fs-4 text-primary" />
                </div>
                <div className="col ps-0">
                  <p className="font-sans-serif lh-1 mb-1 fs-5 text-primary">
                    {totalSwanStrategies}
                  </p>
                </div>
                <div className="col-auto ps-0">
                  <div className="echart-bar-weekly-sales h-100" />
                </div>
              </div>
            </div>
          </div>
        </Link>
        </div>
        <div className="col-md-4 col-xxl-3">
        <Link to='#' className="linkWithoutUnderline">
          <div className="card h-md-100">
            <div className="card-header pb-0">
              <h5 className="mb-0 mt-2"> Active</h5>
            </div>
            <div className="card-body d-flex flex-column justify-content-end">
              <div className="row justify-content-between">
                <div className="col pe-0">
                  <SiSmartthings className="fs-4 text-success" />
                </div>
                <div className="col ps-0">
                  <div className="fs-5 fw-normal font-sans-serif text-700 lh-1 mb-1 ">
                    <p className="font-sans-serif lh-1 mb-1 fs-5 text-success">
                      {totalActiveSwanStrategies}
                    </p>
                  </div>
                </div>
                <div className="col-auto ps-0 mt-n4">
                  <div
                    className="echart-default-total-order"
                    data-echarts='{"tooltip":{"trigger":"axis","formatter":"{b0} : {c0}"},"xAxis":{"data":["Week 4","Week 5","Week 6","Week 7"]},"series":[{"type":"line","data":[20,40,100,120],"smooth":true,"lineStyle":{"width":3}}],"grid":{"bottom":"2%","top":"2%","right":"10px","left":"10px"}}'
                    data-echart-responsive="true"
                  />
                </div>
              </div>
            </div>
          </div>
        </Link>
        </div>
        <div className="col-md-4 col-xxl-3">
        <Link to='#' className="linkWithoutUnderline">

          <div className="card h-md-100">
            <div className="card-header pb-0">
              <h5 className="mb-0 mt-2"> Inactive</h5>
            </div>
            <div className="card-body d-flex flex-column justify-content-end">
              <div className="row justify-content-between">
                <div className="col pe-0">
                  <SiSmartthings className="fs-4 text-danger" />
                </div>
                <div className="col ps-0">
                  <div className="fs-5 fw-normal font-sans-serif text-700 lh-1 mb-1">
                    <p className="font-sans-serif lh-1 mb-1 fs-5  text-danger">
                      {totalInActiveSwanStrategies}
                    </p>
                  </div>
                </div>
                <div className="col-auto ps-0 mt-n4">
                  <div
                    className="echart-default-total-order"
                    data-echarts='{"tooltip":{"trigger":"axis","formatter":"{b0} : {c0}"},"xAxis":{"data":["Week 4","Week 5","Week 6","Week 7"]},"series":[{"type":"line","data":[20,40,100,120],"smooth":true,"lineStyle":{"width":3}}],"grid":{"bottom":"2%","top":"2%","right":"10px","left":"10px"}}'
                    data-echart-responsive="true"
                  />
                </div>
              </div>
            </div>
          </div>
        </Link>
        </div>
      </div>
      <div className="card mb-3">
        <div className="card-body position-relative">
          <div className="row">
            <div className="col-lg-8">
              <h3 className="mb-0">Strategy List</h3>
            </div>
            <div className="col-md-4 d-flex justify-content-end">
              {/* <Link to="/AddStrategies" className="btn btn-sm btn-success">
              <IoMdAdd className="me-1 fs--2" />   Add Strategies
              </Link> */}
            </div>
          </div>
        </div>
      </div>
      <div className="card" id="ticketsTable">
        <div className="card-header w-100 border-bottom border-200">
          <div className="justify-content-end d-flex w-100">
            <div className="justify-content-end mt-1 d-flex w-100">
              <form>
                <div className="input-group input-search-width">
                  <input
                    className="form-control form-control-sm shadow-none search"
                    type="search"
                    placeholder="Search by name"
                    aria-label="search"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="card-body">
          <div className="table-responsive scrollbar">
            <table className="table mb-0 table-view-tickets">
              <thead className="bg-body-tertiary">
                <tr className="thead-dark">
                  <th className="">Sr.</th>
                  <th
                    className="text-800 sort align-middle"
                    data-sort="description"
                    style={{ minWidth: "15.625rem" }}
                     >
                    Strategy Name
                  </th>
                  <th
                    className="text-800 sort align-middle"
                    data-sort="description"
                    style={{ minWidth: "15.625rem" }}
                     >
                    Type
                  </th>

                
                  
               
                  
                  {/* <th className="text-800 sort align-middle" data-sort="status">
                    Status
                  </th> */}
                  <th
                    className="text-800 sort align-middle"
                    data-sort="addbusiness"
                  >
                     Action

                  </th>
                  {/* <th
                    className="text-800 sort align-middle"
                    data-sort="addbusiness"
                  >
                    Action
                  </th> */}
                </tr>
              </thead>
              <tbody className="list">
                {loading && (
                  <tr>
                    <td colSpan="7" className="text-center py-4">
                      <div id="preloader">
                        <div id="loading-center">
                          <div className="loader">
                            <div className="waviy">
                              <img
                                src="https://swaninvestment.in/frontant/assets/img/loader.png"
                                alt="Loader image"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                )}
                {paginatedData.length > 0 ? (
                  paginatedData.map((item, index) => (
                    <tr key={index} className="">
                      {/* <td className="align-middle fs-9 py-3">
                      <div className="form-check mb-0">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id={`table-view-tickets-${index}`}
                          data-bulk-select-row="data-bulk-select-row"
                        />
                      </div>
                    </td> */}
                      <td className="align-middle client white-space-nowrap">
                        <div className="d-flex align-items-center gap-2 position-relative">
                          <div>
                            <span>{startIndex + index + 1}</span>
                          </div>
                        </div>
                      </td>

                      {/* <td className="align-middle subject py-2 pe-4">
                      {item?.profile_image ? (
                        <img
                          src={`${baseurl}/${item.profile_image}`}
                          height={70}
                          width={70}
                          style={{ borderRadius: "50%" }}
                          alt="Plan"
                        />
                      ) : (
                        <div style={{ height: 70, width: 70, borderRadius: "50%", backgroundColor: '#ccc' }} />
                      )}
                    </td> */}
                      <td className="align-middle priority pe-4">
                        <div className="d-flex align-items-center gap-2">
                          <h6 className="mb-0 text-700 text-transform-capitalize">
                            {item?.name ?? "No Name"}
                          </h6>
                        </div>
                      </td>
                      <td className="align-middle priority pe-4">
                        <div className="d-flex align-items-center gap-2">
                          <h6 className="mb-0 text-700 text-transform-capitalize">
                            {item?.strategy_type  ?? " "}
                          </h6>
                        </div>
                      </td>
                  

                    
                      {/* <td className="align-middle status fs-9 pe-4">
                        <div className="d-flex align-items-center gap-2">
                          <h6 className="mb-0 text-700">
                            <a href={`mailto:${item.name}`}>{item.email}</a>
                          </h6>
                        </div>
                      </td> */}
                      {/* <td>
                        <div className="form-check form-switch">
                          <input
                            className="form-check-input toggle_btn"
                            type="checkbox"
                            role="switch"
                            id={`flexSwitchCheckDefault-${item.id}`}
                            checked={item.status === 1}
                            // onChange={() => handleStatusChange(item.id, item.status)}
                            disabled={loading} 
                          />
                        </div>
                      </td> */}
                   

                      {/* <td className="align-middle priority pe-4">
                        <div className="d-flex gap-1  action_button">
                          <OverlayTrigger
                            key="Update"
                            placement="top"
                            overlay={
                              <Tooltip id={`Update`}>Add Business</Tooltip>
                            }
                          >
                            <button
                              onClick={() => handleAddbusiness(item.id, item.name)}
                              className="acive_btn bg-primary"
                              type="button"
                              style={{ "--i": "#07bc0c" }}
                            >
                              Add B
                            </button>
                          </OverlayTrigger>
                          <OverlayTrigger
                            key="Update"
                            placement="top"
                            overlay={
                              <Tooltip id={`Update`}>Add Customer</Tooltip>
                            }
                          >
                            <button
                              onClick={() => HandleAddadminscustomer(item.id,item.name)}
                              className="acive_btn "
                              type="button"
                              style={{ "--i": "#07bc0c" }}
                            >
                              Add C
                            </button>
                          </OverlayTrigger>
                        </div>
                      </td> */}
                      <td className="align-middle priority pe-4">
                        <div className="d-flex gap-1 action_button">
                          {/* <OverlayTrigger
                            key="Edit"
                            placement="top"
                            overlay={<Tooltip id={`Edit`}>Edit</Tooltip>}
                          >
                            <button
                              onClick={() => handleClickEditCompanyId(item.id)}
                              style={{ "--i": "#2c7be5" }}
                            >
                              {" "}
                              <FaRegEdit className="color_link" />
                            </button>
                          </OverlayTrigger> */}
                          <OverlayTrigger
                            key="View"
                            placement="top"
                            overlay={<Tooltip id={`View`}>View</Tooltip>}
                          >
                            <button
                              onClick={() => handleClickCompanyId(item.id)}
                              style={{ "--i": "#27bcfd" }}
                            >
                              <FaEye className="color_link" />
                            </button>
                          </OverlayTrigger>
                          {/* <OverlayTrigger
                            key="Delete"
                            placement="top"
                            overlay={<Tooltip id={`Delete`}>Delete</Tooltip>}
                          >
                            <button
                              style={{ "--i": "red" }}
                              onClick={() => Delete(item.id)}
                            >
                              {" "}
                              <MdDelete className="color_link" />
                            </button>
                       
                          </OverlayTrigger> */}

                          {/* <OverlayTrigger
                          key="Delete"
                          placement="top"
                          overlay={<Tooltip id={`Delete`}>Delete</Tooltip>}
                        >
                          <Link to="#"> <MdDelete className="color_link" /></Link>
                        </OverlayTrigger> */}

                          {/* <button onClick={() => handleClickCompanyId(item.id)}>View Details</button> */}

                          {/* <OverlayTrigger
                          key="Update"
                          placement="top"
                          overlay={<Tooltip id={`Update`}>Update</Tooltip>}
                        >
                          <Link to="/Viewdetailsupdate"><IoMdAdd className="color_link" />
                          </Link>
                        </OverlayTrigger> */}
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="12" className="text-center py-4">
                      No data found.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          {totalPages > 1 && (
            <div className="pagination mt-3 mb-3 mx-3">
              <button
                className="btn btn-sm btn-light"
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                <IoIosArrowBack />
              </button>
              {[...Array(totalPages).keys()].map((pageNumber) => (
                <button
                  key={pageNumber + 1}
                  className={`btn btn-sm mx-1 ${currentPage === pageNumber + 1
                    ? "btn-light text-primary"
                    : "btn-light"
                    }`}
                  onClick={() => handlePageChange(pageNumber + 1)}
                >
                  {pageNumber + 1}
                </button>
              ))}
              <button
                className="btn btn-sm btn-light"
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                <IoIosArrowForward />
              </button>
            </div>
          )}
        </div>
      </div>


    </>
  );
}
