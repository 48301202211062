
import React, { useState, useEffect } from "react";
import axios from "axios";
import { Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
export default function Edidsymbol() {
  const broker_id = localStorage.getItem("broker_id");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [newImage, setNewImage] = useState(null);
  const [fileimage, setFileimage] = useState(null);
  const [errors, setErrors] = useState({});
  const [data, setData] = useState([]);
  const [dataplan, setDataplan] = useState([]);
  const [strategies, setStrategies] = useState([]);
  const [symbols, setSymbols] = useState([]);
  const [baseurl, setBaseUrl] = useState("");
  // const [showSelect, setShowSelect] = useState(false);
  // const [showSelect1, setShowSelect1] = useState(false);
  const [viewData, setViewData] = useState({});
  const [formData, setFormData] = useState({
    name: "",
    strategies: "",
    smalladmins_name: "",
    strateigs_id: "",
    symbol_id: "",
    // hedge_default_lot_size: "",
    // naked_default_lot_size: "",
    profile_image: null,
   
  });

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setFileimage(URL.createObjectURL(file));
    setNewImage(file);
    
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "phone" && (value.length > 10 || isNaN(value))) {
      return;
    }
    if (name === "altername_phone" && (value.length > 10 || isNaN(value))) {
      return;
    }

    let formattedValue = value;

    if (name === "aadharnumber") {
      formattedValue = value.replace(/\D/g, "").slice(0, 12);
    } else if (name === "pannumber") {
      formattedValue = value
        .toUpperCase()
        .replace(/[^A-Z0-9]/g, "")
        .slice(0, 10);
    }

    setFormData({ ...formData, [name]: formattedValue });

    setErrors((prevErrors) => ({ ...prevErrors, [name]: null }));
  };
  useEffect(() => {
    fetchData1();
  }, []);
  const fetchData1 = async () => {
    try {
      const token = localStorage.getItem("bear_token");
    //   const customer_id = localStorage.getItem("customer_id");
      const customer_id = localStorage.getItem("Stratiesid");

    //   setCustomerId(customer_id); // Set customerId in state

      const formData = new FormData();
      formData.append("smalladmins_id", broker_id);
      formData.append("id", customer_id);
      formData.append("status", "1");

      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}small-admins-view-symbols`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      };

      const response = await axios(config);
      const data = response.data.data;
      setFormData(data[0]);
      setViewData(data[0]);
      const dataurl = response.data.url;
      setBaseUrl(dataurl);
    } catch (error) {
      console.error("Error fetching user broker:", error);
    }
  };


  const fetchData = async () => {
    try {
      const token = localStorage.getItem("bear_token");

      const formData = new FormData();
      formData.append("smalladmins_id", broker_id);
      formData.append("status", "1");

      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}small-admins-active-company-lists`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      };

      const response = await axios(config);
      setData(response.data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchDataine_plan = async () => {
    try {
      const token = localStorage.getItem("bear_token");

      const formData = new FormData();
      formData.append("smalladmins_id", broker_id);
      formData.append("status", "1");

      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}small-admins-investment-active-plans-lists`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      };

      const response = await axios(config);
      setDataplan(response.data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
    fetchDataine_plan();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const requiredFields = [
    ];

    let validationErrors = {};
    let hasError = false;

    for (const { field, name } of requiredFields) {
      if (!formData[field]) {
        validationErrors[field] = `Please fill in the ${name}.`;
        hasError = true;
      }
    }

    setErrors(validationErrors);

    if (hasError) {
      setLoading(false);
      return;
    }

    try {
      const token = localStorage.getItem("bear_token");
      const customer_id = localStorage.getItem("Stratiesid");

      const updatedFormData = new FormData();
      updatedFormData.append("smalladmins_id", broker_id);
      updatedFormData.append("status", "1");  
      Object.entries(formData).forEach(([key, value]) => {
        if (value !== null && value !== "" && value !== "null") {
          if (key !== "strategies_name") {
            updatedFormData.append(key, value);
          }
        }
      });
      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}small-admins-update-symbols`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
        data: updatedFormData,
      };

      const response = await axios(config);

      if (response.data.status === 1) {
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: response.data.message,
          // timer: 1500
        });
    
        setTimeout(() => {
          navigate("/Addsymbol");
        }, 2000);
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: response.data.message,
          // timer: 2000
        });
      
      }
    } catch (error) {
      console.warn("Error add-small-admins-customer:", error);
      if (error.response && error.response.data && error.response.data.error && error.response.data.error.email) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: error.response.data.error.email[0],
          // timer: 2000
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: "Failed to add Symbol",
          // timer: 2000
        });
      }
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    const fetchStrategies = async () => {
        try {
            setLoading(true);
            const token = localStorage.getItem("bear_token");

            const params = {
                smalladmins_id: broker_id,
                status: "1",
            };

            const config = {
                method: "post",
                url: `${process.env.REACT_APP_API_URL}small-admins-strategies-lists`,
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
                params: params,
            };

            const response = await axios(config);
            setStrategies(response.data.data);
            setLoading(false);
        } catch (error) {
            console.error("Error fetching data:", error);
            setLoading(false);
        }
    };
    const fetchSymbols = async () => {
        try {
            setLoading(true);
            const token = localStorage.getItem("bear_token");

            const params = {
                smalladmins_id: broker_id,
                status: "1",
            };

            const config = {
                method: "post",
                url: `${process.env.REACT_APP_API_URL}small-admins-symbols-lists`,
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
                params: params,
            };

            const response = await axios(config);
            setSymbols(response.data.data);
            setLoading(false);
        } catch (error) {
            console.error("Error fetching data:", error);
            setLoading(false);
        }
    };
    fetchSymbols();
    fetchStrategies();
}, [broker_id]);
// const toggleShowSelect = () => {
//   setShowSelect((prevShowSelect) => !prevShowSelect);
// };
// const toggleShowSelect1 = () => {
//   setShowSelect1((prevShowSelect) => !prevShowSelect);
// };
  return (
    <div>
      <div className="card mb-3">
        <div
          className="bg-holder d-none d-lg-block bg-card"
          style={{
            backgroundImage:
              "url(../../assets/img/icons/spot-illustrations/corner-4.png)",
          }}
        />
        <div className="card-body position-relative">
          <div className="row ">
            <div className="col-lg-8">
              <h3 className="mb-0">Update Symbol  </h3>
            </div>
            <div className="col-lg-4 text-end">
              <Link to="/Addsymbol" className="">
                <button className="btn btn-success">Back</button>
              </Link>
            </div>
          </div>
        </div>
      </div>



      <div className="card mb-6">
        <form className="card-body" onSubmit={handleSubmit}>
          {/* <h5>Customer Details</h5> */}
          <div className="row g-3 mt-2">
              <div className="col-md-6 form_details mt-3">
              <div className="form-group local-forms">
                <label className="form-label" htmlFor="first_name">
                Strategy <span className="login-danger"></span>
                </label>
                {/* {!showSelect ? ( */}
                  <input
                    type="text"
                    className="form-control"
                    value={viewData.strategies_name}
                    readOnly
                    // onClick={toggleShowSelect}
                  />
                {/* ) : (
                  <select
                    id="strateigs_id"
                    name="strateigs_id"
                    className="form-select"
                    value={formData.strateigs_id}
                    onChange={handleInputChange}
                  >
                    <option value="">Select Strategy</option>
                    {strategies.map((strat) => (
                      <option key={strat.id} value={strat.id}>
                        {strat.name}
                      </option>
                    ))}
                  </select>
                )} */}
              </div>
            </div>
            <div className="col-md-6 form_details mt-3">
              <div className="form-group local-forms">
                <label className="form-label" htmlFor="first_name">
              Symbol <span className="login-danger"></span>
                </label>
                <input
                  className={`form-control`}
                  id="name"
                  name="name"
                  type="text"
                  value={formData.name}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            </div>
          <div className="row g-3 mt-2">
          </div>  
          <div className="pt-4 w-100 d-flex justify-content-end">
            <button
              type="submit"
              className="btn btn-primary me-sm-3 me-1 waves-effect waves-light"
              disabled={loading}
            >
              {loading ? <Spinner animation="border" size="sm" /> : "Submit"}
            </button>
          </div>
        </form>
      </div>

    </div>
  );
}
