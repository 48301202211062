import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";

export default function Addintraday() {
  const navigate = useNavigate();
  const broker_id = localStorage.getItem("broker_id");
  const AddHedgeBuySell_id = localStorage.getItem("ViesNakedpotionn");
  const EditIntra = localStorage.getItem("EditIntra");

  const [formData, setFormData] = useState({
    total_p_l: "",
    invested_lot: "",
    date: "",
    date_from: "",
    date_to: "",
    company_id: "",
    strateigs_id: "",
  });

  const [errors, setErrors] = useState({});

  useEffect(() => {
    fetchData4();
  }, []);

  const fetchData4 = async () => {
    try {
      const token = localStorage.getItem("bear_token");
      const updateformData = new FormData();
      updateformData.append("smalladmins_id", broker_id);
      updateformData.append("company_naked_hedgePL_id", AddHedgeBuySell_id);
      updateformData.append("id", EditIntra);

      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}small-admins-view-manual-intraday`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
        data: updateformData,
      };

      const response = await axios(config);
      const data = response.data;

    
      setFormData({
        total_p_l: data[0].total_p_l || "",
        invested_lot: data[0].invested_lot || "",
        date: data[0].date || "",
        date_from: data[0].date_from || "",
        date_to: data[0].date_to || "",
        company_id: data[0].company_id || "",
        strateigs_id: data[0].strateigs_id || "",
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
  
 

    if (name === "total_p_l" || name === "invested_lot") {
      if (/^\d*$/.test(value) && value.length <= 15) {
        setFormData({ ...formData, [name]: value });
        setErrors((prevErrors) => ({ ...prevErrors, [name]: null }));
      }
    } else {
  
      setFormData({ ...formData, [name]: value });
      setErrors((prevErrors) => ({ ...prevErrors, [name]: null }));
    }
  };
  

  const handleValidation = () => {
    const requiredFields = [
      { field: "invested_lot", name: "invested lot" },
      { field: "total_p_l", name: "total pl" },
      { field: "date", name: "date" },
    ];
    let validationErrors = {};
    let hasError = false;

    requiredFields.forEach(({ field, name }) => {
      if (!formData[field]) {
        validationErrors[field] = `${name} is required`;
        hasError = true;
      }
    });

    setErrors(validationErrors);
    return !hasError;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate the form when submitted
    if (!handleValidation()) {
      return;
    }

    const token = localStorage.getItem("bear_token");

    const updatedFormData = new FormData();
    updatedFormData.append("smalladmins_id", broker_id);
    updatedFormData.append("company_naked_hedgePL_id", AddHedgeBuySell_id);
    updatedFormData.append("id", EditIntra);
    Object.entries(formData).forEach(([key, value]) => {
      if (value !== null && value !== "" && value !== "null") {
        updatedFormData.append(key, value);
      }
    });

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}small-admins-edit-manual-intraday`,
        updatedFormData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 201) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: response.data.message,
          color:"white",
          background: 'transparent',
        });

        setTimeout(() => {
          navigate("/ViesNakedpotionn");
        }, 2000);
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: response.data.message || "Unknown error occurred",
          color:"white",
          background: 'transparent',
        });
      }
    } catch (error) {
      console.error("Error:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "An error occurred while submitting the form.",
        color:"white",
        background: 'transparent',
      });
    }
  };

  return (
    <>
      <div className="card mb-3">
        <div
          className="bg-holder d-none d-lg-block bg-card"
          style={{
            backgroundImage:
              "url(../../assets/img/icons/spot-illustrations/corner-4.png)",
          }}
        />
        <div className="card-body position-relative">
          <div className="row">
            <div className="col-lg-8">
              <h3 className="mb-0">Update Manual P&L Intraday</h3>
            </div>
            <div className="col-lg-4 text-end">
              <Link to="/ViesNakedpotionn">
                <button className="btn btn-success">Back</button>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="card">
        <div className="card-body">
          <form className="row g-3" onSubmit={handleSubmit}>
            {/* Total PL Field */}
            <div className="col-md-6 form_details">
              <div className="form-group local-forms">
                <label>
                  Total PL <span className="login-danger">*</span>
                </label>
                <input
                  className={`form-control ${errors.total_p_l ? "input-error" : ""}`}
                  id="total_p_l"
                  type="text"
                  name="total_p_l"
                  value={formData.total_p_l}
                  onChange={handleChange}
                />
               
              </div>
            </div>

            {/* Invested Lot Field */}
            <div className="col-md-6 form_details">
              <div className="form-group local-forms">
                <label>
                  Invested Lot <span className="login-danger">*</span>
                </label>
                <input
                  className={`form-control ${errors.invested_lot ? "input-error" : ""}`}
                  id="invested_lot"
                  type="text"
                  name="invested_lot"
                  value={formData.invested_lot}
                  onChange={handleChange}
                />
              
              </div>
            </div>

            {/* P&L Date Field */}
            <div className="col-md-6 form_details">
              <div className="form-group local-forms">
                <label>
                  P&L Date <span className="login-danger">*</span>
                </label>
                <input
                  className={`form-control ${errors.date ? "input-error" : ""}`}
                  id="date"
                  type="date"
                  name="date"
                  value={formData.date}
                  onChange={handleChange}
                />
                
              </div>
            </div>

            {/* Submit Button */}
            <div className="col-12 d-flex justify-content-end">
              <button className="btn btn-primary" type="submit">
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
