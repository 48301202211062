import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import { FaRegEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { FaEye } from "react-icons/fa";
import { IoMdAdd } from "react-icons/io";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export default function Custumerdetails() {
  const [data, setData] = useState([]);
  const [baseurl, setbaseurl] = useState();
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState(''); // State to hold the search term
  const navigate = useNavigate();
  const token = localStorage.getItem("bear_token");
  const broker_id = localStorage.getItem("broker_id");
  const custumerdetails_id = localStorage.getItem("custumerdetails");
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);

      // Fetch data from API


      const formData = new FormData();
      formData.append("smalladmins_id", broker_id);
      formData.append("cust_id", custumerdetails_id);
      formData.append("status", '1');

      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}view-small-admins-company`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data"
        },
        data: formData
      };

      const response = await axios(config);
      const data = response.data.data;
      const data1 = response.data.url;
      console.warn(data1)
      setbaseurl(data1)
      // Set fetched data
      setData(data);
      setLoading(false);

    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  const Delete = async () => {
    try {
      setLoading(true);

      // Fetch data from API


      const formData = new FormData();
      formData.append("smalladmins_id", broker_id);
      formData.append("cust_id", custumerdetails_id);

      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}delete-small-admins-company`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data"
        },
        data: formData
      };

      const response = await axios(config);
      const data = response.data.status;
      if (data === 1) {
        toast.success(response.data.message);
        setTimeout(() => {
          navigate('/Customermanagement');
        }, 2000);
      } else {
        toast.error(response.data.message);
      }

    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  return (
    <>
      <div className="card mb-3">
        <div
          className="bg-holder d-none d-lg-block bg-card"
          style={{
            backgroundImage:
              "url(../../assets/img/icons/spot-illustrations/corner-4.png)"
          }}
        />
        {/*/.bg-holder*/}
        <div className="card-body position-relative">
          <div className="row">
            <div className="col-lg-8">
              <h3 className="mb-0">Customer Management</h3>
            </div>
            <div className="col-md-4 d-flex justify-content-end">
              <Link to="/Businessreg" className="btn-sm btn-primary">
                Create Company
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div
        className="card"
        id="ticketsTable"
        data-list='{"valueNames":["client","subject","status","priority","agent"],"page":11,"pagination":true,"fallback":"tickets-table-fallback"}'
      >
        <div className="card-header justify-content-between d-flex border-bottom border-200">
          <div className="justify-content-end d-flex w-100">
            <form>
              <div className="input-group input-search-width">
                <input
                  className="form-control form-control-sm shadow-none search"
                  type="search"
                  placeholder="Search  by name"
                  aria-label="search"
                />
                <button className="btn btn-sm btn-outline-secondary border-300 hover-border-secondary">
                  <span className="fa fa-search fs-10 text-dark" />
                </button>
              </div>
            </form>
          </div>

        </div>
        <div className="card-body p-0">
          <div className="table-responsive scrollbar">
            <table className="table table-sm mb-0 fs-10 table-view-tickets">
              <thead className="bg-body-tertiary">
                <tr>
                  <th className="text-800 sort align-middle" data-sort="GSTIN">
                    Customer ID
                  </th>
                  <th className="text-800 sort align-middle">Customer Name</th>
                  <th className="text-800 sort align-middle">Profile Picture</th>
                  <th className="text-800 sort align-middle">Email</th>
                  <th className="text-800 sort align-middle">Alternative Number</th>
                  <th className="text-800 sort align-middle">Joining Date</th>
                  <th className="text-800 sort align-middle">Refund Date</th>
                  <th className="text-800 sort align-middle">Total Deposit</th>
                  <th className="text-800 sort align-middle">Total Refund</th>
                  <th className="text-800 sort align-middle">Status </th>
                  {/* <th className="text-800 sort align-middle">
                    {" "}
                    Select Investment plan{" "}
                  </th>
                  <th className="text-800 sort align-middle">
                    Select the company{" "}
                  </th> */}
                  <th className="text-800 sort align-middle"  >Action </th>
                </tr>
              </thead>
              <tbody className="list" id="table-ticket-body">
                {data && data.map((item, index) => (
                  <tr>
                    <td>{item.cust_id}</td>
                    <td className="align-middle client white-space-nowrap pe-3 pe-xxl-4 ps-2">
                      {item.name}

                    </td>
                    <td className="align-middle subject py-2 pe-4">
                      <img
                        src={`${baseurl}/${item.profile_image}`}
                        height={70}
                        width={70}
                        style={{ borderRadius: "50%" }}

                      ></img>
                    </td>
                    <td><a href="mailto:{item.email}">{item.email}</a></td>

                    <td>{item.altername_phone}</td>
                    <td>{item.joining_date}</td>
                    <td>{item.refund_date}</td>
                    <td>{item.total_deposit}</td>
                    <td>{item.total_refund}</td>
                    <td>
                      <small className="badge rounded badge-subtle-success false"
                        style={{ color: item.status === 1 ? 'green' : 'red' }}>
                        {item.status === 1 ? 'active' : 'inactive'}
                      </small>
                    </td>
                    {/* <td className="align-middle agent">
                      <select
                        className="form-select form-select-sm w-auto ms-auto"
                        aria-label="agents actions"
                      >
                        <option>Select Agent</option>
                        <option selected="selected">Anindya</option>
                        <option>Nowrin</option>
                        <option>Khalid</option>
                      </select>
                    </td>
                    <td className="align-middle agent">
                      <select
                        className="form-select form-select-sm w-auto ms-auto"
                        aria-label="agents actions"
                      >
                        <option>Select Agent</option>
                        <option selected="selected">Anindya</option>
                        <option>Nowrin</option>
                        <option>Khalid</option>
                      </select>
                    </td> */}
                    {/* <td>
                      <div className="d-flex gap-1">
                        <button
                          className="btn btn-link p-0"
                          type="button"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Edit"
                        >
                          <span className="text-500 fas fa-edit" />
                        </button>
                        <button
                          className="btn btn-link p-0 ms-2"
                          type="button"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Delete"
                        >
                          <span className="text-500 fas fa-trash-alt" />
                        </button>
                        <button
                          className="btn btn-link p-0 ms-2"
                          type="button"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Delete"
                        >
                          <span className="text-500 fas fa-trash-alt" />
                        </button>
                        <button
                          className="btn btn-link p-0 ms-2"
                          type="button"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Delete"
                        >
                          <span className="text-500 fas fa-trash-alt" />
                        </button>
                      </div>
                    </td> */}
                    <td>
                      <div className='d-flex gap-1'>
                        <OverlayTrigger
                          key="Edit"
                          placement="top"
                          overlay={<Tooltip id={`Edit`}>Edit</Tooltip>}
                        >
                          <Link to="/Custumerdetailsedit"> <FaRegEdit className="color_link" /></Link>
                        </OverlayTrigger>
                        <OverlayTrigger
                        
                          key="Delete"
                          placement="top"
                          overlay={<Tooltip id={`Delete`}>Delete</Tooltip>}
                        >
                          <Link onClick={() => {
                          Delete(item.cust_id);
                        }}> <MdDelete className="color_link" /></Link>  
                        </OverlayTrigger>
                        
                        {/* <OverlayTrigger
                          key="View"
                          placement="top"
                          overlay={<Tooltip id={`View`}>View</Tooltip>}
                        >
                          <Link to="/Viewdetails"><FaEye className="color_link" />
                          </Link>
                        </OverlayTrigger> */}
                        <OverlayTrigger
                          key="Update"
                          placement="top"
                          overlay={<Tooltip id={`Update`}>Update</Tooltip>}
                        >
                          <Link to="/Viewdetailsupdate"><IoMdAdd className="color_link" />
                          </Link>
                        </OverlayTrigger>




                      </div>


                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="text-center d-none" id="tickets-table-fallback">
              <p className="fw-bold fs-8 mt-3">No ticket found</p>
            </div>
          </div>
        </div>
        <div className="card-footer">
          <div className="d-flex justify-content-center">
            <button
              className="btn btn-sm btn-falcon-default me-1 disabled"
              type="button"
              title="Previous"
              data-list-pagination="prev"
              disabled=""
            >
              <span className="fas fa-chevron-left" />
            </button>
            <ul className="pagination mb-0">
              <li className="active">
                <button className="page" type="button" data-i={1} data-page={11}>
                  1
                </button>
              </li>
              <li>
                <button className="page" type="button" data-i={2} data-page={11}>
                  2
                </button>
              </li>
              <li>
                <button className="page" type="button" data-i={3} data-page={11}>
                  3
                </button>
              </li>
            </ul>
            <button
              className="btn btn-sm btn-falcon-default ms-1"
              type="button"
              title="Next"
              data-list-pagination="next"
            >
              <span className="fas fa-chevron-right" />
            </button>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>

  )
}
