import React, { useState, useEffect } from "react";
import axios from "axios";
import { Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";

export default function EditViesStraties() {
  const broker_id = localStorage.getItem("broker_id");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [newImage, setNewImage] = useState(null);
  const [fileimage, setFileimage] = useState(null);
  const [errors, setErrors] = useState({});
  const [data, setData] = useState([]);
  const [dataplan, setDataplan] = useState([]);
  const [baseurl, setBaseUrl] = useState("");
  const [viewData, setViewData] = useState({});
  const [formData, setFormData] = useState({
    smalladmins_id: broker_id,
    company_id: "",
    strateigs_id: "",
    symbol_id: "",
    default_lot_price_id: "",
    date_from: "",
    date_to: "",
    sell_price: "",
    buy_price: "",
    strike_price: "",
    type: "",
    status: "1",
  });

  const [profile, setProfile] = useState(null);
  const [companies, setCompanies] = useState([]);
  const [strategies, setStrategies] = useState([]);
  const [defaultLotSizes, setDefaultLotSizes] = useState([]);
  const [symbols, setSymbols] = useState([]);
  const [showSelect, setShowSelect] = useState(false);
  const [showSelect1, setShowSelect1] = useState(false);
  const [showSelect2, setShowSelect2] = useState(false);
  const [showSelect3, setShowSelect3] = useState(false);
  const [showSelectType, setShowSelectType] = useState(false);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setFileimage(URL.createObjectURL(file));
    setNewImage(file);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData({ ...formData, [name]: value });
    setErrors((prevErrors) => ({ ...prevErrors, [name]: null }));
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const token = localStorage.getItem("bear_token");

        const formData = new FormData();
        formData.append("smalladmins_id", broker_id);
        formData.append("status", "1");

        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        };

        const [companiesRes, strategiesRes, defaultLotSizesRes, symbolsRes] =
          await Promise.all([
            axios.post(
              `${process.env.REACT_APP_API_URL}small-admins-active-company-lists`,
              formData,
              config
            ),
            axios.post(
              `${process.env.REACT_APP_API_URL}small-admins-strategies-lists`,
              { smalladmins_id: broker_id, status: "1" },
              config
            ),
            axios.post(
              `${process.env.REACT_APP_API_URL}small-admins-defaultsize-lists`,
              { smalladmins_id: broker_id, status: "1" },
              config
            ),
            axios.post(
              `${process.env.REACT_APP_API_URL}small-admins-symbols-lists`,
              { smalladmins_id: broker_id, status: "1" },
              config
            ),
          ]);

        setCompanies(companiesRes.data.data);
        setStrategies(strategiesRes.data.data);
        setDefaultLotSizes(defaultLotSizesRes.data.data);

        setSymbols(symbolsRes.data.data);

        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, [broker_id]);

  useEffect(() => {
    fetchData1();
  }, []);

  const fetchData1 = async () => {
    try {
      const token = localStorage.getItem("bear_token");
      const customer_id = localStorage.getItem("EditHadget_id");

      const formData = new FormData();
      formData.append("smalladmins_id", broker_id);
      formData.append("id", customer_id);
      formData.append("status", "1");

      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}small-admins-view-hedge`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      };

      const response = await axios(config);
      const data = response.data.data;
      setFormData(data[0]);
      setViewData(data[0]);
      const dataurl = response.data.url;
      setBaseUrl(dataurl);
    } catch (error) {
      console.error("Error fetching user broker:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const requiredFields = [
      // { field: "company_id", name: "Company" },
      // { field: "strateigs_id", name: "Strategy" },
      // { field: "symbol_id", name: "Symbol" },
      // { field: "default_lot_price_id", name: "Default Lot Price" },
      // { field: "date_from", name: "Date From" },
      // { field: "date_to", name: "Date To" },
      // { field: "sell_price", name: "Sell Price" },
      // { field: "buy_price", name: "Buy Price" },
    ];

    let validationErrors = {};
    let hasError = false;

    for (const { field, name } of requiredFields) {
      if (!formData[field]) {
        validationErrors[field] = `Please fill in the ${name}.`;
        hasError = true;
      }
    }

    setErrors(validationErrors);

    if (hasError) {
      setLoading(false);
      return;
    }

    try {
      const token = localStorage.getItem("bear_token");
      const customer_id = localStorage.getItem("EditNakedBuySell_id");

      const updatedFormData = new FormData();
      updatedFormData.append("smalladmins_id", broker_id);
      updatedFormData.append("id", customer_id);
      updatedFormData.append("status", "1");

      Object.entries(formData).forEach(([key, value]) => {
        if (value !== null && value !== "" && value !== "null") {
          if (key !== "company_name") {
            updatedFormData.append(key, value);
          }
        }
      });

      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}small-admins-update-hedge`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
        data: updatedFormData,
      };

      const response = await axios(config);

      if (response.data.status === 1) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: response.data.message,
          // timer: 1500,
        });

        setTimeout(() => {
          navigate("/AddHedge");
        }, 2000);
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: response.data.message,
          // timer: 2000,
        });
      }
    } catch (error) {
      console.warn("Error add-small-admins-customer:", error);
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.email
      ) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: error.response.data.error.email[0],
          // timer: 2000,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Failed to Update Hedge",
          // timer: 2000,
        });
      }
    } finally {
      setLoading(false);
    }
  };
  const toggleShowSelect = () => {
    setShowSelect((prevShowSelect) => !prevShowSelect);
  };
  const toggleShowSelect1 = () => {
    setShowSelect1((prevShowSelect) => !prevShowSelect);
  };
  const toggleShowSelect2 = () => {
    setShowSelect2((prevShowSelect) => !prevShowSelect);
  };
  const toggleShowSelect3 = () => {
    setShowSelect3((prevShowSelect) => !prevShowSelect);
  };
  const toggleShowSelectType = () => {
    setShowSelectType((prevShowSelect) => !prevShowSelect);
  };

  return (
    <div>
      <div className="card mb-3">
        <div
          className="bg-holder d-none d-lg-block bg-card"
          style={{
            backgroundImage:
              "url(../../assets/img/icons/spot-illustrations/corner-4.png)",
          }}
        />
        <div className="card-body position-relative">
          <div className="row ">
            <div className="col-lg-8">
              <h3 className="mb-0">Update Hedge Details</h3>
            </div>
            <div className="col-lg-4 text-end">
              <Link to="/AddHedge" className="">
                <button className="btn btn-success">Back</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      {loading && (
          <div id="preloader">
          <div id="loading-center">
            <div className="loader">
              <div className="waviy">
                <img
                  src="https://swaninvestment.in/frontant/assets/img/loader.png"
                  alt="Loader image"
                />
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="card">
        <div className="card-body">
          <form onSubmit={handleSubmit}>
            <div className="row mb-3">
              <div className="col-md-6">
                <label htmlFor="company_id">Company</label>
                {!showSelect ? (
                  <input
                    type="text"
                    className="form-select"
                    value={viewData.company_name}
                    readOnly
                    onClick={toggleShowSelect}
                  />
                ) : (
                  <select
                    id="company_id"
                    name="company_id"
                    className="form-select"
                    value={formData.company_id}
                    onChange={handleInputChange}
                  >
                    <option value="">Select Company</option>
                    {companies.map((company) => (
                      <option key={company.id} value={company.id}>
                        {company.name}
                      </option>
                    ))}
                  </select>
                )}
              </div>

              <div className="col-md-6">
                <label htmlFor="strateigs_id">Strategy</label>
                {!showSelect1 ? (
                  <input
                    type="text"
                    className="form-select"
                    value={viewData.strateigs_name}
                    onClick={toggleShowSelect1}
                    readOnly
                  />
                ) : (
                  <select
                    id="strateigs_id"
                    name="strateigs_id"
                    className="form-select "
                    value={formData.strateigs_id}
                    onChange={handleInputChange}
                  >
                    <option value="">Select Strategy</option>
                    {strategies.map((strategy) => (
                      <option key={strategy.id} value={strategy.id}>
                        {strategy.name}
                      </option>
                    ))}
                  </select>
                )}
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-md-6">
                <label htmlFor="symbol_id">Symbol</label>
                {!showSelect2 ? (
                  <input
                    type="text"
                    className="form-select"
                    value={viewData.symbol_name}
                    onClick={toggleShowSelect2}
                    readOnly
                  />
                ) : (
                  <select
                    id="symbol_id"
                    name="symbol_id"
                    className="form-select "
                    value={formData.symbol_id}
                    onChange={handleInputChange}
                  >
                    <option value="">Select Symbol</option>
                    {symbols.map(
                      (symbol) =>
                        formData.strateigs_id == symbol.strateigs_id && (
                          <option key={symbol.id} value={symbol.id}>
                            {symbol.name}
                          </option>
                        )
                    )}
                  </select>
                )}
              </div>

              <div className="col-md-6">
                <label htmlFor="default_lot_price_id" className="form-label">
                  Default Lot Price
                </label>
                {!showSelect3 ? (
                  <input
                    type="text"
                    className="form-select"
                    value={viewData.default_lot_price}
                    onClick={toggleShowSelect3}
                    readOnly
                  />
                ) : (
                  <select
                    id="default_lot_price_id"
                    name="default_lot_price_id"
                    className="form-select "
                    value={formData.default_lot_price_id}
                    onChange={handleInputChange}
                  >
                    <option value="">Select DLP</option>
                    {defaultLotSizes.map((symbol) => (
                      <option key={symbol.id} value={symbol.id}>
                        {symbol.default_lot_size}
                      </option>
                    ))}
                  </select>
                )}
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-md-6">
                <label htmlFor="date_from" className="form-label">
                  Date From
                </label>
                <input
                  // type=""
                  id="date_from"
                  name="date_from"
                  className={`form-control ${
                    errors.date_from ? "is-invalid" : ""
                  }`}
                  value={formData.date_from || ""}
                  onChange={handleInputChange}
                />
              </div>

              <div className="col-md-6">
                <label htmlFor="date_to" className="form-label">
                  Date To
                </label>
                <input
                  // type="number"
                  id="date_to"
                  name="date_to"
                  className={`form-control ${
                    errors.date_to ? "is-invalid" : ""
                  }`}
                  value={formData.date_to || ""}
                  onChange={handleInputChange}
                />
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-md-6">
                <label htmlFor="buy_price" className="form-label">
                  Strike Price
                </label>
                <input
                  type="text"
                  id="strike_price"
                  name="strike_price"
                  className={`form-control ${
                    errors.strike_price ? "is-invalid" : ""
                  }`}
                  value={formData.strike_price || ""}
                  onChange={handleInputChange}
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="sell_price" className="form-label">
                  Type
                </label>
                {!showSelectType ? (
                  <input
                    type="text"
                    id="type"
                    name="type"
                    className={`form-control ${
                      errors.type ? "is-invalid" : ""
                    }`}
                    value={formData.type || ""}
                    onClick={toggleShowSelectType}
                  />
                ) : (
                  <select
                    className={`form-select ${
                      errors.type ? "input-error" : ""
                    }`}
                    id="type"
                    value={formData.type}
                    onChange={handleInputChange}
                  >
                    <option value="">Select Type</option>
                    <option value="ce">CE</option>
                    <option value="pe">PE</option>
                  </select>
                )}
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-md-6">
                <label htmlFor="buy_price" className="form-label">
                  Buy Price
                </label>
                <input
                  type="text"
                  id="buy_price"
                  name="buy_price"
                  className={`form-control ${
                    errors.buy_price ? "is-invalid" : ""
                  }`}
                  value={formData.buy_price || ""}
                  onChange={handleInputChange}
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="sell_price" className="form-label">
                  Sell Price
                </label>
                <input
                  type="text"
                  id="sell_price"
                  name="sell_price"
                  className={`form-control ${
                    errors.sell_price ? "is-invalid" : ""
                  }`}
                  value={formData.sell_price || ""}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-md-6">
                <label htmlFor="profit_loss_amt" className="form-label">
                  Profit & Loss Amount
                </label>
                <input
                  type="text"
                  id="profit_loss_amt"
                  name="profit_loss_amt"
                  className={`form-control ${
                    errors.profit_loss_amt ? "is-invalid" : ""
                  }`}
                  value={formData.profit_loss_amt || ""}
                  onChange={handleInputChange}
                  readOnly
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="sell_price" className="form-label">
                  Different Amount
                </label>
                <input
                  type="text"
                  id="different_amt"
                  name="different_amt"
                  className={`form-control ${
                    errors.different_amt ? "is-invalid" : ""
                  }`}
                  value={formData.different_amt || ""}
                  onChange={handleInputChange}
                  readOnly
                />
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-md-6">
                <label htmlFor="status" className="form-label">
                  Status
                </label>
                <select
                  id="status"
                  name="status"
                  className={`form-control ${
                    errors.status ? "is-invalid" : ""
                  }`}
                  value={formData.status || ""}
                  onChange={handleInputChange}
                >
                  <option value="1">Active</option>
                  <option value="0">Inactive</option>
                </select>
              </div>
            </div>

            <div className="  text-end">
              <button type="submit" className="btn btn-primary">
                Update
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
