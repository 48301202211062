import React, { useState, useEffect } from "react";
import axios from "axios";
import { Container, Row, Col, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom';
import Swal from "sweetalert2";
export default function Addplan() {
  const broker_id = localStorage.getItem("broker_id");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const Addplan_id = localStorage.getItem("Addplan_id");
  const [newImage, setNewImage] = useState();
  const [fileimage, setFileimage] = useState();
  const [errors, setErrors] = useState({});
  const [data, setData] = useState([]);
  const [datacom, setDatacompny] = useState([]);
  const [datatype, setDatatype] = useState([]);
  const [dataplan, setDataplan] = useState([]);

  const [formData, setFormData] = useState({
    name: "",
    description: "",
    minimum_investment: "",
    plan_duration: "",
    plan_duration_type: "",
    interest_rate: "",
    invested_amount: "",
    is_compound: "",
  });

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setNewImage(file);
    setFileimage(URL.createObjectURL(file));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let formattedValue = value;
    if (name === "minimum_investment" && (value.length > 10 || isNaN(value))) {
      return;
    }
    if (name === "interest_rate" && (value.length > 8 || isNaN(value))) {
      return;
    }
    if (name === "name") {
      // Remove all non-letter characters and limit to 8 characters
      formattedValue = value
        .replace(/[^A-Za-z]/g, "")  // Remove all non-letter characters
        .slice(0, 40);               // Limit the length to 8 characters
    }
    
    setFormData({ ...formData, [name]: formattedValue  });
    setErrors((prevErrors) => ({ ...prevErrors, [name]: null }));
  };

  useEffect(() => {
    fetchData();
    fetchData1();
    fetchDataine_plan();
  }, []);

  const fetchDataine_plan = async () => {
    try {
      const token = localStorage.getItem("bear_token");

      const formData = new FormData();
      formData.append("smalladmins_id", broker_id);
      // formData.append("status", '1');

      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}small-admins-investment-plans-lists`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      };

      const response = await axios(config);
      setDataplan(response.data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchData = async () => {
    const token = localStorage.getItem("bear_token");

    try {
      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}small-admins-paln-duration`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      };

      const response = await axios(config);
      const rawData = response.data.data;

   
      const transformedData = rawData.map((item) => ({
        id: item,
        name: `${item}`,
      }));

      console.warn(transformedData);


      setData(transformedData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchData1 = async () => {
    const token = localStorage.getItem("bear_token");

    try {
      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}small-admins-plan-duration-type`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      };

      const response = await axios(config);
      const rawData = response.data.data;

      const transformedData = rawData.map((item) => ({
        id: item,
        name: `${item}`, 
      }));

   

    
      setDatatype(transformedData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const requiredFields = [
      { field: "name", name: "Name" },
      // { field: "description", name: "Description" },
      { field: "minimum_investment", name: "Minimum Investment" },
      { field: "plan_duration", name: "Plan duration" },
      { field: "plan_duration_type", name: "Plan duration type" },
      { field: "interest_rate", name: "Interest Rate" },
      { field: "is_compound", name: "is_compound" },
   
    ];

    let validationErrors = {};
    let hasError = false;

    for (const { field, name } of requiredFields) {
      if (!formData[field]) {
        validationErrors[field] = `Please fill in the ${name}.`;
        hasError = true;
      }
    }

    setErrors(validationErrors);

    if (hasError) {
      setLoading(false);
      return;
    }

    try {
      const token = localStorage.getItem("bear_token");
      const updatedFormData = new FormData();
      updatedFormData.append("smalladmins_id", broker_id);
      updatedFormData.append("cust_id", Addplan_id);
      updatedFormData.append("status", "1");
      Object.keys(formData).forEach((key) => {
        updatedFormData.append(key, formData[key]);
      });
      updatedFormData.append("plan_image", newImage);

      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}add-small-admins-investment-plans`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
        data: updatedFormData,
      };

      const response = await axios(config);
      const data = response.data.status;

      if (data === 1) {
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: response.data.message,
          timer: 1500
        });
        setTimeout(() => {
          navigate("/Investment-management");
        }, 2000);
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: response.data.message,
          timer: 2000
        });
      }
    } catch (error) {
      if (error.response && error.response.data) {
        const { error: errorCode, message } = error.response.data;
        if (errorCode === 1) {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: message,
            timer: 2000
          });
   
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: "An unexpected error occurred.",
            timer: 2000
          });
       
        }
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: "An unexpected error occurred.",
          timer: 2000
        });
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <div className="card mb-3">
        <div
          className="bg-holder d-none d-lg-block bg-card"
          style={{
            backgroundImage:
              "url(../../assets/img/icons/spot-illustrations/corner-4.png)",
          }}
        />
        <div className="card-body position-relative">
          <div className="row">
            <div className="col-lg-8">
              <h3 className="mb-0">Add Investment Plan</h3>
            </div>
            <div className="col-lg-4 text-end">
              <Link to="/Investment-management" className="">
                <button className="btn btn-success">Back</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-body">
          <Container fluid>
            <Row>
              <div className="col-md-6 form_details">
                <div className="form-group local-forms">
                  <label className="form-label" htmlFor="name">
                    Plan Name <span className="login-danger">*</span>
                  </label>
                  <input
                    className={`form-control ${
                      errors.name ? "input-error" : ""
                    }`}
                    id="name"
                    name="name"
                    type="text"
                    value={formData.name}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="col-md-6 form_details">
                <div className="form-group local-forms">
                  <label className="form-label" htmlFor="minimum_investment">
                    Minimum Investment <span className="login-danger">*</span>
                  </label>
                  <input
                    className={`form-control ${
                      errors.minimum_investment ? "input-error" : ""
                    }`}
                    id="minimum_investment"
                    name="minimum_investment"
                    type="text"
                    value={formData.minimum_investment}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="col-md-6 form_details mt-3">
                <div className="form-group local-forms">
                  <label className="form-label" htmlFor="interest_rate">
                    Interest Rate <span className="login-danger">*</span>
                  </label>
                  <input
                    className={`form-control ${
                      errors.interest_rate ? "input-error" : ""
                    }`}
                    id="interest_rate"
                    name="interest_rate"
                    type="text"
                    value={formData.interest_rate}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="col-md-6 form_details mt-3">
  <div className="form-group local-forms">
    <label className="form-label" htmlFor="is_compound">
      Select Compound <span className="login-danger">*</span>
    </label>
    <select
      className={`form-select ${
        errors.is_compound ? "input-error" : ""
      }`}
      id="is_compound"
      name="is_compound"
      value={formData.is_compound}
      onChange={handleInputChange}
    >
      <option value=" ">Select</option>
      <option value="0">Not Compound Interest</option>
      <option value="1">Compound Interest</option>
    </select>
  </div>
</div>

              <div className="col-md-6 form_details mt-3">
                <div className="form-group local-forms">
                  <label className="form-label" htmlFor="interest_rate">
                    Plan Duration <span className="login-danger">*</span>
                  </label>
                  <select
                    className={`form-select ${
                      errors.plan_duration ? "input-error" : ""
                    }`}
                    id="plan_duration"
                    name="plan_duration"
                    value={formData.plan_duration}
                    onChange={handleInputChange}
                  >
                    <option value="">Select</option>
                    {data.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-md-6 form_details mt-3">
                <div className="form-group local-forms">
                  <label className="form-label" htmlFor="plan_duration_type">
                    Plan Duration Type <span className="login-danger">*</span>
                  </label>
                  <select
                    className={`form-select ${
                      errors.plan_duration_type ? "input-error" : ""
                    }`}
                    id="plan_duration_type"
                    name="plan_duration_type"
                    value={formData.plan_duration_type}
                    onChange={handleInputChange}
                  >
                    <option value="">Select Type</option>
                    {datatype.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>  

              <div className="col-md-12 form_details mt-3">
                <div className="form-group local-forms">
                  <label>Description </label>
                  <textarea
                    className={`w-100 form-control`}
                    id="description"
                    name="description"
                    value={formData.description}
                    onChange={handleInputChange}
                    placeholder="Description"
                    rows="3"
                  />
                </div>
              </div>
              <Col md={6}>
                <div className="mb-3">
                  <label className="form-label" htmlFor="plan_image">
                    Plan Image
                  </label>
                  <input
                    className="form-control"
                    id="plan_image"
                    type="file"
                    onChange={handleImageChange}
                  />
                </div>
              </Col>

              {fileimage != null && (
                <Col md={6}>
                  <div className="mb-3">
                    <img
                      className=""
                      src={fileimage}
                      alt="fileimage"
                      width={150}
                      height={80}
                    />
                  </div>
                </Col>
              )}
              <Col md={12}>
                <div className="mb-3 d-flex justify-content-end">
                  <button
                    onClick={handleSubmit}
                    disabled={loading}
                    className="btn btn-sm btn-primary"
                    type="submit"
                  >
                    Submit
                    {loading && (
                      <Spinner
                        animation="border"
                        style={{
                          width: "15px",
                          height: "15px",
                          marginLeft: "10px",
                        }}
                      />
                    )}
                  </button>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </div>
  );
}
