import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { FaRegEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { FaEye } from "react-icons/fa";
import { IoMdAdd } from "react-icons/io";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import { IoMdPersonAdd } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
import { IoIosArrowBack } from "react-icons/io";
import { BsFiletypePdf } from "react-icons/bs";
import { MdCurrencyRupee } from "react-icons/md";
export default function Employeemanagement() {
  const [data, setData] = useState([]);
  const [baseurl, setbaseurl] = useState();
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState(""); // State to hold the search term
  const navigate = useNavigate();
  const custumerdetails_id = localStorage.getItem("custumerdetails");
  const [AllEmployee, setAllEmployee] = useState(""); // State to hold the search term
  const [ActiveEmployee, setActiveEmployee] = useState(""); // State to hold the search term
  const [InactiveEmployee, setInactiveEmployee] = useState(""); // State to hold the search term
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const fetchData = async () => {
    try {
      setLoading(true);

      // Fetch data from API
      const token = localStorage.getItem("bear_token");
      const broker_id = localStorage.getItem("broker_id");

      const formData = new FormData();
      formData.append("smalladmins_id", broker_id);
      formData.append("status", "1");

      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}small-admins-emp-lists`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      };

      const response = await axios(config);
      const data = response.data.data;
      setData(data);
      const data1 = response.data.image_url;
      setbaseurl(data1);
      const totalEmp = response.data.totalEmp;
      const ActiveEmployee = response.data.totalActiveEmp;
      const InactiveEmployee = response.data.totalInactiveEmp;
      setAllEmployee(totalEmp);
      setActiveEmployee(ActiveEmployee);
      setInactiveEmployee(InactiveEmployee);
      // Set fetched data
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  const Delete = async (id) => {
    const token = localStorage.getItem("bear_token");
    const broker_id = localStorage.getItem("broker_id");
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        // width:300,
        // height:10,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      });
      if (result.isConfirmed) {
        setLoading(true);
        const formData = new FormData();
        formData.append("smalladmins_id", broker_id);
        formData.append("emp_id", id);

        const config = {
          method: "post",
          url: `${process.env.REACT_APP_API_URL}delete-small-admins-emp`,
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
          data: formData,
        };

        const response = await axios(config);
        const data = response.data.status;
        if (data === 1) {
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: response.data.message,
            // timer: 2000
          });
          // toast.success(response.data.message);
          setLoading(false);
          fetchData();
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: response.data.message,
            // timer: 2000
          });
          // toast.error(response.data.message);
          setLoading(false);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };
  const handleStatusChange = async (business_id, currentStatus) => {
    const token = localStorage.getItem("bear_token");
    const broker_id = localStorage.getItem("broker_id");
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, change status!",
      });

      if (result.isConfirmed) {
        setLoading(true);

        const formData = new FormData();
        formData.append("smalladmins_id", broker_id);
        formData.append("emp_id", business_id);
        formData.append("status", currentStatus === 1 ? 0 : 1);

        const config = {
          method: "post",
          url: `${process.env.REACT_APP_API_URL}small-admins-emp-update-status`,
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
          data: formData,
        };

        const response = await axios(config);
        const data = response.data.status;

        if (data === 1) {
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: response.data.message,
            // timer: 2000
          });
          // toast.success(response.data.message);
          fetchData(); // Refresh data after status change
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: response.data.message,
            // timer: 2000
          });
        }

        setLoading(false);
      }
    } catch (error) {
      console.error("Error changing status:", error);
      toast.error("There was an error changing the status.");
      setLoading(false);
    }
  };
  const filteredData = data ? data.filter((item) =>
    item.emp_name.toLowerCase().includes(searchTerm.toLowerCase())
  ):[];

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedData = filteredData.slice(startIndex, endIndex);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleClickadd = (id) => {
    navigate("/Addemployee");
  };
  const handleClickinvestmentId = (id) => {
    localStorage.setItem("employee_id", id);
    navigate("/EmployeeView");
  };
  const handleClickinvestmenteditId = (id) => {
    localStorage.setItem("employee_id", id);
    navigate("/Employeeedit");
  };
  const handleClickiPaySlip = (id) => {
    localStorage.setItem("employeePaySlip_id", id);
    navigate("/Pay-Slip-Details");
  };
  return (
    <>
      <div className="card mb-3">
        <div
          className="bg-holder d-none d-lg-block bg-card"
          style={{
            backgroundImage:
              "url(../../assets/img/icons/spot-illustrations/corner-4.png)",
          }}
        />
        {/*/.bg-holder*/}
        <div className="card-body position-relative">
          <div className="row">
            <div className="col-lg-8">
              <h3 className="mb-0">Employee Analytics</h3>
            </div>
            <div className="col-lg-4 text-end">
              <Link to="/home" className="">
                <button className="btn btn-success">Back</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="row g-3 mb-3">
        <div className="col-md-4 col-xxl-3">
          <Link
            to="/Employee-management"
            className="linkWithoutUnderline active"
          >
            <div className="card h-md-100 ecommerce-card-min-width">
              <div className="card-header pb-0">
                <h5 className="mb-0 mt-2 d-flex align-items-center">Total</h5>
              </div>
              <div className="card-body d-flex flex-column justify-content-end">
                <div className="row align-items-center">
                  <div className="col pe-0">
                    <IoMdPersonAdd className="fs-4 text-primary" />
                    {/* <img src={company} alt="company" className="img-fluid"/> */}
                  </div>
                  <div className="col ps-0">
                    <p className="font-sans-serif lh-1 mb-1 fs-5 text-primary">
                      {AllEmployee}
                    </p>
                    {/* <span className="badge badge-subtle-success rounded-pill fs-11">
                +3.5%
              </span> */}
                  </div>
                  <div className="col-auto ps-0">
                    <div className="echart-bar-weekly-sales h-100" />
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div>
        <div className="col-md-4 col-xxl-3">
          <Link
            to="/Active-Employeemanagement"
            className="linkWithoutUnderline"
          >
            <div className="card h-md-100">
              <div className="card-header pb-0">
                <h5 className="mb-0 mt-2"> Active </h5>
              </div>
              <div className="card-body d-flex flex-column justify-content-end">
                <div className="row justify-content-between">
                  <div className="col pe-0">
                    <IoMdPersonAdd className="fs-4 text-success" />
                  </div>
                  <div className="col ps-0">
                    <div className="fs-5 fw-normal font-sans-serif text-700 lh-1 mb-1 ">
                      <p className="font-sans-serif lh-1 mb-1 fs-5 text-success">
                        {ActiveEmployee}
                      </p>
                    </div>
                    {/* <span className="badge rounded-pill fs-11 bg-200 text-primary">
                <span className="fas fa-caret-up me-1" />
                13.6%
              </span> */}
                  </div>
                  <div className="col-auto ps-0 mt-n4">
                    <div
                      className="echart-default-total-order"
                      data-echarts='{"tooltip":{"trigger":"axis","formatter":"{b0} : {c0}"},"xAxis":{"data":["Week 4","Week 5","Week 6","Week 7"]},"series":[{"type":"line","data":[20,40,100,120],"smooth":true,"lineStyle":{"width":3}}],"grid":{"bottom":"2%","top":"2%","right":"10px","left":"10px"}}'
                      data-echart-responsive="true"
                    />
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div>
        <div className="col-md-4 col-xxl-3">
          <Link
            to="/Inactive-Employeemanagement "
            className="linkWithoutUnderline"
          >
            <div className="card h-md-100">
              <div className="card-header pb-0">
                <h5 className="mb-0 mt-2"> Inactive </h5>
              </div>
              <div className="card-body d-flex flex-column justify-content-end">
                <div className="row justify-content-between">
                  <div className="col pe-0">
                    <IoMdPersonAdd className="fs-4 text-danger" />
                  </div>
                  <div className="col ps-0">
                    <div className="fs-5 fw-normal font-sans-serif text-700 lh-1 mb-1">
                      <p className="font-sans-serif lh-1 mb-1 fs-5  text-danger">
                        {InactiveEmployee}
                      </p>
                    </div>
                    {/* <span className="badge rounded-pill fs-11 bg-200 ">
                <span className="fas fa-caret-up me-1" />
                13.6%
              </span> */}
                  </div>
                  <div className="col-auto ps-0 mt-n4">
                    <div
                      className="echart-default-total-order"
                      data-echarts='{"tooltip":{"trigger":"axis","formatter":"{b0} : {c0}"},"xAxis":{"data":["Week 4","Week 5","Week 6","Week 7"]},"series":[{"type":"line","data":[20,40,100,120],"smooth":true,"lineStyle":{"width":3}}],"grid":{"bottom":"2%","top":"2%","right":"10px","left":"10px"}}'
                      data-echart-responsive="true"
                    />
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div>
      </div>
      <div className="card mb-3">
        <div
          className="bg-holder d-none d-lg-block bg-card"
          style={{
            backgroundImage:
              "url(../../assets/img/icons/spot-illustrations/corner-4.png)",
          }}
        />
        {/*/.bg-holder*/}
        <div className="card-body position-relative">
          <div className="row">
            <div className="col-lg-8">
              <h3 className="mb-0">Employee List</h3>
            </div>
            <div className="col md-4 d-flex justify-content-end border-0 shadow-none">
              <button
                type="button"
                className="btn btn-sm btn-success"
                onClick={handleClickadd}
              >
                {" "}
                <IoMdAdd className="me-1 fs--2" /> Add Employee
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="card"
        id="ticketsTable"
        data-list='{"valueNames":["client","subject","status","priority","agent"],"page":11,"pagination":true,"fallback":"tickets-table-fallback"}'
      >
        <div className="card-header justify-content-between d-flex border-bottom border-200">
          <div className="justify-content-end d-flex w-100">
            <form>
              <div className="input-group input-search-width">
                <input
                  className="form-control form-control-sm shadow-none search"
                  type="search"
                  placeholder="Search by name"
                  aria-label="search"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
            </form>
          </div>
        </div>
        {loading && (
          <div id="preloader">
            <div id="loading-center">
              <div className="loader">
                <div className="waviy">
                  <img
                    src="https://swaninvestment.in/frontant/assets/img/loader.png"
                    alt="Loader image"
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="card-body p-0">
          <div className="table-responsive scrollbar">
            <table className="table table-sm mb-0 fs-10 table-view-tickets">
              <thead className="bg-body-tertiary">
                <tr>
                  <th className="text-800 sort align-middle">sr.no</th>
                
                  <th className="text-800 sort align-middle">Name</th>
                  <th className="text-800 sort align-middle">email</th>
                  <th className="text-800 sort align-middle">mobile</th>

                  <th className="text-800 sort align-middle">Total Amount</th>
                  <th className="text-800 sort align-middle">Password</th>
            
                  <th className="text-800 sort align-middle">status</th>
                  <th className="text-800 sort align-middle">Action</th>
                </tr>
              </thead>
              <tbody className="list" id="table-ticket-body">
                {paginatedData && paginatedData.length > 0 ? (
                  paginatedData.map((item, index) => (
                    <tr>
                      <td>{index + 1}</td>
                 
                      <td className="capitalize">{item.emp_name}</td>
                     
                      <td><a href={`mailto:${item.email}`}>{item.email}</a></td>
                      <td>{item.mobile}</td>
                      <td className="capitalize"> <MdCurrencyRupee /> {item.user_credit}</td>
                      <td>{item.password}</td>
                   
                      <td>
                        <div className="form-check form-switch">
                          <input
                            className="form-check-input toggle_btn"
                            type="checkbox"
                            role="switch"
                            id={`flexSwitchCheckDefault-${item.emp_id}`}
                            checked={item.status === 1}
                            onChange={() =>
                              handleStatusChange(item.emp_id, item.status)
                            }
                            disabled={loading}
                          />
                        </div>
                      </td>

                    
                      <td className="d-flex">
                        <div className="d-flex gap-1 action_button">
                          <OverlayTrigger
                            key="Edit"
                            placement="top"
                            overlay={<Tooltip id={`Edit`}>Edit</Tooltip>}
                          >
                            <button
                              onClick={() =>
                                handleClickinvestmenteditId(item.emp_id)
                              }
                              style={{ "--i": "#3498db" }}
                            >
                              {" "}
                              <FaRegEdit className="color_link" />
                            </button>
                          </OverlayTrigger>

                          <OverlayTrigger
                            key="View"
                            placement="top"
                            overlay={<Tooltip id={`View`}>View</Tooltip>}
                          >
                            <button
                              type="button"
                              onClick={() =>
                                handleClickinvestmentId(item.emp_id)
                              }
                              style={{ "--i": "#07bc0c" }}
                            >
                              <FaEye className="color_link" />
                            </button>
                          </OverlayTrigger>
                          <OverlayTrigger
                            key="Delete"
                            placement="top"
                            overlay={<Tooltip id={`Delete`}>Delete</Tooltip>}
                          >
                            <Link
                              style={{ "--i": "red" }}
                              onClick={() => {
                                Delete(item.emp_id);
                              }}
                            >
                              {" "}
                              <MdDelete className="color_link" />
                            </Link>
                          </OverlayTrigger>

                          {/* <OverlayTrigger
                            key="Update"
                            placement="top"
                            overlay={
                              <Tooltip id={`Update`}>Pay Slip Details</Tooltip>
                            }
                          >
                            <button
                              type="button"
                              style={{ "--i": "#ba0425", width: "94px" }}
                            >
                              <FaEye
                                className="color_link"
                                onClick={() => handleClickiPaySlip(item.id)}
                              />
                              Pay Slip
                            </button>
                          </OverlayTrigger>
                          <OverlayTrigger
                            key="Update"
                            placement="top"
                            overlay={
                              <Tooltip id={`Update`}>
                                Transaction Details{" "}
                              </Tooltip>
                            }
                          >
                            <button
                              type="button"
                              style={{ "--i": "#d35d0c", width: "110px" }}
                            >
                              <FaEye className="color_link" />
                              Transaction
                            </button>
                          </OverlayTrigger> */}
                        </div>
                        {/* <button type="button" className="btn btn-sm btn-danger mx-1" onClick={handleClickadd}> <BsFiletypePdf className="me-1 fs--4" />Pay Slip</button>
                        <button type="button" className="btn btn-sm btn-danger mx-1" onClick={handleClickadd}> <BsFiletypePdf className="me-1 fs--4" />Transaction </button> */}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="12" className="text-center py-4">
                      No data found.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <div className="text-center d-none" id="tickets-table-fallback">
              <p className="fw-bold fs-8 mt-3">No ticket found</p>
            </div>
            {totalPages > 1 && (
              <div className="pagination mt-3 mb-3 mx-3">
                <button
                  className="btn btn-sm btn-light"
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                >
                  <IoIosArrowBack />
                </button>
                {[...Array(totalPages).keys()].map((pageNumber) => (
                  <button
                    key={pageNumber + 1}
                    className={`btn btn-sm mx-1 ${
                      currentPage === pageNumber + 1
                        ? "btn-light text-primary"
                        : "btn-light"
                    }`}
                    onClick={() => handlePageChange(pageNumber + 1)}
                  >
                    {pageNumber + 1}
                  </button>
                ))}
                <button
                  className="btn btn-sm btn-light"
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalPages}
                >
                  <IoIosArrowForward />
                </button>
              </div>
            )}
          </div>
        </div>
        {/* <div className="card-footer">
          <div className="d-flex justify-content-center">
            <button
              className="btn btn-sm btn-falcon-default me-1 disabled"
              type="button"
              title="Previous"
              data-list-pagination="prev"
              disabled=""
            >
              <span className="fas fa-chevron-left" />
            </button>
            <ul className="pagination mb-0">
              <li className="active">
                <button className="page" type="button" data-i={1} data-page={11}>
                  1
                </button>
              </li>
              <li>
                <button className="page" type="button" data-i={2} data-page={11}>
                  2
                </button>
              </li>
              <li>
                <button className="page" type="button" data-i={3} data-page={11}>
                  3
                </button>
              </li>
            </ul>
            <button
              className="btn btn-sm btn-falcon-default ms-1"
              type="button"
              title="Next"
              data-list-pagination="next"
            >
              <span className="fas fa-chevron-right" />
            </button>
          </div>
        </div> */}
      </div>
      <ToastContainer />
    </>
  );
}
