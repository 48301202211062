import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate,Link } from 'react-router-dom';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import userprofile from "../../assets/img/gallery/2.jpg";
import company from "../../assets/img/icons/building.png"
import { GiReceiveMoney } from "react-icons/gi";
import { FaMoneyCheckAlt, FaRegCalendarAlt, FaInfoCircle ,FaTimesCircle } from 'react-icons/fa';

export default function InvestmentView() {
  const { id } = useParams(); 
  const navigate = useNavigate();
  const [business, setBusiness] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [baseurl, setbaseurl] = useState();
  const [data, setData] = useState([]);
  const [AllEmployee, setAllEmployee] = useState(''); 
const [ActiveEmployee, setActiveEmployee] = useState(''); 
const [InactiveEmployee, setInactiveEmployee] = useState('')
  const fetchDataList = async () => {
    try {
      setLoading(true);

      // Fetch data from API
      const token = localStorage.getItem("bear_token");
      const broker_id = localStorage.getItem("broker_id");

      const formData = new FormData();
      formData.append("smalladmins_id", broker_id);
      formData.append("status", '1');
      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}small-admins-investment-plans-lists`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data"
        },
        data: formData
      };

      const response = await axios(config);
      const totalEmp = response.data.totalPlans;
      const ActiveEmployee = response.data.ActivePlans;
      // alert(ActiveEmployee)
      const InactiveEmployee = response.data.InactivePlans;
    
      setAllEmployee(totalEmp)
      setActiveEmployee(ActiveEmployee)
      setInactiveEmployee(InactiveEmployee)
      setLoading(false);

    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDataList();
  }, []);
  const fetchData = async () => {
    try {
      setLoading(true);

      // Fetch data from API
      const token = localStorage.getItem("bear_token");
      const broker_id = localStorage.getItem("broker_id");
      const investment_id = localStorage.getItem("investment_id");

      const formData = new FormData();
      formData.append("smalladmins_id", broker_id);
      formData.append("id", investment_id);

      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}view-small-admins-investment-plans`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data"
        },
        data: formData
      };

      const response = await axios(config);
      const data = response.data.data;
      const data1 = response.data.url;
      setbaseurl(data1)
      // Set fetched data
      setData(data);
      setLoading(false);

    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <>
 <div className="card mb-3">
        <div
          className="bg-holder d-none d-lg-block bg-card"
          style={{
            backgroundImage:
              "url(../../assets/img/icons/spot-illustrations/corner-4.png)"
          }}
        />
        {/*/.bg-holder*/}
        <div className="card-body position-relative">
          <div className="row">
            <div className="col-lg-8">
             <h3 className="mb-0">Investment Plan Analytics</h3>
            </div>
            <div className="col-lg-4 text-end">
              <Link to="/Investmentedit" className="">
                <button className="btn btn-primary">Update</button>
              </Link>
              <Link to="/Investment-management" className="mx-1">
                <button className="btn btn-success">Back</button>
              </Link>
            </div>
          </div>
        </div>

      </div>
   
      <div className="row g-3 mb-3">
        <div className="col-md-4 col-xxl-3">
        <Link to='/Investment-management' className="linkWithoutUnderline">
          <div className="card h-md-100 ecommerce-card-min-width">
            <div className="card-header pb-0">
              <h5 className="mb-0 mt-2 d-flex align-items-center">
              Total 
              </h5>
            </div>
            <div className="card-body d-flex flex-column justify-content-end">
              <div className="row align-items-center">
                <div className="col pe-0">
                  <GiReceiveMoney className="fs-4 text-success" />
                </div>
                <div className="col ps-0">
                  <p className="font-sans-serif lh-1 mb-1 fs-5 text-success">
                    {AllEmployee}
                  </p>
                </div>
                <div className="col-auto ps-0">
                  <div className="echart-bar-weekly-sales h-100" />
                </div>
              </div>
            </div>
          </div>
          </Link>
        </div>
        <div className="col-md-4 col-xxl-3">
        <Link to='/Active-plan' className="linkWithoutUnderline">
          <div className="card h-md-100">
            <div className="card-header pb-0">
              <h5 className="mb-0 mt-2"> Active </h5>
            </div>
            <div className="card-body d-flex flex-column justify-content-end">
              <div className="row justify-content-between">
                <div className="col pe-0">
                  <GiReceiveMoney className="fs-4 text-primary" />
                </div>
                <div className="col ps-0">
                  <div className="fs-5 fw-normal font-sans-serif text-700 lh-1 mb-1 ">
                    <p className="font-sans-serif lh-1 mb-1 fs-5 text-primary">
                      {ActiveEmployee}
                    </p>
                  </div>
                </div>
                <div className="col-auto ps-0 mt-n4">
                  <div
                    className="echart-default-total-order"
                    data-echarts='{"tooltip":{"trigger":"axis","formatter":"{b0} : {c0}"},"xAxis":{"data":["Week 4","Week 5","Week 6","Week 7"]},"series":[{"type":"line","data":[20,40,100,120],"smooth":true,"lineStyle":{"width":3}}],"grid":{"bottom":"2%","top":"2%","right":"10px","left":"10px"}}'
                    data-echart-responsive="true"
                  />
                </div>
              </div>
            </div>
          </div>
          </Link>
        </div>
        <div className="col-md-4 col-xxl-3">
        <Link to='/Inactive-plan' className="linkWithoutUnderline">
          <div className="card h-md-100">
            <div className="card-header pb-0">
              <h5 className="mb-0 mt-2"> Inactive </h5>
            </div>
            <div className="card-body d-flex flex-column justify-content-end">
              <div className="row justify-content-between">
                <div className="col pe-0">
                  <GiReceiveMoney className="fs-4 text-danger" />
                </div>
                <div className="col ps-0">
                  <div className="fs-5 fw-normal font-sans-serif text-700 lh-1 mb-1">
                    <p className="font-sans-serif lh-1 mb-1 fs-5  text-danger">
                      {InactiveEmployee}
                    </p>
                  </div>
                </div>
                <div className="col-auto ps-0 mt-n4">
                  <div
                    className="echart-default-total-order"
                    data-echarts='{"tooltip":{"trigger":"axis","formatter":"{b0} : {c0}"},"xAxis":{"data":["Week 4","Week 5","Week 6","Week 7"]},"series":[{"type":"line","data":[20,40,100,120],"smooth":true,"lineStyle":{"width":3}}],"grid":{"bottom":"2%","top":"2%","right":"10px","left":"10px"}}'
                    data-echart-responsive="true"
                  />
                </div>
              </div>
            </div>
          </div>
          </Link>
        </div>
      </div>
      <div className="card mb-3">
        <div
          className="bg-holder d-none d-lg-block bg-card"
          style={{
            backgroundImage:
              "url(../../assets/img/icons/spot-illustrations/corner-4.png)"
          }}
        />
        <div className="card-body position-relative">
          <div className="row">
            <div className="col-lg-8">
              <h3 className="mb-0">Investment Plan Details</h3>
            </div>
          </div>
        </div>
      </div>




    <div className="card-body bg-body-tertiary">
        {data && data.map((plan) => (
            <div className="card mb-3" key={plan.id}>
                <div className="card-header border-bottom">
                    <div className="card-title mb-0">Plan Details</div>
                </div>
                <div className="card-body">
                    <div className="row g-3">
                        <div className="col-md-12">
                            <table className="table ">
                                <tbody>
                                    <tr>
                                        <th scope="row">Plan ID</th>
                                        <td className="d-flex align-items-center" style={{ color: 'brown' }}>
                                            {/* < GiReceiveMoney className="me-2"  /> */}
                                            # {plan.id}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Plan Name</th>
                                        <td className="d-flex align-items-center capitalize" style={{ color: 'green' }}>
                                            < GiReceiveMoney className="me-2"  />
                                            {plan.name}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Interest Rate</th>
                                        <td className="d-flex align-items-center" style={{ color: 'purple' }}>
                                            <FaMoneyCheckAlt className="me-2" />
                                            {plan.interest_rate} %
                                        </td>
                                    </tr>
                                    <tr>
                                    <th
                                        scope="row"
                                    
                                    >
                                        {plan.is_compound === 1 ? 'Compound' : 'Compound'} 
                                    </th>
                                    <td
                                        className="d-flex align-items-center"
                                        style={{ color: plan.is_compound === 1 ? 'green' : 'red' }} 
                                    >
                                        {plan.is_compound === 1 ? (
                                <FaMoneyCheckAlt className="me-2" /> 
                            ) : (
                                <FaTimesCircle className="me-2" /> 
                            )}
                                        {plan.is_compound === 1 ? 'Applicable' : 'Not Applicable'} 
                                    </td>
                                </tr>


                                    <tr>
                                        <th scope="row">Plan Duration</th>
                                        <td className="d-flex align-items-center"style={{ color: 'blue' }}>
                                            <FaRegCalendarAlt className="me-2"  />
                                            {`${plan.plan_duration} ${plan.plan_duration_type}`}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Description</th>
                                        <td >
                                        <FaInfoCircle className="me-2" />
                                          {plan.description}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Plan Image</th>
                                        <td>
                                            {plan.plan_image === null ?
                                                <img src={userprofile} height={80} width={100} alt="plan_image" className="img-fluid" />
                                                :
                                                <img src={`${baseurl}/${plan.plan_image}`} height={100} width={100} alt="plan_image" className="img-fluid" />
                                            }
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        
                    </div>
                </div>
            </div>
        ))}
    </div>
 

      <ToastContainer />
    </>
      
 
  );
}
