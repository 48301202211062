import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate ,Link} from 'react-router-dom';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import userprofile from "../../assets/img/gallery/2.jpg";
import company from "../../assets/img/icons/building.png"
import { GiReceiveMoney,GiTakeMyMoney ,GiReturnArrow } from "react-icons/gi";
import { IoMdPersonAdd } from "react-icons/io";
import { MdOutlineMarkEmailRead } from "react-icons/md";
import { IoPersonSharp } from "react-icons/io5";
import { FaUserAlt, FaRegBuilding, FaMoneyBillAlt, FaRegEnvelope, FaRegAddressCard, FaMoneyCheckAlt } from 'react-icons/fa';
import { MdOutlineAccountBalanceWallet } from "react-icons/md";
export default function BusinessetView() {
  const { id } = useParams(); 
  const navigate = useNavigate();
  const [business, setBusiness] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [baseurl, setbaseurl] = useState();
  const [data, setData] = useState([]);
  const [AllBusinesesCustomers, setBusinesesCustomers] = useState();
  const [ActiveBusinesesCustomers, setActiveBusinesesCustomers] = useState();
  const [InactiveBusinesesCustomers, setInactiveBusinesesCustomers] = useState();
  const fetchDataList = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem("bear_token");
      const broker_id = localStorage.getItem("broker_id");

      const formData = new FormData();
      formData.append("smalladmins_id", broker_id);

      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}small-admins-business-customer-lists`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      };

      const response = await axios(config);
   

      // setBusinesesCustomers(response.data.totalBusinesesCustomers)
      // setActiveBusinesesCustomers(response.data.totalActiveBusinesesCustomers)
      // setInactiveBusinesesCustomers(response.data.totalInActiveBusinesesCustomers)
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDataList();
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);

      // Fetch data from API
      const token = localStorage.getItem("bear_token");
      const broker_id = localStorage.getItem("broker_id");
      const customerBusinesset_id = localStorage.getItem("customerBusinesset_id");

      const formData = new FormData();
      formData.append("smalladmins_id", broker_id);
      formData.append("business_cust_id", customerBusinesset_id);

      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}view-small-admins-business-customer`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data"
        },
        data: formData
      };

      const response = await axios(config);
      const data = response.data.data;
      const data1 = response.data.url;

      setBusinesesCustomers(response.data.totalBusinessCustomerInvestedPlansAmount)
      setActiveBusinesesCustomers(response.data.totalBusinessCustomerInvestedPlansCount)
      setInactiveBusinesesCustomers(response.data.totalBusinessCustomerInvestedreturnsGeneratedAmount)
      setbaseurl(data1)
      // Set fetched data
      setData(data);
      setLoading(false);

    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <>
    <div className="card mb-3">
        <div
          className="bg-holder d-none d-lg-block bg-card"
          style={{
            backgroundImage:
              "url(../../assets/img/icons/spot-illustrations/corner-4.png)"
          }}
        />
        <div className="card-body position-relative">
          <div className="row">
            <div className="col-lg-8">
              <h2 className="mb-0">Business Customer Analytics</h2>
            </div>
            <div className="col-lg-4 text-end">
            <Link to='/Business-edit' className="">
              <button className="btn btn-primary">Update</button>
            </Link>
            <Link to='/Bussiness-Customer-management' className="mx-1">
              <button className="btn btn-success">Back</button>
            </Link>
            </div>
          </div>
        </div>

      </div>
    <div className="card mb-3">
        <div
          className="bg-holder d-none d-lg-block bg-card"
          style={{
            backgroundImage:
              "url(../../assets/img/icons/spot-illustrations/corner-4.png)"
          }}
        />
        <div className="card-body position-relative">
          <div className="row">
            <div className="col-lg-4"> 
            <Link to="/Bussiness-Customer-Transaction-Details" className="linkWithoutUnderline">
              <h4 className="mb-0 btn btn-primary w-100">Transactions</h4>
            </Link>
            </div>
            <div className="col-lg-4">
              <h4 className="mb-0 btn btn-success w-100">Message History</h4>
            </div>
            <div className="col-lg-4">
              <h4 className="mb-0 btn btn-warning w-100">Send Message</h4>
            </div>

          </div>
        </div>

      </div>
      <div className="row g-3 mb-3">
        <div className="col-md-4 col-xxl-3">
      {/* <Link to='/Customermanagement' className="linkWithoutUnderline"> */}
      <Link to='#' className="linkWithoutUnderline">
          <div className="card h-md-100 ecommerce-card-min-width">
            <div className="card-header pb-0">
              <h5 className="mb-0 mt-2 d-flex align-items-center">
                Total Invested Amount
              </h5>
            </div>
            <div className="card-body d-flex flex-column justify-content-end">
              <div className="row align-items-center">
                <div className="col pe-0">
                  <GiReceiveMoney className="fs-4 text-success" />
                </div>
                <div className="col ps-0">
                  <p className="font-sans-serif lh-1 mb-1 fs-5 text-success">
                    {AllBusinesesCustomers}
                  </p>
                </div>
                <div className="col-auto ps-0">
                  <div className="echart-bar-weekly-sales h-100" />
                </div>
              </div>
            </div>
          </div>
        </Link>
        </div>
       
        <div className="col-md-4 col-xxl-3">
        <Link to='/Single-Bussiness-Customer-Plan' className="linkWithoutUnderline">
          <div className="card h-md-100">
            <div className="card-header pb-0">
              <h5 className="mb-0 mt-2"> Total Invested Plan</h5>
            </div>
            <div className="card-body d-flex flex-column justify-content-end">
              <div className="row justify-content-between">
                <div className="col pe-0">
                  <GiTakeMyMoney className="fs-4 text-primary" />
                </div>
                <div className="col ps-0">
                  <div className="fs-5 fw-normal font-sans-serif text-700 lh-1 mb-1 ">
                    <p className="font-sans-serif lh-1 mb-1 fs-5 text-primary">
                      {ActiveBusinesesCustomers}
                    </p>
                  </div>
                </div>
                <div className="col-auto ps-0 mt-n4">
                  <div
                    className="echart-default-total-order"
                    data-echarts='{"tooltip":{"trigger":"axis","formatter":"{b0} : {c0}"},"xAxis":{"data":["Week 4","Week 5","Week 6","Week 7"]},"series":[{"type":"line","data":[20,40,100,120],"smooth":true,"lineStyle":{"width":3}}],"grid":{"bottom":"2%","top":"2%","right":"10px","left":"10px"}}'
                    data-echart-responsive="true"
                  />
                </div>
              </div>
            </div>
          </div>
        </Link>
        </div>
        <div className="col-md-4 col-xxl-3">
        <Link to='#' className="linkWithoutUnderline">

          <div className="card h-md-100">
            <div className="card-header pb-0">
              <h5 className="mb-0 mt-2"> Total Return Generated</h5>
            </div>
            <div className="card-body d-flex flex-column justify-content-end">
              <div className="row justify-content-between">
                <div className="col pe-0">
                  <GiReturnArrow  className="fs-4 text-info" />
                </div>
                <div className="col ps-0">
                  <div className="fs-5 fw-normal font-sans-serif text-700 lh-1 mb-1">
                    <p className="font-sans-serif lh-1 mb-1 fs-5  text-info">
                      {InactiveBusinesesCustomers}
                    </p>
                  </div>
                </div>
                <div className="col-auto ps-0 mt-n4">
                  <div
                    className="echart-default-total-order"
                    data-echarts='{"tooltip":{"trigger":"axis","formatter":"{b0} : {c0}"},"xAxis":{"data":["Week 4","Week 5","Week 6","Week 7"]},"series":[{"type":"line","data":[20,40,100,120],"smooth":true,"lineStyle":{"width":3}}],"grid":{"bottom":"2%","top":"2%","right":"10px","left":"10px"}}'
                    data-echart-responsive="true"
                  />
                </div>
              </div>
            </div>
          </div>
        </Link>
        </div>
     
      </div>
      <div className="card mb-3">
        <div
          className="bg-holder d-none d-lg-block bg-card"
          style={{
            backgroundImage:
              "url(../../assets/img/icons/spot-illustrations/corner-4.png)"
          }}
        />
        <div className="card-body position-relative">
        {data && data.map((business)=>(
          <div className="row">
            <div className="col-lg-4 d-flex align-items-center">
            <h5 className="mb-0 capitalize d-flex align-items-center"> 
            
            
            {business.image === null ? 
            
            <>
            <IoPersonSharp className="fs-6 text-dark me-1" />
            </>
            :
            
            <img  src={`${baseurl}/${business.image}`}   height={35}
            width={35} alt="" className="img-fluid rounded-circle fs-6  me-1"  />
            }  {business.business_name}</h5>
            </div>
            <div className="col-lg-4 ">
           <h3 className="mb-0 text-success">  <MdOutlineAccountBalanceWallet   className="fs-4 text-success" /> {business.user_credit}</h3>
            </div>
          </div>
        ))}
        </div>
      </div>


<div className="row g-3">
    {data && data.map((business) => (
        <React.Fragment key={business.business_cust_id}>
            <div className="col-12 mb-3">
  
                <div className="card">
                    <div className="card-header" style={{ borderBottom: 'none' }}>
                        <div className="card-title mb-0">Business Details</div>
                    </div>
                    <div className="card-body bg-body-tertiary">
                        <table className="table">
                            <tbody>
                                <tr>
                                    <th scope="row">Business Customer ID</th>
                                    <td className="d-flex align-items-center fw-bold" style={{ color: 'brown' }}>
                                      
                                      #  {business.business_cust_id}
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">Business Name</th>
                                    <td className="d-flex align-items-center fw-bold capitalize" style={{ color: 'green' }}>
                                        <FaUserAlt className="me-2" />
                                        {business.business_name}
                                    </td>
                                </tr>
                            
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div className="col-12 mb-3">
     
                <div className="card">
                    <div className="card-header" style={{ borderBottom: 'none' }}>
                        <div className="card-title mb-0">Bank Details</div>
                    </div>
                    <div className="card-body bg-body-tertiary">
                        <table className="table">
                            <tbody>
                                <tr>
                                    <th scope="row">Account Holder Name</th>
                                    <td className="d-flex align-items-center fw-bold capitalize" style={{ color: 'blue' }}>
                                        <FaRegAddressCard className="me-2" />
                                        {business.ac_holder_name}
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">Account Number</th>
                                    <td className="d-flex align-items-center fw-bold" style={{ color: 'purple' }}>
                                        <FaMoneyBillAlt className="me-2" />
                                        {business.account_no}
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">IFSC</th>
                                    <td className="d-flex align-items-center fw-bold" style={{ color: 'teal' }}>
                                        <FaMoneyCheckAlt className="me-2" />
                                        {business.ifsc}
                                    </td>
                                </tr>
                            
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div className="col-12 mb-3">
         
                <div className="card">
                    <div className="card-header" style={{ borderBottom: 'none' }}>
                        <div className="card-title mb-0">Company Details</div>
                    </div>
                    <div className="card-body bg-body-tertiary">
                        <table className="table">
                            <tbody>
                                <tr>
                                    <th scope="row">Company Name</th>
                                    <td className="d-flex align-items-center fw-bold capitalize" style={{ color: 'red' }}>
                                        <FaRegBuilding className="me-2" />
                                        {business.company_name === '' ? "Not Assigned" : business.company_name}
                                    </td>
                                </tr>
                  
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div className="col-12 mb-3">
          
                <div className="card">
                    <div className="card-header" style={{ borderBottom: 'none' }}>
                        <div className="card-title mb-0">Admin Details</div>
                    </div>
                    <div className="card-body bg-body-tertiary">
                        <table className="table">
                            <tbody>
                                <tr>
                                    <th scope="row">Sub Admin Email</th>
                                    <td className="d-flex align-items-center fw-bold" style={{ color: 'orange' }}>
                                        <FaRegEnvelope className="me-2" />
                                        {business.sub_admin_email}
                                    </td>
                                </tr>
                            
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </React.Fragment>
    ))}
</div>




      <ToastContainer />
    </>
      
 
  );
}
