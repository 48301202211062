import React, { useState, useEffect } from "react";
import axios from "axios";
import { Spinner } from "react-bootstrap";
import { useNavigate, Link } from "react-router-dom";
import Swal from "sweetalert2";

export default function Settings() {
  const brokerId = localStorage.getItem("broker_id");
  const token = localStorage.getItem("bear_token");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [newImage, setNewImage] = useState(null);
  const [fileImage, setFileImage] = useState(null);
  const [errors, setErrors] = useState({});
  const [BaseUrl, setBaseUrl] = useState("");
  const [baseurlS, setBaseurlS] = useState("");
  const [formData, setFormData] = useState({
   
    small_admins_QR_img: null,
  });

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setFileImage(URL.createObjectURL(file));
    setNewImage(file);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));

    setErrors((prevErrors) => ({ ...prevErrors, [name]: null }));
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const formData = new FormData();
      formData.append("smalladmins_id", brokerId);

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}small-admins-general-settings-view`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const profileData = response.data.data[0];

      console.warn("formData", profileData);
      if (profileData) {
        setFormData({
          // naked_buy_sell_price: profileData.naked_buy_sell_price !== null ? profileData.naked_buy_sell_price.toString() : '',
          // hedge_price: profileData.hedge_price !== null ? profileData.hedge_price.toString() : '',
          // balance_till_now: profileData.balance_till_now !== null ? profileData.balance_till_now.toString() : '',
          small_admins_QR_img: profileData.small_admins_QR_img,
        });
        setBaseUrl(response.data.QRImageURL);
      } else {
        console.error("No profile data found.");
        // Handle case where profileData is null or undefined
      }
      // setBaseUrl(response.data.url);
    } catch (error) {
      console.error("Error fetching settings:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const updatedFormData = new FormData();
      updatedFormData.append("smalladmins_id", brokerId);
      // updatedFormData.append("naked_buy_sell_price", formData.naked_buy_sell_price);
      // updatedFormData.append("hedge_price", formData.hedge_price);
      // updatedFormData.append("balance_till_now", formData.balance_till_now);

      if (newImage) {
        updatedFormData.append("small_admins_QR_img", newImage);
      } 
     

      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}update-small-admins-general-settings`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
        data: updatedFormData,
      };

      const response = await axios(config);

      if (response.data.status === 1) {
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: response.data.message,
          color:"white",
          background: 'transparent',
          // timer: 1500
        });
        window.location.reload()
        fetchData();
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: response.data.message,
          // timer: 2000
        });
      }
    } catch (error) {
      console.error("Error updating settings:", error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: "Failed to update settings",
        // timer: 2000
      });
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getProfile();
  }, []);

  const getProfile = async () => {
    try {
      const broker_id = localStorage.getItem("broker_id");
      const formData = new FormData();
      formData.append("smalladmins_id", broker_id);
      const token = localStorage.getItem("bear_token");
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}show-small-admins-Profile`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const baseurl = response.data.LoginAdminQRImage.small_admins_QR_img;
      setBaseUrl(baseurl);
      const baseurlS = response.data.QRImageURL;
      setBaseurlS(baseurlS);
    } catch (error) {
      console.error("Error fetching user profile:", error);
    }
  };

  return (
    <div>
      <div className="card mb-3">
        <div
          className="bg-holder d-none d-lg-block bg-card"
          style={{
            backgroundImage:
              "url(../../assets/img/icons/spot-illustrations/corner-4.png)",
          }}
        />
        <div className="card-body position-relative">
          <div className="row ">
            <div className="col-lg-8">
              <h3 className="mb-0">Update Settings</h3>
            </div>
            <div className="col-lg-4 text-end">
              <Link to="/home" className="">
                <button className="btn btn-success">Back</button>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="card mb-6">
        <form className="card-body" onSubmit={handleSubmit}>
          <div className="row g-3 mt-2">
            {/* <div className="col-md-6 form_details mt-3">
              <div className="form-group local-forms">
                <label className="form-label" htmlFor="naked_buy_sell_price">
                Naked Trade Price per order

<span className="login-danger"></span>
                </label>
                <input
                  className={`form-control`}
                  id="naked_buy_sell_price"
                  name="naked_buy_sell_price"
                  type="text"
                  value={formData.naked_buy_sell_price}
                  onChange={handleInputChange}
                />
              </div>
            </div>

            <div className="col-md-6 form_details mt-3">
              <div className="form-group local-forms">
                <label className="form-label" htmlFor="hedge_price">
                Hedge Trade Price per order

<span className="login-danger"></span>
                </label>
                <input
                  className={`form-control`}
                  id="hedge_price"
                  name="hedge_price"
                  type="text"
                  value={formData.hedge_price}
                  onChange={handleInputChange}
                />
              </div>
            </div>

            <div className="col-md-6 form_details mt-3">
              <div className="form-group local-forms">
                <label className="form-label" htmlFor="balance_till_now">
                  Balance Till Now <span className="login-danger"></span>
                </label>
                <input
                  className={`form-control`}
                  id="balance_till_now"
                  name="balance_till_now"
                  type="text"
                  value={formData.balance_till_now}
                  onChange={handleInputChange}
                />
              </div>
            </div> */}
            <div className="col-md-6 form_details">
              <div className="form-group local-forms">
                <label className="form-label" htmlFor="small_admins_QR_img">
                  QR Image <span className="login-danger"></span>
                </label>
                {fileImage ? (
                  <img
                    src={fileImage}
                    alt="QR Preview"
                    style={{
                      width: "100px",
                      height: "100px",
                      marginTop: "10px",
                    }}
                  />
                ): (
                  <img
                  src={`${baseurlS}/${BaseUrl}`}
                  alt="Preview"
                  style={{ maxWidth: "100%", maxHeight: "200px" }}
                />
                )}
              
              </div>
            </div>
            <div className="col-md-6 form_details">
              <div className="form-group local-forms">
                {/* <label className="form-label" htmlFor="small_admins_QR_img">
                  QR Image <span className="login-danger"></span>
                </label>
                <input
                  className="form-control"
                  id="small_admins_QR_img"
                  type="file"
                  name="small_admins_QR_img"
                  onChange={handleImageChange}
                />
               */}
              </div>
            </div>
            <div className="col-md-6 form_details">
              <div className="form-group local-forms">
                <label className="form-label" htmlFor="small_admins_QR_img">
                  QR Image <span className="login-danger"></span>
                </label>
                <input
                  className="form-control"
                  id="small_admins_QR_img"
                  type="file"
                  name="small_admins_QR_img"
                  onChange={handleImageChange}
                />
              
              </div>
            </div>
        
          </div>

          <div className="pt-4 w-100 d-flex justify-content-end">
            <button
              type="submit"
              className="btn btn-primary me-sm-3 me-1 waves-effect waves-light"
              disabled={loading}
            >
              {loading ? <Spinner animation="border" size="sm" /> : "Submit"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
