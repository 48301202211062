import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { BsBuildings } from "react-icons/bs";
import { SlActionRedo } from "react-icons/sl";
import { FaRegEdit, FaEye } from "react-icons/fa";
import * as xlsx from 'xlsx/xlsx.mjs';
import { Spinner } from 'react-bootstrap';
import Swal from "sweetalert2";
import {

  FaCalendarAlt,

  FaMoneyBillAlt,

  FaChartLine,
  FaBusinessTime,

  FaPlus,
} from "react-icons/fa";

export default function EditViesStraties() {
  const broker_id = localStorage.getItem("broker_id");
  const customer_id = localStorage.getItem("ViesNaked");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [newImage, setNewImage] = useState(null);
  const [fileimage, setFileimage] = useState(null);
  const [errors, setErrors] = useState({});
  const [data, setData] = useState([]);
  const [tableNaked, setTableNaked] = useState([]);
  const [tableNaked1, setTableNaked1] = useState([]);
  const [tableHedge, setTableHedge] = useState([]);
  const [tableHedge1, setTableHedge1] = useState([]);
  const [baseurl, setBaseUrl] = useState("");
  const [View, setView] = useState(false);
  const [formData, setFormData] = useState({
    id: "",
    name: "",
    smalladmins_name: "",
    company_id: "",
    company_name: "",
    date_from: "",
    date_to: "",
    default_lot_price: "",
    different_amt: "",
    profit_loss_amt: "",
    sell_price: "",
    status: "",
    strateigs_id: "",
    strateigs_name: "",
    symbol_id: "",
    symbol_name: "",
    buy_price: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      await fetchData1();
    };

    fetchData();
  }, []);

  const fetchData1 = async () => {
    try {
      const token = localStorage.getItem("bear_token");
      const customer_id = localStorage.getItem("ViesNaked");

      const formData = new FormData();
      formData.append("smalladmins_id", broker_id);
      formData.append("id", customer_id);
      formData.append("status", "1");

      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}small_admins_view_company_nakedHedgePL`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      };

      const response = await axios(config);
      const data = response.data.data;
      setFormData(data[0]);
      const dataurl = response.data.url;
      setBaseUrl(dataurl);

      // Call fetchData2 and fetchData3 after fetchData1 is successful
      await fetchData2(
        data[0].id,
        data[0].company_id,
        data[0].strateigs_id,
        data[0].symbol_id,
        data[0].date_from,
        data[0].date_to
      );
      // setTimeout(async () => {
      await fetchData3(
        data[0].id,
        data[0].company_id,
        data[0].strateigs_id,
        data[0].symbol_id,
        data[0].date_from,
        data[0].date_to
      );
    // }, 3000);
    } catch (error) {
      console.error("Error fetching user broker:", error);
    }
  };

  const fetchData2 = async (id, company_id, strateigs_id, symbol_id,date_from,date_to) => {
    try {
      const token = localStorage.getItem("bear_token");
      const updateformData = new FormData();
      updateformData.append("smalladmins_id", broker_id);
      // updateformData.append("id", customer_id);
      updateformData.append("company_naked_hedgePL_id", customer_id);
      updateformData.append("company_id", company_id);
      updateformData.append("strateigs_id", strateigs_id);
      updateformData.append("symbol_id", symbol_id);
      updateformData.append("date_from", date_from);
      updateformData.append("date_to", date_to);

      updateformData.append("status", "1");

      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}small-admins-nakedbuysell-lists`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
        data: updateformData,
      };

      const response = await axios(config);
      const data = response.data.data;
      setTableNaked(data);
      const data1 = response.data;
      setView(true)
      setTableNaked1(data1);
    } catch (error) {
      console.error("Error fetching user broker:", error);
    }
  };

  const fetchData3 = async (id, company_id, strateigs_id, symbol_id,date_from,date_to) => {
    try {
      const token = localStorage.getItem("bear_token");
      const updateformData = new FormData();
      updateformData.append("smalladmins_id", broker_id);
      // updateformData.append("id", customer_id);
      updateformData.append("company_naked_hedgePL_id", customer_id);
      updateformData.append("company_id", company_id);
      updateformData.append("strateigs_id", strateigs_id);
      updateformData.append("symbol_id", symbol_id);
      updateformData.append("date_from", date_from);
      updateformData.append("date_to", date_to);
      updateformData.append("status", "1");

      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}small-admins-hedge-lists`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
        data: updateformData,
      };

      const response = await axios(config);
      const data = response.data.data;
      setTableHedge(data);
      const data1 = response.data;
      setTableHedge1(data1);
      setView(true)
      
      setTimeout (()=>{
        setView(false)

      },5000)
    } catch (error) {
      console.error("Error fetching user broker:", error);
    }
  };
  const viewReport = () => {
    const reportUrl = `${process.env.REACT_APP_API_URL}small-admins-nakedbuysell-DownloadWebviewExcel?smalladmins_id=${broker_id}&strateigs_id=${formData.strateigs_id}`;
    window.open(reportUrl, "_blank");
  };
  // const viewReport = async () => {
  //   try {
  //     const reportUrl = `${process.env.REACT_APP_API_URL}small-admins-nakedbuysell-DownloadWebviewExcel?smalladmins_id=${broker_id}&strateigs_id=${formData.strateigs_id}`;
  //     const token = localStorage.getItem("bear_token");

  //     const response = await axios({
  //       method: "get",
  //       url: reportUrl,
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //       responseType: "blob", // important - this indicates we expect a binary response
  //     });

  //     const blob = new Blob([response.data], { type: response.headers["content-type"] });
  //     const url = window.URL.createObjectURL(blob);
  //     window.open(url, "_self"); // Opens the report in the same tab

  //   } catch (error) {
  //     console.error("Error fetching report:", error);
  //   }
  // };
  const handleClickEditCompanyId = (id) => {
    localStorage.setItem("AddNakedBuySell_id", id);
    navigate("/AddNakedPrize");
  };
  const handleClickEditNakedCompanyId = (id) => {
    localStorage.setItem("EditNakedPrize_id", id);
    navigate("/EditNakedPrize");
  };
  const handleClickEditHedgeCompanyId = (id) => {

    localStorage.setItem("EditHedgePrize_id", id);
    navigate("/EditHedgePrize");
  };
  const handleClickHedgeCompanyId = (id) => {
    localStorage.setItem("AddHedgeBuySell_id", id);
    navigate("/AddHedgePrize");
  };
  const exportToExcelNakedHedge = () => {

  
// Check the length of tableNaked and tableHedge
if (tableNaked.length === 0 && tableHedge.length === 0) {
  // Display SweetAlert for data not found
  Swal.fire({
      icon: 'error',
      title: 'Data Not Found',
      text: 'No data available in Future Table or Option Table',
  });
  return;
} else {

  Swal.fire({
      title: 'Are you sure?',
      text: "Do you want to proceed with the data?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, proceed!',
      cancelButtonText: 'No, cancel!'
  }).then((result) => {
      if (result.isConfirmed) {
          // Proceed with your code if the user confirms
          Swal.fire(
              'Confirmed!',
              'You have chosen to proceed.',
              'success'
          );
          try {

            // Utility function to add empty columns to the start of a row
            const addEmptyColumns = (row, count) => {
                return row.concat(Array(count).fill(""));
            };
    
            // Number of empty columns to add as space
            const emptyColumnsCount = 3; // Adjust this number for more/less space
    
            const headerData = [
                // [
                //     formData.strateigs_name,
                //     formData.symbol_name,
                //     formData.date_from,
                //     "To",
                //     formData.date_to,
                //     "Closing",
                // ],
                
                [
                    "", "", 
                ],
                [
                    "", "", "", "", "", ""
                ],
                [
                  "#", "Sell", "Buy", "Sell-Buy", "Naked Default Lot Size", "Total P&L"
                ],
            ];
    
            const formattedData = tableNaked.map((item, index) => ({
                "#": index + 1,
                "Sell": item.sell_price,
                "Buy": item.buy_price,
                "Sell-Buy": item.different_amt,
                "NDL Size": "-",
                "P&L": item.profit_loss_amt,
            }));
    
            const footerData = [
                {
                    "#": "",
                    "Sell": "",
                    "Buy": "",
                    "Sell-Buy": tableNaked1 && tableNaked1.TotalDifferentAmount ? tableNaked1.TotalDifferentAmount : 0,
                    "NDL Size": tableNaked.map((item, index) => index === 0 ? item.naked_default_lot_size : ""),
                    "P&L": tableNaked1 && tableNaked1.TotalProfitAndLoss ? tableNaked1.TotalProfitAndLoss : 0,
                },
                {
                    "#": "",
                    "Sell": "",
                    "Buy": "",
                    "Sell-Buy": "",
                    "NDL Size": "",
                    "P&L": "",
                },
                {
                    "#": "",
                    "Sell": "",
                    "Buy": "TRADE",
                    "Sell-Buy": tableNaked1 && tableNaked1.total_no_of_TradesCount ? tableNaked1.total_no_of_TradesCount : 0,
                    "NDL Size": tableNaked1 && tableNaked1.tradeprizeperorder ? tableNaked1.tradeprizeperorder : 0,
                    "P&L": tableNaked1 && tableNaked1.totalTradeExpences ? tableNaked1.totalTradeExpences : 0,
                },
                {
                    "#": "",
                    "Sell": "",
                    "Buy": "",
                    "Sell-Buy": "",
                    "NDL Size": "",
                    "P&L": "",
                },
                {
                    "#": "",
                    "Sell": "",
                    "Buy": "",
                    "Sell-Buy": "",
                    "NDL Size": "TOTAL P&L",
                    "P&L": tableNaked1 && tableNaked1.TotalProfitAndLossMinustotalTradeExpences ? tableNaked1.TotalProfitAndLossMinustotalTradeExpences : 0,
                },
            ];
            const footerData13 = [
              {
                  "#": "",
                  "Sell": "",
                  "Buy": "",
                  "Sell-Buy": "",
                  "NDL Size": "",
                  "P&L":"",
              },
              {
                  "#": "",
                  "Sell": "",
                  "Buy": "",
                  "Sell-Buy": "",
                  "NDL Size": "",
                  "P&L": "",
              },
             
              {
                  "#": "",
                  "Sell": "",
                  "Buy": "",
                  "Sell-Buy": "",
                  "NDL Size": "",
                  "P&L": "",
              },
             
          ];
            const headerData2 = [
              [
                `${formData.strateigs_name} ${formData.symbol_name} ${formData.date_from} To ${formData.date_to} Closing`
            ],
                [
                    "", "", "", "", "", "", "", ""
                ],
                [
                    "#", "Strike", "Type", "Sell", "Buy", "Sell-Buy", "Hedge Default Lot Size", "Total P&L"
                ],
            ];
    
            const formattedData2 = tableHedge.map((item, index) => ({
                "#": index + 1,
                "Strike": item.strike_price,
                "Type": item.type,
                "Sell": item.sell_price,
                "Buy": item.buy_price,
                "Sell-Buy": item.different_amt,
                "NDL Size": "-",
                "P&L": item.profit_loss_amt,
            }));
    
            const footerData2 = [
                {
                    "#": "",
                    "Strike": "",
                    "Type": "",
                    "Sell": "",
                    "Buy": "",
                    "Sell-Buy": tableHedge1 && tableHedge1.TotalDifferentAmount ? tableHedge1.TotalDifferentAmount : 0,
                    "NDL Size": tableHedge.map((item, index) => index === 0 ? item.hedge_default_lot_size : ""),
                    "P&L": tableHedge1 && tableHedge1.TotalProfitAndLoss ? tableHedge1.TotalProfitAndLoss : 0,
                },
                {
                    "#": "",
                    "Strike": "",
                    "Type": "",
                    "Sell": "",
                    "Buy": "",
                    "Sell-Buy": "",
                    "NDL Size": "",
                    "P&L": "",
                },
                {
                    "#": "",
                    "Strike": "",
                    "Type": "",
                    "Sell": "",
                    "Buy": "TRADE",
                    "Sell-Buy": tableHedge1 && tableHedge1.total_no_of_TradesCount ? tableHedge1.total_no_of_TradesCount : 0,
                    "NDL Size": tableHedge1 && tableHedge1.tradeprizeperorder ? tableHedge1.tradeprizeperorder : 0,
                    "P&L": tableHedge1 && tableHedge1.totalTradeExpences ? tableHedge1.totalTradeExpences : 0,
                },
                {
                    "#": "",
                    "Strike": "",
                    "Type": "",
                    "Sell": "",
                    "Buy": "",
                    "Sell-Buy": "",
                    "NDL Size": "",
                    "P&L": "",
                },
                {
                    "#": "",
                    "Strike": "",
                    "Type": "",
                    "Sell": "",
                    "Buy": "",
                    "Sell-Buy": "",
                    "NDL Size": "TOTAL P&L",
                    "P&L": tableHedge1 && tableHedge1.TotalProfitAndLossMinustotalTradeExpences ? tableHedge1.TotalProfitAndLossMinustotalTradeExpences : 0,
                },
            ];
    
            const footerData12 = [
                {
                    "#": formData.symbol_name,
                    "Strike": "",
                    "Type": tableHedge1.naked_final_calculation_amtUpdate,
                    "Sell": "",
                    "Buy": "",
                    "Sell-Buy": "",
                    "NDL Size": "",
                    "P&L": "",
                },
                {
                    "#": formData.symbol_name,
                    "Strike": "Hedge",
                    "Type": tableHedge1.TotalProfitAndLossMinustotalTradeExpences,
                    "Sell": "",
                    "Buy": "",
                    "Sell-Buy": "",
                    "NDL Size": "",
                    "P&L": "",
                },
                {
                    "#": "",
                    "Strike": "",
                    "Type": "",
                    "Sell": "",
                    "Buy": "",
                    "Sell-Buy": "",
                    "NDL Size": "",
                    "P&L": "",
                },
                {
                    "#": "TOTAL P&L",
                    "Strike": "",
                    "Type": tableHedge1 && tableHedge1.TotalProfitAndLossMinustotalTradeExpences ? tableHedge1.TotalProfitAndLossMinustotalTradeExpences : 0,
                    "Sell": "",
                    "Buy": "",
                    "Sell-Buy": "",
                    "NDL Size": "",
                    "P&L": "",
                },
            ];
    
            // Combine the data for the first table
            const combinedData1 = [
                ...headerData,
                ...formattedData.map(data => Object.values(data)),
                ...footerData.map(data => Object.values(data)),
                ...footerData13.map(data => Object.values(data)),
            ];
    
            // Combine the data for the second table with empty columns at the beginning
            const combinedData2 = [
                ...headerData2,
                ...formattedData2.map(data => Object.values(data)),
                ...footerData2.map(data => Object.values(data)),
                ...footerData12.map(data => Object.values(data)),
            ];
    
            // Combine both tables into one array, row by row
            const combinedData = combinedData1.map((row, index) => {
                const emptyColumns = Array(emptyColumnsCount).fill("");
                const secondTableRow = combinedData2[index] ? combinedData2[index] : Array(headerData2[0].length).fill("");
                return row.concat(emptyColumns).concat(secondTableRow);
            });
    
            // Add any remaining rows from the second table that were not added yet
            if (combinedData1.length < combinedData2.length) {
                for (let i = combinedData1.length; i < combinedData2.length; i++) {
                    const emptyColumns = Array(headerData[0].length + emptyColumnsCount).fill("");
                    combinedData.push(emptyColumns.concat(combinedData2[i]));
                }
            }
    
            const wb = xlsx.utils.book_new();
            const ws = xlsx.utils.aoa_to_sheet(combinedData);
    
            xlsx.utils.book_append_sheet(wb, ws, "Future_And_Option_Table");
    
            xlsx.writeFile(wb, "Future_And_Option_Table.xlsx");
    
            console.log("Exported to Excel successfully.");
        } catch (error) {
            console.error("Error exporting to Excel:", error);
        }
      } else {
          // Handle the case where the user cancels
          Swal.fire(
              'Cancelled',
              'You have chosen not to proceed.',
              'error'
          );
          return;
      }
  });
}


    

};


  const exportToExcelNaked = () => {
    if (tableNaked.length === 0) {
      // Display SweetAlert for data not found
      Swal.fire({
          icon: 'error',
          title: 'Data Not Found',
          text: 'No data available in  Future Table',
      });
      return;
    } else {
    
      Swal.fire({
          title: 'Are you sure?',
          text: "Do you want to proceed with the  Future Table?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, proceed!',
          cancelButtonText: 'No, cancel!'
      }).then((result) => {
          if (result.isConfirmed) {
              // Proceed with your code if the user confirms
              Swal.fire(
                  'Confirmed!',
                  'You have chosen to proceed.',
                  'success'
              );
    try {
        const headerData = [
          [
            `${formData.strateigs_name} ${formData.symbol_name} ${formData.date_from} To ${formData.date_to} Closing`
        ],
            // [
            //     formData.strateigs_name,
            //     formData.symbol_name,
            //     formData.date_from,
            //     "To",
            //     formData.date_to,
            //     "Closing",
            // ],
            [
                "", "", "", "", "", ""
            ],
            [
              "#", "Sell", "Buy", "Sell-Buy", "Naked Default Lot  Size", "Total P&L"
          ],
        ];

        const formattedData = tableNaked.map((item, index) => ({
            "#": index + 1,
            "Sell": item.sell_price,
            "Buy": item.buy_price,
            "Sell-Buy": item.different_amt,
            "NDL Size": "-",
            "P&L": item.profit_loss_amt,
        }));

        const footerData = [
            {
                "#": "",
                "Sell": "",
                "Buy": "",
                "Sell-Buy": tableNaked1 && tableNaked1.TotalDifferentAmount ? tableNaked1.TotalDifferentAmount : 0,
                "NDL Size": tableNaked.map((item, index) => index === 0 ? item.naked_default_lot_size : ""),
                "P&L": tableNaked1 && tableNaked1.TotalProfitAndLoss ? tableNaked1.TotalProfitAndLoss : 0,
            },
            {
                "#": "",
                "Sell": "",
                "Buy": "",
                "Sell-Buy": "",
                "NDL Size": "",
                "P&L": "",
            },
            {
                "#": "",
                "Sell": "",
                "Buy": "TRADE",
                "Sell-Buy": tableNaked1 && tableNaked1.total_no_of_TradesCount ? tableNaked1.total_no_of_TradesCount : 0,
                "NDL Size": tableNaked1 && tableNaked1.tradeprizeperorder ? tableNaked1.tradeprizeperorder : 0,
                "P&L": tableNaked1 && tableNaked1.totalTradeExpences ? tableNaked1.totalTradeExpences : 0,
            },
            {
                "#": "",
                "Sell": "",
                "Buy": "",
                "Sell-Buy": "",
                "NDL Size": "",
                "P&L": "",
            },
            {
                "#": "",
                "Sell": "",
                "Buy": "",
                "Sell-Buy": "",
                "NDL Size": "TOTAL P&L",
                "P&L": tableNaked1 && tableNaked1.TotalProfitAndLossMinustotalTradeExpences ? tableNaked1.TotalProfitAndLossMinustotalTradeExpences : 0,
            },
        ];

        const combinedData = [
            ...headerData,
            ...formattedData.map(data => Object.values(data)),
            ...footerData.map(data => Object.values(data)),
        ];

        const wb = xlsx.utils.book_new();
        const ws = xlsx.utils.aoa_to_sheet(combinedData);

        xlsx.utils.book_append_sheet(wb, ws, "Future_Table");

        xlsx.writeFile(wb, "Future_Table.xlsx");

        console.log("Exported to Excel successfully.");
    } catch (error) {
        console.error("Error exporting to Excel:", error);
    }
  } else {
    // Handle the case where the user cancels
    Swal.fire(
        'Cancelled',
        'You have chosen not to proceed.',
        'error'
    );
    return;
  }
  });
  }
  };


const exportToExcelHedge = () => {

if (tableHedge.length === 0) {
  // Display SweetAlert for data not found
  Swal.fire({
      icon: 'error',
      title: 'Data Not Found',
      text: 'No data available in  Option Table',
  });
  return;
} else {

  Swal.fire({
      title: 'Are you sure?',
      text: "Do you want to proceed with the  Option Table?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, proceed!',
      cancelButtonText: 'No, cancel!'
  }).then((result) => {
      if (result.isConfirmed) {
          // Proceed with your code if the user confirms
          Swal.fire(
              'Confirmed!',
              'You have chosen to proceed...',
              'success'
          );
  try {
      const headerData = [
        [
          `${formData.strateigs_name} ${formData.symbol_name} ${formData.date_from} To ${formData.date_to} Closing`
      ],
          // [
          //     formData.strateigs_name,
          //     formData.symbol_name,
          //     formData.date_from,
          //     "To",
          //     formData.date_to,
          //     "Closing",
          //     "",
          //      "",
          // ],
          [
              "", "", "", "", "", "","", ""
          ],
          [
            "#", "Strike", "Type", "Sell", "Buy", "Sell-Buy", "Hedge Default Lot Size", "Total P&L"
        ],
      ];

      const formattedData = tableHedge.map((item, index) => ({
          "#": index + 1,
            "Strike": item.strike_price,
          "Type": item.type,
          "Sell": item.sell_price,
          "Buy": item.buy_price,
          "Sell-Buy": item.different_amt,
          "NDL Size": "-",
          "P&L": item.profit_loss_amt,
      }));

      const footerData = [
          {
              "#": "",
               "Strike":"",
              //  "Strike": item.strike_price,
              "Type":"",
              // "Type": item.type,
              "Sell": "",
              "Buy": "",
              "Sell-Buy": tableHedge1 && tableHedge1.TotalDifferentAmount ? tableHedge1.TotalDifferentAmount : 0,
              "NDL Size": tableHedge.map((item, index) => index === 0 ? item.hedge_default_lot_size : ""),
              "P&L": tableHedge1 && tableHedge1.TotalProfitAndLoss ? tableHedge1.TotalProfitAndLoss : 0,
          },
          {
              "#": "",
              "Strike":"",
               "Type":"",
              "Sell": "",
              "Buy": "",
              "Sell-Buy": "",
              "NDL Size": "",
              "P&L": "",
          },
          {
              "#": "",
              "Strike":"",
               "Type":"",
              "Sell": "",
              "Buy": "TRADE",
              "Sell-Buy": tableHedge1 && tableHedge1.total_no_of_TradesCount ? tableHedge1.total_no_of_TradesCount : 0,
              "NDL Size": tableHedge1 && tableHedge1.tradeprizeperorder ? tableHedge1.tradeprizeperorder : 0,
              "P&L": tableHedge1 && tableHedge1.totalTradeExpences ? tableHedge1.totalTradeExpences : 0,
          },
          {
              "#": "",
              "Strike":"",
               "Type":"",
              "Sell": "",
              "Buy": "",
              "Sell-Buy": "",
              "NDL Size": "",
              "P&L": "",
          },
          {
              "#": "",
                "Strike":"",
               "Type":"",
              "Sell": "",
              "Buy": "",
              "Sell-Buy": "",
              "NDL Size": "TOTAL P&L",
              "P&L": tableHedge1 && tableHedge1.TotalProfitAndLossMinustotalTradeExpences ? tableHedge1.TotalProfitAndLossMinustotalTradeExpences : 0,
          },
      ];
      const footerData1 = [
        {
            "#": formData.symbol_name,
             "Strike":"",
            //  "Strike": item.strike_price,
            "Type":tableHedge1.naked_final_calculation_amtUpdate,
            // "Type": item.type,
            "Sell": "",
            "Buy": "",
            "Sell-Buy": "",
            "NDL Size": "",
            "P&L": "",
        },
        {
            "#": formData.symbol_name,
            "Strike":"Hedge",
             "Type": tableHedge1.TotalProfitAndLossMinustotalTradeExpences,
            "Sell": "",
            "Buy": "",
            "Sell-Buy": "",
            "NDL Size": "",
            "P&L": "",
        },
        {
          "#": "",
          "Strike":"",
           "Type":"",
          "Sell": "",
          "Buy": "",
          "Sell-Buy": "",
          "NDL Size": "",
          "P&L": "",
      },
       
        {
            "#": "TOTAL P&L",
              "Strike":"",
             "Type":tableHedge1 && tableHedge1.finalHedgeCalculation ? tableHedge1.finalHedgeCalculation : 0,
            "Sell": "",
            "Buy": "",
            "Sell-Buy": "",
            "NDL Size": "",
            "P&L": "",
        },
    ];
      const combinedData = [
          ...headerData,
          ...formattedData.map(data => Object.values(data)),
          ...footerData.map(data => Object.values(data)),
          ...footerData1.map(data => Object.values(data)),
      ];

      const wb = xlsx.utils.book_new();
      const ws = xlsx.utils.aoa_to_sheet(combinedData);

      xlsx.utils.book_append_sheet(wb, ws, "Option_Table");

      xlsx.writeFile(wb, "Option_Table.xlsx");

      console.log("Exported to Excel successfully.");
  } catch (error) {
      console.error("Error exporting to Excel:", error);
  }
} else {
  // Handle the case where the user cancels
  Swal.fire(
      'Cancelled',
      'You have chosen not to proceed.',
      'error'
  );
  return;
}
});
}
};
  return (
    <div>
      <div className="card mb-3">
        <div
          className="bg-holder d-none d-lg-block bg-card"
          style={{
            backgroundImage:
              "url(../../assets/img/icons/spot-illustrations/corner-4.png)",
          }}
        />
        <div className="card-body position-relative">
          <div className="row ">
            <div className="col-lg-6">
              <h3 className="mb-0">Company Profit And Loss Details</h3>
            </div>
            <div className="col-lg-3 text-center">
              <button className="btn btn-primary" onClick={exportToExcelNakedHedge}>
              Export To Excel F&O
              </button>
            </div>
            <div className="col-lg-3 text-end">
              <Link to="/AddNakedBuySell" className="">
                <button className="btn btn-success">Back</button>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12 mb-3">
          <div className="card">
            <div className="card-header">
              <h5 className="card-title mb-0">Financial Details</h5>
            </div>
            <div className="card-body p-3">
              <div className="row">
                <div className="col-md-6">Company Name</div>
                <div
                  className="col-md-6 capitalize"
                  style={{ color: "green", fontWeight: "bold" }}
                >
                  <BsBuildings className="me-2" /> {formData.company_name}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 mb-3">
          <div className="card">
            <div className="card-header d-flex justify-content-between">
              <h5 className="card-title mb-0">Future Table</h5>
              <button className="card-title mb-0 bg-primary btn text-light" onClick={exportToExcelNaked}>Export To Excel</button>
              <div className=" gap-1  action_button">
                <OverlayTrigger
                  key="Edit"
                  placement="top"
                  overlay={<Tooltip id={`Edit`}>Add Naked P&L</Tooltip>}
                >
                  <button
                    onClick={() => handleClickEditCompanyId(customer_id)}
                    style={{ "--i": "green" }}
                  >
                    {" "}
                    <FaPlus className="color_link" />
                  </button>
                </OverlayTrigger>
              </div>
            </div>
            <div className="card-body p-0">
              <>
                <link
                  href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/css/bootstrap.min.css"
                  rel="stylesheet"
                />
                <style
                  dangerouslySetInnerHTML={{
                    __html:
                      "\n        body {\n            font-family: Arial, sans-serif;\n        }\n        table {\n            width: 80%;\n            margin: 20px auto;\n            border-collapse: collapse;\n            box-shadow: 0 2px 3px rgba(0,0,0,0.1);\n        }\n        th, td {\n            padding: 12px;\n            text-align: left;\n            border-bottom: 1px solid #ddd;\n        }\n        th {\n            background-color: #f4f4f4;\n        }\n        tr:nth-child(even) {\n            background-color: #f9f9f9;\n        }\n        .footer-row {\n            background-color: #f4f4f4;\n            font-weight: bold;\n        }\n    ",
                  }}
                />
                <div className="">
                  <h6
                    style={{
                      textAlign: "center",
                      background: "gray",
                      color: "#fff",
                      padding: "10px",
                      textTransform: "uppercase",
                      marginBottom: "0px",
                    }}
                    className="capitalize"
                  >
                    {formData.strateigs_name} {formData.symbol_name}{" "}
                    {formData.date_from} To {formData.date_to} Closing
                  </h6>
                  <div id="table-scroll" className={`table-scroll ${tableNaked.length > 0 ? "table-heightdataget" : "table-heightdatanull"}`}>
                    <table
                      id="main-table"
                      className="main-table table  table-bordered table-striped fs-10 mb-0"
                    >
                      <thead>
                        <tr className="bg-200">
                          <th className="text-900 sort">#</th>
                          <th className="text-900 sort">Sell</th>
                          <th className="text-900 sort">Buy</th>
                          <th className="text-900 sort">Sell-Buy</th>
                          <th className="text-900 sort">NDL Size</th>
                          <th className="text-900 sort">P&L</th>
                          <th className="text-900 sort"><FaRegEdit/></th>
                        </tr>
                      </thead>
                      {tableNaked.length > 0 ? 
                      <>
                
                      <tbody>
                        { tableNaked.map((tableNaked, index) => (
                          <tr>
                            <td >{index + 1}</td>
                          
                            <td className="text-center">
                              {tableNaked.sell_price}
                            </td>
                            <td className="text-center">
                              {tableNaked.buy_price}
                            </td>
                            <td className="text-center">
                              {tableNaked.different_amt}
                            </td>
                            <td className="text-center">-</td>
                            {/* <td>{tableNaked.naked_default_lot_size}</td> */}
                            <td className="text-center">
                              {tableNaked.profit_loss_amt}
                            </td>
                              <td className="align-middle priority text-center pe-4">
                        <div className="d-flex gap-1 action_button">
                          <OverlayTrigger
                          key="Edit"
                          placement="top"
                          overlay={<Tooltip id={`Edit`}>Edit</Tooltip>}
                        >
                          <button
                            onClick={() => handleClickEditNakedCompanyId(tableNaked.id)}
                            style={{ "--i": "#2c7be5" }}
                          >
                            {" "}
                            <FaRegEdit className="color_link" />
                          </button>
                        </OverlayTrigger>
                      
                      
                        </div>
                      </td>
                          </tr>
                        ))}
                      </tbody>
                      <tfoot className="tfoot_custum">
                        <tr className="footer-row">
                          <td></td>
                          <td></td>
                          <td></td>
                      
                          <td className="text-center">
                            {tableNaked1.TotalDifferentAmount}
                          </td>
                          {tableNaked.map((item, index) =>
                            index === 0 ? (
                              <td key={index} className="text-center">{item.naked_default_lot_size}</td>
                            ) : null
                          )}

                          <td className="text-center">
                            {tableNaked1.TotalProfitAndLoss}
                          </td>
                          <td></td>
                        </tr>
                        <tr className="footer-row">
                          <td></td>
                          <td></td>
                          {tableNaked1.total_no_of_TradesCount && (
                            <td className="text-danger">TRADE</td>
                          )}
                          <td className="text-danger text-center">
                            {tableNaked1.total_no_of_TradesCount}
                          </td>
                          <td className="text-danger  text-center">
                            {tableNaked1.tradeprizeperorder}
                          </td>
                          <td className="text-danger text-center">
                            {tableNaked1.totalTradeExpences}
                          </td>
                          <td></td>
                        </tr>
                        <tr className="footer-row ">
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td className="text-success  text-center">TOTAL P&L</td>
                          <td
                            // colSpan={4}
                            style={{ textAlign: "right", fontSize: "larger" }}
                            className="text-success  text-center"
                            >
                            {
                              tableNaked1.TotalProfitAndLossMinustotalTradeExpences
                            }
                          </td>
                            <td></td>
                          {/* <td></td> */}
                        </tr>
                      </tfoot>
                      </>
                      :
                      <> 
                         <tr>
                    <td colSpan="7" className="text-center">
                    {tableNaked.length > 0 &&
                          <div class="spinner-border text-primary text-center" role="status">
  <span class="visually-hidden">Loading...</span>
</div>}
                      No records found
                    </td>
                  </tr></>
                       }
                    </table>
                  </div>
                </div>
              </>
            </div>
          </div>
        </div>
        <div className="col-lg-6 mb-3">
          <div className="card">
            <div className="card-header d-flex justify-content-between">
              <h5 className="card-title mb-0">Option Table</h5>
              <button className="card-title mb-0 bg-primary btn text-light" onClick={exportToExcelHedge}>Export To Excel</button>

              <div className=" gap-1  action_button">
                <OverlayTrigger
                  key="Edit"
                  placement="top"
                  overlay={<Tooltip id={`Edit`}>Add Hedge P&L</Tooltip>}
                >
                  <button
                    onClick={() => handleClickHedgeCompanyId(formData.id)}
                    style={{ "--i": "green" }}
                  >
                    {" "}
                    <FaPlus className="color_link" />
                  </button>
                </OverlayTrigger>
              </div>
            </div>
            <div className="card-body p-0">
              <>
                <link
                  href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/css/bootstrap.min.css"
                  rel="stylesheet"
                />
                <style
                  dangerouslySetInnerHTML={{
                    __html:
                      "\n        body {\n            font-family: Arial, sans-serif;\n        }\n        table {\n            width: 80%;\n            margin: 20px auto;\n            border-collapse: collapse;\n            box-shadow: 0 2px 3px rgba(0,0,0,0.1);\n        }\n        th, td {\n            padding: 12px;\n            text-align: left;\n            border-bottom: 1px solid #ddd;\n        }\n        th {\n            background-color: #f4f4f4;\n        }\n        tr:nth-child(even) {\n            background-color: #f9f9f9;\n        }\n        .footer-row {\n            background-color: #f4f4f4;\n            font-weight: bold;\n        }\n    ",
                  }}
                />
                <div className="">
                  <h6
                    style={{
                      textAlign: "center",
                      background: "gray",
                      color: "#fff",
                      padding: "10px",
                      textTransform: "uppercase",
                      marginBottom: "0px",
                    }}
                    className="capitalize"
                  >
                    {formData.strateigs_name} {formData.symbol_name}{" "}
                    {formData.date_from} To {formData.date_to} Closing
                  </h6>
                  <div id="table-scroll"  className={`table-scroll ${tableHedge.length > 0 ? "table-heightdataget" : "table-heightdatanull"}`}>
                    <table
                      id="main-table"
                      className="main-table table  table-bordered table-striped fs-10 mb-0"
                    >
                      <thead>
                        <tr className="bg-200  border-bottom-dark">
                          <th className="text-900 sort">#</th>
                          <th className="text-900 sort">Strike</th>
                          <th className="text-900 sort">Type</th>
                          <th className="text-900 sort">Sell</th>
                          <th className="text-900 sort">Buy</th>
                          <th className="text-900 sort">Sell-Buy</th>
                          <th className="text-900 sort">HDL Size</th>
                          <th className="text-900 sort">P&L</th>
                          <th className="text-900 sort"><FaRegEdit/></th>
                        </tr>
                      </thead>
                      {tableHedge.length > 0 ? 
                    <>
                   
                      <tbody>
                        {tableHedge.map((tableHedge, index) => (
                          <tr>
                            <td >{index + 1}</td>
                            <td className=" text-center">
                              {tableHedge.strike_price}
                            </td>
                            <td className=" text-center">{tableHedge.type}</td>
                            <td className=" text-center">
                              {tableHedge.sell_price}
                            </td>
                            <td className=" text-center">
                              {tableHedge.buy_price}
                            </td>
                            <td className=" text-center">
                              {tableHedge.different_amt}
                            </td>
                            <td className=" text-center">-</td>

                            <td className=" text-center">
                              {tableHedge.profit_loss_amt}
                            </td>
                            <td className="align-middle priority text-center pe-4">
                        <div className="d-flex gap-1 action_button">
                          <OverlayTrigger
                          key="Edit"
                          placement="top"
                          overlay={<Tooltip id={`Edit`}>Edit</Tooltip>}
                        >
                          <button
                            onClick={() => handleClickEditHedgeCompanyId(tableHedge.id)}
                            style={{ "--i": "#2c7be5" }}
                          >
                            {" "}
                            <FaRegEdit className="color_link" />
                          </button>
                        </OverlayTrigger>
                      
                      
                        </div>
                      </td>
                          </tr>
                        ))}
                      </tbody>
                      <tfoot>
                        <tr className="footer-row">
                          <td />
                          <td />
                          <td />
                          <td />
                          <td />
                          {/* <td>5/3</td> */}
                          <td className=" text-center">
                            {tableHedge1.TotalDifferentAmount}
                          </td>
                          {tableHedge.map((item, index) =>
                            index === 0 ? (
                              <td key={index} className="text-center">{item.hedge_default_lot_size}</td>
                            ) : null
                          )}
                          <td className=" text-center">
                            {tableHedge1.TotalProfitAndLoss}
                          </td>
                          <td />
                        </tr>

                        <tr className="footer-row">
                          <td />
                          <td />
                          <td />
                          <td />
                          {tableHedge1.total_no_of_TradesCount && (
                            <td className="text-danger text-center">TRADE</td>
                          )}
                          <td className="text-danger text-center">
                            {tableHedge1.total_no_of_TradesCount}
                          </td>
                          <td className="text-danger text-center">
                            {tableHedge1.tradeprizeperorder}
                          </td>
                          <td className="text-danger text-center">
                            {tableHedge1.totalTradeExpences}
                          </td>
                          <td />
                        </tr>
                        <tr className="footer-row">
                          <td />
                          <td />
                          <td />
                          <td />
                          <td />
                          <td />
                          <td className="text-success  text-center"></td>
                          <td
                            // colSpan={4}
                            style={{ textAlign: "right", fontSize: "larger" }}
                            className="text-success text-center"
                          >
                            {
                              tableHedge1.TotalProfitAndLossMinustotalTradeExpences
                            }
                          </td>
                          <td />
                        </tr>
                        <tr className="footer-row">
                          <td
                            style={{
                              textTransform: "uppercase",
                              whiteSpace: "nowrap",
                            }}
                     
                          >
                            {formData.symbol_name}{" "}
                          </td>
                          <td />
                          <td className="text-center">
                            {tableHedge1.naked_final_calculation_amtUpdate}
                          </td>

                          <td />
                          <td />
                          <td />
                          <td />
                          <td />
                          <td />
                        </tr>
                        <tr className="footer-row">
                          <td
                            style={{
                              textTransform: "uppercase",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {formData.symbol_name}{" "}
                          </td>
                          <td
                            style={{
                              textTransform: "uppercase",
                              whiteSpace: "nowrap",
                            }}
                          >
                            Hedge
                          </td>
                          <td className="text-center">
                            {
                              tableHedge1.TotalProfitAndLossMinustotalTradeExpences
                            }
                          </td>

                          <td />
                          <td />
                          <td />
                          <td />
                          <td />
                          <td />
                        </tr>
                        <tr className="footer-row">
                        <td   className="text-success">TOTAL P&L</td> 
                         <td />
                          <td
                        
                            style={{ textAlign: "right", fontSize: "larger" }}
                            className="text-success text-center"
                          >
                            {tableHedge1.finalHedgeCalculation}
                          </td>
                          <td />
                          <td />
                          <td />
                          <td />
                          <td />
                          <td />
                        </tr>
                      </tfoot>
                      </>  
                    :
                    
                    <>
                       <tr>
                    <td colSpan="9" className="text-center">
                    {tableHedge.length > 0 &&
                          <div class="spinner-border text-primary text-center" role="status">
  <span class="visually-hidden">Loading...</span>
</div>}
                      No records found
                    </td>
                  </tr>
                  </>}
                    </table>
                  </div>
                </div>
              </>
            </div>
          </div>
        </div>
        <div className="col-lg-12 mb-3">
          <div className="card">
            <div className="card-body">
              <table className="table m-0">
                <tbody>
                  <tr>
                    <th scope="row">Strategies Name</th>
                    <td
                      style={{ color: "blue", fontWeight: "bold" }}
                      className="capitalize"
                    >
                      <FaChartLine className="me-2" /> {formData.strateigs_name}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Symbol Name</th>
                    <td
                      style={{ color: "green", fontWeight: "bold" }}
                      className="capitalize"
                    >
                      <FaBusinessTime className="me-2" /> {formData.symbol_name}
                    </td>
                  </tr>
                  {/* <tr>
                    <th scope="row">Different Amount</th>
                    <td style={{ color: formData.different_amt < 0 ? 'red' : 'green', fontWeight: 'bold' }}><FaPercent className="me-2" /> {formData.different_amt}</td>
                  </tr>
                  <tr>
                    <th scope="row">Profit/Loss Amount</th>
                    <td style={{ color: formData.profit_loss_amt < 0 ? 'red' : 'green', fontWeight: 'bold' }}><FaFileInvoiceDollar className="me-2" /> {formData.profit_loss_amt}</td>
                  </tr> */}
                  <tr>
                    <th scope="row">Default Lot Size</th>
                    <td style={{ color: "navy", fontWeight: "bold" }}>
                      <FaMoneyBillAlt className="me-2" />{" "}
                      {formData.default_lot_price}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Naked Default Lot Size</th>
                    <td style={{ color: "darkgreen", fontWeight: "bold" }}>
                      <FaMoneyBillAlt className="me-2" />{" "}
                      {formData.naked_default_lot_size}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Hedge Default Lot Size</th>
                    <td style={{ color: "navy", fontWeight: "bold" }}>
                      <FaMoneyBillAlt className="me-2" />{" "}
                      {formData.hedge_default_lot_size}
                    </td>
                  </tr>

                  {/* <tr>
                    <th scope="row">Buy Price</th>
                    <td style={{ color: 'darkgreen', fontWeight: 'bold' }}><FaDollarSign className="me-2" /> {formData.buy_price}</td>
                  </tr>
                  <tr>
                    <th scope="row">Sell Price</th>
                    <td style={{ color: 'navy', fontWeight: 'bold' }}><FaMoneyCheckAlt className="me-2" /> {formData.sell_price}</td>
                  </tr> */}

                  <tr>
                    <th scope="row">Date From</th>
                    <td style={{ color: "brown", fontWeight: "bold" }}>
                      <FaCalendarAlt className="me-2" /> {formData.date_from}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Date To</th>
                    <td style={{ color: "brown", fontWeight: "bold" }}>
                      <FaCalendarAlt className="me-2" /> {formData.date_to}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
