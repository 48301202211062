import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom';
import Swal from "sweetalert2";

export default function Adddefaultlotsize() {
    const navigate = useNavigate();
    const broker_id = localStorage.getItem("broker_id");
    const [fileimage, setFileimage] = useState("");
    const [newImage, setNewImage] = useState(null);
    const [strategies, setStrategies] = useState([]);
    const [symbols, setSymbols] = useState([]);
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        smalladmins_id: broker_id,
        default_lot_size: "",
        strateigs_id: "",
        symbol_id: "",
        hedge_default_lot_size: "",
        naked_default_lot_size: "",

        status: "1",
    });
    const [profile, setProfile] = useState(null);
    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData({ ...formData, [id]: value });
        setErrors((prevErrors) => ({ ...prevErrors, [id]: null }));
    };

    const handleValidation = () => {
        const requiredFields = [
            { field: "default_lot_size", name: "Default Lot Size" },
            { field: "hedge_default_lot_size", name: "Hedge Default Lot Size" },
            { field: "naked_default_lot_size", name: "Naked Default Lot Size" },
            { field: "strateigs_id", name: "Strategy" },
            { field: "symbol_id", name: "Symbol" },
        ];

        let validationErrors = {};
        let hasError = false;

        for (const { field, name } of requiredFields) {
            if (!formData[field]) {
                validationErrors[field] = `${name} is required`;
                hasError = true;
            }
        }

        setErrors(validationErrors);
        return !hasError;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const token = localStorage.getItem("bear_token");

        if (!handleValidation()) {
            return;
        }

        const dataUpdate = new FormData();
        dataUpdate.append("smalladmins_id", broker_id);
        dataUpdate.append("default_lot_size", formData.default_lot_size);
        dataUpdate.append("strateigs_id", formData.strateigs_id);
        dataUpdate.append("symbol_id", formData.symbol_id);
        dataUpdate.append("hedge_default_lot_size", formData.hedge_default_lot_size);
        dataUpdate.append("naked_default_lot_size", formData.naked_default_lot_size);
        dataUpdate.append("status", formData.status);

        if (newImage) {
            dataUpdate.append("profile", newImage);
        }

        try {
            const config = {
                method: "post",
                url: `${process.env.REACT_APP_API_URL}small-admins-add-defaultsize`,
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "multipart/form-data",
                },
                data: dataUpdate,
            };

            const response = await axios(config);

            if (response.status === 201) {
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: response.data.message,
                    // timer: 1500
                });

                setTimeout(() => {
                    navigate("/Defaultlotsize");
                }, 2000);
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: response.data.message,
                    // timer: 2000
                });
            }
        } catch (error) {
            console.error("Error:", error);
            Swal.fire({
                icon: 'error',
                title: 'Symbol',
                text: "This Symbol already added, please try again",
                // timer: 4000
            });
        }
    };

    useEffect(() => {
        const fetchStrategies = async () => {
            try {
                setLoading(true);
                const token = localStorage.getItem("bear_token");

                const params = {
                    smalladmins_id: broker_id,
                    status: "1",
                };

                const config = {
                    method: "post",
                    url: `${process.env.REACT_APP_API_URL}small-admins-strategies-lists`,
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                    params: params,
                };

                const response = await axios(config);
                setStrategies(response.data.data);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching data:", error);
                setLoading(false);
            }
        };
        const fetchSymbols = async () => {
            try {
                setLoading(true);
                const token = localStorage.getItem("bear_token");

                const params = {
                    smalladmins_id: broker_id,
                    status: "1",
                };

                const config = {
                    method: "post",
                    url: `${process.env.REACT_APP_API_URL}small-admins-symbols-lists`,
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                    params: params,
                };

                const response = await axios(config);
                setSymbols(response.data.data);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching data:", error);
                setLoading(false);
            }
        };
        fetchSymbols();
        fetchStrategies();
    }, [broker_id]);

    return (
        <>
            <div className="card mb-3">
                <div
                    className="bg-holder d-none d-lg-block bg-card"
                    style={{
                        backgroundImage:
                            "url(../../assets/img/icons/spot-illustrations/corner-4.png)",
                    }}
                />
                <div className="card-body position-relative">
                    <div className="row">
                        <div className="col-lg-8">
                            <h3 className="mb-0">Add Default Lot Size</h3>
                        </div>
                        <div className="col-lg-4 text-end">
                            <Link to="/Defaultlotsize" className="">
                                <button className="btn btn-success">Back</button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card">
                <div className="card-body">
                    <form className="row g-3" onSubmit={handleSubmit}>
                        <div className="col-md-6 form_details">
                            <div className="form-group local-forms">
                                <label>
                                    Strategy <span className="login-danger">*</span>
                                </label>
                                <select
                                    className={`form-select ${errors.strateigs_id ? "input-error" : ""}`}
                                    id="strateigs_id"
                                    value={formData.strateigs_id}
                                    onChange={handleChange}
                                >
                                    <option value="">Select Strategy</option>
                                    {strategies.map(strategy => (
                                        <option key={strategy.id} value={strategy.id}>
                                            {strategy.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="col-md-6 form_details">
                            <div className="form-group local-forms">
                                <label>
                                    Symbol <span className="login-danger">*</span>
                                </label>
                                <select
                                    className={`form-select ${errors.symbol_id ? "input-error" : ""}`}
                                    id="symbol_id"
                                    value={formData.symbol_id}
                                    onChange={handleChange}
                                >
                                    <option value="">Select Symbol</option>
                                    {symbols.map(symbol => (
                                        formData.strateigs_id == symbol.strateigs_id &&
                                        <option key={symbol.id} value={symbol.id}>
                                            {symbol.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="col-md-6 form_details">
                            <div className="form-group local-forms">
                                <label>
                                    Default Lot Size  <span className="login-danger">*</span>
                                </label>
                                <input
                                    className={`form-control ${errors.default_lot_size ? "input-error" : ""}`}
                                    id="default_lot_size"
                                    type="text"
                                    value={formData.default_lot_size}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                    
                        <div className="col-md-6 form_details">
                            <div className="form-group local-forms">
                                <label>
                                    Naked Default Lot Size  <span className="login-danger">*</span>
                                </label>
                                <input
                                    className={`form-control ${errors.naked_default_lot_size ? "input-error" : ""}`}
                                    id="naked_default_lot_size"
                                    type="text"
                                    value={formData.naked_default_lot_size}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <div className="col-md-6 form_details">
                            <div className="form-group local-forms">
                                <label>
                                  Hedge  Default Lot Size  <span className="login-danger">*</span>
                                </label>
                                <input
                                    className={`form-control ${errors.hedge_default_lot_size ? "input-error" : ""}`}
                                    id="hedge_default_lot_size"
                                    type="text"
                                    value={formData.hedge_default_lot_size}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <div className="col-md-6 form_details">
                            <div className="form-group local-forms">
                                <label>
                                    Status <span className="login-danger">*</span>
                                </label>
                                <select
                                    className="form-select"
                                    id="status"
                                    value={formData.status}
                                    onChange={handleChange}
                                >
                                    <option value="1">Active</option>
                                    <option value="0">Inactive</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-12 d-flex justify-content-end">
                            <button className="btn btn-primary" type="submit">
                                Submit
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}
