import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom'
import Swal from "sweetalert2";
export default function AddBusiness() {
  const navigate = useNavigate();
  const broker_id = localStorage.getItem("broker_id");
  const [fileimage, setFileimage] = useState("");
  const [newImage, setNewImage] = useState(null);
  const [formData, setFormData] = useState({
    smalladmins_id: broker_id,
    name: "",
    description: "",
    email: "",
    address: "",
    phone: "",
    status: 1,
  });
  const [profile, setProfile] = useState(null);
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { id, value } = e.target;

    let formattedValue = value;

    // if (id === "name") {
    //   if (value.length > 40 || !/^[a-zA-Z\s]*$/.test(value)) {
    //   return
    //   }
    // }
  
    if (id === "phone" && (value.length > 10 || isNaN(value))) {
      return;
    }

    setFormData({ ...formData, [id]: formattedValue });

    setErrors((prevErrors) => ({ ...prevErrors, [id]: null }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setProfile(e.target.files[0]);
    setFileimage(URL.createObjectURL(file));
    setNewImage(file);
  };


  const validateForm = () => {
    const { name, email,phone } = formData;
    
    const isEmpty = (value) => !value || !value.trim();
    if (isEmpty(name)) {
        Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Name is required',
            // timer: 2000
        });
        return false;
    }
    if (isEmpty(email)) {
        Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Email is required',
            // timer: 2000
        });
        return false;
    }
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(email)) {
        Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Invalid email format',
            // timer: 2000
        });
        return false;
    }
    if (isEmpty(phone) || !/^\d{10}$/.test(phone)) {
      Swal.fire({
          icon: 'error',
          title: 'Error',
          text: isEmpty(phone) ? 'Phone number is required' : 'Phone number must be correct',
          // timer: 2000
      });
      return false;
  }
  
    return true;
};

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem("bear_token");

    if (!validateForm()) {
      return;
  }

    const dataUpdate = new FormData();
    dataUpdate.append("smalladmins_id", broker_id);
    dataUpdate.append("name", formData.name);
    dataUpdate.append("description", formData.description);
    dataUpdate.append("email", formData.email);
    dataUpdate.append("address", formData.address);
    dataUpdate.append("phone", formData.phone);
    dataUpdate.append("status", formData.status);
    if (newImage) {
      dataUpdate.append("profile", newImage);
    }
    try {
      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}add-small-admins-company`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
        data: dataUpdate,
      };

      const response = await axios(config);

      if (response.status === 201) {
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: response.data.message,
          color:"white",
          background: 'transparent',
          // timer: 1500
        });

        setTimeout(() => {
          navigate("/Companymangement");
        }, 2000);
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: response.data.message,
          // timer: 2000
        });
      }
    } catch (error) {
      console.error("Error:", error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: "Company already added!",
        // timer: 2000
      });

    }
  };

  return (
    <>
      <div className="card mb-3">
        <div
          className="bg-holder d-none d-lg-block bg-card"
          style={{
            backgroundImage:
              "url(../../assets/img/icons/spot-illustrations/corner-4.png)",
          }}
        />
        <div className="card-body position-relative">
          <div className="row">
            <div className="col-lg-8">
              <h3 className="mb-0">Company Registration</h3>
            </div>
            <div className="col-lg-4 text-end">
              <Link to="/Companymangement" className="">
                <button className="btn btn-success">Back</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-body">
          <form className="row g-3" onSubmit={handleSubmit}>
            <div className="col-md-6 form_details">
              <div className="form-group local-forms">
                <label>
                Company Name <span className="login-danger">*</span>
                </label>
                <input
                  className={`form-control ${errors.name ? "input-error" : ""}`}
                  id="name"
                  type="text"
                  value={formData.name}
                  onChange={handleChange}
                />
              </div>
              {/* {errors.name && <div className="text-danger">{errors.name}</div>} */}
            </div>

            <div className="col-md-6 form_details">
              <div className="form-group local-forms">
                <label className="form-label" htmlFor="email">
                Company Email <span className="login-danger">*</span>
                </label>
                <input
                  className={`form-control ${errors.email ? "input-error" : ""}`}
                  id="email"
                  type="email"
                  value={formData.email}
                  onChange={handleChange}
                />
                {/* {errors.email && <div className="text-danger">{errors.email}</div>} */}
              </div>
            </div>
            <div className="col-md-6 form_details">
              <div className="form-group local-forms">
                <label className="form-label" htmlFor="phone">
                Company Phone <span className="login-danger">*</span>
                </label>
                <input
                  className={`form-control ${errors.phone ? "input-error" : ""}`}
                  id="phone"
                  type="text"
                  value={formData.phone}
                  onChange={handleChange}
                  // required
                />
                {/* {errors.phone && <div className="text-danger">{errors.phone}</div>} */}
              </div>
            </div>
            <div className="col-md-6 form_details">
              <div className="form-group local-forms">
                <label className="form-label" htmlFor="address">
                Company Address 
                </label>
                <input
                  className="form-control"
                  id="address"
                  type="text"
                  value={formData.address}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="col-md-12 form_details">
              <div className="form-group local-forms">
                <label>Company Description </label>
                <textarea
                  className="w-100 form-control"
                  id="description"
                  value={formData.description}
                  onChange={handleChange}
                  placeholder="Description"
                  rows="3"
                />
              </div>
            </div>
            <div className="col-md-6 form_details">
              <div className="form-group local-forms">
                <p className="form-label" htmlFor="profile">
                  Upload Company Photo (150px X 150px)
                </p>
                <input
                  className="form-control"
                  // id="profile"
                  type="file"
                   id="profile"
                    name="profile"
                  onChange={handleFileChange}
                />
              </div>
            </div>
            {fileimage && (
                    <div className="mt-3">
                      <img
                        src={fileimage}
                        alt="Selected profile"
                        style={{ height: "100px" }}
                      />
                    </div>
                  )}
            <div className="col-12 d-flex justify-content-end">
              <button className="btn btn-primary" type="submit">
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
