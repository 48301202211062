import React, { useState, useEffect } from "react";
import axios from "axios";
import { Container, Row, Col, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
export default function Addplan() {
  const broker_id = localStorage.getItem("broker_id");
  const clearcompany_id = localStorage.getItem("clearcompany_id");
  const company_id = localStorage.getItem("company_id");
  const company_Name = localStorage.getItem("company_Name");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [newImage, setNewImage] = useState();
  const [fileimage, setFileimage] = useState();
  const [errors, setErrors] = useState({});
  const [data, setData] = useState([]);

  const [formData, setFormData] = useState({
    // company_id: "",
    business_name: "",
    sub_admin_name: "",
    sub_admin_email: "",
    business_gst: "",
    account_no: "",
    ifsc: "",
    bank_branch: "",
    ac_holder_name: "",
    pannumber: "",
    company_id:company_id,
    upi_id:"",
  });

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setFileimage(URL.createObjectURL(file));
    setNewImage(file);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    let formattedValue = value;

    if (name === "pannumber") {
      formattedValue = value
        .toUpperCase()
        .replace(/[^A-Z0-9]/g, "")
        .slice(0, 10);
    } else if (name === "ifsc") {
      formattedValue = value
        .toUpperCase()
        .replace(/[^A-Z0-9]/g, "")
        .slice(0, 11);
    } else if (name === "account_no") {
      formattedValue = value.replace(/[^0-9]/g, "").slice(0, 18);
    } else if (name === "business_gst") {
      formattedValue = value
        .toUpperCase()
        .replace(/[^A-Z0-9]/g, "")
        .slice(0, 15);
    
    } 
    else if (name === "password") {
      formattedValue = value
        // .toUpperCase()
        .replace(/[^A-Z0-9]/g, "")
        .slice(0, 6);
    }
    else if (name === "ac_holder_name") {
      formattedValue = value
        .replace(/[^A-Za-z]/g, "") 
        .slice(0, 40);          
    }
    else if (name === "business_name") {
      formattedValue = value
        .replace(/[^A-Za-z]/g, "") 
        .slice(0, 40);          
    }
    

    setFormData({ ...formData, [name]: formattedValue });

    setErrors((prevErrors) => ({ ...prevErrors, [name]: null }));
  };

  useEffect(() => {
    fetchData();


    if (clearcompany_id === "2") {
      localStorage.removeItem("company_id");
    }
  }, [clearcompany_id]);

  const fetchData = async () => {
    try {
      const token = localStorage.getItem("bear_token");

      const formData = new FormData();
      formData.append("smalladmins_id", broker_id);
      formData.append("status", "1");

      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}small-admins-active-company-lists`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      };

      const response = await axios(config);
      const data = response.data.data;



      setData(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const requiredFields = [
      // { field: "company_id", name: "company id" },
      { field: "business_name", name: "business name" },
      { field: "pannumber", name: "pannumber" },
      // { field: "sub_admin_email", name: "sub admin email" },
      // { field: "business_gst", name: "business gst" },
      { field: "account_no", name: "account no" },
      { field: "ac_holder_name", name: "ac holder name" },
      { field: "ifsc", name: "ifsc" },
      // { field: "bank_branch", name: "bank branch" },
      // { field: "bank_address", name: "bank address" },
    ];

    let validationErrors = {};
    let hasError = false;
    for (const { field, name } of requiredFields) {
      if (!formData[field]) {
        validationErrors[field] = `Please fill in the ${name}.`;
        hasError = true;
      }
    }

    setErrors(validationErrors);

    if (hasError) {
      setLoading(false);
      return;
    }
    try {
      const token = localStorage.getItem("bear_token");
      const updatedFormData = new FormData();
      updatedFormData.append("smalladmins_id", broker_id);
     
      if(formData.company_id !== null){
        updatedFormData.append("company_id", formData.company_id);
      
      }
      updatedFormData.append("status", "1");
      // updatedFormData.append("sub_admin_email", formData.sub_admin_email);
      Object.entries(formData).forEach(([key, value]) => {
        if (value !== null && value !== "" && value !== "null") {
          if (key !== "company_name" && key !=="company_id") {
            updatedFormData.append(key, value);
          }
        }
      });
    
      if (newImage) {
        updatedFormData.append("image", newImage);
      }

      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}add-small-admins-business-customer`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
        data: updatedFormData,
      };

      const response = await axios(config);
      const data = response.data.status;

      if (data === 1) {
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: response.data.message,
          color:"white",
          background: 'transparent',
          // timer: 1500
        });
        setTimeout(() => {
          navigate("/Bussiness-Customer-management");
        }, 2000);
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: response.data.message,
          // timer: 2000
        });
      }
    } catch (error) {

      console.warn("Error add-small-admins-customer:", error);
      // if 
      // (error.response && error.response.data && error.response.data.error && error.response.data.error.email) {
      //   Swal.fire({
      //     icon: 'error',
      //     title: 'Error',
      //     text: error.response.data.error.email[0],
      //     timer: 2000
      //   });
      // } else 
      // {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: "Failed to add Bussiness customer",
          // timer: 2000
        });
      // }
  } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <div className="card mb-3">
        <div
          className="bg-holder d-none d-lg-block bg-card"
          style={{
            backgroundImage:
              "url(../../assets/img/icons/spot-illustrations/corner-4.png)",
          }}
        />
        <div className="card-body position-relative">
          <div className="row">
            <div className="col-lg-8">
              <h3 className="mb-0">Add Business Customer</h3>
            </div>
            <div className="col-lg-4 text-end">
              <Link to="/Bussiness-Customer-management" className="">
                <button className="btn btn-success">Back</button>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="card mb-4">
        <form className="card-body" onSubmit={handleSubmit}>
          <h5>Business Details</h5>
          <div className="row g-3 mt-2">
            <div className="col-md-6 form_details">
              <div className="form-group local-forms">
                <label className="form-label" htmlFor="business_name">
                  Name <span className="login-danger">*</span>
                </label>
                <input
                  className={`form-control ${
                    errors.business_name ? "input-error" : ""
                  }`}
                  id="business_name"
                  name="business_name"
                  type="text"
                  value={formData.business_name}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="col-md-6 form_details">
              <div className="form-group local-forms">
                <label className="form-label" htmlFor="business_gst">
                  GST No <span className="login-danger"></span>
                </label>
                <input
                  className={`form-control`}
                  id="business_gst"
                  name="business_gst"
                  type="text"
                  value={formData.business_gst}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="col-md-6 form_details">
              <div className="form-group local-forms">
                <label className="form-label" htmlFor="pannumber">
                  Pan Number <span className="login-danger">*</span>
                </label>
                <input
                  className={`form-control ${
                    errors.pannumber ? "input-error" : ""
                  }`}
                  id="pannumber"
                  name="pannumber"
                  type="text"
                  value={formData.pannumber}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="col-md-6 form_details">
              <div className="form-group local-forms">
                <label className="form-label" htmlFor="profile">
                  {/* Upload Business Photo (150px X 150px) */}
                  <span className="login-danger"></span>
                </label>
                <input
                  className="form-control"
                  id="profile"
                  name="profile"
                  type="file"
                  value={formData.profile}
                  onChange={handleImageChange}
                />
              </div>
              <div className="col-md-6 form_details">
                <div className="form-group local-forms"></div>
              </div>
              <div className="col-md-6 form_details">
                {fileimage != null && (
                  <div className="form-group local-forms">
                    <img
                      src={fileimage}
                      width={100}
                      height={80}
                      onChange={handleImageChange}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <hr className="my-4 mx-n4" />
          <h5>Bank Details</h5>
          <div className="row g-3 mt-2">
            {/* <div className="col-md-6 form_details">
              <div className="form-group local-forms">
                <label className="form-label" htmlFor="bank_branch">
                  Branch Name <span className="login-danger"></span>
                </label>
                <input
                  className={`form-control ${
                    errors.bank_branch ? "input-error" : ""
                  }`}
                  id="bank_branch"
                  name="bank_branch"
                  type="text"
                  value={formData.bank_branch}
                  onChange={handleInputChange}
                />
              </div>
            </div> */}
            <div className="col-md-6 form_details">
              <div className="form-group local-forms">
                <label className="form-label" htmlFor="ac_holder_name">
                  Account Holder Name <span className="login-danger">*</span>
                </label>
                <input
                  className={`form-control ${
                    errors.ac_holder_name ? "input-error" : ""
                  }`}
                  id="ac_holder_name"
                  name="ac_holder_name"
                  type="text"
                  value={formData.ac_holder_name}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="col-md-6 form_details">
              <div className="form-group local-forms">
                <label className="form-label" htmlFor="account_no">
                  Account Number <span className="login-danger">*</span>
                </label>
                <input
                  className={`form-control ${
                    errors.account_no ? "input-error" : ""
                  }`}
                  id="account_no"
                  name="account_no"
                  type="text"
                  value={formData.account_no}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="col-md-6 form_details">
              <div className="form-group local-forms">
                <label className="form-label" htmlFor="ifsc">
                  IFSC Code <span className="login-danger">*</span>
                </label>
                <input
                  className={`form-control ${errors.ifsc ? "input-error" : ""}`}
                  id="ifsc"
                  name="ifsc"
                  type="text"
                  value={formData.ifsc}
                  onChange={handleInputChange}
                />
              </div>
            </div>

            <div className="col-md-6 form_details">
              <div className="form-group local-forms">
                <label className="form-label" htmlFor="upi_id">
                  Upi Id <span className="login-danger"></span>
                </label>
                <input
                  className={`form-control `}
                  id="upi_id"
                  name="upi_id"
                  type="text"
                  value={formData.upi_id}
                  onChange={handleInputChange}
                />
              </div>
            </div>
          </div>
          <hr className="my-4 mx-n4" />
          <h5>Sub Admin Details</h5>
          <Row className="g-3 mt-2">
            <div className="col-md-6 form_details">
              <div className="form-group local-forms">
                <label className="form-label" htmlFor="sub_admin_email">
                  Sub Admin Email<span className="login-danger"></span>
                </label>
                <input
                  className={`form-select`}
                  id="sub_admin_email"
                  name="sub_admin_email"
                  type="text"
                  value={formData.sub_admin_email}
                  onChange={handleInputChange}
                />
              </div>
            </div>

            
          </Row>
          <hr className="my-4 mx-n4" />
          <h5>Company Details</h5>
          <Row className="g-3 mt-2">
            
            {company_Name !== null ? (
            <div className="col-md-6 form_details mt-3">
              <div className="form-group local-forms">
                <label className="form-label" htmlFor="company_id">
                  Company <span className="login-danger"></span>
                </label>
                <select
                  className={`form-select `}

                  id="company_id"
                  name="company_id"
                  value={formData.company_id}
                  onChange={handleInputChange}
                >
                    <option key={formData.company_id} value={formData.company_id}>
                      {company_Name}
                    </option>
                  
                </select>
              </div>
            </div>
              ):(
            <div className="col-md-6 form_details mt-3">
              <div className="form-group local-forms">
                <label className="form-label" htmlFor="company_id">
                  Company <span className="login-danger"></span>
                </label>
                <select
                  className={`form-select `}
                  id="company_id"
                  name="company_id"
                  value={formData.company_id}
                  onChange={handleInputChange}
                >
              <option value="">Select Company</option>
                  {data.map((company) => (
                    <option key={company.id} value={company.id}>
                      {company.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            )}
       
          </Row>

          <div className="text-end">
            <button
              className="btn btn-primary"
              type="submit"
              disabled={loading}
            >
              {loading ? <Spinner animation="border" size="sm" /> : "Submit"}
            </button>
          </div>
        </form>
      </div>
  
    </div>
  );
}
