import React, { useState, useEffect } from "react";
import axios from "axios";

export default function Aboutus() {
  const broker_id = localStorage.getItem("broker_id");
  const [data, setData] = useState(null); // Initially, data is null
  const [baseurl, setBaseurl] = useState('');
  const [loading, setLoading] = useState(true); // State for loading
  const [error, setError] = useState(null); // State for error message
  const token = localStorage.getItem("bear_token");

  useEffect(() => {
    getAboutus();
  }, []);

  const getAboutus = async () => {
    try {
      const formData = new FormData();
      formData.append("smalladmins_id", broker_id);

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}small-admins-cms-about-us`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const { data: responseData, image_url } = response.data;
      setBaseurl(image_url);
      setData(responseData);
      setLoading(false); // Set loading to false after data is fetched
    } catch (error) {
      setError("Error fetching the about us information. Please try again later.");
      setLoading(false); // Set loading to false even if there's an error
    }
  };

  return (
    <div className="card mb-3">
      <div
        className="bg-holder d-none d-lg-block bg-card"
        style={{
          backgroundImage: "url(../../assets/img/icons/spot-illustrations/corner-4.png)",
        }}
      />
      <div className="card-body position-relative">
        {loading && <div>Loading...</div>}
        {error && <div>{error}</div>}
        {!loading && !error && !data && <div>No data available.</div>}
        {!loading && !error && data && (
          <>
            <h2 className="card-title">{data.title}</h2>
            <div className="row">
              <div className="col-md-4">
                <div className="aboutusimage">
                  <img
                    src={`${baseurl}/${data.image}`}
                    alt={data.title}
                    className=""
                  />
                </div>
              </div>
              <div className="col-md-8">
                <div className="card-body">
                  <p className="card-text">{data.descriptions}</p>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
