import React, { useState, useEffect } from "react";
import axios from "axios";
import { Container, Row, Col, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export default function AddMoreplanbusiness() {
  const broker_id = localStorage.getItem("broker_id");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const Addplan_id = localStorage.getItem("Addplan_id");
  const [newImage, setNewImage] = useState();
  const [fileimage, setFileimage] = useState();
  const [errors, setErrors] = useState({});
  const [data, setData] = useState([]);
  const [datacom, setDatacompny] = useState([]);
  const [datatype, setDatatype] = useState([]);
  const [dataplan, setDataplan] = useState([]);


  const [formData, setFormData] = useState({
    name: "",
    description: "",
    minimum_investment: "",
    maximum_investment: "",
    plan_duration: "",
    plan_duration_type: "",
    interest_rate: "",
    company_id: "",
    invested_amount: "",
    invest_plain_id: ""
    // Plan_image: null,
  });

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setNewImage(file);
    setFileimage(URL.createObjectURL(file));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  useEffect(() => {
    fetchData();
    fetchDatacompny();
    fetchData1();
    fetchDataine_plan();
  }, []);

  const fetchDataine_plan = async () => {
    try {
        const token = localStorage.getItem("bear_token");

        const formData = new FormData();
        formData.append("smalladmins_id", broker_id);
        formData.append("status", '1');

        const config = {
            method: "post",
            url: `${process.env.REACT_APP_API_URL}small-admins-investment-plans-lists`,
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "multipart/form-data"
            },
            data: formData
        };

        const response = await axios(config);
        setDataplan(response.data.data);

    } catch (error) {
        console.error("Error fetching data:", error);
    }
};
  const fetchDatacompny = async () => {
    try {
      const token = localStorage.getItem("bear_token");

      const formData = new FormData();
      formData.append("smalladmins_id", broker_id);
      formData.append("status", '1');

      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}small-admins-company-lists`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data"
        },
        data: formData
      };

      const response = await axios(config);
      setDatacompny(response.data.data);

    } catch (error) {
      console.error("Error fetching data:", error);

    }
  };
  const fetchData = async () => {
    const token = localStorage.getItem("bear_token");

    try {
      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}small-admins-paln-duration`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      };

      const response = await axios(config);
      const rawData = response.data.data;

      // Transform the data into the expected format
      const transformedData = rawData.map((item) => ({
        id: item,
        name: `${item}`, // Assuming you want to display "Duration <number>"
      }));

      console.warn(transformedData);

      // Set transformed data
      setData(transformedData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchData1 = async () => {
    const token = localStorage.getItem("bear_token");

    try {
      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}small-admins-plan-duration-type`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      };

      const response = await axios(config);
      const rawData = response.data.data;

      // Transform the data into the expected format
      const transformedData = rawData.map((item) => ({
        id: item,
        name: `${item}`, // Assuming you want to display "Duration <number>"
      }));

      console.warn(transformedData);

      // Set transformed data
      setDatatype(transformedData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
  
    const requiredFields = [
      { field: "name", name: "Name" },
      { field: "description", name: "Description" },
      { field: "minimum_investment", name: "Minimum Investment" },
      { field: "maximum_investment", name: "Maximum Investment" },
      { field: "plan_duration", name: "Plan duration" },
      { field: "plan_duration_type", name: "Plan duration type" },
      { field: "interest_rate", name: "Interest Rate" },
    ];
  
    let validationErrors = {};
    let hasError = false;
  
    for (const { field, name } of requiredFields) {
      if (!formData[field]) {
        validationErrors[field] = `Please fill in the ${name}.`;
        hasError = true;
      }
    }
  
    setErrors(validationErrors);
  
    if (hasError) {
      setLoading(false);
      return;
    }
  
    try {
      const token = localStorage.getItem("bear_token");
      const updatedFormData = new FormData();
      updatedFormData.append("smalladmins_id", broker_id);
      updatedFormData.append("cust_id", Addplan_id);
      updatedFormData.append("status", "1");
      Object.keys(formData).forEach((key) => {
        updatedFormData.append(key, formData[key]);
      });
      updatedFormData.append("Plan_image", newImage);
    
      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}invest-more-small-admins-business-customers`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
        data: updatedFormData,
      };
    
      const response = await axios(config);
      const data = response.data.status;
    
      if (data === 1) {
        toast.success(response.data.message);
        setTimeout(() => {
          navigate("/Bussiness-Customer-management");
        }, 2000);
      } else {
        console.error("API Error:", response.data.message);
        toast.error(response.data.message);
      }
    } catch (error) {
      if (error.response && error.response.data) {
        const { error: errorCode, message } = error.response.data;
        if (errorCode === 1) {
          console.error("API Error:", message);
          toast.error(message);
        } else {
          console.error("API Error:", message);
          toast.error("An unexpected error occurred.");
        }
      } else {
        console.error("Unexpected Error:", error.message);
        toast.error("An unexpected error occurred.");
      }
    } finally {
      setLoading(false);
    }
    
  };
  

  return (
    <div>
      <div className="card mb-3">
        <div
          className="bg-holder d-none d-lg-block bg-card"
          style={{
            backgroundImage:
              "url(../../assets/img/icons/spot-illustrations/corner-4.png)",
          }}
        />
        <div className="card-body position-relative">
          <div className="row">
            <div className="col-lg-8">
              <h3 className="mb-0">Add Plan</h3>
            </div>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-body">
          <Container fluid>
            <Row>
              <Col md={6}>
                <div className="mb-3">
                  <label className="form-label" htmlFor="first_name">
                    Name
                  </label>
                  <input
                    className="form-control"
                    id="name"
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                  />
                  {errors.name && (
                    <div className="text-danger">{errors.name}</div>
                  )}
                </div>
              </Col>

              <Col md={6}>
                <div className="mb-3">
                  <label className="form-label" htmlFor="minimum_investment">
                    Minimum Investment
                  </label>
                  <input
                    className="form-control"
                    id="minimum_investment"
                    type="number"
                    name="minimum_investment"
                    value={formData.minimum_investment}
                    onChange={handleInputChange}
                  />
                  {errors.minimum_investment && (
                    <div className="text-danger">
                      {errors.minimum_investment}
                    </div>
                  )}
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <label className="form-label" htmlFor="maximum_investment">
                    Maximum Investment
                  </label>
                  <input
                    className="form-control"
                    id="maximum_investment"
                    type="number"
                    name="maximum_investment"
                    value={formData.maximum_investment}
                    onChange={handleInputChange}
                  />
                  {errors.maximum_investment && (
                    <div className="text-danger">
                      {errors.maximum_investment}
                    </div>
                  )}
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <label className="form-label" htmlFor="invested_amount">
                     Investment Amount
                  </label>
                  <input
                    className="form-control"
                    id="invested_amount"
                    type="number"
                    name="invested_amount"
                    value={formData.invested_amount}
                    onChange={handleInputChange}
                  />
                  {errors.invested_amount && (
                    <div className="text-danger">
                      {errors.invested_amount}
                    </div>
                  )}
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <label className="form-label" htmlFor="plan_duration">
                    Plan Duration
                  </label>
                  <select
                    className="form-select"
                    id="plan_duration"
                    name="plan_duration"
                    value={formData.plan_duration}
                    onChange={handleInputChange}
                  >
                    <option value="">Select</option>
                    {data.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                  {errors.plan_duration && (
                    <div className="text-danger">{errors.plan_duration}</div>
                  )}
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <label className="form-label" htmlFor="plan_duration_type">
                    Plan Duration Type
                  </label>
                  <select
                    className="form-select"
                    id="plan_duration_type"
                    name="plan_duration_type"
                    value={formData.plan_duration_type}
                    onChange={handleInputChange}
                  >
                    <option value="">Select Type</option>
                    {datatype.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                  {errors.plan_duration_type && (
                    <div className="text-danger">
                      {errors.plan_duration_type}
                    </div>
                  )}
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <label className="form-label" htmlFor="company_id">
                    Select Company
                  </label>
                  <select
                    className="form-select"
                    id="company_id"
                    name="company_id"
                    value={formData.company_id}
                    onChange={handleInputChange}
                  >
                    <option value="">Select Company Name</option>
                    {datacom.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                  {errors.company_id && (
                    <div className="text-danger">
                      {errors.company_id}
                    </div>
                  )}
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <label className="form-label" htmlFor="invest_plain_id">
                  Invest Plan Name
                  </label>
                  <select
                    className="form-select"
                    id="invest_plain_id"
                    name="invest_plain_id"
                    value={formData.invest_plain_id}
                    onChange={handleInputChange}
                  >
                    <option value="">Select Plan Name</option>
                    {dataplan.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                  {errors.invest_plain_id && (
                    <div className="text-danger">
                      {errors.invest_plain_id}
                    </div>
                  )}
                </div>
              </Col>
              {/* <Col md={6}>
              <div className="row g-3">
                <div className="col-md-6">
                  <div className="mb-3">
                    <label className="form-label" htmlFor="company_id">Select Company</label>
                    <select className="form-select" id="company_id" name="company_id" value={formData.company_id} onChange={handleInputChange}>
                      <option value="">Select Company Name</option>
                      {datacom.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              </Col> */}
              <Col md={6}>
                <div className="mb-3">
                  <label className="form-label" htmlFor="description">
                    Description
                  </label>
                  <input
                    className="form-control"
                    id="description"
                    type="text"
                    name="description"
                    value={formData.description}
                    onChange={handleInputChange}
                  />
                  {errors.description && (
                    <div className="text-danger">{errors.description}</div>
                  )}
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <label className="form-label" htmlFor="interest_rate">
                    Interest Rate
                  </label>
                  <input
                    className="form-control"
                    id="interest_rate"
                    type="text"
                    name="interest_rate"
                    value={formData.interest_rate}
                    onChange={handleInputChange}
                  />
                  {errors.interest_rate && (
                    <div className="text-danger">{errors.interest_rate}</div>
                  )}
                </div>
              </Col>

              <Col md={6}>
                <div className="mb-3">
                  <label className="form-label" htmlFor="Plan_image">
                    Plan Image
                  </label>
                  <input
                    className="form-control"
                    id="Plan_image"
                    type="file"
                    onChange={handleImageChange}
                  />
                  {errors.Plan_image && (
                    <div className="text-danger">{errors.Plan_image}</div>
                  )}
                </div>
              </Col>
              {fileimage != null && (
                <Col md={6}>
                  <div className="mb-3">
                    <img
                      className=""
                      src={fileimage}
                      alt="fileimage"
                      width={150}
                      height={80}
                    />
                  </div>
                </Col>
              )}
              <Col md={12}>
                <div className="mb-3 d-flex justify-content-end">
                  <button
                    onClick={handleSubmit}
                    disabled={loading}
                    className="btn btn-sm btn-primary"
                    type="submit"
                  >
                    Submit
                    {loading && (
                      <Spinner
                        animation="border"
                        style={{
                          width: "15px",
                          height: "15px",
                          marginLeft: "10px",
                        }}
                      />
                    )}
                  </button>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}
