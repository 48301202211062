import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FaUser } from "react-icons/fa";
import { IoDocumentText } from "react-icons/io5";
import { GrTransaction } from "react-icons/gr";
import { MdDashboard } from "react-icons/md";
import { HiUserGroup } from "react-icons/hi";
import { IoSettingsOutline } from "react-icons/io5";
import { TiContacts } from "react-icons/ti";
import { MdOutlineContactSupport } from "react-icons/md";
import { CgProfile } from "react-icons/cg";
import { FaRegAddressBook } from "react-icons/fa6";
import { IoPersonOutline } from "react-icons/io5";
import { GrShieldSecurity } from "react-icons/gr";
import { MdNotificationsActive } from "react-icons/md";
import {
  MdOutlinePersonalInjury,
  MdOutlineManageAccounts,
  MdManageHistory,
  MdBusinessCenter,
  MdHistory,
} from "react-icons/md";
import { PiHandCoinsFill } from "react-icons/pi";
import { GiReceiveMoney } from "react-icons/gi";
import { GrUserManager } from "react-icons/gr";
import { RiMoneyRupeeCircleFill } from "react-icons/ri";
import { TbArrowsTransferDown, TbReport } from "react-icons/tb";
import Logo from "../assets/img/logos/swan.logo.png";
import Settings from './../pages/Home/Settings';

const Sidebar = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [activeMenu, setActiveMenu] = useState("");

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  useEffect(() => {
    // Add or remove class from the <html> tag based on the 'collapsed' state
    if (isSidebarOpen) {
      document.documentElement.classList.remove("navbar-vertical-collapsed");
    } else {
      document.documentElement.classList.add("navbar-vertical-collapsed");
    }
  }, [isSidebarOpen]);

  const handleClickHedgeCompanyId = () => {
    localStorage.setItem("clearcompany_id", "1");
  };

  const handleClickBusinessCompanyId = () => {
    localStorage.setItem("clearcompany_id", "2");
  };

  const handleMenuClick = (menuId) => {
    setActiveMenu(menuId);
  };
  return (
    <div className={`sidebar ${isSidebarOpen ? "open" : "closed"}`}>
      <nav className="navbar navbar-light navbar-vertical navbar-expand-xl">
        <div className="d-flex align-items-center">
          <div className="toggle-icon-wrapper">
            <button
              className="btn navbar-toggler-humburger-icon toggle-btn navbar-vertical-toggle"
              data-bs-toggle="tooltip"
              data-bs-placement="left"
              onClick={toggleSidebar}
              title="Toggle Navigation"
            >
              <span className="navbar-toggle-icon">
                <span className="toggle-line" />
              </span>
            </button>
          </div>
          <Link className="navbar-brand" to="/Home">
            <div className="d-flex align-items-center">
              <img className="me-2" src={Logo} alt="" width={130} />
            </div>
          </Link>
        </div>
        <div className="collapse navbar-collapse" id="navbarVerticalCollapse">
          <div className="navbar-vertical-content scrollbar">
            <ul className="navbar-nav flex-column mb-3" id="navbarVerticalNav">
              {/* Dashboard */}
              <li className={`nav-item new_menu ${activeMenu === "dashboard" ? "active" : ""}`}>
                <Link className="nav-link menulinkactive" to="/Home" onClick={() => handleMenuClick("dashboard")}>
                  <div className="d-flex align-items-center">
                    <span className="nav-link-icon">
                      <MdDashboard />
                    </span>
                    <span className="nav-link-text ps-1">Dashboard</span>
                  </div>
                </Link>
              </li>
              {/*  Customer Management */}
              <li className={`nav-item new_menu ${activeMenu === "customer-management" ? "active" : ""}`}>

                <a
                  onClick={() => handleMenuClick("customer-management")}
                  className="nav-link dropdown_menu dropdown-indicator"
                  href="#CustomerManagement"
                  role="button"
                  data-bs-toggle="collapse"
                  aria-expanded="false"
                  aria-controls="CustomerManagement"
                >
                  <div className="d-flex align-items-center">
                    <span className="nav-link-icon">
                      <MdOutlineManageAccounts />
                    </span>
                    <span className="nav-link-text ps-1">
                      Customer Management
                    </span>
                  </div>
                </a>
                <ul className="nav collapse" id="CustomerManagement">
                  <li className="nav-item">
                    <Link className="nav-link" to="/Customermanagement">
                      <div className="d-flex align-items-center" onClick={handleClickHedgeCompanyId}>
                        <span className="nav-link-icon">
                          <span className="fa-solid fa-circle-question" />
                        </span>
                        <span className="nav-link-text ps-1">
                          Normal Customer
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className="nav-link"
                      to="/Bussiness-Customer-management"
                    >
                      <div className="d-flex align-items-center" onClick={handleClickBusinessCompanyId}>
                        <span className="nav-link-icon">
                          <span className="fa-solid fa-circle-question" />
                        </span>
                        <span className="nav-link-text ps-1">
                          Business Customer
                        </span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </li>
              <li className={`nav-item new_menu ${activeMenu === "InvestmentPlan" ? "active" : ""}`}>
                <Link className="nav-link menulinkactive" to="/Investment-management" onClick={() => handleMenuClick("InvestmentPlan")}>

                  <div className="d-flex align-items-center">
                    <span className="nav-link-icon">
                      <PiHandCoinsFill />
                    </span>
                    <span className="nav-link-text ps-1">Investment Plan</span>
                  </div>
                </Link>
              </li>
              <li className={`nav-item new_menu ${activeMenu === "CompanyManagement" ? "active" : ""}`}>
                <Link className="nav-link menulinkactive" to="/Companymangement" onClick={() => handleMenuClick("CompanyManagement")}>
                  <div className="d-flex align-items-center">
                    <span className="nav-link-icon">
                      <MdManageHistory />
                    </span>
                    <span className="nav-link-text ps-1">
                      {" "}
                      Company Management
                    </span>
                  </div>
                </Link>
              </li>
              {/* Customer Investments */}
              <li className={`nav-item new_menu ${activeMenu === "customer-investments" ? "active" : ""}`}>
                <a
                  className="nav-link dropdown_menu dropdown-indicator"
                  href="#CustomerInvestments"
                  role="button"
                  data-bs-toggle="collapse"
                  aria-expanded="false"
                  aria-controls="CustomerInvestments"
                  onClick={() => handleMenuClick("customer-investments")}
                >
                  <div className="d-flex align-items-center">
                    <span className="nav-link-icon">
                      <MdOutlineManageAccounts />
                    </span>
                    <span className="nav-link-text ps-1">
                      Customer Investments
                    </span>
                  </div>
                </a>
                <ul className="nav collapse" id="CustomerInvestments">
                  <li className="nav-item">
                    <Link
                      className={`nav-link `}
                      to="/Customer-investments"
                      // onClick={() => handleMenuClick("customer-investments-normal")}
                    >
                      <div className="d-flex align-items-center">
                        <span className="nav-link-icon">
                          <span className="fa-solid fa-circle-question" />
                        </span>
                        <span className="nav-link-text ps-1">
                          Normal Customer
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className={`nav-link `}
                      to="/Bussiness-investments"
                      // onClick={() => handleMenuClick("customer-investments-business")}
                    >
                      <div className="d-flex align-items-center">
                        <span className="nav-link-icon">
                          <span className="fa-solid fa-circle-question" />
                        </span>
                        <span className="nav-link-text ps-1">
                          Business Customer
                        </span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </li>
              {/* Investment-management */}

              {/* Company Management */}


              {/* Employee Management */}
              <li className={`nav-item new_menu ${activeMenu === "EmployeeManagement" ? "active" : ""}`}>

                <Link className="nav-link menulinkactive" to="/Employeemanagement" onClick={() => handleMenuClick("EmployeeManagement")}>
                  <div className="d-flex align-items-center">
                    <span className="nav-link-icon">
                      <GrUserManager />
                    </span>
                    <span className="nav-link-text ps-1">
                      Employee Management
                    </span>
                  </div>
                </Link>
              </li>

              <li className={`nav-item new_menu ${activeMenu === "profitandloss" ? "active" : ""}`}>
                <a
                  className="nav-link dropdown_menu dropdown-indicator"
                  href="#ProfitLossManagement"
                  role="button"
                  onClick={() => handleMenuClick("profitandloss")}
                  data-bs-toggle="collapse"
                  aria-expanded="false"
                  aria-controls="ProfitLossManagement"
                >
                  <div className="d-flex align-items-center">
                    <span className="nav-link-icon">
                      <TbArrowsTransferDown />
                    </span>
                    <span className="nav-link-text ps-1">
                      Profit & Loss Management
                    </span>
                  </div>
                </a>
                <ul className="nav collapse" id="ProfitLossManagement">
                  <li className="nav-item">
                    <Link className="nav-link" to="/Straties">
                      <div className="d-flex align-items-center">
                        <span className="nav-link-icon">
                          <span className="fa-solid fa-circle-question" />
                        </span>
                        <span className="nav-link-text ps-1">
                          Strategy List
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li className="nav-item new_menu">
                    <Link className="nav-link" to="/Addsymbol">
                      <div className="d-flex align-items-center">
                        <span className="nav-link-icon">
                          <span className="fa-solid fa-circle-question" />
                        </span>
                        <span className="nav-link-text ps-1">
                          Symbol List
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li className="nav-item new_menu">
                    <Link className="nav-link" to="/Defaultlotsize">
                      <div className="d-flex align-items-center">
                        <span className="nav-link-icon">
                          <span className="fa-solid fa-circle-question" />
                        </span>
                        <span className="nav-link-text ps-1">
                          Default Lot Size List
                        </span>
                      </div>
                    </Link>
                  </li>

                  {/* <li className="nav-item new_menu">
      <a
        className="nav-link dropdown_menu dropdown-indicator"
        href="#AddBillSubmenu"
        role="button"
        data-bs-toggle="collapse"
        aria-expanded="false"
        aria-controls="AddBillSubmenu"
      >
        <div className="d-flex align-items-center">
          <span className="nav-link-icon">
            <span className="fa-solid fa-circle-question" />
          </span>
          <span className="nav-link-text ps-1">
            Add Bill
          </span>
        </div>
      </a>
      <ul className="nav collapse" id="AddBillSubmenu">
      <li className="nav-item new_menu">
          <Link className="nav-link" to="/AddNakedBuySell">
            <div className="d-flex align-items-center">
              <span className="nav-link-icon">
                <span className="fa-solid fa-circle-question" />
              </span>
              <span className="nav-link-text ps-1">
                Add Naked Buy/Sell
              </span>
            </div>
          </Link>
        </li>
        <li className="nav-item new_menu">
          <Link className="nav-link" to="/AddHedge">
            <div className="d-flex align-items-center">
              <span className="nav-link-icon">
                <span className="fa-solid fa-circle-question" />
              </span>
              <span className="nav-link-text ps-1">
                Add Hedge
              </span>
            </div>
          </Link>
        </li>
     
      </ul>
    </li> */}
                  <li className={`nav-item new_menu  ${activeMenu === "CompanyList" ? "active" : ""}`}>
                    <Link className="nav-link" to="/AddNakedBuySell" onClick={() => handleMenuClick("CompanyList")}>
                      <div className="d-flex align-items-center">
                        <span className="nav-link-icon">
                          <span className="fa-solid fa-circle-question" />
                        </span>
                        <span className="nav-link-text ps-1">
                          Company P&L List
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li className="nav-item new_menu">
                    <Link className="nav-link" to="/PL-management">
                      {/* <Link className="nav-link" to="/AddPL"> */}
                      <div className="d-flex align-items-center">
                        <span className="nav-link-icon">
                          <span className="fa-solid fa-circle-question" />
                        </span>
                        <span className="nav-link-text ps-1">
                          Over All P&L List
                        </span>
                      </div>
                    </Link>
                  </li>
                  {/* <li className="nav-item new_menu">
      <Link className="nav-link" to="/PL-management">
        <div className="d-flex align-items-center">
          <span className="nav-link-icon">
            <span className="fa-solid fa-circle-question" />
          </span>
          <span className="nav-link-text ps-1">
            Profit and Loss
          </span>
        </div>
      </Link>
    </li> */}
                </ul>
              </li>




              <li className={`nav-item new_menu ${activeMenu === "FAQs" ? "active" : ""}`}>
                <Link className="nav-link menulinkactive" to="/Faq" onClick={() => handleMenuClick("FAQs")}>
                              <div className="d-flex align-items-center">
                    <MdOutlineContactSupport />
                    <span className="nav-link-text ps-1">
                      {" "}
                      <span className="nav-link-icon">
                        <span className="fa-solid fa-building-shield" />
                      </span>
                      FAQs
                    </span>
                  </div>
                </Link>
              </li>

              {/* <li className="nav-item new_menu">
                <Link className="nav-link" to="/Profile">
                  <div className="d-flex align-items-center">
                    <span className="nav-link-text ps-1">
                      {" "}
                      <span className="nav-link-icon">
                        <span className="fa-solid fa-building-shield" />
                      </span>
                      Profile
                    </span>
                  </div>
                </Link>
             
              </li> */}

<li className={`nav-item new_menu ${activeMenu === "Contactus" ? "active" : ""}`}>
                <Link className="nav-link menulinkactive" to="/Contactus" onClick={() => handleMenuClick("Contactus")}>
            
                  <div className="d-flex align-items-center">
                    <TiContacts />
                    <span className="nav-link-text ps-1">
                      {" "}
                      <span className="nav-link-icon">
                        <span className="fa-solid fa-building-shield" />
                      </span>
                      Contact us
                    </span>
                  </div>
                </Link>

              </li>
              
<li className={`nav-item new_menu ${activeMenu === "Aboutus" ? "active" : ""}`}>
                <Link className="nav-link menulinkactive" to="/Aboutus" onClick={() => handleMenuClick("Aboutus")}>
            
                  <div className="d-flex align-items-center">
                    <IoPersonOutline />
                    <span className="nav-link-text ps-1">
                      {" "}
                      <span className="nav-link-icon">
                        <span className="fa-solid fa-building-shield" />
                      </span>
                      About Us
                    </span>
                  </div>
                </Link>

              </li>
              <li className={`nav-item new_menu ${activeMenu === "Termsandcondition" ? "active" : ""}`}>
                <Link className="nav-link menulinkactive" to="/Termsandcondition" onClick={() => handleMenuClick("Termsandcondition")}>
            
            
                  <div className="d-flex align-items-center">
                    <FaRegAddressBook />
                    <span className="nav-link-text ps-1">
                      {" "}
                      <span className="nav-link-icon">
                        <span className="fa-solid fa-building-shield" />
                      </span>
                      Terms &amp; Conditions
                    </span>
                  </div>
                </Link>

              </li>
              <li className={`nav-item new_menu ${activeMenu === "Privacypolicy" ? "active" : ""}`}>
                <Link className="nav-link menulinkactive" to="/Privacypolicy" onClick={() => handleMenuClick("Privacypolicy")}>
           
          
                  <div className="d-flex align-items-center">
                    <GrShieldSecurity />
                    <span className="nav-link-text ps-1">
                      {" "}
                      <span className="nav-link-icon">
                        <span className="fa-solid fa-building-shield" />
                      </span>
                      Privacy policy
                    </span>
                  </div>
                </Link>

              </li>
              <li className={`nav-item new_menu ${activeMenu === "Profile" ? "active" : ""}`}>
                <Link className="nav-link menulinkactive" to="/Profile" onClick={() => handleMenuClick("Profile")}>

                  <div className="d-flex align-items-center">
                    <CgProfile />
                    <span className="nav-link-text ps-1">
                      {" "}
                      <span className="nav-link-icon">
                        <span className="fa-solid fa-building-shield" />
                      </span>
                      Profile
                    </span>
                  </div>
                </Link>

              </li>
              <li className={`nav-item new_menu ${activeMenu === "Notifications" ? "active" : ""}`}>
                <Link className="nav-link menulinkactive" to="/Notifications" onClick={() => handleMenuClick("Notifications")}>

  
                  <div className="d-flex align-items-center">
                    <MdNotificationsActive />


                    <span className="nav-link-text ps-1">
                      {" "}
                      <span className="nav-link-icon">
                        <span className="fa-solid fa-building-shield" />
                      </span>
                      Notifications
                    </span>
                  </div>
                </Link>

              </li>
              <li className={`nav-item new_menu ${activeMenu === "Settings" ? "active" : ""}`}>
                <Link className="nav-link menulinkactive" to="/Settings" onClick={() => handleMenuClick("Settings")}>

  
                  <div className="d-flex align-items-center">
                    <IoSettingsOutline />


                    <span className="nav-link-text ps-1">
                      {" "}
                      <span className="nav-link-icon">
                        <span className="fa-solid fa-building-shield" />
                      </span>
                      Settings
                    </span>
                  </div>
                </Link>

              </li>
            
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Sidebar;
