import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { FaFilePdf, FaRegEdit, FaEye, FaSearch } from "react-icons/fa";
import { FaCloudDownloadAlt } from "react-icons/fa";
import {
  MdDelete,
  MdOutlinePendingActions,
  MdOutlineSmsFailed,
  MdArrowOutward
} from "react-icons/md";
import { MdCurrencyRupee } from "react-icons/md";
import { IoPersonSharp } from "react-icons/io5";
import { IoMdAdd, IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import { Button, Row } from "react-bootstrap";
import Swal from "sweetalert2";
import { toast, ToastContainer } from "react-toastify";
import { GrTransaction } from "react-icons/gr";
import { FaDiagramSuccessor } from "react-icons/fa6";
import pdf from './../../assets/img/pdf.svg'

export default function TransactionDetails() {
  const [data, setData] = useState([]);
  const [baseurl, setbaseurl] = useState();
  const [baseurlprofil, setbaseurlprofil] = useState();
  const [profilimage, setprofilimage] = useState();
  const [CustomerName, setCustomerName] = useState();
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();
  const [AllEmployee, setAllEmployee] = useState("");
  const [ActiveEmployee, setActiveEmployee] = useState("");
  const [PendingEmployee, setPendingEmployee] = useState("");
  const [FaildEmployee, setFaildEmployee] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const custumerdetails_id = localStorage.getItem("custumerdetails");
  const emp_id = localStorage.getItem("employee_id");
  const userid = localStorage.getItem("userid");
  const token = localStorage.getItem("bear_token");
  const broker_id = localStorage.getItem("broker_id");
  const employee_id = localStorage.getItem("employee_id");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  useEffect(() => {
    getProfile();
  }, []);

  const getProfile = async () => {
    try {
      const formData = new FormData();
      formData.append("smalladmins_id", broker_id);
      formData.append("emp_id", employee_id);
      const token = localStorage.getItem("bear_token");
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}small-admins-emp-Sucesstransactions-list`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setbaseurl(response.data.trnximageUrl)
      setbaseurlprofil(response.data.profileImage)
      setprofilimage(response.data.image)
      setCustomerName(response.data.EmpData)
      const totalEmp = response.data.TotalTransaction;
      const ActiveEmployee = response.data.TotalSuccessTransaction;
      const InactiveEmployee =response.data.data[0].user_credit;

      setAllEmployee(totalEmp);
      setActiveEmployee(ActiveEmployee);
      setPendingEmployee(InactiveEmployee);
      setData(response.data.data);
    } catch (error) {
      console.error("Error fetching user profile:", error);
    }
  };
  const getFilteredTransactions = async (fromDate, toDate) => {
    if (data.length == 0) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: "Sorry, no data found!",
        // timer: 2000
      });
      return;
    }
    if (!fromDate || !toDate) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: "Please choose a valid date!",
        // timer: 2000
      });
      setLoading(false);
      return;
    }
    try {
      const token = localStorage.getItem("bear_token");
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}small-admins-emp-FilterByDate-transactions?from_date=${fromDate}&to_date=${toDate}&smalladmins_id=${broker_id}&emp_id=${employee_id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      setData(response.data.data);
    } catch (error) {
      console.error("Error fetching filtered transactions:", error);
    }
  };


  const downloadFile = async (fromDate, toDate) => {
    if (data.length == 0) {
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: "Sorry, no data found!",
        // timer: 2000
      });
      return;
    }
    if (!fromDate || !toDate) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: "Please choose a valid date!",
        // timer: 2000
      });
      setLoading(false);
      return;
    }
    try {
      const token = localStorage.getItem("bear_token");
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}small-admins-emp-FilterByDate-transactions-PDF?from_date=${fromDate}&to_date=${toDate}&smalladmins_id=${broker_id}&emp_id=${employee_id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/pdf",
          },
          responseType: "blob",
        }
      );

      if (response.headers["content-type"] === "application/pdf") {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = "employee_Filter_transaction.pdf";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        console.error("Response is not a PDF file");
      }
    } catch (error) {
      console.error("Error fetching PDF file:", error);
    }
  };


  const handleDownload = () => {
    downloadFile(fromDate, toDate);
  };

  const handleFilter = () => {
    getFilteredTransactions(fromDate, toDate);
  };

  const handleClick = (id) => {
    localStorage.setItem("Addplan_id", id);
    navigate("/Add-More-plan");
  };

  const handleClickAdd = (id) => {
    navigate("/Add-admins-customer");
  };

  const handleEditCustomerClick = (emp_id) => {
    localStorage.setItem("employee_id", emp_id);
    navigate("/EmployeeViewedit");
  };

 
  const handleViewCustomerClick = (emp_id) => {
    localStorage.setItem("employee_idTransaction", emp_id);
    navigate("/Add-Employee-Transaction");
  }

  const filteredData = data ? data.filter(
    (item) =>
      item.transaction_id &&
      item.transaction_id.toLowerCase().includes(searchTerm.toLowerCase())
  ):[];

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedData = filteredData.slice(startIndex, endIndex);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <>
      <div className="card mb-3">
        <div
          className="bg-holder d-none d-lg-block bg-card"
          style={{
            backgroundImage:
              "url(../../assets/img/icons/spot-illustrations/corner-4.png)",
          }}
        />
        <div className="card-body position-relative">
          <div className="row">
            <div className="col-lg-8">
              <h2 className="mb-0">Employee Transactions Analytics</h2>
            </div>
            <div className="col-lg-4 text-end">
              <Link to="/EmployeeView" className="">
                <button className="btn btn-success">Back</button>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="row g-3 mb-3">
      <div className="col-md-4 col-xxl-3">
          <Link to="#" className="linkWithoutUnderline ">
            <div className="card h-md-100 ecommerce-card-min-width">
              <div className="card-header pb-0">
                <h5 className="mb-0 mt-2 d-flex align-items-center">Total Credit Amount</h5>
              </div>
              <div className="card-body d-flex flex-column justify-content-end">
                <div className="row align-items-center">
                  <div className="col pe-0">
                  <MdCurrencyRupee className="fs-4 text-success" />
                  </div>
                  <div className="col ps-0">
                    <p className="font-sans-serif lh-1 mb-1 fs-5 text-success">
                      {PendingEmployee}
                    </p>
                  </div>
                  <div className="col-auto ps-0">
                    <div className="echart-bar-weekly-sales h-100" />
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div>
        <div className="col-md-4 col-xxl-3">
          <Link to="/Employee-Transaction-details" className="linkWithoutUnderline">
            <div className="card h-md-100 ecommerce-card-min-width">
              <div className="card-header pb-0">
                <h5 className="mb-0 mt-2 d-flex align-items-center">Total</h5>
              </div>
              <div className="card-body d-flex flex-column justify-content-end">
                <div className="row align-items-center">
                  <div className="col pe-0">
                    <GrTransaction className="fs-4 text-primary" />
                  </div>
                  <div className="col ps-0">
                    <p className="font-sans-serif lh-1 mb-1 fs-5 text-primary">
                      {AllEmployee}
                    </p>
                  </div>
                  <div className="col-auto ps-0">
                    <div className="echart-bar-weekly-sales h-100" />
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div>
        <div className="col-md-4 col-xxl-3">
          <Link to="/Employee-Transaction-detailsSuccess" className="linkWithoutUnderline active">
            <div className="card h-md-100">
              <div className="card-header pb-0">
                <h5 className="mb-0 mt-2">Success</h5>
              </div>
              <div className="card-body d-flex flex-column justify-content-end">
                <div className="row justify-content-between">
                  <div className="col pe-0">
               
                    <GrTransaction className="fs-4 text-success" />
                  </div>
                  <div className="col ps-0">
                    <div className="fs-5 fw-normal font-sans-serif text-700 lh-1 mb-1 ">
                      <p className="font-sans-serif lh-1 mb-1 fs-5 text-success">
                        {ActiveEmployee}
                      </p>
                    </div>
                  </div>
                  <div className="col-auto ps-0 mt-n4">
                    <div
                      className="echart-default-total-order"
                      data-echarts='{"tooltip":{"trigger":"axis","formatter":"{b0} : {c0}"},"xAxis":{"data":["Week 4","Week 5","Week 6","Week 7"]},"series":[{"type":"line","data":[20,40,100,120],"smooth":true,"lineStyle":{"width":3}}],"grid":{"bottom":"2%","top":"2%","right":"10px","left":"10px"}}'
                      data-echart-responsive="true"
                    />
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div>
       
        {/* <div className="col-md-3 col-xxl-3">
          <Link to="/Employee-Transaction-detailsPending" className="linkWithoutUnderline">
            <div className="card h-md-100">
              <div className="card-header pb-0">
                <h5 className="mb-0 mt-2">Pending</h5>
              </div>
              <div className="card-body d-flex flex-column justify-content-end">
                <div className="row justify-content-between">
                  <div className="col pe-0">
                    <MdOutlineSmsFailed className="fs-4 text-warning" />
                  </div>
                  <div className="col ps-0">
                    <div className="fs-5 fw-normal font-sans-serif text-700 lh-1 mb-1 ">
                      <p className="font-sans-serif lh-1 mb-1 fs-5 text-warning">
                      {PendingEmployee}
                      </p>
                    </div>
                  </div>
                  <div className="col-auto ps-0 mt-n4">
                    <div
                      className="echart-default-total-order"
                      data-echarts='{"tooltip":{"trigger":"axis","formatter":"{b0} : {c0}"},"xAxis":{"data":["Week 4","Week 5","Week 6","Week 7"]},"series":[{"type":"line","data":[20,40,100,120],"smooth":true,"lineStyle":{"width":3}}],"grid":{"bottom":"2%","top":"2%","right":"10px","left":"10px"}}'
                      data-echart-responsive="true"
                    />
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div>
        <div className="col-md-3 col-xxl-3">
          <Link to="/Employee-Transaction-detailsFaild" className="linkWithoutUnderline">
            <div className="card h-md-100">
              <div className="card-header pb-0">
                <h5 className="mb-0 mt-2">Failed</h5>
              </div>
              <div className="card-body d-flex flex-column justify-content-end">
                <div className="row justify-content-between">
                  <div className="col pe-0">
                    <MdOutlineSmsFailed className="fs-4 text-danger" />
                  </div>
                  <div className="col ps-0">
                    <div className="fs-5 fw-normal font-sans-serif text-700 lh-1 mb-1 ">
                      <p className="font-sans-serif lh-1 mb-1 fs-5 text-danger">
                        {FaildEmployee}
                      </p>
                    </div>
                  </div>
                  <div className="col-auto ps-0 mt-n4">
                    <div
                      className="echart-default-total-order"
                      data-echarts='{"tooltip":{"trigger":"axis","formatter":"{b0} : {c0}"},"xAxis":{"data":["Week 4","Week 5","Week 6","Week 7"]},"series":[{"type":"line","data":[20,40,100,120],"smooth":true,"lineStyle":{"width":3}}],"grid":{"bottom":"2%","top":"2%","right":"10px","left":"10px"}}'
                      data-echart-responsive="true"
                    />
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div> */}
      </div>
     

<div className="card mb-3">
        <div className="bg-holder d-none d-lg-block bg-card" />
        <div className="card-body position-relative">
          <div className="row">
          <div className="col-lg-8 d-flex align-items-center">
            <h5 className="mb-0 capitalize d-flex align-items-center"> 
       
            {profilimage == null ? 
            
            <>
            <IoPersonSharp className="fs-6 text-dark me-1" />
            </>
            :
            
            <img  src={`${baseurlprofil}/${profilimage}`}   height={35}
            width={35} alt="plan_image" className="img-fluid rounded-circle fs-6  me-1"  />
            }  {CustomerName}</h5>
            </div>
            <div className="col-lg-4 d-flex justify-content-end text-end">
              {/* <OverlayTrigger  overlay={<Tooltip id="tooltip-disabled">Download All Transaction PDF</Tooltip>}>

              <button className="btn  text-light bg-info" onClick={downloadFiled}>
           
                <img src={pdf} alt="pdf" width={20}  type="button" />
                Download
              
              </button>
             </OverlayTrigger> */}
              <OverlayTrigger
                overlay={
                  <Tooltip id="tooltip-disabled">Add Transaction</Tooltip>
                }
              >
                   <button className="btn  text-light bg-success" onClick={ ()=> {handleViewCustomerClick(employee_id)}}>
                  <IoMdAdd className="me-1 fs--2" /> Add Transaction
                </button>
              </OverlayTrigger>
            </div>
          </div>
        </div>
      </div>
      <div className="card mb-3">
        <div className="card-body position-relative">
          <div className="row">
            <div className="col-lg-3 mb-3">
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search by Transaction Id"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
            </div>
            <div className="col-lg-9 text-end">
              <Row>
                <div className="col-md-4">
                  <input
                    type="date"
                    className="form-control"
                    value={fromDate}
                    onChange={(e) => setFromDate(e.target.value)}
                    placeholder="From Date"
                  />
                </div>
                <div className="col-md-4">
                  <input
                    type="date"
                    className="form-control"
                    value={toDate}
                    onChange={(e) => setToDate(e.target.value)}
                    placeholder="To Date"
                  />
                </div>
                <div className="col-md-4 d-flex gap-1 justify-content-evenly  align-item-space-between">
                  {/* <div className="mb-3"> */}
                  <button className="btn btn-primary " onClick={handleFilter}>
                    Search
                  </button>
                  <OverlayTrigger
                    overlay={
                      <Tooltip id="tooltip-disabled">
                        {" "}
                        Download Transaction PDF
                      </Tooltip>
                    }
                  >
                    <span className="d-inline-block">
                      <button
                        className="buttonfordownload"
                        onClick={handleDownload}
                      >
                        {/* <img src={pdf} alt="pdf" width={60}  type="button" /> */}
                        <FaCloudDownloadAlt className="fs-4 text-dark" />
                      </button>
                    </span>
                  </OverlayTrigger>
                  {/* <MdArrowOutward className="" /> */}
                </div>
              </Row>
            </div>
          </div>
        </div>
      </div>

      <div className="card">
        <div className="card-body">
          <div className="table-responsive">
          <div className="table-responsive">
  <table className="table table-bordered">
    <thead style={{ backgroundColor: "#f2f2f2", textAlign: "left", fontWeight: "bold" }}>
      <tr>
        <th style={{ padding: "10px", borderBottom: "2px solid #ddd" }}>Sr. No.</th>
        <th style={{ padding: "10px", borderBottom: "2px solid #ddd" }}>Transaction Id</th>
        <th style={{ padding: "10px", borderBottom: "2px solid #ddd" }}>Payment mode</th>
        <th style={{ padding: "10px", borderBottom: "2px solid #ddd" }}>Payment type</th>
     
        <th style={{ padding: "10px", borderBottom: "2px solid #ddd" }}>Amount</th>
        <th style={{ padding: "10px", borderBottom: "2px solid #ddd" }}>Date</th>
        <th style={{ padding: "10px", borderBottom: "2px solid #ddd" }}>Status</th>
 
      </tr>
    </thead>
    <tbody>
      {paginatedData.map((item, index) => (
        <tr key={index}>
          <td style={{ padding: "10px", borderBottom: "1px solid #ddd" }}>{startIndex + index + 1}</td>
          <td style={{ padding: "10px", borderBottom: "1px solid #ddd" }}>{item.transaction_id}</td>
          <td className="capitalize ">           <span 
          className={`badge bg-${
            item.payment_type === "debit"
              ? "primary"
              : item.payment_type === "credit"
              ? "success"
              : "secondary"
          }`}
        >
          {item.payment_type}
        </span></td>
        <td className="capitalize">{item.payment_mode}</td>
          <td style={{ padding: "10px", borderBottom: "1px solid #ddd" }}>{item.transaction_amount}</td>
          <td style={{ padding: "10px", borderBottom: "1px solid #ddd" }}>{item.transaction_date}</td>
          <td style={{ padding: "10px", borderBottom: "1px solid #ddd" }}> <span  className={`badge bg-${
                          item.status === "1"
                            ? "success"
                            : item.transaction_status === "pending"
                            ? "warning"
                            : "success"
                        }`}>
                          Success
                        </span></td>
         
        </tr>
      ))}
    </tbody>
  </table>
</div>
          </div>
          <div className="d-flex justify-content-between mt-3">
          <div>
              Page {currentPage} of {totalPages}
            </div>
            <div className="d-flex gap-2">
            <div>
              <button
                className="border-0 "
                disabled={currentPage === 1}
                onClick={() => handlePageChange(currentPage - 1)}
              >
                <IoIosArrowBack />
                
              </button>
            </div>
              <div className="pagecurrent">
              {currentPage}
              </div>
            <div>
              <button
                className="border-0 "
                disabled={currentPage === totalPages}
                onClick={() => handlePageChange(currentPage + 1)}
              >
                
                <IoIosArrowForward />
              </button>
            </div>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer />
    </>
  );
}
