import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import company from "../../assets/img/icons/building.png";
import { IoPersonSharp } from "react-icons/io5";
import { FaMoneyBillTrendUp } from "react-icons/fa6";
import { GiCalendarHalfYear } from "react-icons/gi";
import { FcMoneyTransfer } from "react-icons/fc";
import { FaBuilding } from "react-icons/fa";
import { RiMoneyRupeeCircleFill } from "react-icons/ri";
// import { FaRegBuilding } from "react-icons/fa";
import { MdOutlineMarkEmailRead } from "react-icons/md";
import {FaRegBuilding, FaMoneyCheckAlt, FaRegCalendarAlt, FaInfoCircle ,FaTimesCircle } from 'react-icons/fa';

export default function ViewBusiness() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [business, setBusiness] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [baseurl, setBaseurl] = useState("");
  const [Baseurluser, setBaseurluser] = useState("");
  useEffect(() => {
    const fetchBusiness = async () => {
      const token = localStorage.getItem("bear_token");
      const cust_idPlanView = localStorage.getItem("cust_idPlanView");
      const invest_plain_idPlanView = localStorage.getItem("invest_plain_idPlanView");
      const invest_idPlanView = localStorage.getItem("invest_idPlanView");
      const broker_id = localStorage.getItem("broker_id");

      const formData = new FormData();
      formData.append("smalladmins_id", broker_id);
      formData.append("cust_id", cust_idPlanView);
      formData.append("invest_plain_id", invest_plain_idPlanView);
      formData.append("investment_id", invest_idPlanView);

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}small-admins-narmal-customer-tranx-details`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'multipart/form-data',
            },
          }
        );

        if (response.data.status === 1) {
          setBusiness(response.data.data);
          setBaseurl(response.data.url);
          setBaseurluser(response.data.user_profile);
        } else {
          throw new Error(response.data.message);
        }
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchBusiness();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <>
     <div className="card mb-3">
        <div className="card-body position-relative">
          <div className="row">
            <div className="col-lg-8">
              <h3 className="mb-0">Normal Customer Investment Plan Details</h3>
            </div>
            <div className="col-lg-4 text-end">
              <Link to="/Single-Customer-Plan">
                <button className="btn btn-success">Back</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="card mb-3">
        <div
          className="bg-holder d-none d-lg-block bg-card"
          style={{
            backgroundImage:
              "url(../../assets/img/icons/spot-illustrations/corner-4.png)"
          }}
        />
        <div className="card-body position-relative">
        {business && business.map((business)=>(
          <div className="row">
            <div className="col-lg-4 d-flex align-items-center">
            <h5 className="mb-0 capitalize d-flex align-items-center"> 
            {business.user_profile === null ? 
            
            <>
            <IoPersonSharp className="fs-6 text-dark me-1" />
            </>
            :
        
            <img  src={`${Baseurluser}/${business.user_profile}`}   height={35}
            width={35} alt="." className="img-fluid rounded-circle fs-6  me-1"  />

            }
              {business.nar_cust_name}</h5>
            </div>
            <div className="col-lg-8 text-end">
           <h5 className="mb-0 text-info">  <MdOutlineMarkEmailRead className="fs-4 text-info" /> {business.email}</h5>
            </div>
          </div>
        ))}
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          {business &&
            business.map((business) => (
              <React.Fragment>
             
                <div className="card mb-3">
                  <div className="card-header border-bottom">
                    <div className="card-title mb-0">Plan Details</div>
                  </div>
                  <div className="card-body bg-body-tertiary">
                    <div className="row">
                      <div className="col-md-8 offset-md-4">
                        <table className="table" style={{ border: "none" }}>
                          <tbody>
                            <tr style={{ border: "none" }}>
                              <th
                                className="fw-bold"
                                style={{ border: "none", width: "350px" }}
                              >
                                Plan Name
                              </th>
                              <td
                                className="d-flex align-items-center gap-2"
                                style={{
                                  border: "none",
                                  textTransform: "capitalize",
                                  color: "black",
                                  fontWeight: "bold",
                                }}
                              >
                                <FaBuilding />
                                {business.PlanName}
                              </td>
                            </tr>
                            <tr style={{ border: "none" }}>
                              <th
                                className="fw-bold"
                                style={{ border: "none", width: "350px" }}
                              >
                                Plan Duration
                              </th>
                              <td
                                className="d-flex align-items-center gap-2"
                                style={{
                                  border: "none",
                                  textTransform: "capitalize",
                                  color: "black",
                                  fontWeight: "bold",
                                }}
                              >
                                <GiCalendarHalfYear />
                                {`${business.plan_duration} ${business.plan_duration_type}`}
                              </td>
                            </tr>
                            <tr style={{ border: "none" }}>
                              <th
                                className="fw-bold"
                                style={{ border: "none", width: "350px" }}
                              >
                                Plan Start Date
                              </th>
                              <td
                                className="d-flex align-items-center gap-2"
                                style={{
                                  border: "none",
                                  textTransform: "capitalize",
                                  color: "black",
                                  fontWeight: "bold",
                                }}
                              >
                                <FaRegCalendarAlt />
                                {business.plan_start_date}
                              </td>
                            </tr>
                            <tr style={{ border: "none" }}>
                              <th
                                className="fw-bold"
                                style={{ border: "none", width: "350px" }}
                              >
                                Plan Image
                              </th>
                              <td
                                style={{
                                  border: "none",
                                  textTransform: "capitalize",
                                  color: "black",
                                  fontWeight: "bold",
                                }}
                              >
                                <div className="imageplan">
                                  <img
                                      src={`${baseurl}/${business.plan_investment_image}`} 
                                    alt="Plan Image"
                                    className="img-fluid"
                                  />
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card mb-3">
                  <div className="card-header border-bottom">
                    <div className="card-title">Investment Details</div>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-8 offset-md-4">
                        <table className="table" style={{ border: "none" }}>
                          <tbody>
                            <tr style={{ border: "none" }}>
                              <th
                                className="fw-bold"
                                style={{ border: "none", width: "350px" }}
                              >
                              Interest Earned
                              </th>
                              <td
                                className="d-flex gap-2 align-items-center"
                                style={{
                                  border: "none",
                                  textTransform: "capitalize",
                                  color: "black",
                                  fontWeight: "bold",
                                }}
                              >
                                <FcMoneyTransfer />
                                {(business.expected_profit - business.invested_amount).toFixed(2)}
                              </td>
                            </tr>
                            <tr style={{ border: "none" }}>
                              <th
                                className="fw-bold"
                                style={{ border: "none", width: "350px" }}
                              >
                                Invested Amount
                              </th>
                              <td
                                className="d-flex gap-2 align-items-center"
                                style={{
                                  border: "none",
                                  textTransform: "capitalize",
                                  color: "brown",
                                  fontWeight: "bold",
                                }}
                              >
                                <RiMoneyRupeeCircleFill />
                                {business.invested_amount}
                              </td>
                            </tr>
                            
                         
                            <tr style={{ border: "none" }}>
                              <th
                                className="fw-bold"
                                style={{
                                  border: "none",
                                  width: "350px",
                                  color: "green",
                                }}
                              >
                                Expected Profit
                              </th>
                              <td
                                className="d-flex gap-2 align-items-center fw-bold"
                                style={{
                                  border: "none",
                                  textTransform: "capitalize",
                                  color: "black",
                                  color: "green",
                                  fontWeight: "bold",
                                }}
                              >
                                <RiMoneyRupeeCircleFill />
                                {business.expected_profit}
                              </td>
                            </tr>
                            <tr style={{ border: "none" }}>
                              <th
                                className="fw-bold"
                                style={{
                                  border: "none",
                                  width: "350px",
                                  color: "blue",
                                }}
                              >
                                Interest Rate
                              </th>
                              <td
                                className="d-flex gap-2 align-items-center"
                                style={{
                                  border: "none",
                                  textTransform: "capitalize",
                                  color: "black",
                                  color: "blue",
                                  fontWeight: "bold",
                                }}
                              >
                                <FaMoneyBillTrendUp />
                                {business.interest_rate} %
                              </td>
                            </tr>
                            <tr>
                                    <th
                                        scope="row"
                                    
                                    >
                                        {business.is_compound === 1 ? 'Compound Interest' : 'Compound Interest'} 
                                    </th>
                                    <td
                                        className="d-flex align-items-center"
                                        style={{ color: business.is_compound === 1 ? 'green' : 'red' }} 
                                    >
                                        {business.is_compound === 1 ? (
                                <FaMoneyCheckAlt className="me-2" /> 
                            ) : (
                                <FaTimesCircle className="me-2" /> 
                            )}
                                        {business.is_compound === 1 ? 'Applicable' : 'Not Applicable'} 
                                    </td>
                                </tr>
                            <tr style={{ border: "none" }}>
                              <th
                                className="fw-bold"
                                style={{ border: "none", width: "350px" }}
                              >
                                Expected Date
                              </th>
                              <td
                                className="d-flex align-items-center gap-2"
                                style={{
                                  border: "none",
                                  textTransform: "capitalize",
                                  color: "black",
                                  fontWeight: "bold",
                                }}
                              >
                                <FaRegCalendarAlt />
                                {business.expected_date}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-header border-bottom">
                    <div className="card-title">Company Details </div>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-8 offset-md-4">
                        <table className="table" style={{ border: "none" }}>
                          <tbody>
                            <tr style={{ border: "none" }}>
                              <th
                                className="fw-bold"
                                style={{ border: "none", width: "350px" }}
                              >
                                Company
                              </th>
                              <td
                                style={{
                                  border: "none",
                                  textTransform: "capitalize",
                                  color: "black",
                                  fontWeight: "bold",
                                }}
                              >
                               <FaRegBuilding className='mx-1'/>
                                 {business.company_name}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            ))}
        </div>
      </div>
      
      <ToastContainer />
    </>
  );
}
