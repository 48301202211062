import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { FaUserAlt, FaUniversity, FaMoneyCheckAlt, FaCalendarAlt, FaHourglassHalf, FaFileInvoiceDollar, FaMoneyBillAlt, FaPercent, FaArrowLeft } from 'react-icons/fa';

export default function InvestmentView() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [data, setData] = useState([]);
  const [baseurl, setbaseurl] = useState();
  const [baseurlprofil, setbaseurlprofil] = useState();
  const [profilimage, setprofilimage] = useState();
  const fetchData = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem("bear_token");
      const broker_id = localStorage.getItem("broker_id");
      const transactionid = localStorage.getItem("transactionid");
      const customer_id = localStorage.getItem("customer_id");

      const formData = new FormData();
      formData.append("smalladmins_id", broker_id);
      formData.append("id", transactionid);
      formData.append("cust_id", customer_id);

      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}small-admins-narmal-customer-tranx-view`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data"
        },
        data: formData
      };

      const response = await axios(config);
      const data = response.data.data;
      setData(data);
      setLoading(false);

      setbaseurl(response.data.trnximageUrl)
      setbaseurlprofil(response.data.data[0].trnx_image)
      setprofilimage(response.data.image)
    } catch (error) {
      console.error("Error fetching data:", error);
      setError(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div>
      <div className="card mb-3">
        <div className="card-body">
          <div className="row">
            <div className="col-lg-8">
              <h2 className="mb-0">NormalCustomer Transaction Details</h2>
            </div>
            <div className="col-lg-4 text-end">
              <Link to='/Customer-Transaction-Details' className="text-white">
                <button className="btn btn-success"> Back</button>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        {/* Transaction Details */}
        {data && data.map((data) => (
          <>
        <div className="col-lg-12 mb-3">
          <div className="card">
            <div className="card-header">
              <h5 className="card-title mb-0">Transaction Details</h5>
            </div>
            <div className="card-body">
              <table className="table">
                <tbody>
                  <tr>
                    <th scope="row">Customer ID</th>
                    <td style={{ color: 'green', fontWeight: 'bold' }}># {data.cust_id}</td>
                  </tr>
                  <tr>
                    <th scope="row">Customer Name</th>
                    <td style={{ color: 'blue', fontWeight: 'bold' }}className='capitalize'><FaUserAlt className="me-2" /> {data.smalladmins_name}</td>
                  </tr>
                  <tr>
                  {data.payment_mode !=='offline' ?
                    <th scope="row">Transaction ID</th>
                    :
                    <th scope="row">Bill ID</th>
        }
                    <td style={{ color: 'purple', fontWeight: 'bold' }}><FaMoneyCheckAlt className="me-2" /> {data.transaction_id}</td>
                  </tr>
                  <tr>
                    <th scope="row">Transaction Amount</th>
                    <td style={{ color: 'teal', fontWeight: 'bold' }}><FaFileInvoiceDollar className="me-2" /> {data.transaction_amount}</td>
                  </tr>
                  <tr>
                    <th scope="row">Transaction Date</th>
                    <td style={{ color: 'red', fontWeight: 'bold' }}><FaCalendarAlt className="me-2" /> {data.transaction_date}</td>
                  </tr>
                  <tr>
                    <th scope="row">Transaction Status</th>
                    <td style={{ color: 'green', fontWeight: 'bold' }}className='capitalize'><FaHourglassHalf className="me-2" /> {data.transaction_status}</td>
                  </tr>
                  <tr>
                    <th scope="row">Transaction Image</th>
                    <td style={{ color: 'brown', fontWeight: 'bold' }}>  <img src={`${baseurl}/${baseurlprofil}`}  className="img-fluid" width={150} height={150} alt="Transaction" /></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        {/* Bank Details */}
        {data.bank_details &&
        <div className="col-lg-12 mb-3">
          <div className="card">
            <div className="card-header">
              <h5 className="card-title mb-0">Bank Details</h5>
            </div>
            <div className="card-body">
              <table className="table">
                <tbody>
                  <tr>
            
                    <th scope="row">Bank Details</th>
                    <td style={{ color: 'blue', fontWeight: 'bold' }}className='capitalize'><FaUniversity className="me-2" /> {data.bank_details}</td>
                  </tr>
                
                </tbody>
              </table>
            </div>
          </div>
        </div>}

        {/* Payment Details */}
        <div className="col-lg-12 mb-3">
          <div className="card">
            <div className="card-header">
              <h5 className="card-title mb-0">Payment Details</h5>
            </div>
            <div className="card-body">
              <table className="table">
                <tbody>
                  <tr>
                    <th scope="row">Payment Type</th>
                    <td style={{ color: 'cyan', fontWeight: 'bold' }} className='capitalize'>{data.payment_type || 'N/A'}</td>
                  </tr>
                  <tr>
                    <th scope="row">Payment Mode</th>
                    <td style={{ color: 'magenta', fontWeight: 'bold' }} className='capitalize'>{data.payment_mode || 'N/A'}</td>
                  </tr>
                  <tr>
                    <th scope="row">Payment Mode Type</th>
                    <td style={{ color: 'green', fontWeight: 'bold' }} className='capitalizeall'>{data.payment_mode_type || 'N/A'}</td>
                  </tr>
                  {/* <tr>
                    <th scope="row">Interest Rate</th>
                    <td style={{ color: 'gray', fontWeight: 'bold' }}>{data.interest_rate || 'N/A'}</td>
                  </tr> */}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        </>
        ))}
      </div>

    
    </div>
  );
}
