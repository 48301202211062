import React, { useState } from 'react';
import { useNavigate,Link, useLocation } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import { Spinner } from "react-bootstrap";
import { EyeFill, EyeSlash } from 'react-bootstrap-icons';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function ChangePassword() {
  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState('');
  // const email = localStorage.getItem('email');
  const location = useLocation();
  const email = location.state?.email;


  const navigate = useNavigate();

  const onInputChangeOtp = (e) => {
    setOtp(e.target.value.slice(0, 6));
  };
  const onInputChangePassword = (e) => {
    setPassword(e.target.value);
  };
  const onInputChangeConfirmPassword = (e) => {
    setConfirmPassword(e.target.value);
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };


  const Submit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      if (!otp) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'OTP is missing!',
        
      });
        // toast.error("OTP is missing!");
        setLoading(false);
        return;
      }
      if (password.length < 6) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Password must be at least 6 characters long!',
        
      });
   
        setLoading(false);
        return;
      }
      if (password !== confirmPassword) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Passwords do not match!',
        
      });
      
        setLoading(false);
        return;
      }

      let url = `${process.env.REACT_APP_API_URL}reset-password`;

      const formData = new FormData();
      formData.append("email", email);
      formData.append("otp", otp);
      formData.append("password", password);

      const config = {
        method: "POST",
        url: url,
        data: formData,
      };

      axios(config)
        .then(function (response) {
          if (response.data.status === 1) {
            Swal.fire({
              icon: 'success',
              title: 'Success!',
              text: response.data.data.message,
              color:"white",
              background: 'transparent',
            })
            // toast.success(response.data.message);
            setTimeout(() => {
              navigate('/');
            }, 1000);
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: response.data.message,
            
          });
         
          }
        })
        .finally(() => {
          setLoading(false);
        });

    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
    }
  };

  return (
    <main className="main" id="top">
      <div className="container-fluid">
        <div className="row min-vh-100 flex-center g-0">
          <div className="col-lg-8 col-xxl-5 py-3 position-relative">
            <img
              className="bg-auth-circle-shape"
              src="assets/img/icons/spot-illustrations/bg-shape.png"
              alt=""
              width={250}
            />
            <img
              className="bg-auth-circle-shape-2"
              src="assets/img/icons/spot-illustrations/shape-1.png"
              alt=""
              width={150}
            />
            <div className="card overflow-hidden z-1">
              <div className="card-body p-0">
                <div className="row g-0 h-100">
                  <div className="col-md-5 text-center bg-card-gradient  d-flex align-items-center">
                      <div>
                      <div
                      className="position-relative p-4 pt-md-5 pb-md-7"
                      data-bs-theme="light"
                    >
                      <div
                        className="bg-holder bg-auth-card-shape"
                        style={{
                          backgroundImage:
                            "url(assets/img/icons/spot-illustrations/half-circle.png)"
                        }}
                      />
                      <div className="z-1 position-relative">
                        <a
                          className="link-light mb-4 font-sans-serif fs-5 d-inline-block fw-bolder"
                          href="index.php"
                        >
                          Swan
                        </a>
                        <p className="opacity-75 text-white">
                          With the power of Swan, you can now focus only on
                          functionaries for your digital products, while leaving the
                          UI design on us!
                        </p>
                      </div>
                    </div>
                    <div
                      className="mt-3 mb-4 mt-md-4 mb-md-5"
                      data-bs-theme="light"
                    >
                      <p className="mb-0 mt-4 mt-md-5 fs-10 fw-semi-bold text-white opacity-75">
                        Read our{" "}
                        <a
                          className="text-decoration-underline text-white"
                          href="#!"
                        >
                          terms
                        </a>{" "}
                        and{" "}
                        <a
                          className="text-decoration-underline text-white"
                          href="#!"
                        >
                          conditions{" "}
                        </a>
                      </p>
                    </div>
                      </div>
                  </div>
                  <div className="col-md-7 d-flex flex-center">
                    <div className="p-4 p-md-5 flex-grow-1">
                      <div className="text-center text-md-start d-flex justify-content-between">
                        <h4 className="mb-0"> Reset your password?</h4>
                        
                        <Link to='/forgetpassword'>
                    <button  className='btn bg-success text-light'> Back</button>
                          </Link>
                      </div>
                      <p className="mb-4">Enter your OTP we'll send you.</p>
                      <div className="mb-3">
                        <label className="form-label" htmlFor="otp">
                          OTP*
                        </label>
                        <input
                          value={otp}
                          onChange={onInputChangeOtp}
                          className="form-control"
                          type="number"
                          placeholder="Enter OTP"
                          max={999999}
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label" htmlFor="password">
                          Enter New Password
                        </label>
                        <input
                          value={password}
                          onChange={onInputChangePassword}
                          className="form-control"
                          type={showPassword ? "text" : "password"}
                          placeholder="Enter New Password"
                          id="password"
                        />
                        <button
                                className="btn btn-outline-secondary eye1"
                                type="button"
                                onClick={togglePasswordVisibility}
                              >
                                {showPassword ? (
                                  <EyeSlash className="fs-3 eye" style={{ height: "21px" }} />
                                ) : (
                                  <EyeFill className="fs-3 eye" style={{ height: "21px" }} />
                                )}
                              </button>
                      </div>
                      <div className="mb-3">
                        <label className="form-label" htmlFor="confirmPassword">
                          Confirm Password
                        </label>
                        <input
                          value={confirmPassword}
                          onChange={onInputChangeConfirmPassword}
                          className="form-control"
                          type={showPassword ? "text" : "password"}
                          placeholder="Re-enter Your Password"
                          id="confirmPassword"
                        />
                        <button
                                className="btn btn-outline-secondary eye2"
                                type="button"
                                onClick={togglePasswordVisibility}
                              >
                                {showPassword ? (
                                  <EyeSlash className="fs-3 eye" style={{ height: "21px" }} />
                                ) : (
                                  <EyeFill className="fs-3 eye" style={{ height: "21px" }} />
                                )}
                              </button>
                      </div>
                      <button
                        disabled={loading}
                        onClick={Submit}
                        className="btn btn-primary d-block w-100 mt-3"
                        type="submit"
                        name="submit"
                      >
                        Submit
                        {loading && <Spinner animation="border" style={{ width: '15px', height: '15px', marginLeft: '10px' }} />}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </main>
  );
}
