
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { FaPlus } from "react-icons/fa";

export default function Addintraday() {
  const navigate = useNavigate();
  const broker_id = localStorage.getItem("broker_id");
  const AddHedgeBuySell_id = localStorage.getItem("Addintraday");
  const date_from = localStorage.getItem("date_from");
  const date_to = localStorage.getItem("date_to");
  const company_id123 = localStorage.getItem("company_id123");
  const strateigs_id123 = localStorage.getItem("strateigs_id123");

  const [formData, setFormData] = useState({
    invested_lot: "",
    total_p_l: "",
    date: "",
    status: 1,
  });

  const [errors, setErrors] = useState({});

 

  const handleChange = (e) => {
    const { id, value, name } = e.target;

    if (name === "total_p_l" || name === "invested_lot") {
      if (/^\d*$/.test(value) && value.length <= 15) {
        setFormData({ ...formData, [name]: value });
        setErrors((prevErrors) => ({ ...prevErrors, [name]: null }));
      }
    } else {
  
      setFormData({ ...formData, [name]: value });
      setErrors((prevErrors) => ({ ...prevErrors, [name]: null }));
    }
    // setFormData({ ...formData, [id]: value });

    // Clear the error for the current field when the user starts typing
    // setErrors((prevErrors) => ({ ...prevErrors, [id]: null }));
  };

  const handleValidation = () => {
    const requiredFields = [
      { field: "invested_lot", name: "invested lot" },
      { field: "total_p_l", name: "total pl" },
      { field: "date", name: "date" }, // Add date field to validation
    ];
    let validationErrors = {};
    let hasError = false;

    requiredFields.forEach(({ field, name }) => {
      if (!formData[field]) {
        validationErrors[field] = `${name} is required`;
        hasError = true;
      }
    });

    setErrors(validationErrors); // Set validation errors
    return !hasError;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate the form when submitted
    if (!handleValidation()) {
      return;
    }

    const token = localStorage.getItem("bear_token");

    const updatedFormData = new FormData();
    updatedFormData.append("smalladmins_id", broker_id);
    updatedFormData.append("company_naked_hedgePL_id", AddHedgeBuySell_id);
    updatedFormData.append("company_id", company_id123);
    updatedFormData.append("strateigs_id", strateigs_id123);
    updatedFormData.append("date_from", date_from);
    updatedFormData.append("date_to", date_to);

    Object.entries(formData).forEach(([key, value]) => {
      if (value !== null && value !== "" && value !== "null") {
        if (
          key !== "strateigs_name" &&
          key !== "symbol_name" &&
          key !== "id" &&
          key !== "company_name"
        ) {
          updatedFormData.append(key, value);
        }
      }
    });

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}small-admins-add-manual-intraday`,
        updatedFormData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 201) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: response.data.message,
        });

        setTimeout(() => {
          navigate("/ViesNakedpotionn");
        }, 2000);
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: response.data.message || "Unknown error occurred",
        });
      }
    } catch (error) {
      console.error("Error:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "An error occurred while submitting the form.",
      });
    }
  };
  useEffect(() => {
    // Set the default date to today's date in the 'YYYY-MM-DD' format
    const today = new Date().toISOString().split("T")[0];
    setFormData((prevFormData) => ({
      ...prevFormData,
      date: today, // Set the default value to today's date
    }));
  }, []);
  return (
    <>
      <div className="card mb-3">
        <div
          className="bg-holder d-none d-lg-block bg-card"
          style={{
            backgroundImage:
              "url(../../assets/img/icons/spot-illustrations/corner-4.png)",
          }}
        />
        <div className="card-body position-relative">
          <div className="row">
            <div className="col-lg-8">
              <h3 className="mb-0">Add Manual P&L Intraday</h3>
            </div>
            <div className="col-lg-4 text-end">
              <Link to="/ViesNakedpotionn">
                <button className="btn btn-success">Back</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-body">
          <form className="row g-3" onSubmit={handleSubmit}>
            <div className="col-md-6 form_details">
              <div className="form-group local-forms">
                <label>
                  Total PL <span className="login-danger">*</span>
                </label>
                <input
                  className={`form-control ${
                    errors.total_p_l ? "input-error" : ""
                  }`}
                  id="total_p_l"
                  type="text"
                  name="total_p_l"
                  value={formData.total_p_l || ""} 
                  onChange={handleChange}
                />
                {errors.total_p_l && (
                  <span className="text-danger">{errors.total_p_l}</span>
                )}
              </div>
            </div>
            <div className="col-md-6 form_details">
              <div className="form-group local-forms">
                <label>
                  Invested Lot <span className="login-danger">*</span>
                </label>
                <input
                  className={`form-control ${
                    errors.invested_lot ? "input-error" : ""
                  }`}
                  id="invested_lot"
                  type="text"
                  name="invested_lot"
                  value={formData.invested_lot}
                  onChange={handleChange}
                />
                {errors.invested_lot && (
                  <span className="text-danger">{errors.invested_lot}</span>
                )}
              </div>
            </div>

            <div className="col-md-6 form_details">
              <div className="form-group local-forms">
                <label>
                  P&L Date <span className="login-danger">*</span>
                </label>
                <input
                  className={`form-control ${errors.date ? "input-error" : ""}`}
                  id="date"
                  type="date"
                  name="date"
                  value={formData.date}
                  onChange={handleChange}
                />
                {errors.date && (
                  <span className="text-danger">{errors.date}</span>
                )}
              </div>
            </div>

            <div className="col-12 d-flex justify-content-end">
              <button className="btn btn-primary" type="submit">
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
