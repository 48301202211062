import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { FaRegEdit, FaEye, FaSearch } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import { IoMdAdd } from "react-icons/io";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { BsCreditCard2BackFill } from "react-icons/bs";
import { FaRegShareFromSquare } from "react-icons/fa6";
import company from "../../assets/img/icons/building.png";
import { MdBusinessCenter } from "react-icons/md";

export default function Companymangement() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [baseurl, setbaseurl] = useState();
  const [AproveStatus, setAproveStatus] = useState();
  const [Status, setStatus] = useState();
  const [Allcompany, setCompany] = useState();
  const [Activecompany, setActivecompany] = useState();
  const [Inactivecompany, setInactivecompany] = useState();
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [requestSent, setRequestSent] = useState(false);
  const broker_id = localStorage.getItem("broker_id");
  const token = localStorage.getItem("bear_token");

  const fetchData = async () => {
    try {
      setLoading(true);

      const formData = new FormData();
      formData.append("smalladmins_id", broker_id);
      formData.append("status", "1");

      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}small-admins-profit-loss-lists`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      };

      const response = await axios(config);
      console.warn(response)
      setData(response.data.data);
      setbaseurl(response.data.image_url);
      setCompany(response.data.total_debit)
      setActivecompany(response.data.total_credit)
      setInactivecompany(response.data.net_profit_loss)
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    shareStatus();
  }, []);

  const filteredData =data &&  data.filter((item) =>
   item.company_namne && item.company_namne.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const totalPages = Math.ceil(filteredData && filteredData.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedData =filteredData && filteredData.slice(startIndex, endIndex);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

 
  const shareStatus = async () => {
    try {
      const formData = new FormData();
      formData.append("smalladmins_id", broker_id);
 

      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}small-admins-notification-lists`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      };

      const response = await axios(config);
      
      setStatus(response.data.status)
   setAproveStatus(response.data.data[0].super_admin_approve_status)


    } catch (error) {
      console.error("Error sending request:", error);
     
    }
  };
  const sendRequest = async () => {

    const result = await Swal.fire({
      title: 'Are you sure?',
      text: "Do you really want to send this request?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, send it!'
    });
  
    if (result.isConfirmed) {
      try {
        const formData = new FormData();
        formData.append("smalladmins_id", broker_id);
  
        const config = {
          method: "post",
          url: `${process.env.REACT_APP_API_URL}small-admins-send-pl-sequests-tosuperAdmin`,
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
          data: formData,
        };
  
        const response = await axios(config);
        if (response.data.status === 1) {
          Swal.fire({
            icon: 'success',
            title: 'Request Sent',
            text: 'Your request has been sent successfully.',
            // timer: 2000,
          });
          setRequestSent(true);
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: response.data.message,
            // timer: 2000,
          });
        }
      } catch (error) {
        console.error("Error sending request:", error);
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'There was an error sending the request.',
          // timer: 2000,
        });
      }
    }
  };
  
  const handleClickCompanyId = (id) => {
    localStorage.setItem("company_id", id);
    navigate("/CompanyView");
  };

  const handleAddbusiness = (id,name) => {
    localStorage.setItem("company_id", id);
    localStorage.setItem("company_Name", name);
    navigate("/Addbusiness");
  };

  const HandleAddadminscustomer = (id,name) => {
    localStorage.setItem("company_id", id);
    localStorage.setItem("company_Name", name);
    navigate("/Add-admins-customer");
  };

  
  const handleClickEditCompanyId = (id) => {
    localStorage.setItem("Companydetailsedit_id", id);
    navigate("/Companydetailsedit");
  };
 
  return (
    <>
      <div className="card mb-3">
        <div
          className="bg-holder d-none d-lg-block bg-card"
          style={{
            backgroundImage:
              "url(../../assets/img/icons/spot-illustrations/corner-4.png)"
          }}
        />
        <div className="card-body position-relative">
          <div className="row">
            <div className="col-lg-8">
              <h3 className="mb-0">Profit and Loss Analytics</h3>
            </div>
            <div className="col-lg-4 text-end">
              <Link to="/Plmanagement" className="">
                <button className="btn btn-success">Back</button>
              </Link>
            </div>
          </div>
        </div>

      </div>
      <div className="row g-3 mb-3">
      <div className="col-md-4 col-xxl-3">
        <Link to='#' className="linkWithoutUnderline">
          <div className="card h-md-100">
            <div className="card-header pb-0">
              <h5 className="mb-0 mt-2"> Total Debit </h5>
            </div>
            <div className="card-body d-flex flex-column justify-content-end">
              <div className="row justify-content-between">
                <div className="col pe-0">
                  <BsCreditCard2BackFill className="fs-4 text-primary" />
                </div>
                <div className="col ps-0">
                  <div className="fs-5 fw-normal font-sans-serif text-700 lh-1 mb-1 ">
                    <p className="font-sans-serif lh-1 mb-1 fs-5 text-primary">
                      {Allcompany}
                    </p>
                  </div>
                </div>
                <div className="col-auto ps-0 mt-n4">
                  <div
                    className="echart-default-total-order"
                    data-echarts='{"tooltip":{"trigger":"axis","formatter":"{b0} : {c0}"},"xAxis":{"data":["Week 4","Week 5","Week 6","Week 7"]},"series":[{"type":"line","data":[20,40,100,120],"smooth":true,"lineStyle":{"width":3}}],"grid":{"bottom":"2%","top":"2%","right":"10px","left":"10px"}}'
                    data-echart-responsive="true"
                  />
                </div>
              </div>
            </div>
          </div>
          </Link>
        </div>
      <div className="col-md-4 col-xxl-3">
        <Link to='#' className="linkWithoutUnderline">
          <div className="card h-md-100">
            <div className="card-header pb-0">
              <h5 className="mb-0 mt-2"> Total Credit </h5>
            </div>
            <div className="card-body d-flex flex-column justify-content-end">
              <div className="row justify-content-between">
                <div className="col pe-0">
                  <BsCreditCard2BackFill className="fs-4 text-success" />
                </div>
                <div className="col ps-0">
                  <div className="fs-5 fw-normal font-sans-serif text-700 lh-1 mb-1 ">
                    <p className="font-sans-serif lh-1 mb-1 fs-5 text-success">
                      {Activecompany}
                    </p>
                  </div>
             
                </div>
                <div className="col-auto ps-0 mt-n4">
                  <div
                    className="echart-default-total-order"
                    data-echarts='{"tooltip":{"trigger":"axis","formatter":"{b0} : {c0}"},"xAxis":{"data":["Week 4","Week 5","Week 6","Week 7"]},"series":[{"type":"line","data":[20,40,100,120],"smooth":true,"lineStyle":{"width":3}}],"grid":{"bottom":"2%","top":"2%","right":"10px","left":"10px"}}'
                    data-echart-responsive="true"
                  />
                </div>
              </div>
            </div>
          </div>
          </Link>
        </div>
      <div className="col-md-4 col-xxl-3">
        <Link to='#' className="linkWithoutUnderline">
          <div className="card h-md-100">
            <div className="card-header pb-0">
              <h5 className="mb-0 mt-2"> Total Profit & Loss </h5>
            </div>
            <div className="card-body d-flex flex-column justify-content-end">
              <div className="row justify-content-between">
                <div className="col pe-0">
                  <BsCreditCard2BackFill className="fs-4 text-danger" />
                </div>
                <div className="col ps-0">
                  <div className="fs-5 fw-normal font-sans-serif text-700 lh-1 mb-1 ">
                    <p className="font-sans-serif lh-1 mb-1 fs-5 text-danger">
                      {Inactivecompany}
                    </p>
                  </div>
             
                </div>
                <div className="col-auto ps-0 mt-n4">
                  <div
                    className="echart-default-total-order"
                    data-echarts='{"tooltip":{"trigger":"axis","formatter":"{b0} : {c0}"},"xAxis":{"data":["Week 4","Week 5","Week 6","Week 7"]},"series":[{"type":"line","data":[20,40,100,120],"smooth":true,"lineStyle":{"width":3}}],"grid":{"bottom":"2%","top":"2%","right":"10px","left":"10px"}}'
                    data-echart-responsive="true"
                  />
                </div>
              </div>
            </div>
          </div>
          </Link>
        </div>
      </div>
      <div className="card mb-3">
        <div className="card-body position-relative">
          <div className="row">
            <div className="col-lg-8">
              <h3 className="mb-0">Profit and Loss List</h3>
            </div>
            <div className="col-md-4 d-flex justify-content-end gap-1">
          <Link to='/AddBill'>
            <button className="btn btn-sm btn-success gap-1"> <IoMdAdd className="me-1 fs--2" /> Add Bill </button>
            </Link>
          <Link to='/AddPL'>
            <button className="btn btn-sm btn-primary gap-1"> <IoMdAdd className="me-1 fs--2" /> Add PL</button>
            </Link>
            </div>

          </div>
        </div>
      </div>
      <div className="card" id="ticketsTable">
        <div className="card-header w-100 border-bottom border-200">
          <div className="justify-content-end d-flex w-100">
            <div className="justify-content-end mt-1 d-flex w-100">
              <form>
                <div className="input-group input-search-width">
                  <input
                    className="form-control form-control-sm shadow-none search"
                    type="search"
                    placeholder="Search by company name"
                    aria-label="search"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="card-body p-0">
          <div className="table-responsive scrollbar">
            <table className="table table-sm mb-0 fs-10 table-view-tickets">
              <thead className="bg-body-tertiary">
                <tr>
                  <th className="fs-9 pl-0">Sr.No.</th>
              <th className="text-800 sort align-middle">Company Name</th>
              <th className="text-800 sort align-middle">Admin Name</th>
              <th className="text-800 sort align-middle">Transaction Type</th>
              <th className="text-800 sort align-middle">Amount</th>
            
                </tr>
              </thead>
              <tbody className="list">
                {loading && (
                  <tr>
                    <td colSpan="7" className="text-center py-4">
                      <div id="preloader">
                        <div id="loading-center">
                          <div className="loader">
                            <div className="waviy">
                              <img
                                src="https://swaninvestment.in/frontant/assets/img/loader.png"
                                alt="Loader image"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                )}
                {paginatedData && paginatedData.length > 0 ? (
                  paginatedData.map((item, index) => (
                    <tr key={index} className="">
                     
                      <td className="align-middle client white-space-nowrap pe-3 pe-xxl-4 ps-2">
                        <div className="d-flex align-items-center gap-2 position-relative">
                          <div>
                            <span>{startIndex + index + 1}</span>
                          </div>
                        </div>
                      </td>

                
                <td className="align-middle">{item.company_namne}</td>
                <td className="align-middle">{item.smalladmins_name}</td>
             
                <td className="align-middle">
                  <span className={`badge ${item.trnxType === "credit" ? "bg-success" : "bg-primary"}`}>
                    {item.trnxType}
                  </span>
                </td>
                <td className={`align-middle ${item.trnxType === "credit" ? 'text-success' : 'text-primary'}`}>
                  {item.pl_amount}
                </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="12" className="text-center py-4">
                      No data found.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          {totalPages > 1 && (
            <div className="pagination mt-3 mb-3 mx-3">
              <button
                className="btn btn-sm btn-light"
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                <IoIosArrowBack />
              </button>
              {[...Array(totalPages).keys()].map((pageNumber) => (
                <button
                  key={pageNumber + 1}
                  className={`btn btn-sm mx-1 ${currentPage === pageNumber + 1
                    ? "btn-light text-primary"
                    : "btn-light"
                    }`}
                  onClick={() => handlePageChange(pageNumber + 1)}
                >
                  {pageNumber + 1}
                </button>
              ))}
              <button
                className="btn btn-sm btn-light"
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                <IoIosArrowForward />
              </button>
            </div>
          )}
        </div>
      </div>


    </>
  );
}
