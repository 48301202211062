import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaRegEdit, FaEye } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import { IoMdAdd } from "react-icons/io";
import Swal from "sweetalert2";
import axios from "axios";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { Spinner } from "react-bootstrap";

import { TfiReload } from "react-icons/tfi";
const ProfitAndLoss = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({
    companyName: "",
    strategyName: "",
    symbolName: "",
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem("bear_token");
      const broker_id = localStorage.getItem("broker_id");
      const params = {
        smalladmins_id: broker_id,
        status: "1",
      };
      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}small_admins_add_company_nakedHedgePL_list`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        params: params,
      };

      const response = await axios(config);
      setData(response.data.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  const filteredData = data.filter((item) => {
    const companyNameMatch =
      filters.companyName === "" ||
      item.company_name
        .toLowerCase()
        .includes(filters.companyName.toLowerCase());
  
    const strategyNameMatch =
      filters.strategyName === "" ||
      item.strateigs_name
        .toLowerCase()
        .includes(filters.strategyName.toLowerCase());
  
    const symbolMatch =
      !filters.symbolName || // Check for null, undefined, or empty string
      (item.symbol_name && item.symbol_name.toLowerCase().includes(filters.symbolName?.toLowerCase() || ""));
  
    return companyNameMatch && strategyNameMatch && symbolMatch;
  });

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedData = filteredData.slice(startIndex, endIndex);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const Delete = async (id) => {
    const token = localStorage.getItem("bear_token");
    const broker_id = localStorage.getItem("broker_id");

    if (!id) {
      console.error("ID is required");
      return;
    }

    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
        color:"white",
        background: 'transparent',
      });

      if (result.isConfirmed) {
        setLoading(true);

        const formData = new FormData();
        formData.append("smalladmins_id", broker_id);
        formData.append("id", id);
        formData.append("status", "1");

        const config = {
          method: "post",
          url: `${process.env.REACT_APP_API_URL}small_admins_delete_company_nakedHedgePL`,
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
          data: formData,
        };

        const response = await axios(config);
        if (response.data.status === 1) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: response.data.message,
            color:"white",
            background: 'transparent',
          });
          fetchData();
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: response.data.message,
            color:"white",
          background: 'transparent',
          });
        }
        setLoading(false);
      }
    } catch (error) {
      console.error("Error deleting data:", error);
      setLoading(false);
    }
  };

  const handleStatusChange = async (business_id, currentStatus) => {
    const broker_id = localStorage.getItem("broker_id");

    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, change status!",
        color:"white",
        background: 'transparent',
      });

      if (result.isConfirmed) {
        setLoading(true);

        const formData = new FormData();
        formData.append("smalladmins_id", broker_id);
        formData.append("id", business_id);
        formData.append("status", currentStatus === 1 ? 0 : 1);

        const config = {
          method: "post",
          url: `${process.env.REACT_APP_API_URL}small_admins_update_company_nakedHedgePL_status`,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("bear_token")}`,
            "Content-Type": "multipart/form-data",
          },
          data: formData,
        };

        const response = await axios(config);
        if (response.data.status === 1) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Status updated successfully!",
            color:"white",
            background: 'transparent',
          });
          fetchData();
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: response.data.message,
            color:"white",
          background: 'transparent',
          });
        }
        setLoading(false);
      }
    } catch (error) {
      console.error("Error changing status:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "There was an error changing the status.",
        color:"white",
          background: 'transparent',
      });
      setLoading(false);
    }
  };

  const handleClickCompanyId = (id) => {
    localStorage.setItem("ViewsPL", id);
    navigate("/ViewsPL");
  };
  const handleRefresh = () => {
    setFilters({
      companyName: "",
      strategyName: "",
      symbolName: "",
   
    });
    fetchData();
  };
  const handleClickCompanyintraday = (id,date_from,date_to,company_id,strateigs_id,company_name,strateigs_name,strategy_type) => {
    localStorage.setItem("ViesNakedpotionn", id);
    localStorage.setItem("date_from", date_from);
    localStorage.setItem("date_to", date_to);
    localStorage.setItem("company_id123", company_id);
    localStorage.setItem("company_name123", company_name);
    localStorage.setItem("strateigs_name123", strateigs_name);
    localStorage.setItem("strateigs_type123", strategy_type);
    localStorage.setItem("strateigs_id123", strateigs_id);
    navigate("/ViewsPLIntra");

  };
  return (
    <>
      <div className="card mb-3">
        <div className="card-body position-relative">
          <div className="row">
            <div className="col-lg-8">
              <h3 className="mb-0">Over All Profit And Loss List</h3>
            </div>
            <div className="col-md-4 d-flex justify-content-end">
              {/* <Link to="/AddNakedadd" className="btn btn-sm btn-success">
                <IoMdAdd className="me-1 fs--2" /> Add P&L
              </Link> */}
            </div>
          </div>
        </div>
      </div>

      <div className="card mb-3">
        <div className="card-body">
          <div className="row g-3">
            <SearchSelect
              label="Company Name"
              value={filters.companyName}
              onChange={(value) => setFilters({ ...filters, companyName: value })}
              options={data.map((item) => ({
                value: item.company_name,
                label: item.company_name,
              }))}
            />
            <SearchSelect
              label="Strategy Name"
              value={filters.strategyName}
              onChange={(value) => setFilters({ ...filters, strategyName: value })}
              options={data.map((item) => ({
                value: item.strateigs_name,
                label: item.strateigs_name,
              }))}
            />
            <SearchSelect
              label="Symbol Name"
              value={filters.symbolName}
              onChange={(value) => setFilters({ ...filters, symbolName: value })}
              options={data.map((item) => ({
                value: item.symbol_name,
                label: item.symbol_name,
              }))}
            />
             <div className="col-lg-1  form_details ">
                    <button className="btn btn-primary ml-auto" onClick={handleRefresh} >
                    <TfiReload className="fs-6 text-light"/>
                    </button>
                </div>
                <div className="col-lg-1  form_details mt-3">
                    
                </div>
          </div>
        </div>
        <div className="card-body">
          <div className="table-responsive scrollbar">
            <table className="table mb-0 table-view-tickets">
              <thead className="bg-body-tertiary">
                <tr className="thead-dark">
                  <th className="">Sr.</th>
                  <th className="text-800 sort align-middle" data-sort="company_name">
                    Company
                  </th>
                  <th className="text-800 sort align-middle" data-sort="company_name">
                    Type
                  </th>
                  <th className="text-800 sort align-middle" data-sort="strateigs_name">
                    Strategy
                  </th>
                  <th className="text-800 sort align-middle" data-sort="symbol_name">
                    Symbol
                  </th>
                  {/* <th className="text-800 sort align-middle" data-sort="buy_price">
                    Start Date
                  </th>
                  <th className="text-800 sort align-middle" data-sort="sell_price">
                    End Date
                  </th> */}
                  <th className="text-800 sort align-middle" data-sort="status">
                    Status
                  </th>
                  <th className="text-800 sort align-middle" data-sort="addbusiness">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody className="list">
                {loading ? (
                  <tr>
                    <td colSpan="7" className="text-center py-4">
                      <Spinner animation="border" role="status" />
                    </td>
                  </tr>
                ) : paginatedData.length > 0 ? (
                  paginatedData.map((item, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td  className="text-transform-capitalize">{item.company_name}</td>
                      <td className="text-transform-capitalize">{item.strategy_type}</td>
                      <td className="text-transform-capitalize">{item.strateigs_name}</td>
                      <td className="text-transform-capitalize">{item.symbol_name || 'NA'}</td>
                      {/* <td>{item.buy_price}</td>
                      <td>{item.sell_price}</td> */}
                      <td>
                        <div className="form-check form-switch">
                          <input
                            className="form-check-input toggle_btn"
                            type="checkbox"
                            role="switch"
                            id={`flexSwitchCheckDefault-${item.id}`}
                            checked={item.status === 1}
                            onChange={() =>
                              handleStatusChange(item.id, item.status)
                            }
                            disabled={loading}
                          />
                        </div>
                      </td>
                      <td className="align-middle priority pe-4">
                        <div className="d-flex gap-1 action_button">
                          {/* <OverlayTrigger
                          key="Edit"
                          placement="top"
                          overlay={<Tooltip id={`Edit`}>Edit</Tooltip>}
                        >
                          <button
                            onClick={() => handleClickEditCompanyId(item.id)}
                            style={{ "--i": "#2c7be5" }}
                          >
                            {" "}
                            <FaRegEdit className="color_link" />
                          </button>
                        </OverlayTrigger> */}
 <OverlayTrigger
                            key={`View-${item.strategy_type}`}
                            placement="top"
                            overlay={
                              <Tooltip id={`View-${item.strategy_type}`}>
                                {item.strategy_type === "positional"
                                  ? "View positional"
                                  : "View intraday"}
                              </Tooltip>
                            }
                          >
                            {/* <button
                              onClick={() => handleClickCompanyId(item.id)}
                              style={{
                                backgroundColor:
                                  item.strategy_type === "positional"
                                    ? "#27bcfd"
                                    : "#ff5733",
                                color: "white",
                                border: "none",
                                padding: "10px 20px",
                                // borderRadius: "5px",
                              }}
                            >
                              <FaEye className="color_link" />
                            </button> */}



{item.strategy_type === "positional" ? (
  <button
    onClick={() => handleClickCompanyId(item.id)}
    style={{
      backgroundColor: "#27bcfd", 
      color: "white",
      border: "none",
    }}
  >
    <FaEye className="d-inline" /> 
  </button>
) : (
  <button
    onClick={() => handleClickCompanyintraday(item.id,item.date_from,item.date_to,item.company_id,item.strateigs_id,item.company_name,item.strateigs_name,item.strategy_type)}
    style={{
      backgroundColor: "#ff5733", 
      color: "white",
      border: "none",
    }}
  >
    <FaEye className="d-inline" /> 
  </button>
)}

                          </OverlayTrigger>



                          {/* <OverlayTrigger
                            key="View"
                            placement="top"
                            overlay={<Tooltip id={`View`}>View</Tooltip>}
                          >
                            <button
                              onClick={() => handleClickCompanyId(item.id)}
                              style={{ "--i": "#27bcfd" }}
                            >
                              <FaEye className="color_link" />
                            </button>
                          </OverlayTrigger> */}
                          <OverlayTrigger
                            key="Delete"
                            placement="top"
                            overlay={<Tooltip id={`Delete`}>Delete</Tooltip>}
                          >
                            <button
                              style={{ "--i": "red" }}
                              onClick={() => Delete(item.id)}
                            >
                              {" "}
                              <MdDelete className="color_link" />
                            </button>
                          </OverlayTrigger>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="7" className="text-center py-4">
                      No data found.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        {/* {paginatedData.length > 0 && (
          <div className="card-footer py-3">
            <div className="d-flex justify-content-end align-items-center">
              <span className="me-2">
                Page {currentPage} of {totalPages}
              </span>
              <button
                className={`btn btn-outline-primary btn-sm me-1 ${
                  currentPage === 1 ? "disabled" : ""
                }`}
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                <IoIosArrowBack />
              </button>
              <button
                className={`btn btn-outline-primary btn-sm ${
                  currentPage === totalPages ? "disabled" : ""
                }`}
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                <IoIosArrowForward />
              </button>
            </div>
          </div>
        )} */}
           {paginatedData > 1 && (
            <div className="pagination mt-3 mb-3 mx-3">
              <button
                className="btn btn-sm btn-light"
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                <IoIosArrowBack />
              </button>
              {[...Array(totalPages).keys()].map((pageNumber) => (
                <button
                  key={pageNumber + 1}
                  className={`btn btn-sm mx-1 ${
                    currentPage === pageNumber + 1
                      ? "btn-light text-primary"
                      : "btn-light"
                  }`}
                  onClick={() => handlePageChange(pageNumber + 1)}
                >
                  {pageNumber + 1}
                </button>
              ))}
              <button
                className="btn btn-sm btn-light"
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                <IoIosArrowForward />
              </button>
            </div>
          )}
      </div>
    </>
  );
};

export default ProfitAndLoss;

// SearchSelect component used for select dropdown

const SearchSelect = ({ label, value, onChange, options }) => (
  <div className="col-lg-3  form_details ">
          <div className="form-group local-forms">
    <label className="form-label">{label}</label>
    <select
      className="form-select mx-1"
      value={value}
      onChange={(e) => onChange(e.target.value)}
    >
      <option value="">All</option>
      {options.map((option, index) => (
        <option key={index} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
    </div>
  </div>
);



