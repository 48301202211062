import React, { useState,useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";

export default function AddStrategies() {
  const navigate = useNavigate();
  const broker_id = localStorage.getItem("broker_id");
  const date_from = localStorage.getItem("date_from");
  const date_to = localStorage.getItem("date_to");
  const ViesNakedpotionn = localStorage.getItem("ViesNakedpotionn");
  const company_id123 = localStorage.getItem("company_id123");
  const strateigs_id123 = localStorage.getItem("strateigs_id123");
  const intraday_id = localStorage.getItem("intraday_id");
  const [formData, setFormData] = useState({
    company_id: "",
    date_from: "",
    date_to: "",
    default_lot_price: "",
    default_lot_price_id: "",
    naked_default_lot_size: "",
    hedge_default_lot_size: "",
    strateigs_id: "",
    symbol_id: "",
    buy_price: "",
    sell_price:"",
    status:1,
  });
  const [formData2, setFormData2] = useState({
    no_of_lot:""
 
  });
  useEffect(() => {
    fetchData2();
  }, []);


  const fetchData2 = async () => {
    try {
      const token = localStorage.getItem("bear_token");
      const customer_id = localStorage.getItem("ViewsPL");

      const form1Data = new FormData();
      form1Data.append("smalladmins_id", broker_id);
      form1Data.append("id", intraday_id);
      form1Data.append("status", "1");
      form1Data.append("company_naked_hedgePL_id", ViesNakedpotionn);
      form1Data.append("company_id", company_id123);
      form1Data.append("strateigs_id", strateigs_id123);

      form1Data.append("date_from", date_from);
      form1Data.append("date_to", date_to );
   
  
      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}small-admins-ovelallcompanyPL-Intracalculations-view`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
        data: form1Data,
      };

      const response = await axios(config);
      const data = response.data.Data;
      setFormData2(data[0]);
      console.warn("data",data)
    } catch (error) {
      console.error("Error fetching user broker:", error);
    }
  };
 
  const [profile, setProfile] = useState(null);
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { id, value, name } = e.target;
    if (name === "no_of_lot" ) {
      if (value.length > 10 || isNaN(value)) {
        return;
      }
    }
    setFormData2({ ...formData2, [id]: value });
    setFormData({ ...formData, [id]: value });
    setErrors((prevErrors) => ({ ...prevErrors, [id]: null }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setProfile(file);
    }
  };

  const handleValidation = () => {
    const requiredFields = [
      // { field: "no_of_lot", name: "No of LOT" },
 
    ];
    let validationErrors = {};
    let hasError = false;

    requiredFields.forEach(({ field, name }) => {
      if (!formData[field]) {
        validationErrors[field] = `${name} is required`;
        hasError = true;
      }
    });

    setErrors(validationErrors);
    return !hasError;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!handleValidation()) {
      return;
    }

    const token = localStorage.getItem("bear_token");

    const updatedFormData = new FormData();
      updatedFormData.append("smalladmins_id", broker_id);
      updatedFormData.append("company_naked_hedgePL_id", ViesNakedpotionn);
      updatedFormData.append("id", intraday_id);
      updatedFormData.append("status", "1");
   
      updatedFormData.append("company_id", company_id123);
      updatedFormData.append("strateigs_id", strateigs_id123);

      updatedFormData.append("no_of_lot", formData2.no_of_lot);
      updatedFormData.append("date_from", date_from);
      updatedFormData.append("date_to", date_to );
 


   

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}small-admins-overAllcompanyPL-Intra-calculations-edit`,
        updatedFormData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 201) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: response.data.message,
          color:"white",
          background: 'transparent',
        });

        setTimeout(() => {
          navigate("/ViewsPLIntra"); 
        }, 2000);
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: response.data.message || "Unknown error occurred",
          color:"white",
          background: 'transparent',
        });
      }
    } catch (error) {
      console.error("Error:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "An error occurred while submitting the form.",
        color:"white",
        background: 'transparent',
      });
    }
  };

  return (
    <>
         <div className="card mb-3">
                <div
                    className="bg-holder d-none d-lg-block bg-card"
                    style={{
                        backgroundImage:
                            "url(../../assets/img/icons/spot-illustrations/corner-4.png)",
                    }}
                />
                <div className="card-body position-relative">
                    <div className="row">
                        <div className="col-lg-8">
                            <h3 className="mb-0">Update  No of LOT Details </h3>
                        </div>
                        <div className="col-lg-4 text-end">
                            <Link to="/ViewsPL" className="">
                                <button className="btn btn-success">Back</button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
      <div className="card">
        <div className="card-body">
          <form className="row g-3" onSubmit={handleSubmit}>
       
                      <div className="col-md-6 form_details">
                            <div className="form-group local-forms">
                                <label>
                                No of LOT <span className="login-danger"></span>
                                </label>
                                <input
                                    className={`form-control ${errors.no_of_lot ? "input-error" : ""}`}
                                    id="no_of_lot"
                                    type="text"
                                    name="no_of_lot"
                                    value={formData2.no_of_lot}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        {/* <div className="col-md-6 form_details">
                            <div className="form-group local-forms">
                                <label>
                                Buy Price <span className="login-danger">*</span>
                                </label>
                                <input
                                    className={`form-control ${errors.buy_price ? "input-error" : ""}`}
                                    id="buy_price"
                                    type="text"
                                    value={formData.buy_price}
                                    onChange={handleChange}
                                />
                            </div>
                        </div> */}
       
            <div className="col-12 d-flex justify-content-end">
              {/* Submit button */}
              <button className="btn btn-primary" type="submit">
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
