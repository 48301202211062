import React from 'react';
const Navbar = () => {
  return (
    <div className="navbar">
      <div className="logo"></div>
      <div className="user-info"></div>
    </div>
  );
};
export default Navbar;
