import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from "react-toastify";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { MdPersonAddAlt1 } from "react-icons/md";
import company from "../../assets/img/icons/building.png";
import { FaUserAlt,FaRegBuilding, FaRegEnvelope, FaMapMarkerAlt, FaPhone, FaInfoCircle, FaImage } from 'react-icons/fa';

import { IoMdPersonAdd } from "react-icons/io";
export default function ViewBusiness() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [business, setBusiness] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [baseurl, setBaseurl] = useState("");
  const [AllEmployee, setAllEmployee] = useState(''); 
  const [AllBusinesesCustomers, setBusinesesCustomers] = useState();
  const company_id = localStorage.getItem("company_id");
  useEffect(() => {
    const fetchBusiness = async () => {
      const token = localStorage.getItem("bear_token");
      const broker_id = localStorage.getItem("broker_id");

      const formData = new FormData();
      formData.append("smalladmins_id", broker_id);
      formData.append("company_id", company_id);

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}view-small-admins-company`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'multipart/form-data'
            }
          }
        );
        setBusiness(response.data.data);

        // setBusinesesCustomers(response.data.TotalBusinessCustomerInthisCompany)
        // setAllEmployee(response.data.TotalNarmalCustomerInthisCompany)
        


        setBaseurl(response.data.url);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchBusiness();
  }, []);

  const fetchDatabussinesscount = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem("bear_token");
      const broker_id = localStorage.getItem("broker_id");

      const formData = new FormData();
      formData.append("smalladmins_id", broker_id);

      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}small-admins-business-customer-lists`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      };

      const response = await axios(config);
      const data = response.data.data;
      const data1 = response.data.image_url;

      const filteredData = data.filter(item => item.company_id == company_id);

      const totalFilteredEmp = filteredData.length;
      setBusinesesCustomers(totalFilteredEmp);
   
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  const fetchDatacustomercount = async () => {
    try {
      setLoading(true);


      const token = localStorage.getItem("bear_token");
      const broker_id = localStorage.getItem("broker_id");

      const formData = new FormData();
      formData.append("smalladmins_id", broker_id);
      formData.append("status", "1");

      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}small-admins-customer-lists`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      };

      const response = await axios(config);
      const data = response.data.data;
      const filteredData = data.filter(item => item.company_id == company_id);

      const totalFilteredEmp = filteredData.length;
      setAllEmployee(totalFilteredEmp);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };
  useEffect (() => {
    fetchDatacustomercount();
    fetchDatabussinesscount();
  },[]);
  
  const handleClickNCostumerCompanyId = (company_id) => {
    localStorage.setItem("company_id_NCustumer", company_id);
    navigate("/Company-Normal-Customer");
  };
  const handleClickBCostumerCompanyId = (company_id) => {
    localStorage.setItem("company_id_BCustumer", company_id);
    navigate("/Company-Bussiness-Customer");
  };


  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }
  return (
    <>
      <div className="card mb-3">
        <div
          className="bg-holder d-none d-lg-block bg-card"
          style={{
            backgroundImage:
              "url(../../assets/img/icons/spot-illustrations/corner-4.png)"
          }}
        />
        <div className="card-body position-relative">
          <div className="row">
            <div className="col-lg-8">
              <h3 className="mb-0">Company Analytics</h3>
            </div>
            <div className="col-lg-4 text-end">
              <Link to="/Companydetailsedit" className="">
                <button className="btn btn-primary">Update</button>
              </Link>
              <Link to="/Companymangement" className="mx-1">
                <button className="btn btn-success">Back</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="row g-3 mb-3">
        <div className="col-md-4 col-xxl-3">
      <div onClick={()=>handleClickNCostumerCompanyId(company_id)}    
            className={`linkWithoutUnderline `}>
          <div className="card h-md-100 ecommerce-card-min-width">
            <div className="card-header pb-0">
              <h5 className="mb-0 mt-2 d-flex align-items-center">
              Total Normal Customer
              </h5>
            </div>
            <div className="card-body d-flex flex-column justify-content-end">
              <div className="row align-items-center">
                <div className="col pe-0">
                  <IoMdPersonAdd className="fs-4 text-success" />
                </div>
                <div className="col ps-0">
                  <p className="font-sans-serif lh-1 mb-1 fs-5 text-success">
                    {AllEmployee}
                  </p>
                </div>
                <div className="col-auto ps-0">
                  <div className="echart-bar-weekly-sales h-100" />
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
        <div className="col-md-4 col-xxl-3">
        <div onClick={()=>handleClickBCostumerCompanyId(company_id)}   className="linkWithoutUnderline">

          <div className="card h-md-100">
            <div className="card-header pb-0">
              <h5 className="mb-0 mt-2"> Total Business Customer</h5>
            </div>
            <div className="card-body d-flex flex-column justify-content-end">
              <div className="row justify-content-between">
                <div className="col pe-0">
                  <IoMdPersonAdd className="fs-4 text-info" />
                </div>
                <div className="col ps-0">
                  <div className="fs-5 fw-normal font-sans-serif text-700 lh-1 mb-1">
                    <p className="font-sans-serif lh-1 mb-1 fs-5  text-info">
                      {AllBusinesesCustomers}
                    </p>
                  </div>
                </div>
                <div className="col-auto ps-0 mt-n4">
                  <div
                    className="echart-default-total-order"
                    data-echarts='{"tooltip":{"trigger":"axis","formatter":"{b0} : {c0}"},"xAxis":{"data":["Week 4","Week 5","Week 6","Week 7"]},"series":[{"type":"line","data":[20,40,100,120],"smooth":true,"lineStyle":{"width":3}}],"grid":{"bottom":"2%","top":"2%","right":"10px","left":"10px"}}'
                    data-echart-responsive="true"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
        <div className="col-md-4 col-xxl-3">
        <Link to='#' className="linkWithoutUnderline">

          <div className="card h-md-100">
            <div className="card-header pb-0">
              <h5 className="mb-0 mt-2"> Total Sub Admin</h5>
            </div>
            <div className="card-body d-flex flex-column justify-content-end">
              <div className="row justify-content-between">
                <div className="col pe-0">
                  <IoMdPersonAdd className="fs-4 text-danger" />
                </div>
                <div className="col ps-0">
                  <div className="fs-5 fw-normal font-sans-serif text-700 lh-1 mb-1">
                    <p className="font-sans-serif lh-1 mb-1 fs-5  text-danger">
                     0
                    </p>
                  </div>
                </div>
                <div className="col-auto ps-0 mt-n4">
                  <div
                    className="echart-default-total-order"
                    data-echarts='{"tooltip":{"trigger":"axis","formatter":"{b0} : {c0}"},"xAxis":{"data":["Week 4","Week 5","Week 6","Week 7"]},"series":[{"type":"line","data":[20,40,100,120],"smooth":true,"lineStyle":{"width":3}}],"grid":{"bottom":"2%","top":"2%","right":"10px","left":"10px"}}'
                    data-echart-responsive="true"
                  />
                </div>
              </div>
            </div>
          </div>
        </Link>
        </div>
       
      
      </div>
     
      <div className="card mb-3">
        <div
          className="bg-holder d-none d-lg-block bg-card"
          style={{
            backgroundImage:
              "url(../../assets/img/icons/spot-illustrations/corner-4.png)"
          }}
        />
        <div className="card-body position-relative">
          <div className="row">
            <div className="col-lg-8">
              <h3 className="mb-0">Company Details</h3>
            </div>
          </div>
        </div>
      </div>
      <div className="row g-3">
    {business && business.map((business) => (
      <React.Fragment key={business.id}>
        
        <div className="col-12 mb-3">
          <div className="card">
            <div className="card-header" style={{ borderBottom: 'none' }}>
          
            </div>
            <div className="card-body bg-body-tertiary">
              <table className="table">
                <tbody>
                  <tr>
                    <th scope="row">Name</th>
                    <td className="d-flex align-items-center fw-bold capitalize" style={{ color: 'green' }}>
                      <FaRegBuilding className="me-2" />
                      {business.name}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Email</th>
                    <td className="d-flex align-items-center fw-bold" style={{ color: 'orange' }}>
                      <FaRegEnvelope className="me-2" />
                      <a style={{ color: 'orange' }} href={`mailto:${business.email}`}>{business.email}</a>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Address</th>
                    <td className="d-flex align-items-center fw-bold capitalize" style={{ color: 'blue' }}>
                      <FaMapMarkerAlt className="me-2" />
                      {business.address === "null" ? "" : business.address}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Phone</th>
                    <td className="d-flex align-items-center fw-bold" style={{ color: 'purple' }}>
                      <FaPhone className="me-2" />
                     
                      <a style={{ color: 'purple' }} href={`tel:${business?.phone ?? "1234567899"}`}>
        {business?.phone ?? "1234567899"}
      </a>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Description</th>
                    <td className="d-flex align-items-center fw-bold capitalize" style={{ color: 'teal' }}>
                      <FaInfoCircle className="me-2" />
                      {business.description === "null" ? "" : business.description}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Profile</th>
                    <td className="d-flex align-items-center fw-bold" style={{ color: 'red' }}>
                      <FaImage className="me-2" />
                      <img
                        src={business.profile == null ? company : `${baseurl}/${business.profile}`}
                        height={100}
                        width={100}
                        alt="Profile"
                        className="img-fluid"
                      />
                    </td>
                  </tr>
                </tbody>
                <div className="card-header" style={{ borderBottom: 'none' }}>
            </div>
              </table>
            </div>
          </div>
        </div>
      </React.Fragment>
    ))}
  </div>
      <ToastContainer />
    </>
  );
}
