
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { BsBuildings } from "react-icons/bs";
import { FaRegEdit, FaEye } from "react-icons/fa";
import { Spinner } from 'react-bootstrap';
import Swal from "sweetalert2";
import {
  FaUserAlt,
  FaUniversity,
  FaMoneyCheckAlt,
  FaCalendarAlt,
  FaHourglassHalf,
  FaFileInvoiceDollar,
  FaMoneyBillAlt,
  FaPercent,
  FaArrowLeft,
  FaChartLine,
  FaBusinessTime,
  FaDollarSign,
  FaPlus,
} from "react-icons/fa";
import * as xlsx from 'xlsx/xlsx.mjs';
export default function ViesNakedpotionn() {
  const broker_id = localStorage.getItem("broker_id");
  const company_name123 = localStorage.getItem("company_name123");
  const strateigs_name123 = localStorage.getItem("strateigs_name123");
  const strateigs_type123 = localStorage.getItem("strateigs_type123");
  const date_from = localStorage.getItem("date_from");
  const date_to = localStorage.getItem("date_to");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [newImage, setNewImage] = useState(null);
  const [fileimage, setFileimage] = useState(null);
  const [errors, setErrors] = useState({});
  const [data, setData] = useState([]);
  const [tableNaked, setTableNaked] = useState([]);
  const [tableNaked1, setTableNaked1] = useState([]);
  const [tableHedge, setTableHedge] = useState([]);
  const [tableHedge1, setTableHedge1] = useState([]);
  const [baseurl, setBaseUrl] = useState("");
  const [View, setView] = useState(false);
  const ViesNakedpotionn = localStorage.getItem("ViesNakedpotionn");
  const [formData, setFormData] = useState({
    id: "",
    name: "",
    smalladmins_name: "",
    company_id: "",
    company_name: "",
    date_from: "",
    date_to: "",
    default_lot_price: "",
    different_amt: "",
    profit_loss_amt: "",
    sell_price: "",
    status: "",
    strateigs_id: "",
    strateigs_name: "",
    symbol_id: "",
    symbol_name: "",
    buy_price: "",
  });

  useEffect(() => {

    fetchData4();

  }, []);


  const fetchData4 = async () => {
    try {
      const token = localStorage.getItem("bear_token");
      const updateformData = new FormData();
      updateformData.append("smalladmins_id", broker_id);
      updateformData.append("company_naked_hedgePL_id", ViesNakedpotionn);

      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}small-admins-manual-intraday-lists`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
        data: updateformData,
      };

      const response = await axios(config);
      const data = response.data.data;
      setTableNaked(data);
      console.warn("data", data)
      const data1 = response.data;
      setTableNaked1(data1);
    } catch (error) {
      console.error("Error fetching user broker:", error);
    }
  };

  // const viewReport = async () => {
  //   try {
  //     const reportUrl = `${process.env.REACT_APP_API_URL}small-admins-nakedbuysell-DownloadWebviewExcel?smalladmins_id=${broker_id}&strateigs_id=${formData.strateigs_id}`;
  //     const token = localStorage.getItem("bear_token");

  //     const response = await axios({
  //       method: "get",
  //       url: reportUrl,
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //       responseType: "blob", // important - this indicates we expect a binary response
  //     });

  //     const blob = new Blob([response.data], { type: response.headers["content-type"] });
  //     const url = window.URL.createObjectURL(blob);
  //     window.open(url, "_self"); // Opens the report in the same tab

  //   } catch (error) {
  //     console.error("Error fetching report:", error);
  //   }
  // };
  const handleClickEditCompanyId = (id) => {
    localStorage.setItem("Addintraday", id);
    navigate("/Addintraday");
  };
  const handleClickEditNakedCompanyId = (id) => {
    localStorage.setItem("EditIntra", id);
    navigate("/EditIntra");
  };
  // const exportToExcel = () => {
  //   if (tableNaked.length === 0) {
  //     // Display SweetAlert for data not found
  //     Swal.fire({
  //         icon: 'error',
  //         title: 'Data Not Found',
  //         text: 'No data available in  Over All Table',
  //     });
  //     return;
  //   } else {

  //     Swal.fire({
  //         title: 'Are you sure?',
  //         text: "Do you want to proceed with the  Over All Table?",
  //         icon: 'warning',
  //         showCancelButton: true,
  //         confirmButtonColor: '#3085d6',
  //         cancelButtonColor: '#d33',
  //         confirmButtonText: 'Yes, proceed!',
  //         cancelButtonText: 'No, cancel!'
  //     }).then((result) => {
  //         if (result.isConfirmed) {
  //             // Proceed with your code if the user confirms
  //             Swal.fire(
  //                 'Confirmed!',
  //                 'You have chosen to proceed...',
  //                 'success'
  //             );
  //   try {
  //     // Prepare main data
  //     const headerData = [
  //       [
  //         `${formData.strateigs_name} ${formData.symbol_name} ${formData.date_from} To ${formData.date_to} Closing`
  //       ],
  //       [
  //         "", "", "", "", "", ""
  //       ],
  //       [
  //         "Start Date", "End Date", "One LOT P&L", "No. of LOT", "Total P&L"
  //       ],
  //     ];

  //     const formattedData = tableNaked.map(item => ({
  //       "Start Date": item.date_from,
  //       "End Date": item.date_to,
  //       "One LOT P&L": item.one_lot_pl,
  //       "No. of LOT": item.no_of_lot,
  //       "Total P&L": item.total_pl,
  //     }));

  //     // Prepare footer data
  //     const footerData = [
  //       {
  //         "Start Date": "",
  //         "End Date": "",
  //         "One LOT P&L": "",
  //         "No. of LOT": "",
  //         "Total P&L": "",
  //       },
  //       {
  //         "Start Date": "",
  //         "End Date": "",
  //         "One LOT P&L": "",
  //         "No. of LOT": "Over All P&L",
  //         "Total P&L": tableNaked.reduce((sum, item) => sum + item.total_pl, 0),
  //       },
  //       {
  //         "Start Date": "",
  //         "End Date": "",
  //         "One LOT P&L": "",
  //         "No. of LOT": "",
  //         "Total P&L": "",
  //       },
  //     ];

  //     // Combine main data and footer data
  //     const combinedData = [
  //       ...headerData,
  //       ...formattedData.map(data => Object.values(data)),
  //       ...footerData.map(data => Object.values(data)),
  //     ];

  //     // Create workbook and worksheet
  //     const wb = xlsx.utils.book_new();
  //     const ws = xlsx.utils.aoa_to_sheet(combinedData);

  //     // Append worksheet to workbook
  //     xlsx.utils.book_append_sheet(wb, ws, "Overall_PL");

  //     // Write to file
  //     xlsx.writeFile(wb, "Overall_PL.xlsx");

  //     console.log("Exported to Excel successfully.");
  //   } catch (error) {
  //     console.error("Error exporting to Excel:", error);
  //   }
  // } else {
  //   // Handle the case where the user cancels
  //   Swal.fire(
  //       'Cancelled',
  //       'You have chosen not to proceed.',
  //       'error'
  //   );
  //   return;
  // }
  // });
  // }
  // };




  return (
    <div>
      <div className="card mb-3">
        <div
          className="bg-holder d-none d-lg-block bg-card"
          style={{
            backgroundImage:
              "url(../../assets/img/icons/spot-illustrations/corner-4.png)",
          }}
        />
        <div className="card-body position-relative">
          <div className="row ">
            <div className="col-lg-6">
              <h3 className="mb-0">Company Profit & Loss Manual (Intraday) Details</h3>
            </div>
            <div className="col-lg-2 text-center">
              {/* <button className="btn btn-primary"
              //  onClick={exportToExcel}
              >
              Export To Excel
              </button> */}
            </div>
            <div className="col-lg-4 text-end">
              <Link to="/PL-management" className="">
                <button className="btn btn-success">Back</button>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12 mb-3">
          <div className="card">
            <div className="card-header">
              <h5 className="card-title mb-0">Financial Details</h5>
            </div>
            <div className="card-body p-3">
              <div className="row">
                <div className="col-md-6">Company Name</div>
                <div
                  className="col-md-6 capitalize"
                  style={{ color: "green", fontWeight: "bold" }}
                >
                  <BsBuildings className="me-2" />

                  {company_name123}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-12 mb-3">
          <div className="card">
            <div className="card-header d-flex justify-content-between">
              <div className="col-lg-11">

                <h5 className="card-title mb-0">IntraDay List</h5>
              </div>

              {/* <div className="col-lg-4 text-end"> */}
              {/* <Link to="/Addintraday" className=""> */}

              {/* </Link> */}
              <div className=" col-lg-1  action_button text-end">
                <OverlayTrigger
                  key="Edit"
                  placement="top"
                  overlay={<Tooltip id={`Edit`}>Add IntraDay</Tooltip>}
                >
                  <button
                    onClick={() => handleClickEditCompanyId(ViesNakedpotionn)}
                    style={{ "--i": "green" }}
                  >
                    {" "}
                    <FaPlus className="color_link" />
                  </button>
                </OverlayTrigger>
              </div>
              {/* </div> */}

            </div>
            <div className="card-body p-0">
              <>
                <link
                  href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/css/bootstrap.min.css"
                  rel="stylesheet"
                />
                <style
                  dangerouslySetInnerHTML={{
                    __html:
                      "\n        body {\n            font-family: Arial, sans-serif;\n        }\n        table {\n            width: 80%;\n            margin: 20px auto;\n            border-collapse: collapse;\n            box-shadow: 0 2px 3px rgba(0,0,0,0.1);\n        }\n        th, td {\n            padding: 12px;\n            text-align: left;\n            border-bottom: 1px solid #ddd;\n        }\n        th {\n            background-color: #f4f4f4;\n        }\n        tr:nth-child(even) {\n            background-color: #f9f9f9;\n        }\n        .footer-row {\n            background-color: #f4f4f4;\n            font-weight: bold;\n        }\n    ",
                  }}
                />
                <div className="">
                  <h6
                    style={{
                      textAlign: "center",
                      background: "gray",
                      color: "#fff",
                      padding: "10px",
                      textTransform: "uppercase",
                      marginBottom: "0px",
                    }}
                    className="capitalize"
                  >
                    {strateigs_name123} {" "}
                    {date_from} To {date_to} Closing
                  </h6>
                  <div id="table-scroll" className={`table-scroll ${tableNaked && tableNaked.length > 0 ? "table-heightdataget" : "table-heightdatanull"}`}>
                    <table
                      id="main-table"
                      className="main-table table  table-bordered table-striped fs-10 mb-0"
                    >
                      <thead>
                        <tr className="bg-200">
                          <th className="text-900  text-center">#</th>


                          <th className="text-900  text-center">Total P&L</th>
                          <th className="text-900  text-center">Invested Lot</th>
                          <th className="text-900  text-center">One LOT P&L</th>
                          <th className="text-900  text-center">Date</th>
                          <th className="text-900  text-center">Start Date</th>
                          <th className="text-900  text-center">End Date</th>
                          <th className="text-900  text-center">Action</th>
                        </tr>
                      </thead>

                      {tableNaked && tableNaked.length > 0 ?
                        <>

                          <tbody>
                            {tableNaked && tableNaked.map((tableNaked, index) => (
                              <tr>
                                <td className="text-center" >{index + 1}</td>




                                {/* <td>{tableNaked.naked_default_lot_size}</td> */}

                                <td className="text-center ">
                                  {tableNaked.total_p_l}
                                  {/* {tableNaked.one_lot_pl * tableNaked.no_of_lot} */}
                                </td>
                                <td className="text-center">
                                  {tableNaked.invested_lot}
                                </td>
                                <td className="text-center text-success bold">
                                  {tableNaked.per_lot}
                                </td>
                                <td className="text-center">
                                  {new Date(tableNaked.date).toLocaleDateString('en-GB', {
                                    day: '2-digit',
                                    month: '2-digit',
                                    year: 'numeric',
                                  }).replace(/\//g, '-')}
                                </td>

                                <td className="text-center">
                                  {tableNaked.date_from}
                                </td>
                                <td className="text-center">
                                  {tableNaked.date_to}
                                </td>
                                <td className="align-middle priority text-center pe-4">
                                  <div className="d-flex gap-1 action_button text-center">
                                    <OverlayTrigger
                                      key="Edit"
                                      placement="top"
                                      overlay={<Tooltip id={`Edit`}>Edit</Tooltip>}
                                    >
                                      <button
                                        onClick={() => handleClickEditNakedCompanyId(tableNaked.id)}
                                        style={{ "--i": "#2c7be5" }}
                                      >
                                        {" "}
                                        <FaRegEdit className="color_link" />
                                      </button>
                                    </OverlayTrigger>


                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                          <tfoot className="tfoot_custum">

                            <tr className="footer-row">
                              <td></td>
                              <td></td>
                              {/* {tableNaked1.total_no_of_TradesCount && ( */}

                              {/* // )} */}
                              <td className=" text-center text-success bold">
                                {/* {tableNaked1.total_no_of_TradesCount} */}
                                Total One Lot P&L
                              </td>

                              <td className=" text-center text-success bold">

                                {tableNaked1 && tableNaked1.overallPL}
                                {/* {tableNaked.one_lot_pl * tableNaked.no_of_lot} */}
                              </td>
                              <td className="text-danger"></td>
                              <td className="  text-center">
                                {/* {tableNaked1.tradeprizeperorder} */}
                              </td>
                              <td className="  text-center">
                                {/* {tableNaked1.tradeprizeperorder} */}
                              </td>


                              <td className="text-danger"></td>
                            </tr>
                            {/* <tr className="footer-row ">
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td className="text-success  text-center">TOTAL P&L</td>
                          <td
                            colSpan={4}
                            style={{ textAlign: "right", fontSize: "larger" }}
                            className="text-success  text-center"
                          >
                            {
                              tableNaked1.TotalProfitAndLossMinustotalTradeExpences
                            }
                          </td>
                        </tr> */}
                          </tfoot>
                        </>
                        :
                        <>
                          <tr>
                            <td colSpan="7" className="text-center">
                              {tableNaked && tableNaked.length > 0 &&
                                <div class="spinner-border text-primary text-center" role="status">
                                  <span class="visually-hidden">Loading...</span>
                                </div>}
                              No records found
                            </td>
                          </tr></>
                      }
                    </table>
                  </div>
                </div>
              </>
            </div>
          </div>
        </div>

        <div className="col-lg-12 mb-3">
          <div className="card">
            <div className="card-body">
              <table className="table m-0">
                <tbody>
                  <tr>
                    <th scope="row">Strategies Name</th>
                    <td
                      style={{ color: "blue", fontWeight: "bold" }}
                      className="capitalize"
                    >
                      <FaChartLine className="me-2" />
                      {strateigs_name123}

                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Type</th>
                    <td
                      style={{ color: "green", fontWeight: "bold" }}
                      className="capitalize"
                    >
                      <FaBusinessTime className="me-2" /> {strateigs_type123}
                    </td>
                  </tr>
                  {/* <tr>
                    <th scope="row">Different Amount</th>
                    <td style={{ color: formData.different_amt < 0 ? 'red' : 'green', fontWeight: 'bold' }}><FaPercent className="me-2" /> {formData.different_amt}</td>
                  </tr>
                  <tr>
                    <th scope="row">Profit/Loss Amount</th>
                    <td style={{ color: formData.profit_loss_amt < 0 ? 'red' : 'green', fontWeight: 'bold' }}><FaFileInvoiceDollar className="me-2" /> {formData.profit_loss_amt}</td>
                  </tr> */}
                  {/* <tr>
                    <th scope="row">Default Lot Size</th>
                    <td style={{ color: "navy", fontWeight: "bold" }}>
                      <FaMoneyBillAlt className="me-2" />{" "}
                      {formData.default_lot_price}
                    </td>
                  </tr> */}
                  {/* <tr>
                    <th scope="row">Naked Default Lot Size</th>
                    <td style={{ color: "darkgreen", fontWeight: "bold" }}>
                      <FaMoneyBillAlt className="me-2" />{" "}
                      {formData.naked_default_lot_size}
                    </td>
                  </tr> */}
                  {/* <tr>
                    <th scope="row">Hedge Default Lot Size</th>
                    <td style={{ color: "navy", fontWeight: "bold" }}>
                      <FaMoneyBillAlt className="me-2" />{" "}
                      {formData.hedge_default_lot_size}
                    </td>
                  </tr> */}

                  {/* <tr>
                    <th scope="row">Buy Price</th>
                    <td style={{ color: 'darkgreen', fontWeight: 'bold' }}><FaDollarSign className="me-2" /> {formData.buy_price}</td>
                  </tr>
                  <tr>
                    <th scope="row">Sell Price</th>
                    <td style={{ color: 'navy', fontWeight: 'bold' }}><FaMoneyCheckAlt className="me-2" /> {formData.sell_price}</td>
                  </tr> */}

                  {/* <tr>
                    <th scope="row">Date From</th>
                    <td style={{ color: "brown", fontWeight: "bold" }}>
                      <FaCalendarAlt className="me-2" /> {formData.date_from}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Date To</th>
                    <td style={{ color: "brown", fontWeight: "bold" }}>
                      <FaCalendarAlt className="me-2" /> {formData.date_to}
                    </td>
                  </tr> */}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
