import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";

export default function AddStrategies() {
  const navigate = useNavigate();
  const broker_id = localStorage.getItem("broker_id");
  const [formData, setFormData] = useState({
    smalladmins_id: broker_id,
    name: "",
    status: "1", // Assuming default status is "Active"
  });
  const [profile, setProfile] = useState(null);
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
    setErrors((prevErrors) => ({ ...prevErrors, [id]: null }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setProfile(file);
    }
  };

  const handleValidation = () => {
    const requiredFields = [
      { field: "name", name: "Strategies Name" },
      { field: "status", name: "Status" },
    ];

    let validationErrors = {};
    let hasError = false;

    requiredFields.forEach(({ field, name }) => {
      if (!formData[field]) {
        validationErrors[field] = `${name} is required`;
        hasError = true;
      }
    });

    setErrors(validationErrors);
    return !hasError;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!handleValidation()) {
      return;
    }

    const token = localStorage.getItem("bear_token");

    const dataUpdate = new FormData();
    dataUpdate.append("smalladmins_id", broker_id);
    dataUpdate.append("name", formData.name);
    dataUpdate.append("status", formData.status);

    if (profile) {
      dataUpdate.append("profile", profile);
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}small-admins-add-strategies`,
        dataUpdate,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 201) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: response.data.message,
          // timer: 1500,
        });

        setTimeout(() => {
          navigate("/Straties"); 
        }, 2000);
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: response.data.message || "Unknown error occurred",
          // timer: 2000,
        });
      }
    } catch (error) {
      console.error("Error:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "An error occurred while submitting the form.",
        // timer: 2000,
      });
    }
  };

  return (
    <>
         <div className="card mb-3">
                <div
                    className="bg-holder d-none d-lg-block bg-card"
                    style={{
                        backgroundImage:
                            "url(../../assets/img/icons/spot-illustrations/corner-4.png)",
                    }}
                />
                <div className="card-body position-relative">
                    <div className="row">
                        <div className="col-lg-8">
                            <h3 className="mb-0">Add Strategy</h3>
                        </div>
                        <div className="col-lg-4 text-end">
                            <Link to="/Straties" className="">
                                <button className="btn btn-success">Back</button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
      <div className="card">
        <div className="card-body">
          <form className="row g-3" onSubmit={handleSubmit}>
          <div className="col-md-6 form_details">
                            <div className="form-group local-forms">
                                <label>
                                Strategy  Name <span className="login-danger">*</span>
                                </label>
                                <input
                                    className={`form-control ${errors.name ? "input-error" : ""}`}
                                    id="name"
                                    type="text"
                                    value={formData.name}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
        
                        <div className="col-md-6 form_details">
                        <div className="form-group local-forms">
            <label>
                              Status <span className="login-danger">*</span>
                                </label>
              <select
                className="form-select"
                id="status"
                value={formData.status}
                onChange={handleChange}
              >
                <option value="1">Active</option>
                <option value="0">Inactive</option>
              </select>
             
            </div>
            </div>
            <div className="col-12 d-flex justify-content-end">
              {/* Submit button */}
              <button className="btn btn-primary" type="submit">
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
