import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { FaRegEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { FaEye } from "react-icons/fa";
import { IoMdAdd } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
import { IoIosArrowBack } from "react-icons/io";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { FaSearch } from "react-icons/fa";
import company from "../../assets/img/icons/building.png";
import { MdCurrencyRupee } from "react-icons/md";
import { IoMdPersonAdd } from "react-icons/io";
import Swal from 'sweetalert2';

import { FaRupeeSign } from "react-icons/fa";
export default function ActineCustomer() {
  const [data, setData] = useState([]);
  const [baseurl, setbaseurl] = useState();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [AllEmployee, setAllEmployee] = useState(''); 
  const [ActiveEmployee, setActiveEmployee] = useState(''); 
  const [InactiveEmployee, setInactiveEmployee] = useState(''); 
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const company_id = localStorage.getItem("company_id");
  const company_Name = localStorage.getItem("company_Name");

  const custumerdetails_id = localStorage.getItem("custumerdetails");


  const broker_id = localStorage.getItem("broker_id");
  const token = localStorage.getItem("bear_token");

  const fetchData = async () => {
    try {
      setLoading(true);


      const token = localStorage.getItem("bear_token");
      const broker_id = localStorage.getItem("broker_id");

      const formData = new FormData();
      formData.append("smalladmins_id", broker_id);
      formData.append("status", "1");

      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}small-admins-customer-active-lists`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      };

      const response = await axios(config);
      const data = response.data.data;
      const data1 = response.data.image_url;

      const totalEmp = response.data.totalnarmalcustomer;
      const ActiveEmployee = response.data.totalActivenarmalcustomer;
      const InactiveEmployee = response.data.totalInActivenarmalcustomer;

      setAllEmployee(totalEmp)
      setActiveEmployee(ActiveEmployee)
      setInactiveEmployee(InactiveEmployee)
      setbaseurl(data1);
      setData(data);
  
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);



  // const Delete = async (id) => {
  //   try {
  //     const result = await Swal.fire({
  //       title: "Are you sure?",
  //       text: "You won't be able to revert this!",
  //       icon: "warning",
  //       showCancelButton: true,
  //       confirmButtonColor: "#3085d6",
  //       cancelButtonColor: "#d33",
  //       confirmButtonText: "Yes, delete it!",
  //     });
  //     if (result.isConfirmed) {
  //       setLoading(true);
  //       const formData = new FormData();
  //       formData.append("smalladmins_id", broker_id);
  //       formData.append("cust_id", id);

  //       const config = {
  //         method: "post",
  //         url: `${process.env.REACT_APP_API_URL}delete-small-admins-customers`,
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //           "Content-Type": "multipart/form-data",
  //         },
  //         data: formData,
  //       };

  //       const response = await axios(config);
  //       const data = response.data.status;
  //       if (data === 1) {
  //         Swal.fire({
  //           icon: 'success',
  //           title: 'Success',
  //           text: response.data.message,
  //           // timer: 1500
  //         })
       
  //         fetchData();
  //       } else {
  //         Swal.fire({
  //           icon: 'error',
  //           title: 'Error',
  //           text: response.data.message,
  //           // timer: 2000
  //         });
       
  //       }
  //       setLoading(false);
  //     }
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //     setLoading(false);
  //   }
  // };

  const handleStatusChange = async (business_id, currentStatus) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, change status!",
        
      });

      if (result.isConfirmed) {
        setLoading(true);

        const formData = new FormData();
        formData.append("smalladmins_id", broker_id);
        formData.append("cust_id", business_id);
        formData.append("status", currentStatus === 1 ? 0 : 1); 

        const config = {
          method: "post",
          url: `${process.env.REACT_APP_API_URL}customer-small-admins-update-status`,
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
          data: formData,
        };

        const response = await axios(config);
        const data = response.data.status;

        if (data === 1) {
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: response.data.message,
            color:"white",
            background: 'transparent',
  
          });
   
          fetchData();
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: response.data.message,
            // timer: 2000
          });
       
        }

        setLoading(false);
      }
    } catch (error) {
      console.error("Error changing status:", error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: "There was an error changing the status.",
        // timer: 2000
      });
  
      setLoading(false);
    }
  };


  const handleClick = async (id, companyName, company_id, user_credit, status) => {
 
    localStorage.setItem("company_id", company_id);
    localStorage.setItem("user_credit", user_credit);
    localStorage.setItem("cid", id);
    localStorage.setItem("cidwallet", id);
    if (companyName === "Not Assign" || companyName === "NA" && status === 1) {
      const result = await Swal.fire({
        title: "Alert",
        text: "Please assign a company first!",
        icon: "error",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Assign Company!",
        color:"white",
          background: 'transparent',

      });
  
      if (result.isConfirmed) {
        localStorage.setItem('customer_id', id);
        navigate("/Custumerdetailsedit");
      }
    } else if (status === undefined || status === null ||status === 0) {
      await Swal.fire({
        title: "Alert",
        text: "Please Update Status!",
        icon: "error",
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
        color:"white",
        background: 'transparent',
      });

    } else {
      localStorage.setItem("Addplan_id", id);
      navigate("/Add-More-plan");
    }
  };
  
  
  const handleClickadd = () => {
    navigate("/Add-admins-customer");
    localStorage.removeItem("company_id")
    localStorage.removeItem("company_Name")
  };

  const handleEditCustomerClick = (cust_id) => {
    localStorage.setItem('customer_id', cust_id);
    navigate("/Custumerdetailsedit");
  };


  const handleviveCustomerClick = (cust_id) => {
    localStorage.setItem('customer_id', cust_id);
    navigate("/Custumerdetails");
  };


  const filteredData = data ? data.filter((item) =>
    item.name.toLowerCase().includes(searchTerm.toLowerCase())
  ):[];

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedData = filteredData.slice(startIndex, endIndex);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const Delete = (cust_id) => {
    localStorage.setItem('c_id', cust_id);
    navigate("/Add-Normal-Transaction");
  };
  return (
    <>
      <div className="card mb-3">
        <div
          className="bg-holder d-none d-lg-block bg-card"
          style={{
            backgroundImage:
              "url(../../assets/img/icons/spot-illustrations/corner-4.png)"
          }}
        />
        <div className="card-body position-relative">
          <div className="row">
            <div className="col-lg-8">
            <h3 className="mb-0">Normal Customer Analytics</h3>
            </div>
            <div className="col-lg-4 text-end">
              <Link to="/Home" className="">
                <button className="btn btn-success">Back</button>
              </Link>
            </div>
          </div>
        </div>

      </div>
      <div className="row g-3 mb-3">
        <div className="col-md-4 col-xxl-3">
        <Link to='/Customermanagement' className="linkWithoutUnderline">
          <div className="card h-md-100 ecommerce-card-min-width">
            <div className="card-header pb-0">
              <h5 className="mb-0 mt-2 d-flex align-items-center">
                Total
              </h5>
            </div>
            <div className="card-body d-flex flex-column justify-content-end">
              <div className="row align-items-center">
                <div className="col pe-0">
                  <IoMdPersonAdd className="fs-4 text-primary" />
                </div>
                <div className="col ps-0">
                  <p className="font-sans-serif lh-1 mb-1 fs-5 text-primary">
                    {AllEmployee}
                  </p>
                </div>
                <div className="col-auto ps-0">
                  <div className="echart-bar-weekly-sales h-100" />
                </div>
              </div>
            </div>
          </div>
          </Link>
        </div>
        <div className="col-md-4 col-xxl-3">
        <Link to='/Active-Customer' className="linkWithoutUnderline active">
          <div className="card h-md-100">
            <div className="card-header pb-0">
              <h5 className="mb-0 mt-2"> Active </h5>
            </div>
            <div className="card-body d-flex flex-column justify-content-end">
              <div className="row justify-content-between">
                <div className="col pe-0">
                  <IoMdPersonAdd className="fs-4 text-success" />
                </div>
                <div className="col ps-0">
                  <div className="fs-5 fw-normal font-sans-serif text-700 lh-1 mb-1 ">
                    <p className="font-sans-serif lh-1 mb-1 fs-5 text-success">
                      {ActiveEmployee}
                    </p>
                  </div>           
                </div>
                <div className="col-auto ps-0 mt-n4">
                  <div
                    className="echart-default-total-order"
                    data-echarts='{"tooltip":{"trigger":"axis","formatter":"{b0} : {c0}"},"xAxis":{"data":["Week 4","Week 5","Week 6","Week 7"]},"series":[{"type":"line","data":[20,40,100,120],"smooth":true,"lineStyle":{"width":3}}],"grid":{"bottom":"2%","top":"2%","right":"10px","left":"10px"}}'
                    data-echart-responsive="true"
                  />
                </div>
              </div>
            </div>
          </div>
          </Link>
        </div>
        <div className="col-md-4 col-xxl-3">
        <Link to='/Inactive-Customer' className="linkWithoutUnderline">
          <div className="card h-md-100">
            <div className="card-header pb-0">
              <h5 className="mb-0 mt-2"> Inactive </h5>
            </div>
            <div className="card-body d-flex flex-column justify-content-end">
              <div className="row justify-content-between">
                <div className="col pe-0">
                  <IoMdPersonAdd className="fs-4 text-danger" />
                </div>
                <div className="col ps-0">
                  <div className="fs-5 fw-normal font-sans-serif text-700 lh-1 mb-1">
                    <p className="font-sans-serif lh-1 mb-1 fs-5  text-danger">
                      {InactiveEmployee}
                    </p>
                  </div>
                  {/* <span className="badge rounded-pill fs-11 bg-200 ">
                <span className="fas fa-caret-up me-1" />
                13.6%
              </span> */}
                </div>
                <div className="col-auto ps-0 mt-n4">
                  <div
                    className="echart-default-total-order"
                    data-echarts='{"tooltip":{"trigger":"axis","formatter":"{b0} : {c0}"},"xAxis":{"data":["Week 4","Week 5","Week 6","Week 7"]},"series":[{"type":"line","data":[20,40,100,120],"smooth":true,"lineStyle":{"width":3}}],"grid":{"bottom":"2%","top":"2%","right":"10px","left":"10px"}}'
                    data-echart-responsive="true"
                  />
                </div>
              </div>
            </div>
          </div>
          </Link>
        </div>
      </div>
      <div className="card mb-3">
        <div
          className="bg-holder d-none d-lg-block bg-card"
          style={{
            backgroundImage:
              "url(../../assets/img/icons/spot-illustrations/corner-4.png)",
          }}
        />
        {/*/.bg-holder*/}
        <div className="card-body position-relative">
          <div className="row">
            <div className="col-lg-8">
            <h3 className="mb-0">Normal Customer List</h3>
            </div>
            <div className="col md-4 d-flex justify-content-end border-0 shadow-none">
              <button
                type="button"
                className="btn btn-sm btn-success"
                onClick={()=>handleClickadd()}
              >
                <IoMdAdd className="me-1 fs--2" />  Add Customer
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="card"
        id="ticketsTable"
        data-list='{"valueNames":["client","subject","status","priority","agent"],"page":11,"pagination":true,"fallback":"tickets-table-fallback"}'
      >
        <div className="card-header justify-content-between d-flex border-bottom border-200">
          <div className="justify-content-end d-flex w-100">
            <form>
              <div className="input-group input-search-width">
                <input
                  className="form-control form-control-sm shadow-none search"
                  type="search"
                  placeholder="Search by name"
                  aria-label="search"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
                {/* <button className="btn btn-sm btn-outline-secondary border-300 hover-border-secondary">
                  <FaSearch  className="fs-10 text-dark"/>
                </button> */}
              </div>
            </form>
          </div>
        </div>
        {loading && (
          <div id="preloader">
            <div id="loading-center">
              <div className="loader">
                <div className="waviy">
                  <img
                    src="https://swaninvestment.in/frontant/assets/img/loader.png"
                    alt="Loader image"
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="card-body p-0">
          <div className="table-responsive scrollbar">
            <table className="table table-sm mb-0 fs-10 table-view-tickets">
              <thead className="bg-body-tertiary">
                <tr>
                  <th className="text-800 sort align-middle">Sr.</th>
                  <th className="text-800 sort align-middle">Name</th>
                  <th className="text-800 sort align-middle">Email</th>
                  <th className="text-800 sort align-middle">Company Name</th>
                  <th className="text-800 sort align-middle">Total Amount</th>
                  {/* <th className="text-800 sort align-middle">Password</th> */}
                  <th className="text-800 sort align-middle">Status</th>
                  <th className="text-800 sort align-middle">Action</th>
                </tr>
              </thead>
              <tbody className="list " id="table-ticket-body">
                {paginatedData.length > 0 ? (
                  paginatedData.map((item, index) => (
                    <tr>
                
           
                     
                      <td>{startIndex + index + 1}</td>
                      <td className="capitalize">{item.name}</td>
                      <td>
                        <a href="mailto:{item.email}">{item.email}</a>
                        <a href="mailto:{item.email}">{item.email}</a>
                      </td>
                      <td className="capitalize">{item.company_name}</td>
                      <td className="capitalize"> <MdCurrencyRupee /> {item.user_credit}</td>
                      {/* <td>{item.password}</td> */}

                      {/* <td className="align-middle status fs-9 pe-4">
                        {item.status === 1 ?
                          <div class="form-check form-switch">
                            <input
                              className="form-check-input toggle_btn"
                              type="checkbox"
                              role="switch"
                              id="flexSwitchCheckDefault"
                              checked
                            />
                          </div>
                          :
                          <div class="form-check form-switch">
                            <input
                              className="form-check-input toggle_btn"
                              type="checkbox"
                              role="switch"
                              id="flexSwitchCheckDefault"

                            />
                          </div>
                        }
                      </td> */}
                      <td>
                        <div className="form-check form-switch">
                          <input
                            className="form-check-input toggle_btn"
                            type="checkbox"
                            role="switch"
                            id={`flexSwitchCheckDefault-${item.cust_id}`}
                            checked={item.status === 1}
                            onChange={() => handleStatusChange(item.cust_id, item.status)}
                            disabled={loading} // Disable the button while loading
                          />
                        </div>
                      </td>
                      <td>
                        <div className="d-flex gap-1 action_button">
                          <OverlayTrigger
                            key="Edit"
                            placement="top"
                            overlay={<Tooltip id={`Edit`}>Edit</Tooltip>}
                          >
                            <button
                              // to="/Custumerdetailsedit"
                              onClick={() => handleEditCustomerClick(item.cust_id)}
                              style={{ "--i": "#2c7be5" }}
                            >
                              {" "}
                              <FaRegEdit className="color_link" />
                            </button>
                          </OverlayTrigger>

                          <OverlayTrigger
                            key="View"
                            placement="top"
                            overlay={<Tooltip id={`View`}>View</Tooltip>}
                          >
                            <button
                              onClick={() => handleviveCustomerClick(item.cust_id)}
                              style={{ "--i": "#27bcfd" }}
                            >
                              <FaEye className="color_link" />
                            </button>
                          </OverlayTrigger>
                          <OverlayTrigger
                            key="Update"
                            placement="top"
                            overlay={
                              <Tooltip id={`Update`}>Add More Plan</Tooltip>
                            }
                          >
                            <button
                              type="button"
                              style={{ "--i": "#07bc0c" }}
                              // onClick={handleClick}
                              onClick={() => handleClick(item.cust_id, item.company_name, item.company_id, item.user_credit, item.status)}            

                            // to="/Addplan"
                            >
                              <IoMdAdd className="color_link" />
                            </button>
                          </OverlayTrigger>
                          <OverlayTrigger
                            key="Delete"
                            placement="top"
                            overlay={<Tooltip id={`Delete`}>Add Fund</Tooltip>}
                          >
                            <button
                              style={{ "--i": "#444" }}
                              onClick={() => Delete(item.cust_id)}
                            >
                              {" "}
                              <FaRupeeSign className="color_link" />
                            </button>
                            {/* <Link style={{ '--i': 'red' }} onClick={() => {
                          Delete(item.cust_id);
                        }}> <MdDelete className="color_link" /></Link> */}
                          </OverlayTrigger>
                        </div>
                      </td>
                
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="12" className="text-center py-4">
                      No data found.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>

          </div>
          {totalPages > 1 && (
            <div className="pagination mt-3 mb-3 mx-3">
              <button
                className="btn btn-sm btn-light"
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                <IoIosArrowBack />
              </button>
              {[...Array(totalPages).keys()].map((pageNumber) => (
                <button
                  key={pageNumber + 1}
                  className={`btn btn-sm mx-1 ${currentPage === pageNumber + 1
                    ? "btn-light text-primary"
                    : "btn-light"
                    }`}
                  onClick={() => handlePageChange(pageNumber + 1)}
                >
                  {pageNumber + 1}
                </button>
              ))}
              <button
                className="btn btn-sm btn-light"
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                <IoIosArrowForward />
              </button>
            </div>
          )}
        </div>

      </div>
     
    </>
  );
}
