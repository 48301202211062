
    import React, { useState, useEffect } from "react";
    import axios from "axios";
    import { Spinner } from "react-bootstrap";
    import { useNavigate } from "react-router-dom";
    import Swal from "sweetalert2";
    import { Link } from "react-router-dom";
    import { FaChartBar,FaChartLine } from 'react-icons/fa';
    import { FaUserAlt, FaUniversity, FaMoneyCheckAlt, FaCalendarAlt, FaHourglassHalf, FaFileInvoiceDollar, FaMoneyBillAlt, FaPercent, FaArrowLeft } from 'react-icons/fa';
    import { BsFileEarmarkRuledFill } from "react-icons/bs";
    import { SiSymbolab } from "react-icons/si";
    export default function ViesDefaultlotsize() {
      const broker_id = localStorage.getItem("broker_id");
      const navigate = useNavigate();
      const [loading, setLoading] = useState(false);
      const [newImage, setNewImage] = useState(null);
      const [fileimage, setFileimage] = useState(null);
      const [errors, setErrors] = useState({});
      const [data, setData] = useState([]);
      const [dataplan, setDataplan] = useState([]);
      const [baseurl, setBaseUrl] = useState("");
      const [formData, setFormData] = useState({
        name: "",
        strategies: "",
        smalladmins_name: "",
       
    
       
      });
    
      useEffect(() => {
        fetchData1();
      }, []);
      const fetchData1 = async () => {
        try {
          const token = localStorage.getItem("bear_token");
    
          const customer_id = localStorage.getItem("Stratiesid");
    
    
    
          const formData = new FormData();
          formData.append("smalladmins_id", broker_id);
          formData.append("id", customer_id);
          formData.append("status", "1");
    
          const config = {
            method: "post",
            url: `${process.env.REACT_APP_API_URL}small-admins-view-defaultsize`,
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },
            data: formData,
          };
    
          const response = await axios(config);
          const data = response.data.data;
          setFormData(data[0]);
          const dataurl = response.data.url;
          setBaseUrl(dataurl);
        } catch (error) {
          console.error("Error fetching user broker:", error);
        }
      };
    
      return (
        <div>
          <div className="card mb-3">
            <div
              className="bg-holder d-none d-lg-block bg-card"
              style={{
                backgroundImage:
                  "url(../../assets/img/icons/spot-illustrations/corner-4.png)",
              }}
            />
            <div className="card-body position-relative">
              <div className="row ">
                <div className="col-lg-8">
                  <h3 className="mb-0">Default Lot Size  Details</h3>
                </div>
                <div className="col-lg-4 text-end">
                  {/* <Link to="/AditDefaultlotsize" className="">
                    <button className="btn btn-primary">Update</button>
                  </Link> */}
                  <Link to="/Defaultlotsize" className="">
                    <button className="btn btn-success">Back</button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
    
          <div className="col-lg-12 mb-3">
              <div className="card">
                <div className="card-header">
                  <h5 className="card-title mb-0">Default Lot Size Details</h5>
                </div>
                <div className="card-body">
                  <table className="table">
                  <tbody>
                    <tr>
                      <th scope="row">ID</th>
                      <td style={{ color: 'green', fontWeight: 'bold' }}># {formData.id}</td>
                    </tr>
                    <tr>
                      <th scope="row">Strategy</th>
                      <td style={{ color: 'blue', fontWeight: 'bold' }} className='capitalize'>
                        <BsFileEarmarkRuledFill className="me-2" /> {formData.strategies_name}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Type</th>
                      <td style={{ color: 'purple', fontWeight: 'bold' }} className='capitalize'>
                      {formData.strategy_type === "positional" ? 
                    
                    <FaChartLine className="me-2" />
                    :
                    <FaChartBar className="me-2" />
                  }{formData.strategy_type} 
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Symbol</th>
                      <td style={{ color: 'red', fontWeight: 'bold' }} className='capitalize'>
                        <SiSymbolab className="me-2" /> {formData.symbol_name}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Naked Default Lot Size</th>
                      <td style={{ color: 'orange', fontWeight: 'bold' }} className='capitalize'>
                        Rs. {formData.naked_default_lot_size}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Hedge Default Lot Size</th>
                      <td style={{ color: 'purple', fontWeight: 'bold' }} className='capitalize'>
                        Rs. {formData.hedge_default_lot_size}
                      </td>
                    </tr>
                    {/* <tr>
                      <th scope="row">Default Lot Size</th>
                      <td style={{ color: 'brown', fontWeight: 'bold' }} className='capitalize'>
                        Rs. {formData.default_lot_size}
                      </td>
                    </tr> */}
                    {/* <tr>
                      <th scope="row">Admin Name</th>
                      <td style={{ color: 'purple', fontWeight: 'bold' }}>
                        <FaUserAlt className="me-2" /> {formData.smalladmins_name}
                      </td>
                    </tr> */}
                  </tbody>
                  </table>
                </div>
              </div>
            </div>
    
     
    
        </div>
      );
    }
    