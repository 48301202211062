import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import {
  FaUserAlt, FaUniversity, FaMoneyCheckAlt, FaCalendarAlt,
  FaHourglassHalf, FaFileInvoiceDollar, FaMoneyBillAlt,
  FaPercent, FaArrowLeft, FaChartLine, FaBusinessTime, FaDollarSign
} from 'react-icons/fa';

export default function EditViesStraties() {
  const broker_id = localStorage.getItem("broker_id");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [newImage, setNewImage] = useState(null);
  const [fileimage, setFileimage] = useState(null);
  const [errors, setErrors] = useState({});
  const [data, setData] = useState([]);
  const [dataplan, setDataplan] = useState([]);
  const [baseurl, setBaseUrl] = useState("");
  const [formData, setFormData] = useState({
    id: "",
    name: "",
    smalladmins_name: "",
    company_id: "",
    company_name: "",
    date_from: "",
    date_to: "",
    default_lot_price: "",
    different_amt: "",
    profit_loss_amt: "",
    sell_price: "",
    status: "",
    strateigs_id: "",
    strateigs_name: "",
    symbol_id: "",
    symbol_name: "",
    buy_price: "",
    strike_price: "",
    type: "",
  });

  useEffect(() => {
    fetchData1();
  }, []);

  const fetchData1 = async () => {
    try {
      const token = localStorage.getItem("bear_token");
      const customer_id = localStorage.getItem("ViewsHadge");

      const formData = new FormData();
      formData.append("smalladmins_id", broker_id);
      formData.append("id", customer_id);
      formData.append("status", "1");

      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}small-admins-view-hedge`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      };

      const response = await axios(config);
      const data = response.data.data;
      setFormData(data[0]);
      const dataurl = response.data.url;
      setBaseUrl(dataurl);
    } catch (error) {
      console.error("Error fetching user broker:", error);
    }
  };
  const viewReport = () => {
    const reportUrl = `${process.env.REACT_APP_API_URL}small-admins-hedge-DownloadWebviewExcel?smalladmins_id=${broker_id}&strateigs_id=${formData.strateigs_id}`;
    window.open(reportUrl, "_blank");
  }

 
  // const viewReport = async () => {
  //   try {
  //     const reportUrl = `${process.env.REACT_APP_API_URL}small-admins-hedge-DownloadWebviewExcel?smalladmins_id=${broker_id}&strateigs_id=${formData.strateigs_id}`;
  //     const token = localStorage.getItem("bear_token");

  //     const response = await axios({
  //       method: "get",
  //       url: reportUrl,
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //       responseType: "blob", // important - this indicates we expect a binary response
  //     });

  //     const blob = new Blob([response.data], { type: response.headers["content-type"] });
  //     const url = window.URL.createObjectURL(blob);
  //     window.open(url, "_self"); // Opens the report in the same tab

  //   } catch (error) {
  //     console.error("Error fetching report:", error);
  //   }
  // };
  return (
    <div>
       <div className="card mb-3">
        <div
          className="bg-holder d-none d-lg-block bg-card"
          style={{
            backgroundImage:
              "url(../../assets/img/icons/spot-illustrations/corner-4.png)",
          }}
        />
        <div className="card-body position-relative">
          <div className="row ">
            <div className="col-lg-4">
              <h3 className="mb-0">Hedge Details</h3>
            </div>
            <div className="col-lg-4 text-center">
        
          <button className="btn btn-primary" onClick={viewReport}>View Report</button>
        
            </div>
            <div className="col-lg-4 text-end">
              <Link to="/AddHedge" className="">
                <button className="btn btn-success"><FaArrowLeft className="me-2" /> Back</button>
              </Link>
            </div>
          </div>
        </div>
        </div>

      <div className="row">
      <div className="col-lg-12 mb-3">
          <div className="card">
            <div className="card-header">
              <h5 className="card-title mb-0">Financial Details</h5>
            </div>
            <div className="card-body">
              <table className="table">
                <tbody>
                  
                <tr>
                    <th scope="row">Strategies Name</th>
                    <td style={{ color: 'blue', fontWeight: 'bold' }} className='capitalize'><FaChartLine className="me-2" /> {formData.strateigs_name}</td>
                  </tr>
                  <tr>
                    <th scope="row">Symbol Name</th>
                    <td style={{ color: 'gold', fontWeight: 'bold' }}><FaBusinessTime className="me-2" /> {formData.symbol_name}</td>
                  </tr>
                  <tr>
                    <th scope="row">Default Lot Price</th>
                    <td style={{ color: 'navy', fontWeight: 'bold' }}><FaMoneyBillAlt className="me-2" /> {formData.default_lot_price}</td>
                  </tr>
                  <tr>
                    <th scope="row">Buy Price</th>
                    <td style={{ color: 'darkgreen', fontWeight: 'bold' }}><FaDollarSign className="me-2" /> {formData.buy_price}</td>
                  </tr>
                  <tr>
                    <th scope="row">Sell Price</th>
                    <td style={{ color: 'navy', fontWeight: 'bold' }}><FaMoneyCheckAlt className="me-2" /> {formData.sell_price}</td>
                  </tr>
               
                  <tr>
                    <th scope="row">Different Amount</th>
                    <td style={{ color: formData.different_amt < 0 ? 'red' : 'green', fontWeight: 'bold' }}><FaPercent className="me-2" /> {formData.different_amt}</td>
                  </tr>
                  <tr>
                    <th scope="row">Profit/Loss Amount</th>
                    <td style={{ color: formData.profit_loss_amt < 0 ? 'red' : 'green', fontWeight: 'bold' }}><FaFileInvoiceDollar className="me-2" /> {formData.profit_loss_amt}</td>
                  </tr>
                
              
                  <tr>
                    <th scope="row">Strike Price</th>
                    <td style={{ color: 'brown', fontWeight: 'bold' }}><FaChartLine className="me-2" /> {formData.strike_price}</td>
                  </tr>
                 
                  <tr>
                    <th scope="row">Type</th>
                    <td style={{ color: 'navy', fontWeight: 'bold' }}><FaChartLine className="me-2" /> {formData.type}</td>
                  </tr>
                  <tr>
                    <th scope="row">Date From</th>
                    <td style={{ color: 'brown', fontWeight: 'bold' }}><FaCalendarAlt className="me-2" /> {formData.date_from}</td>
                  </tr>
                  <tr>
                    <th scope="row">Date To</th>
                    <td style={{ color: 'brown', fontWeight: 'bold' }}><FaCalendarAlt className="me-2" /> {formData.date_to}</td>
                  </tr>
               
                </tbody>
              </table>
            </div>
          </div>
        </div>
  

        <div className="col-lg-12 mb-3">
          <div className="card">
            <div className="card-header">
              <h5 className="card-title mb-0">Company Details</h5>
            </div>
            <div className="card-body">
              <table className="table">
                <tbody>
                  {/* <tr>
                    <th scope="row">Company ID</th>
                    <td style={{ color: 'teal', fontWeight: 'bold' }}><FaUniversity className="me-2" /> {formData.company_id}</td>
                  </tr> */}
                  <tr>
                    <th scope="row">Company Name</th>
                    <td style={{ color: 'orange', fontWeight: 'bold' }}><FaUniversity className="me-2" /> {formData.company_name}</td>
                  </tr>
                 
                </tbody>
              </table>
            </div>
          </div>
        </div>

     
      </div>
    </div>
  );
}
