import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Sidebar from "./components/Sidebar";
import Navbar from "./components/Navbar";
import Home from "./pages/Home/Home";
import Termsandcondition from "./pages/Home/Termsandcondition";
import Aboutus from "./pages/Home/Aboutus";
import Login from "./components/Login/Login";

import Privacypolicy from "./pages/Home/Privacypolicy";
import Customermanagement from "./pages/customermanagement/Customermanagement"

import Settings from "./pages/Home/Settings";
import Custumerdetailsedit from "./pages/customermanagement/Custumerdetailsedit";
import Addsmalladmins_customer from "./pages/customermanagement/Addsmalladmins_customer";
import CustomerPlan from "./pages/customermanagement/CustomerPlan";
import BussinessCustomermanagement from "./pages/customermanagement/BussinessCustomermanagement";
import ActiveCustomer from "./pages/customermanagement/ActiveCustomer";
import InactiveCustomer from "./pages/customermanagement/InactiveCustomer";

import Profile from "./pages/Home/Profile";
import ChangePass from "./pages/Home/ChangePass";
import Custumerdetails from "./pages/customermanagement/Custumerdetails";
import Addsmalladmins_customer_c from "./pages/customermanagement/Addsmalladmins_customer_c";
import Contactus from "./pages/Home/Contactus";
import Notifications from "./pages/Home/Notifications";
import NotificationReply from "./pages/Home/NotificationReply";
import Faq from "./pages/Home/Faq";

import Companymangement from "./pages/companymangement/Companymangement";
import ActiveCompanymangement from "./pages/companymangement/ActiveCompanymangement";
import InactiveCompanymangement from "./pages/companymangement/InactiveCompanymangement";
import Companydetails from "./pages/companymangement/Companydetails";
import Companydetailsedit from "./pages/companymangement/Companydetailsedit";
import CustumerdetailseditSelectCompany from "./pages/customermanagement/CustumerdetailseditSelectCompany";
/*****single normal cuctomer Transaction ********/
import AddNormalTransaction from "./pages/Customer_Transaction/AddNormalTransaction";
import CustumerTransactionView from "./pages/Customer_Transaction/CustumerTransactionView";
import CustomerTransactiondetails from "./pages/Customer_Transaction/CustomerTransactiondetails";
import CustomerTransactiondetailsSuccess from "./pages/Customer_Transaction/CustomerTransactiondetailsSuccess";
import CustomerTransactiondetailsFaild from "./pages/Customer_Transaction/CustomerTransactiondetailsFaild";
import CustomerTransactiondetailsPending from "./pages/Customer_Transaction/CustomerTransactiondetailsPending";
/*****single normal cuctomer investment plan ********/
import SingleBCustomerInvestPlanView  from "./pages/customer_plan_inverst/SingleBCustomerInvestPlanView"
import SingleCustomerInvestPlanView from "./pages/customer_plan_inverst/SingleCustomerInvestPlanView"
import SingleCustomerPlan from "./pages/customer_plan_inverst/SingleCustomerPlan"
import SingleCustomerPlanActive from "./pages/customer_plan_inverst/SingleCustomerPlanActive"
import SingleCustomerPlanExpired from "./pages/customer_plan_inverst/SingleCustomerPlanExpired"
import SingleCustomerPlansPending from "./pages/customer_plan_inverst/SingleCustomerPlansPending"
/*****single bussiness cuctomer  Transaction ********/
import AddBussinessTransaction from "./pages/Bussiness_Customer_Transaction/AddBussinessTransaction";
import BCustumerTransactionView from "./pages/Bussiness_Customer_Transaction/BCustumerTransactionView";
import BCustomerTransactiondetails from "./pages/Bussiness_Customer_Transaction/BCustomerTransactiondetails";
import BCustomerTransactiondetailsSuccess from "./pages/Bussiness_Customer_Transaction/BCustomerTransactiondetailsSuccess";
import BCustomerTransactiondetailsFaild from "./pages/Bussiness_Customer_Transaction/BCustomerTransactiondetailsFaild";
import BCustomerTransactiondetailsPending from "./pages/Bussiness_Customer_Transaction/BCustomerTransactiondetailsPending";
/*****single Bussiness cuctomer investment plan ********/
import SingleBussinessinvestments from "./pages/customer_plan_inverst/SingleBussinessinvestments"
import SingleBussinessinvestmentsActive from "./pages/customer_plan_inverst/SingleBussinessinvestmentsActive"
import SingleBussinessinvestmentsExpired from "./pages/customer_plan_inverst/SingleBussinessinvestmentsExpired"
import SingleBussinessinvestmentsPending from "./pages/customer_plan_inverst/SingleBussinessinvestmentsPending"
/*****Employee   Transactiondetails   -----------------********/
import AddEmployeeTransaction from "./pages/Employee_Transaction/AddEmployeeTransaction";
import TransactionView from "./pages/Employee_Transaction/TransactionView";
import EmployeeSalaryShlipdetails from "./pages/Employee_Transaction/EmployeeSalaryShlipdetails";
import EmployeeTransactiondetails from "./pages/Employee_Transaction/EmployeeTransactiondetails";
import EmployeeTransactiondetailsSuccess from "./pages/Employee_Transaction/EmployeeTransactiondetailsSuccess";
import EmployeeTransactiondetailsFaild from "./pages/Employee_Transaction/EmployeeTransactiondetailsFaild";
import EmployeeTransactiondetailsPending from "./pages/Employee_Transaction/EmployeeTransactiondetailsPending";
/*****investmentplan ********/
import Investmentmanagement from "./pages/investmentmanagement/Investmentmanagement";
import Addplan from "./pages/investmentmanagement/Addplan";
import AddMoreplan from "./pages/investmentmanagement/AddMoreplan";
import AddBMoreplan from "./pages/investmentmanagement/AddBMoreplan";
import InvestmentView from "./pages/investmentmanagement/InvestmentView";
import Investmentedit from "./pages/investmentmanagement/Investmentedit";
import Activeplan from "./pages/investmentmanagement/Activeplan";
import Inactiveplan from "./pages/investmentmanagement/Inactiveplan";
/******customer_investments********/
import CustomerinvestmentsActive from "./pages/customer_investments/CustomerinvestmentsActive";
import CustomerinvestmentsInactive from "./pages/customer_investments/CustomerinvestmentsInactive";
import Customerinvestmentspending from "./pages/customer_investments/Customerinvestmentspending";
import Customerinvestmentsexpired from "./pages/customer_investments/Customerinvestmentsexpired";
import Customerinvestments from "./pages/customer_investments/Customerinvestments";
import Bussinessinvestments from "./pages/customer_investments/Bussinessinvestments";
import BussinessinvestmentsActive from "./pages/customer_investments/BussinessinvestmentsActive";
import BussinessinvestmentsPending from "./pages/customer_investments/BussinessinvestmentsPending";
import BussinessinvestmentsExpired from "./pages/customer_investments/BussinessinvestmentsExpired";
/***** business **** */
import Addbusiness from "./pages/businessregmanagement/Addbusiness";
import AddMoreplanbusiness from "./pages/businessregmanagement/AddMoreplanbusiness";
import Businessedit from "./pages/businessregmanagement/Businessedit";
import Businessemanagement from "./pages/businessregmanagement/Businessemanagement";
import ActiveBussiness from "./pages/businessregmanagement/ActiveBussiness";
import InctiveBussiness from "./pages/businessregmanagement/InctiveBussiness";
import BusinessetView from "./pages/businessregmanagement/BusinessetView";
//****Employee**** /
import Addemployee from "./pages/employeemanagement/Addemployee";
import EmployeeView from "./pages/employeemanagement/EmployeeView";
import Employeeedit from "./pages/employeemanagement/Employeeedit";
import Employeemanagement from "./pages/employeemanagement/Employeemanagement";
import ActiveEmployeemanagement from "./pages/employeemanagement/ActiveEmployeemanagement";
import PaySlipDetails from "./pages/employeemanagement/PaySlipDetails";
import InActiveEmployeemanagement from "./pages/employeemanagement/InActiveEmployeemanagement";
import Changepasssword from "./components/Login/Changepasssword";
import Forgetpassword from "./components/Login/Forgetpassword";
import Addcompany from "./pages/companymangement/Addcompany";
import CompanyView from "./pages/companymangement/CompanyView";
import Footer from "./components/Footer";
import { Container } from "react-bootstrap";  
//BUSSINESS CUSTOMER OF COMPANY 
import SingleBCompanyActiveBussiness from "./pages/SingleCompany_customer/SingleBCompanyActiveBussiness"
import SingleBCompanyBussinessCustomer from "./pages/SingleCompany_customer/SingleBCompanyBussinessCustomer"
import SingleBCompanyInctiveBussiness from "./pages/SingleCompany_customer/SingleBCompanyInctiveBussiness"
// NORMAL CUSTOMER OF COMPANY 
import SingleCompanyActiveCustomer from "./pages/SingleCompany_customer/SingleCompanyActiveCustomer"
import SingleCompanyCustomer from "./pages/SingleCompany_customer/SingleCompanyCustomer"
import SingleCompanyInactiveCustomer from "./pages/SingleCompany_customer/SingleCompanyInactiveCustomer"
/// profit  and loss
import Profitandlossmangement from "./pages/Profitandlossmangement/ProfitandLoss"
import ViewsPL from "./pages/Profitandlossmangement/ViewsPL"
import Addintraday from "./pages/Profitandlossmangement/Addintraday"
import Updateintraday from "./pages/Profitandlossmangement/Updateintraday"
import Straties from "./pages/Profitandlossmangement/Straties"
import AddStrategies from "./pages/Profitandlossmangement/AddStrategies"
import EditViesStraties from "./pages/Profitandlossmangement/EditViesStraties"
import ViesStraties from "./pages/Profitandlossmangement/ViesStraties"
import AddPL from "./pages/Profitandlossmangement/AddPL"
import AddBill from "./pages/Profitandlossmangement/AddBill"
import AddBills from "./pages/Profitandlossmangement/AddBills"
import Addsymbol from "./pages/Profitandlossmangement/Symbol"
import Newaddsymbol from "./pages/Profitandlossmangement/Newaddsymbol"
import Edidsymbol from "./pages/Profitandlossmangement/Edidsymbol"
import Veissymbol from "./pages/Profitandlossmangement/Veissymbol"
import Defaultlotsize from "./pages/Profitandlossmangement/Defaultlotsize"
import Adddefaultlotsize from "./pages/Profitandlossmangement/Adddefaultlotsize"
import AditDefaultlotsize from "./pages/Profitandlossmangement/AditDefaultlotsize"
import ViesDefaultlotsize from "./pages/Profitandlossmangement/ViesDefaultlotsize"
import AddHedge from "./pages/Profitandlossmangement/AddHedge"
import AddHadgeadd from "./pages/Profitandlossmangement/AddHadgeadd"
import AddNakedadd  from "./pages/Profitandlossmangement/AddNakedadd "
import AddNakedBuySell from "./pages/Profitandlossmangement/AddNakedBuySell"
import ViesNaked from "./pages/Profitandlossmangement/ViesNaked"
import ViesNakedpotionn from "./pages/Profitandlossmangement/ViesNakedpotionn"
import ViewsHadge from "./pages/Profitandlossmangement/ViewsHadge"
import EditNakedBuySell from "./pages/Profitandlossmangement/EditNakedBuySell"
import EditHadge from "./pages/Profitandlossmangement/EditHadge"
import AddNakedPrize from "./pages/Profitandlossmangement/AddNakedPrize"
import EditNakedPrize from "./pages/Profitandlossmangement/EditNakedPrize"
import EditHedgePrize from "./pages/Profitandlossmangement/EditHedgePrize"
import AddHedgePrize from "./pages/Profitandlossmangement/AddHedgePrize"
import EditPL from "./pages/Profitandlossmangement/EditPL"
import EditPLIndtra from "./pages/Profitandlossmangement/EditPLIndtra"
import ViewsPLIntra from "./pages/Profitandlossmangement/ViewsPLIntra"
//////////////////////////////////////////
import "./App.css";
// import stylesheet fiels

function App() {
  return (
    <React.Fragment>
      
      {/* <Container fluid> */}
        <Router>
          <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/forgetpassword" element={<Forgetpassword />} />
          <Route path="/changepasssword" element={<Changepasssword />} />

          <Route path="/*" element={
            <>        
              <Sidebar />
          <div className="content" style={{ marginLeft: "235px" }}>
            <Navbar />
            <main className="main" id="top">
              <Container fluid>

              <Routes>
              <Route path="/home" element={<Home />} />
              <Route path="/Investment-management" element={<Investmentmanagement />} />
                <Route path="/Addplan" element={<Addplan />} />
                <Route path="/Add-More-plan" element={<AddMoreplan />} />
                <Route path="/Add-B-More-plan" element={<AddBMoreplan />} />
                <Route path="/Add-More-plan-business" element={<AddMoreplanbusiness />} />

                <Route path="/InvestmentView" element={<InvestmentView />} />
                <Route path="/Investmentedit" element={<Investmentedit />} />
                <Route path="/Active-plan" element={<Activeplan />} />
                <Route path="/Inactive-plan" element={<Inactiveplan />} />

                <Route path="/Addbusiness" element={<Addbusiness />} />
                <Route path="/Business-edit" element={<Businessedit />} />
                <Route path="/Bussiness-investments" element={<Bussinessinvestments />} />
                <Route path="/Businesse-management" element={<Businessemanagement />} />
                <Route path="/Businesset-View" element={<BusinessetView />} />
                <Route path="/Active-Bussiness" element={<ActiveBussiness />} />
                <Route path="/Inctive-Bussiness" element={<InctiveBussiness />} />

                <Route path="/Employee-management" element={<Employeemanagement />} />
                <Route path="/Employeeedit" element={<Employeeedit />} />
                <Route path="/EmployeeView" element={<EmployeeView />} />
                <Route path="/Active-Employeemanagement" element={<ActiveEmployeemanagement />} />
                <Route path="/Pay-Slip-Details" element={<PaySlipDetails />} />
                <Route path="/InActive-Employeemanagement" element={<InActiveEmployeemanagement />}/> 
                <Route path="/Addemployee" element={<Addemployee />} />
                <Route path="/faq" element={<Faq />} />
                <Route path="/companymangement" element={<Companymangement />} />
                <Route path="/Active-Company-mangement" element={<ActiveCompanymangement />} />
                <Route path="/Inactive-Company-mangement" element={<InactiveCompanymangement />} />
                <Route path="/Addcompany" element={<Addcompany />} />
                <Route path="/CompanyView" element={<CompanyView />} />
                <Route path="/Companydetails" element={<Companydetails />} />
                <Route path="/Companydetailsedit" element={<Companydetailsedit />} />

                <Route path="/Customermanagement" element={<Customermanagement />} />
                <Route path="/Privacypolicy" element={<Privacypolicy />} />
                <Route path="/Contactus" element={<Contactus />} />
                <Route path="/Notifications" element={<Notifications />} />
                <Route path="/NotificationReply" element={<NotificationReply />} />
        
                <Route path="/Custumerdetails" element={<Custumerdetails />} />
                <Route path="/Profile" element={<Profile />} />
                <Route path="/ChangePass" element={<ChangePass />} />

                <Route path="/Custumerdetailsedit" element={<Custumerdetailsedit />} />
                <Route path="/Custumerdetailsedit-Select-Company" element={<CustumerdetailseditSelectCompany />} />
                <Route path="/Add-admins-customer" element={<Addsmalladmins_customer />} />
                <Route path="/CustomerPlan" element={<CustomerPlan />} />

                <Route path="/Customer-investments" element={<Customerinvestments />} />
                <Route path="/Customer-investments-Active" element={<CustomerinvestmentsActive />} />
                <Route path="/Customer-investments-Inactive" element={<CustomerinvestmentsInactive />} />
                <Route path="/Customer-investments-pending" element={<Customerinvestmentspending />} />
                <Route path="/Customer-investments-expired" element={<Customerinvestmentsexpired />} />
                <Route path="/Bussiness-investments-Active" element={<BussinessinvestmentsActive />} />
                <Route path="/Bussiness-investments-Pending" element={<BussinessinvestmentsPending />} />
                <Route path="/Bussiness-investments-Expired" element={<BussinessinvestmentsExpired />} />
                {/* ----------------------------------------------------------------------------------------- */}

                <Route path="/Add-Normal-Transaction" element={<AddNormalTransaction />} />
                <Route path="/Normal-Transaction-View" element={<CustumerTransactionView />} />
                <Route path="/Customer-Transaction-Details" element={<CustomerTransactiondetails />} />
                <Route path="/Customer-Transactiondetails-credit" element={<CustomerTransactiondetailsSuccess />} />
                <Route path="/Customer-Transactiondetails-debit" element={<CustomerTransactiondetailsPending />} />
                <Route path="/Customer-Transactiondetails-Failds" element={<CustomerTransactiondetailsFaild />} />

                <Route path="/Single-B-Customer-InvestPlan-View" element={<SingleBCustomerInvestPlanView  />} />
                <Route path="/Single-Customer-InvestPlan-View" element={<SingleCustomerInvestPlanView />} />
                <Route path="/Single-Customer-Plan" element={<SingleCustomerPlan />} />
                <Route path="/Single-Customer-Plan-Active" element={<SingleCustomerPlanActive />} />
                <Route path="/Single-Customer-Plan-Pending" element={<SingleCustomerPlansPending />} />
                <Route path="/Single-Customer-Plan-Expired" element={<SingleCustomerPlanExpired />} />
                
                {/* ---------------------------------------------------------------------------------------- */}
                <Route path="/Add-Bussiness-Transaction" element={<AddBussinessTransaction />} />
                <Route path="/Bussiness-Transaction-View" element={<BCustumerTransactionView />} />
                <Route path="/Bussiness-Customer-Transaction-Details" element={<BCustomerTransactiondetails />} />
                <Route path="/Bussiness-Customer-Transactiondetails-credit" element={<BCustomerTransactiondetailsSuccess />} />
                <Route path="/Bussiness-Customer-Transactiondetails-debit" element={<BCustomerTransactiondetailsPending />} />
                <Route path="/Bussiness-Customer-Transactiondetails-Failds" element={<BCustomerTransactiondetailsFaild />} />

                <Route path="/Single-Bussiness-Customer-Plan" element={<SingleBussinessinvestments />} />
                <Route path="/Single-Bussiness-Customer-Plan-Active" element={<SingleBussinessinvestmentsActive />} />
                <Route path="/Single-Bussiness-Customer-Plan-Pending" element={<SingleBussinessinvestmentsPending />} />
                <Route path="/Single-Bussiness-Customer-Plan-Expired" element={<SingleBussinessinvestmentsExpired />} />
                {/* ---------------------------------------------------------------------------------------------------- */}
                <Route path="/Add-Employee-Transaction" element={<AddEmployeeTransaction />} />
                <Route path="/TransactionView" element={<TransactionView />} />
                <Route path="/Employee-Salary-Shlip-details" element={<EmployeeSalaryShlipdetails />} />
                <Route path="/Employee-Transaction-details" element={<EmployeeTransactiondetails />} />
                <Route path="/Employee-Transaction-detailsSuccess" element={<EmployeeTransactiondetailsSuccess />} />
                <Route path="/Employee-Transaction-detailsFaild" element={<EmployeeTransactiondetailsFaild />} />
                <Route path="/Employee-Transaction-detailsPending" element={<EmployeeTransactiondetailsPending />} /> 
              {/* -------------------------------------------------------------------------------------------------- */}
              <Route path="/Company-Active-Bussiness-Customer" element={<SingleBCompanyActiveBussiness />} />
              <Route path="/Company-Inactive-Bussiness-Customer" element={<SingleBCompanyInctiveBussiness />} />
              <Route path="/Company-Bussiness-Customer" element={<SingleBCompanyBussinessCustomer />} />
              {/* -------------------------------------------------------------------------------------------------- */}
              <Route path="/Company-Active-Normal-Customer" element={<SingleCompanyActiveCustomer />} />
              <Route path="/Company-Inactive-Normal-Customer" element={<SingleCompanyInactiveCustomer />} />
              <Route path="/Company-Normal-Customer" element={<SingleCompanyCustomer />} />
              <Route path="/Company-Normal-Customer-company" element={<Addsmalladmins_customer_c />} />

              {/* -------------------------------------------------------------------------------------------------- */}
              <Route path="/PL-management" element={<Profitandlossmangement />} />
              <Route path="/ViewsPLIntra" element={<ViewsPLIntra />} />
              <Route path="/Straties" element={<Straties />} />
              <Route path="/AddStrategies" element={<AddStrategies />} />
              <Route path="/EditViesStraties" element={<EditViesStraties />} />
              <Route path="/ViesStraties" element={<ViesStraties />} />
              <Route path="/AddPL" element={<AddPL />} />
              <Route path="/AddBill" element={<AddBill />} />
              <Route path="/AddBills" element={<AddBills />} />
              <Route path="/EditPLIndtra" element={<EditPLIndtra />} />
              
               {/* ---------------------------------------------------------------------------------------------- */}
                <Route path="/Active-Customer" element={<ActiveCustomer />} />
                <Route path="/Inactive-Customer" element={<InactiveCustomer />} />

                {/* <Route path="/Customer-investments" element={<Customerinvestments />} /> */}
                <Route path="/Bussiness-Customer-management" element={<BussinessCustomermanagement />} />                 
                <Route path="/Employeemanagement" element={<Employeemanagement />} />               
                <Route path="/aboutus" element={<Aboutus />} />            
                <Route path="/Settings" element={<Settings />} />
                <Route path="/Addsymbol" element={<Addsymbol />} />
                <Route path="/Newaddsymbol" element={<Newaddsymbol />} />
                <Route path="/Edidsymbol" element={<Edidsymbol />} />
                <Route path="/Veissymbol" element={<Veissymbol />} />
                <Route path="/Defaultlotsize" element={<Defaultlotsize />} />
                <Route path="/Adddefaultlotsize" element={<Adddefaultlotsize />} />
                <Route path="/AditDefaultlotsize" element={<AditDefaultlotsize />} />
                <Route path="/ViesDefaultlotsize" element={<ViesDefaultlotsize />} />
                <Route path="/AddHedge" element={<AddHedge />} />
                <Route path="/AddHadgeadd" element={<AddHadgeadd />} />
                <Route path="/AddNakedadd" element={<AddNakedadd  />} />
                <Route path="/AddNakedBuySell" element={<AddNakedBuySell />} />
                <Route path="/ViesNaked" element={<ViesNaked />} />
                <Route path="/ViesNakedpotionn" element={<ViesNakedpotionn />} />
                <Route path="/ViewsHadge" element={<ViewsHadge />} />
                <Route path="/EditNakedBuySell" element={<EditNakedBuySell />} />
                <Route path="/EditHadge" element={<EditHadge />} />
                <Route path="/AddNakedPrize" element={<AddNakedPrize />} />
                <Route path="/EditNakedPrize" element={<EditNakedPrize />} />
                <Route path="/EditHedgePrize" element={<EditHedgePrize />} />
                <Route path="/AddHedgePrize" element={<AddHedgePrize />} />
                <Route path="/EditPL" element={<EditPL />} />
                <Route path="/ViewsPL" element={<ViewsPL />} />
                <Route path="/Addintraday" element={<Addintraday />} />
                <Route path="/EditIntra" element={<Updateintraday />} />
                <Route
                  path="/termsandcondition"
                  element={<Termsandcondition />}
                />
            
           
              </Routes>
              </Container>
            </main>
          </div>
          <Footer />
          </>
        }/>
        </Routes>
              
        </Router>
      {/* </Container> */}
    </React.Fragment>
  );
}
export default App;
