import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import AddEmployee from './../employeemanagement/Addemployee copy';

export default function AddNormalCustomerTransaction() {
  const navigate = useNavigate();
  const broker_id = localStorage.getItem("broker_id");
  const employee_idTransaction = localStorage.getItem("employee_idTransaction");
  const customer_id = localStorage.getItem("customer_id");
  const cid = localStorage.getItem("cid");
  const c_id = localStorage.getItem("c_id");

  const [transactionAmount, setTransactionAmount] = useState("");
  const [transactionId, setTransactionId] = useState("");
  const [fileimage, setFileimage] = useState("");
  const [BaseQrUrl, setBaseQrUrl] = useState("");
  const [BaseQrImage, setBaseQrImage] = useState("");
  const [newImage, setNewImage] = useState(null);
  const [paymentType, setPaymentType] = useState("");
  const [type, setType] = useState("online");
  const [paymentMode, setPaymentMode] = useState("");
  const [billId, setBillId] = useState("");
  const [utrNo, setUtrNo] = useState("");
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isOnline, setIsOnline] = useState(true);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    let formattedValue = value;

    if (name === "transaction_amount") {
      if (isNaN(value) || value.length > 12) return;
      formattedValue = value.replace(/[^0-9]/g, "");
    } else if (name === "transaction_id") {
      formattedValue = value.toUpperCase().replace(/[^A-Z0-9]/g, "").slice(0, 20);
    } 
    // else if (name === "transaction_id") {
    //   formattedValue = value.replace(/[^a-zA-Z0-9]/g, "").slice(0, 15);
    // } 
    // else if (name === "utr_no") {
    //   formattedValue = value.replace(/[^a-zA-Z0-9]/g, "").slice(0, 15);
    // }

    setErrors((prevErrors) => ({ ...prevErrors, [name]: null }));

    if (name === "transaction_amount") {
      setTransactionAmount(formattedValue);
    } else if (name === "transaction_id") {
      setTransactionId(formattedValue);
    } 
    // else if (name === "bill_id") {
    //   setBillId(formattedValue);
    // } 
    // else if (name === "utr_no") {
    //   setUtrNo(formattedValue);
    // }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFileimage(URL.createObjectURL(file));
    setNewImage(file);
    setErrors((prevErrors) => ({ ...prevErrors, trnx_image: null }));
  };

  // Payment Mode Handler
  const handlePaymentModeChange = (isOnlineMode) => {
    setType(isOnlineMode ? "online" : "offline");
    setIsOnline(isOnlineMode);
  };

  const handlePaymentTypeChange = (e) => {
    setPaymentType(e.target.value);
    setErrors((prevErrors) => ({ ...prevErrors, payment_type: null }));
    
  };
  const handlePaymentModeChange1 = (e) => {
    setPaymentMode(e.target.value);
    setErrors((prevErrors) => ({ ...prevErrors, payment_mode: null }));
  };

  const handleValidation = () => {
    let validationErrors = {};
    let hasError = false;

    if (!transactionAmount) {
      validationErrors.transaction_amount = "Transaction amount is required";
      hasError = true;
    } else if (isNaN(transactionAmount) || transactionAmount <= 0 || transactionAmount.length > 12) {
      validationErrors.transaction_amount = "Transaction amount must be a positive number and not exceed 12 digits";
      hasError = true;
    }

    if (isOnline && !transactionId) {
      validationErrors.transaction_id = "Transaction ID is required for online mode";
      hasError = true;
    } else if (isOnline && !/^[a-zA-Z0-9]+$/.test(transactionId)) {
      validationErrors.transaction_id = "Transaction ID must be alphanumeric";
      hasError = true;
    }

    // if (!isOnline && !billId) {
    //   validationErrors.bill_id = "Bill ID is required for offline mode";
    //   hasError = true;
    // }

    if (!newImage) {
      validationErrors.trnx_image = "Transaction image is required";
      hasError = true;
    }

    if (!paymentType) {
      validationErrors.payment_type = "Payment type is required";
      hasError = true;
    }

    if (!paymentMode) {
      validationErrors.payment_mode = "Payment mode is required";
      hasError = true;
    }
    if (!type) {
      validationErrors.type = "Payment type is required";
      hasError = true;
    }

    setErrors(validationErrors);
    return !hasError;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem("bear_token");

    if (!handleValidation()) {
      return;
    }

    setIsSubmitting(true);

    const dataUpdate = new FormData();
    dataUpdate.append("smalladmins_id", broker_id);
   
    dataUpdate.append("emp_id", employee_idTransaction);
 

    dataUpdate.append("transaction_amount", transactionAmount);
  

    if (isOnline) {
      dataUpdate.append("transaction_id", transactionId);
      dataUpdate.append("type", "online");
      dataUpdate.append("payment_mode", "online");
    } else {
      dataUpdate.append("transaction_id", transactionId);
      dataUpdate.append("type", "offline");
      dataUpdate.append("payment_mode", "offline");
    }

    dataUpdate.append("trnx_image", newImage);
    dataUpdate.append("payment_type", paymentType);
    dataUpdate.append("payment_mode_type", paymentMode);
    // dataUpdate.append("utr_no", utrNo);
   
    // dataUpdate.append("payment_mode", paymentMode);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}add-small-admins-employee-transactions`,
        // `${process.env.REACT_APP_API_URL}add-normal-customers-transactions`,
        dataUpdate,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data.status === 1) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Normal Customer Transaction Added Successfully",
        });
        setTimeout(() => {
          navigate("/Employee-Transaction-details");
        //    if(c_id){

        //     window.location.href = "/Customermanagement";
        //   }
        //  else if (cid) {
            
        //     window.location.href = "/Add-More-plan"; 
        //   } else {
        //     window.location.href = "/Customer-Transaction-Details";
         
        //   }
        //   localStorage.removeItem('c_id');
        //   localStorage.removeItem('cid');
    }, 2000);
       
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: response.data.message,
        });

        setIsSubmitting(false);
      }
    } catch (error) {
      console.error("Error:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "An error occurred while submitting the form.",
      });

      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    getProfile();
  }, []);

  const getProfile = async () => {
    try {
      const formData = new FormData();
      formData.append("smalladmins_id", broker_id);
      const token = localStorage.getItem("bear_token");
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}show-small-admins-Profile`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const bsLoginAdminQRImage = response.data.QRImageURL;
      setBaseQrUrl(bsLoginAdminQRImage);
      const bsimgLoginAdminQRImage = response.data.LoginAdminQRImage.small_admins_QR_img;
      setBaseQrImage(bsimgLoginAdminQRImage);
    } catch (error) {
      console.error("Error fetching user profile:", error);
    }
  };

  return (
    <>
      <div className="card mb-3">
        <div
          className="bg-holder d-none d-lg-block bg-card"
          style={{
            backgroundImage:
              "url(../../assets/img/icons/spot-illustrations/corner-4.png)",
          }}
        />
        <div className="card-body position-relative">
          <div className="row">
            <div className="col-lg-8">
              <h3 className="mb-0">Add Employee Transaction</h3>
            </div>
            <div className="col-lg-4 text-end">
              <Link to="/Customer-Transaction-Details" className="">
                <button className="btn btn-success">Back</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-body">
          <form className="row g-3" onSubmit={handleSubmit}>
            <div className="col-md-12">
              <div className="form-group local-forms d-flex align-items-center">
                <label className="w-25">
                  Payment Mode <span className="text-danger">*</span> :
                </label>
                <div className="d-flex w-75">
                  <button
                    type="button"
                    className={`btn ${isOnline ? "btn-primary" : "btn-outline-primary"}`}
                    onClick={() => handlePaymentModeChange(true)}
                  >
                    Online
                  </button>
                  <button
                    type="button"
                    className={`btn mx-1 ${!isOnline ? "btn-primary" : "btn-outline-primary"}`}
                    onClick={() => handlePaymentModeChange(false)}
                  >
                    Offline
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-12">
            <div className="form-group local-forms d-flex align-items-center">
              <label htmlFor="transaction_amount" className="w-25">Transaction Amount <span className="text-danger">*</span> : </label>
              <input
                type="text"
                className={`form-control w-75 ${errors.transaction_amount ? "is-invalid" : ""}`}
                id="transaction_amount"
                name="transaction_amount"
                value={transactionAmount}
                onChange={handleInputChange}
              />
            
            </div>
            </div>
            {isOnline && (
              <>
              <div className="col-md-12">
                     <div className="form-group local-forms d-flex align-items-center">
                <label htmlFor="transaction_id" className="w-25">Transaction ID <span className="text-danger">*</span> : </label>
                <input
                  type="text"
                  className={`form-control w-75 ${errors.transaction_id ? "is-invalid" : ""}`}
                  id="transaction_id"
                  name="transaction_id"
                  value={transactionId}
                  onChange={handleInputChange}
                />
              
              </div>
              </div>
          
              </>
            )}
            {!isOnline && (
              <div className="col-md-12">
                 <div className="form-group local-forms d-flex align-items-center">
                <label htmlFor="transaction_id" className="w-25">Bill ID <span className="text-danger">*</span> : </label>
                <input
                  type="text"
                  className={`form-control w-75 ${errors.transaction_id ? "is-invalid" : ""}`}
                  id="transaction_id"
                  name="transaction_id"
                  value={transactionId}
                  onChange={handleInputChange}
                />
              
              </div>
              </div>
            )}
            <div className="col-md-12">
            <div className="form-group local-forms d-flex align-items-center">
              <label htmlFor="payment_type" className="w-25"> Payment Type <span className="text-danger">*</span> : </label>
              <select
  className={`form-select w-75 ${
    errors.payment_type ? "input-error" : ""
  }`}
  id="payment_type"
  name="payment_type"
  value={paymentType}
  onChange={handlePaymentTypeChange}
  style={{
    color: paymentType === "" ? "#b0bcc7" : "#000000", // Secondary color for placeholder, dark color for other options
  }}
>
  <option value=""  className="text-dark" disabled>
    Select Payment Type
  </option>
  <option value="credit" className="text-dark">
    Credit
  </option>
  <option value="debit" className="text-dark">
    Debit
  </option>
</select>

            </div>
            </div>
            <div className="col-md-12">
              <div className="form-group local-forms d-flex align-items-center">
                <label className="w-25">
                 Payment Mode <span className="text-danger">*</span> :
                </label>
                <select
  className={`form-select w-75 ${
    errors.payment_mode ? "input-error" : ""
  }`}
  name="payment_mode"
  value={paymentMode}
  onChange={handlePaymentModeChange1}
  style={{
    color: paymentMode === "" ? "#b0bcc7" : "#000000", // Secondary color for placeholder, dark color for other options
  }}
>
  <option
    value=""
    className="text-dark" disabled
  >
    Select Payment Mode
  </option>
  {!isOnline && (
    <>
      <option value="cash" className="text-dark">Cash</option>
    </>
  )}
  {isOnline && (
    <>
      <option value="upi" className="text-dark">UPI</option>
      <option value="neft" className="text-dark">NEFT</option>
      <option value="rtgs"className="text-dark">RTGS</option>
    </>
  )}
</select>

              </div>
            </div>
            <div className="col-md-12">
            <div className="form-group local-forms d-flex align-items-center">
              <label htmlFor="trnx_image" className="w-25">Transaction Image <span className="text-danger">*</span> : </label>
              <input
                type="file"
                className={`form-control w-75 ${errors.trnx_image ? "is-invalid" : ""}`}
                id="trnx_image"
                name="trnx_image"
                onChange={handleFileChange}
              />
         
            </div>
            </div>
            {fileimage && (
              <div className="row mt-3">
                <p className="col-3"></p>
                <div className="col-9">
                  <img
                    src={fileimage}
                    alt="Selected transaction"
                    style={{ height: "150px", width: "150px" }}
                  />
                </div>
              </div>
            )}
            <div className="col-md-12 text-end">
              <button
                type="submit"
                className="btn btn-primary"
                disabled={isSubmitting}
              >
                {isSubmitting ? "Submitting..." : "Submit"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
