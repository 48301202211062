import React, { useState,useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";

export default function AddStrategies() {
  const navigate = useNavigate();
  const broker_id = localStorage.getItem("broker_id");
  const editNakedBuySell_id = localStorage.getItem("EditNakedPrize_id");
  const AddNakedBuySell_id = localStorage.getItem("ViesNaked");
  const [formData, setFormData] = useState({
    company_id: "",
    date_from: "",
    date_to: "",
    default_lot_price: "",
    default_lot_price_id: "",
    naked_default_lot_size: "",
    hedge_default_lot_size: "",
    strateigs_id: "",
    symbol_id: "",
    buy_price: "",
    sell_price:"",
    status:1,
  });
  const [formData2, setFormData2] = useState({
    buy_price: "",
    sell_price:"",
 
  });
  useEffect(() => {
    fetchData1();
  }, []);

  const fetchData1 = async () => {
    try {
      const token = localStorage.getItem("bear_token");
      const customer_id = localStorage.getItem("ViesNaked");

      const formData = new FormData();
      formData.append("smalladmins_id", broker_id);
      formData.append("id", AddNakedBuySell_id);
      formData.append("status", "1");

      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}small_admins_view_company_nakedHedgePL`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      };

      const response = await axios(config);
      const data = response.data.data;
      setFormData(data[0]);
      await fetchData2(

        data[0].company_id,
        data[0].strateigs_id,
        data[0].symbol_id,
        data[0].naked_default_lot_size,
      
      );
      console.warn("data",data)
    } catch (error) {
      console.error("Error fetching user broker:", error);
    }
  };
  const fetchData2 = async (company_id,strateigs_id,symbol_id,naked_default_lot_size) => {
    try {
      const token = localStorage.getItem("bear_token");


      const form1Data = new FormData();
      form1Data.append("smalladmins_id", broker_id);
      form1Data.append("id", editNakedBuySell_id);
      form1Data.append("status", "1");
      form1Data.append("company_naked_hedgePL_id",AddNakedBuySell_id);
      form1Data.append("company_id", company_id);
      form1Data.append("strateigs_id", strateigs_id);
      form1Data.append("symbol_id", symbol_id);
      form1Data.append("naked_default_lot_size", naked_default_lot_size);
 

      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}small-admins-nakedbuysell-view`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
        data: form1Data,
      };

      const response = await axios(config);
      const data = response.data.Data;
   
      setFormData2(data[0]);
     
    } catch (error) {
      console.error("Error fetching user broker:", error);
    }
  };
  const [profile, setProfile] = useState(null);
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    // const { id, value } = e.target;
    const { id, value, name } = e.target;
    if (name === "buy_price" || name === "sell_price") {
      if (value.length > 10 || isNaN(value)) {
        return;
      }
    }
    setFormData2({ ...formData2, [id]: value });
    setFormData({ ...formData, [id]: value });
    setErrors((prevErrors) => ({ ...prevErrors, [id]: null }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setProfile(file);
    }
  };

  const handleValidation = () => {
    const requiredFields = [
      // { field: "buy_price", name: "Buy Price" },
      // { field: "sell_price", name: "Sell Price" },
    ];
    let validationErrors = {};
    let hasError = false;

    requiredFields.forEach(({ field, name }) => {
      if (!formData[field]) {
        validationErrors[field] = `${name} is required`;
        hasError = true;
      }
    });

    setErrors(validationErrors);
    return !hasError;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!handleValidation()) {
      return;
    }

    const token = localStorage.getItem("bear_token");

    const updatedFormData = new FormData();
      updatedFormData.append("smalladmins_id", broker_id);
      updatedFormData.append("company_naked_hedgePL_id", AddNakedBuySell_id);
      updatedFormData.append("id", editNakedBuySell_id);
      updatedFormData.append("buy_price", formData2.buy_price);
      updatedFormData.append("sell_price", formData2.sell_price);
      updatedFormData.append("naked_default_lot_size", formData.naked_default_lot_size);
      updatedFormData.append("company_id", formData.company_id);
      updatedFormData.append("strateigs_id", formData.strateigs_id);
      updatedFormData.append("symbol_id", formData.symbol_id);
      updatedFormData.append("status", "1");

      // Object.entries(formData).forEach(([key, value]) => {
      //   if (value !== null && value !== "" && value !== "null") {
      //     if (key !== "strateigs_name" && key !== "symbol_name" && key !== "company_name" && key !=="buy_price" &&  key !=="buy_price") {
      //       updatedFormData.append(key, value);
      //     }
      //   }
      // });

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}small-admins-nakedbuysell-edit`,
        updatedFormData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 201) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: response.data.message,
          // timer: 1500,
        });

        setTimeout(() => {
          navigate("/ViesNaked"); 
        }, 2000);
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: response.data.message || "Unknown error occurred",
          // timer: 2000,
        });
      }
    } catch (error) {
      console.error("Error:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "An error occurred while submitting the form.",
        // timer: 2000,
      });
    }
  };

  return (
    <>
         <div className="card mb-3">
                <div
                    className="bg-holder d-none d-lg-block bg-card"
                    style={{
                        backgroundImage:
                            "url(../../assets/img/icons/spot-illustrations/corner-4.png)",
                    }}
                />
                <div className="card-body position-relative">
                    <div className="row">
                        <div className="col-lg-8">
                            <h3 className="mb-0">Update Naked </h3>
                        </div>
                        <div className="col-lg-4 text-end">
                            <Link to="/ViesNaked" className="">
                                <button className="btn btn-success">Back</button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
      <div className="card">
        <div className="card-body">
          <form className="row g-3" onSubmit={handleSubmit}>
       
                      <div className="col-md-6 form_details">
                            <div className="form-group local-forms">
                                <label>
                                Sell Price <span className="login-danger"></span>
                                </label>
                                <input
                                    className={`form-control ${errors.sell_price ? "input-error" : ""}`}
                                    id="sell_price"
                                    type="text"
                                        name="sell_price"
                                    value={formData2.sell_price}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <div className="col-md-6 form_details">
                            <div className="form-group local-forms">
                                <label>
                                Buy Price <span className="login-danger"></span>
                                </label>
                                <input
                                    className={`form-control ${errors.buy_price ? "input-error" : ""}`}
                                    id="buy_price"
                                    type="text"
                                     name="buy_price"
                                    value={formData2.buy_price}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
       
            <div className="col-12 d-flex justify-content-end">
              {/* Submit button */}
              <button className="btn btn-primary" type="submit">
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
