import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { FaRegEdit, FaEye, FaSearch } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import { IoMdAdd } from "react-icons/io";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { MdAutoGraph } from "react-icons/md";
import company from "../../assets/img/icons/building.png";
import { MdBusinessCenter } from "react-icons/md";
import { SiSmartthings } from "react-icons/si";
import { MdAttachMoney, MdShowChart } from 'react-icons/md';
import { BsGraphUpArrow,BsGraphDownArrow } from "react-icons/bs";
import { GoGraph } from "react-icons/go";

export default function Straties() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [baseurl, setbaseurl] = useState();
  const [Allcompany, setCompany] = useState();
  const [Activecompany, setActivecompany] = useState();
  const [Inactivecompany, setInactivecompany] = useState();
     console.warn(Inactivecompany)
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);

  const broker_id = localStorage.getItem("broker_id");
  const token = localStorage.getItem("bear_token");



  const [totalSwanHedge, setTotalSwanHedge] = useState(0);
//   console.warn(totalSwanHedge)
  const [totalActiveSwanHedge, setTotalActiveSwanHedge] = useState(0);
  const [totalInActiveSwanHedge, setTotalInActiveSwanHedge] = useState(0);
  const [NetProofitLossSwanHedge, setNetProofitLossSwanHedge] = useState(0);

  const fetchData = async () => {
    try {
      setLoading(true);
  
      const token = localStorage.getItem("bear_token");
      const broker_id = localStorage.getItem("broker_id");
  
      const params = {
        smalladmins_id: broker_id,
        status: "1",
      };
  
      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}small-admins-hedge-lists`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        params: params,
      };
  
      const response = await axios(config);
      console.warn(response);
  
      setData(response.data.data);
      setTotalSwanHedge(response.data.tradeprizeperorder);
      setTotalActiveSwanHedge(response.data.total_no_of_TradesCount);
      setTotalInActiveSwanHedge(response.data.grossProofitLoss);
      setNetProofitLossSwanHedge(response.data.NetProofitLoss);
      
      
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };
  

  useEffect(() => {
    fetchData();
  }, []); // Empty dependenc
  const filteredData = data ? data.filter((item) =>
    item.name && item.name.toLowerCase().includes(searchTerm.toLowerCase())
  ):[];

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedData = filteredData.slice(startIndex, endIndex);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const Delete = async (id) => {
    console.warn(id);
    const token = localStorage.getItem("bear_token");
    const broker_id = localStorage.getItem("broker_id");
  
    if (!id) {
      console.error("ID is required");
      return;
    }
  
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      });
  
      if (result.isConfirmed) {
        setLoading(true);
  
        const formData = new FormData();
        formData.append("smalladmins_id", broker_id);
        formData.append("id", id);  
        formData.append("status", "1");
  
        const config = {
          method: "post",
          url: `${process.env.REACT_APP_API_URL}small-admins-delete-hedge`,
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
          data: formData,
        };
  
        const response = await axios(config);
        const data = response.data.status;
  
        if (data === 1) {
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: response.data.message,
            // timer: 1500,
          });
  
          fetchData();
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: response.data.message,
            // timer: 2000,
          });
        }
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };
  
  
  
  const handleStatusChange = async (business_id, currentStatus) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, change status!",
      });

      if (result.isConfirmed) {
        setLoading(true);

        const formData = new FormData();
        formData.append("smalladmins_id", broker_id);
        formData.append("id", business_id);
        formData.append("status", currentStatus === 1 ? 0 : 1); 

        const config = {
          method: "post",
          url: `${process.env.REACT_APP_API_URL}small-admins-updatehedge-status`,
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
          data: formData,
        };

        const response = await axios(config);
        const data = response.data.status;

        if (data === 1) {
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: "Status updated successfully!",
            // timer: 1500
          });
      
          
          fetchData(); // Refresh data after status change
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: response.data.message,
            // timer: 2000
          });
        }

        setLoading(false);
      }
    } catch (error) {
      console.error("Error changing status:", error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: "There was an error changing the status.",
        // timer: 2000
      });
      setLoading(false);
    }
  };

 

  const handleAddbusiness = (id,name) => {
    localStorage.setItem("company_id", id);
    localStorage.setItem("company_Name", name);
    navigate("/Addbusiness");
  };

  const HandleAddadminscustomer = (id,name) => {
    localStorage.setItem("company_id", id);
    localStorage.setItem("company_Name", name);
    navigate("/Add-admins-customer");
  };

  
  const handleClickEditCompanyId = (id) => {
    localStorage.setItem("EditHadget_id", id);
    navigate("/EditHadge");
  };

  const handleClickCompanyId = (id) => {
    localStorage.setItem("ViewsHadge", id);
    navigate("/ViewsHadge");
  };
 
  return (
    <>
      <div className="card mb-3">
        <div
          className="bg-holder d-none d-lg-block bg-card"
          style={{
            backgroundImage:
              "url(../../assets/img/icons/spot-illustrations/corner-4.png)"
          }}
        />
        {/*/.bg-holder*/}
        <div className="card-body position-relative">
          <div className="row">
            <div className="col-lg-8">
              <h3 className="mb-0">Add Hedge Analytics</h3>
            </div>
            <div className="col-lg-4 text-end">
              <Link to="/" className="">
                <button className="btn btn-success">Back</button>
              </Link>
            </div>
          </div>
        </div>

      </div>
      <div className="row g-3 mb-3">
        <div className="col-md-3 col-xxl-3">
      <Link to='#'     
            className={`linkWithoutUnderline active`}>
          <div className="card h-md-100 ecommerce-card-min-width">
            <div className="card-header pb-0">
              <h5 className="mb-0 mt-2 d-flex align-items-center">
              Trade prize per order
              
              </h5>
            </div>
            <div className="card-body d-flex flex-column justify-content-end">
              <div className="row align-items-center">
                <div className="col pe-0">
                  <MdAttachMoney className="fs-6 text-primary" />
                </div>
                <div className="col ps-0">
                  <p className="font-sans-serif lh-1 mb-1 fs-5 text-primary">
                    {totalSwanHedge}
                  </p>
                </div>
                <div className="col-auto ps-0">
                  <div className="echart-bar-weekly-sales h-100" />
                </div>
              </div>
            </div>
          </div>
        </Link>
        </div>
        <div className="col-md-3 col-xxl-3">
        <Link to='#' className="linkWithoutUnderline">
          <div className="card h-md-100">
            <div className="card-header pb-0">
              <h5 className="mb-0 mt-2"> Total no of Trades</h5>
            </div>
            <div className="card-body d-flex flex-column justify-content-end">
              <div className="row justify-content-between">
                <div className="col pe-0">
                  <GoGraph className="fs-6 text-success" />
                </div>
                <div className="col ps-0">
                  <div className="fs-5 fw-normal font-sans-serif text-700 lh-1 mb-1 ">
                    <p className="font-sans-serif lh-1 mb-1 fs-5 text-success">
                      {totalActiveSwanHedge}
                    </p>
                  </div>
                </div>
               
              </div>
            </div>
          </div>
        </Link>
        </div>
        <div className="col-md-3 col-xxl-3">
        <Link to='#' className="linkWithoutUnderline">

          <div className="card h-md-100">
            <div className="card-header pb-0">
              <h5 className="mb-0 mt-2">  Gross Profit & Loss</h5>
            </div>
            <div className="card-body d-flex flex-column justify-content-end">
              <div className="row justify-content-between">
                <div className="col pe-0">
                  <BsGraphUpArrow className="fs-6 text-info" />
                </div>
                <div className="col ps-0">
                  <div className="fs-5 fw-normal font-sans-serif text-700 lh-1 mb-1">
                    <p className="font-sans-serif lh-1 mb-1 fs-5  text-info">
                      {totalInActiveSwanHedge}
                    </p>
                  </div>
                </div>
               
              </div>
            </div>
          </div>
        </Link>
        </div>
        <div className="col-md-3 col-xxl-3">
        <Link to='#' className="linkWithoutUnderline">

          <div className="card h-md-100">
            <div className="card-header pb-0">
              <h5 className="mb-0 mt-2"> Net Proofit And Loss</h5>
            </div>
            <div className="card-body d-flex flex-column justify-content-end">
              <div className="row justify-content-between">
                <div className="col pe-0">
                  <BsGraphDownArrow className="fs-6 text-danger" />
                </div>
                <div className="col ps-0">
                  <div className="fs-5 fw-normal font-sans-serif text-700 lh-1 mb-1">
                    <p className="font-sans-serif lh-1 mb-1 fs-5  text-danger">
                      {NetProofitLossSwanHedge}
                    </p>
                  </div>
                </div>
               
              </div>
            </div>
          </div>
        </Link>
        </div>
      </div>
      <div className="card mb-3">
        <div className="card-body position-relative">
          <div className="row">
            <div className="col-lg-8">
              <h3 className="mb-0">Add Hedge List</h3>
            </div>
            <div className="col-md-4 d-flex justify-content-end">
              <Link to="/AddHadgeadd" className="btn btn-sm btn-success">
              <IoMdAdd className="me-1 fs--2" />   Add Hedge
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="card" id="ticketsTable">
        <div className="card-header w-100 border-bottom border-200">
          <div className="justify-content-end d-flex w-100">
            <div className="justify-content-end mt-1 d-flex w-100">
              <form>
                <div className="input-group input-search-width">
                  <input
                    className="form-control form-control-sm shadow-none search"
                    type="search"
                    placeholder="Search by name"
                    aria-label="search"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="card-body">
          <div className="table-responsive scrollbar">
          <table className="table mb-0 table-view-tickets">
            <thead className="bg-body-tertiary">
  <tr className="thead-dark">
    <th className="">Sr.</th>
    <th className="text-800 sort align-middle" data-sort="company_name">
      Company
    </th>
    <th className="text-800 sort align-middle" data-sort="strateigs_name">
      Strategy
    </th>
    <th className="text-800 sort align-middle" data-sort="symbol_name">
      Symbol
    </th>
    <th className="text-800 sort align-middle" data-sort="symbol_name">
      Strike
    </th>
    <th className="text-800 sort align-middle" data-sort="buy_price">
      Buy 
    </th>
    <th className="text-800 sort align-middle" data-sort="sell_price">
      Sell 
    </th>
    <th className="text-800 sort align-middle" data-sort="different_amt">
      Diff. Amount
    </th>
    <th className="text-800 sort align-middle" data-sort="profit_loss_amt">
      P/L Amount
    </th>
    <th className="text-800 sort align-middle" data-sort="status">
      Status
    </th>
    <th className="text-800 sort align-middle" data-sort="addbusiness">
      Action
    </th>
  </tr>
</thead>

              <tbody className="list">
  {loading && (
    <tr>
      <td colSpan="7" className="text-center py-4">
        <div id="preloader">
          <div id="loading-center">
            <div className="loader">
              <div className="waviy">
                <img
                  src="https://swaninvestment.in/frontant/assets/img/loader.png"
                  alt="Loader image"
                />
              </div>
            </div>
          </div>
        </div>
      </td>
    </tr>
  )}
  {data.length > 0 ? (
    data.map((item, index) => (
      <tr key={index} className="">
        <td className="align-middle client white-space-nowrap">
          <div className="d-flex align-items-center gap-2 position-relative">
            <div>
              <span>{index + 1}</span>
            </div>
          </div>
        </td>
        <td className="align-middle priority pe-4">
          <div className="d-flex align-items-center gap-2">
            <h6 className="mb-0 text-700 text-transform-capitalize">
              {item?.company_name ?? "No Name"}
            </h6>
          </div>
        </td>
        <td className="align-middle priority pe-4">
          <div className="d-flex align-items-center gap-2">
            <h6 className="mb-0 text-700 text-transform-capitalize">
              {item?.strateigs_name ?? "No Name"}
            </h6>
          </div>
        </td>
        <td className="align-middle priority pe-4">
          <div className="d-flex align-items-center gap-2">
            <h6 className="mb-0 text-700 text-transform-capitalize">
              {item?.symbol_name ?? "No Name"}
            </h6>
          </div>
        </td>
        <td className="align-middle priority pe-4">
          <div className="d-flex align-items-center gap-2">
            <h6 className="mb-0 text-700 text-transform-capitalize">
              {item?.strike_price ?? "No Name"}
            </h6>
          </div>
        </td>
        <td className="align-middle priority pe-4">
          <div className="d-flex align-items-center gap-2">
            <h6 className="mb-0 text-700 text-transform-capitalize">
              {item?.buy_price ?? "No Price"}
            </h6>
          </div>
        </td>
        <td className="align-middle priority pe-4">
          <div className="d-flex align-items-center gap-2">
            <h6 className="mb-0 text-700 text-transform-capitalize">
              {item?.sell_price ?? "No Price"}
            </h6>
          </div>
        </td>
        <td className="align-middle priority pe-4">
          <div className="d-flex align-items-center gap-2">
            <h6 className="mb-0 text-700 text-transform-capitalize">
              {item?.different_amt ?? "No Amount"}
            </h6>
          </div>
        </td>
        <td className="align-middle priority pe-4">
          <div className="d-flex align-items-center gap-2">
            <h6 className="mb-0 text-700 text-transform-capitalize">
              {item?.profit_loss_amt ?? "No Amount"}
            </h6>
          </div>
        </td>
        <td>
                        <div className="form-check form-switch">
                          <input
                            className="form-check-input toggle_btn"
                            type="checkbox"
                            role="switch"
                            id={`flexSwitchCheckDefault-${item.id}`}
                            checked={item.status === 1}
                            onChange={() => handleStatusChange(item.id, item.status)}
                            disabled={loading} 
                          />
                        </div>
                      </td>
        <td className="align-middle priority pe-4">
                        <div className="d-flex gap-1 action_button">
                          <OverlayTrigger
                            key="Edit"
                            placement="top"
                            overlay={<Tooltip id={`Edit`}>Edit</Tooltip>}
                          >
                            <button
                              onClick={() => handleClickEditCompanyId(item.id)}
                              style={{ "--i": "#2c7be5" }}
                            >
                              {" "}
                              <FaRegEdit className="color_link" />
                            </button>
                          </OverlayTrigger>
                          <OverlayTrigger
                            key="View"
                            placement="top"
                            overlay={<Tooltip id={`View`}>View</Tooltip>}
                          >
                            <button
                              onClick={() => handleClickCompanyId(item.id)}
                              style={{ "--i": "#27bcfd" }}
                            >
                              <FaEye className="color_link" />
                            </button>
                          </OverlayTrigger>
                          <OverlayTrigger
                            key="Delete"
                            placement="top"
                            overlay={<Tooltip id={`Delete`}>Delete</Tooltip>}
                          >
                            <button
                              style={{ "--i": "red" }}
                              onClick={() => Delete(item.id)}
                            >
                              {" "}
                              <MdDelete className="color_link" />
                            </button>
                        
                          </OverlayTrigger>

                 
                        </div>
                      </td>
      </tr>
    ))
  ) : (
    <tr>
      <td colSpan="8" className="text-center py-4">
        No data found.
      </td>
    </tr>
  )}
</tbody>

            </table>
          </div>
          {totalPages > 1 && (
            <div className="pagination mt-3 mb-3 mx-3">
              <button
                className="btn btn-sm btn-light"
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                <IoIosArrowBack />
              </button>
              {[...Array(totalPages).keys()].map((pageNumber) => (
                <button
                  key={pageNumber + 1}
                  className={`btn btn-sm mx-1 ${currentPage === pageNumber + 1
                    ? "btn-light text-primary"
                    : "btn-light"
                    }`}
                  onClick={() => handlePageChange(pageNumber + 1)}
                >
                  {pageNumber + 1}
                </button>
              ))}
              <button
                className="btn btn-sm btn-light"
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                <IoIosArrowForward />
              </button>
            </div>
          )}
        </div>
      </div>


    </>
  );
}
