import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom';
import Swal from "sweetalert2";

export default function AddHedge() {
    const navigate = useNavigate();
    const broker_id = localStorage.getItem("broker_id");
    const [fileimage, setFileimage] = useState("");
    const [newImage, setNewImage] = useState(null);
    const [formData, setFormData] = useState({
        smalladmins_id: broker_id,
        company_id: "",
        strateigs_id: "",
        symbol_id: "",
        default_lot_price_id: "",
        strike_price: "",
        type: "",
        date_from: "",
        date_to: "",
        sell_price: "",
        buy_price: "",
        status: "1",
    });
    const [profile, setProfile] = useState(null);
    const [errors, setErrors] = useState({});
    const [companies, setCompanies] = useState([]);
    const [strategies, setStrategies] = useState([]);
    const [symbols, setSymbols] = useState([]);
    const [loading, setLoading] = useState(false);
    const [defaultLotSizes, setDefaultLotSizes] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const token = localStorage.getItem("bear_token");

                const formData = new FormData();
                formData.append("smalladmins_id", broker_id);
                formData.append("status", "1");

                const config = {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "multipart/form-data",
                    },
                };

                const [companiesRes, strategiesRes, defaultLotSizesRes, symbolsRes] = await Promise.all([
                    axios.post(`${process.env.REACT_APP_API_URL}small-admins-active-company-lists`, formData, config),
                    axios.post(`${process.env.REACT_APP_API_URL}small-admins-strategies-lists`, { smalladmins_id: broker_id, status: "1" }, config),
                    axios.post(`${process.env.REACT_APP_API_URL}small-admins-defaultsize-lists`, { smalladmins_id: broker_id, status: "1" }, config),
                    axios.post(`${process.env.REACT_APP_API_URL}small-admins-symbols-lists`, { smalladmins_id: broker_id, status: "1" }, config)
                ]);

                setCompanies(companiesRes.data.data);
                setStrategies(strategiesRes.data.data);
                setDefaultLotSizes(defaultLotSizesRes.data.data);
                setSymbols(symbolsRes.data.data);

                setLoading(false);
            } catch (error) {
                console.error("Error fetching data:", error);
                setLoading(false);
            }
        };

        fetchData();
    }, [broker_id]);


    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData({ ...formData, [id]: value });
        setErrors((prevErrors) => ({ ...prevErrors, [id]: null }));
    };

   

    const handleValidation = () => {
        const requiredFields = [
            { field: "company_id", name: "Company ID" },
            { field: "strateigs_id", name: "Strategic ID" },
            { field: "symbol_id", name: "Symbol ID" },
            { field: "default_lot_price_id", name: "Default Lot Price" },
            { field: "strike_price", name: "Strike Price" },
            { field: "type", name: "Type" },
            { field: "date_from", name: "Date From" },
            { field: "date_to", name: "Date To" },
            { field: "sell_price", name: "Sell Price" },
            { field: "buy_price", name: "Buy Price" }
        ];

        let validationErrors = {};
        let hasError = false;

        for (const { field, name } of requiredFields) {
            if (!formData[field]) {
                validationErrors[field] = `${name} is required`;
                hasError = true;
            }
        }

        setErrors(validationErrors);
        return !hasError;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const token = localStorage.getItem("bear_token");

        if (!handleValidation()) {
            return;
        }

        const dataUpdate = new FormData();
        for (const key in formData) {
            dataUpdate.append(key, formData[key]);
        }

        if (newImage) {
            dataUpdate.append("profile", newImage);
        }

        try {
            const config = {
                method: "post",
                url: `${process.env.REACT_APP_API_URL}small-admins-add-hedge`,
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "multipart/form-data",
                },
                data: dataUpdate,
            };

            const response = await axios(config);

            if (response.status === 201) {
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: response.data.message,
                    // timer: 1500
                });

                setTimeout(() => {
                    navigate("/AddHedge");
                }, 2000);
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: response.data.message,
                    // timer: 2000
                });
            }
        } catch (error) {
            console.error("Error:", error);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: "An error occurred while submitting the form.",
                // timer: 2000
            });
        }
    };

    return (
        <>
            <div className="card mb-3">
                <div
                    className="bg-holder d-none d-lg-block bg-card"
                    style={{
                        backgroundImage:
                            "url(../../assets/img/icons/spot-illustrations/corner-4.png)",
                    }}
                />
                <div className="card-body position-relative">
                    <div className="row">
                        <div className="col-lg-8">
                            <h3 className="mb-0">Add Hedge</h3>
                        </div>
                        <div className="col-lg-4 text-end">
                            <Link to="/AddHedge" className="">
                                <button className="btn btn-success">Back</button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card">
                <div className="card-body">
                    <form className="row g-3" onSubmit={handleSubmit}>
                        <div className="col-md-6 form_details">
                            <div className="form-group local-forms">
                                <label>
                                    Company <span className="login-danger">*</span>
                                </label>
                                <select
                                    className={`form-select ${errors.company_id ? "input-error" : ""}`}
                                    id="company_id"
                                    value={formData.company_id}
                                    onChange={handleChange}
                                >
                                    <option value="">Select Company</option>
                                    {companies.map(company => (
                                        <option key={company.id} value={company.id}>
                                            {company.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>

                        <div className="col-md-6 form_details">
                            <div className="form-group local-forms">
                                <label>
                                    Strategic <span className="login-danger">*</span>
                                </label>
                                <select
                                    className={`form-select ${errors.strateigs_id ? "input-error" : ""}`}
                                    id="strateigs_id"
                                    value={formData.strateigs_id}
                                    onChange={handleChange}
                                >
                                    <option value="">Select Strategic</option>
                                    {strategies.map(strategy => (
                                        <option key={strategy.id} value={strategy.id}>
                                            {strategy.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>

                        <div className="col-md-6 form_details">
                            <div className="form-group local-forms">
                                <label>
                                    Symbol <span className="login-danger">*</span>
                                </label>
                                <select
                                    className={`form-select ${errors.symbol_id ? "input-error" : ""}`}
                                    id="symbol_id"
                                    value={formData.symbol_id}
                                    onChange={handleChange}
                                >
                                    <option value="">Select Symbol</option>
                                    {symbols.map(symbol => (
                                        formData.strateigs_id == symbol.strateigs_id &&
                                        <option key={symbol.id} value={symbol.id}>
                                            {symbol.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>

                        <div className="col-md-6 form_details">
                            <div className="form-group local-forms">
                                <label>
                                    Default Lot Price <span className="login-danger">*</span>
                                </label>
                                <select
                                    className={`form-select ${errors.default_lot_price_id ? "input-error" : ""}`}
                                    id="default_lot_price_id"
                                    value={formData.default_lot_price_id}
                                    onChange={handleChange}
                                >
                                    <option value="">Select Default Lot Price</option>
                                    {defaultLotSizes.map(defaultLotSize => (
                                        <option key={defaultLotSize.id} value={defaultLotSize.id}>
                                            {defaultLotSize.default_lot_size}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>

                        <div className="col-md-6 form_details">
                            <div className="form-group local-forms">
                                <label>
                                    Strike Price <span className="login-danger">*</span>
                                </label>
                                <input
                                    className={`form-control ${errors.strike_price ? "input-error" : ""}`}
                                    id="strike_price"
                                    type="text"
                                    value={formData.strike_price}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>

                        <div className="col-md-6 form_details">
                            <div className="form-group local-forms">
                                <label>
                                    Type <span className="login-danger">*</span>
                                </label>
                                <select
                                    className={`form-select ${errors.type ? "input-error" : ""}`}
                                    id="type"
                                    value={formData.type}
                                    onChange={handleChange}
                                >
                                    <option value="">Select Type</option>
                                    <option value="ce">CE</option>
                                    <option value="pe">PE</option>
                                </select>
                            </div>
                        </div>

                        <div className="col-md-6 form_details">
                            <div className="form-group local-forms">
                                <label>
                                    Buy Price <span className="login-danger">*</span>
                                </label>
                                <input
                                    className={`form-control ${errors.buy_price ? "input-error" : ""}`}
                                    id="buy_price"
                                    type="text"
                                    value={formData.buy_price}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>

                        <div className="col-md-6 form_details">
                            <div className="form-group local-forms">
                                <label>
                                    Sell Price <span className="login-danger">*</span>
                                </label>
                                <input
                                    className={`form-control ${errors.sell_price ? "input-error" : ""}`}
                                    id="sell_price"
                                    type="text"
                                    value={formData.sell_price}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>

                        <div className="col-md-6 form_details">
                            <div className="form-group local-forms">
                                <label>
                                    Date From <span className="login-danger">*</span>
                                </label>
                                <input
                                    className={`form-control ${errors.date_from ? "input-error" : ""}`}
                                    id="date_from"
                                    type="date"
                                    value={formData.date_from}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>

                        <div className="col-md-6 form_details">
                            <div className="form-group local-forms">
                                <label>
                                    Date To <span className="login-danger">*</span>
                                </label>
                                <input
                                    className={`form-control ${errors.date_to ? "input-error" : ""}`}
                                    id="date_to"
                                    type="date"
                                    value={formData.date_to}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>

                        <div className="col-12 d-flex justify-content-end">
                            <button className="btn btn-primary" type="submit">
                                Submit
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}
