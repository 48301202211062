import React, { useState, useEffect } from "react";
import axios from "axios";
import { Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
export default function AditDefaultlotsize() {
  const broker_id = localStorage.getItem("broker_id");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [newImage, setNewImage] = useState(null);
  const [fileimage, setFileimage] = useState(null);
  const [errors, setErrors] = useState({});
  const [data, setData] = useState([]);
  const [dataplan, setDataplan] = useState([]);
  const [baseurl, setBaseUrl] = useState("");
  const [viewData, setViewData] = useState({});
  const [formData, setFormData] = useState({
    default_lot_size: "",
    smalladmins_name: "",
    hedge_default_lot_size: "",
    naked_default_lot_size: "",
    strateigs_id: "",
    symbol_id: "",
    strategies_name: "",
    symbol_name: "",
   
  });
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setFileimage(URL.createObjectURL(file));
    setNewImage(file);
    
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "default_lot_size" && (value.length > 10 || isNaN(value))) {
      return;
    }
  

    let formattedValue = value;

 

    setFormData({ ...formData, [name]: formattedValue });

    setErrors((prevErrors) => ({ ...prevErrors, [name]: null }));
  };
  useEffect(() => {
    fetchData1();
  }, []);
  const fetchData1 = async () => {
    try {
      const token = localStorage.getItem("bear_token");
    //   const customer_id = localStorage.getItem("customer_id");
      const customer_id = localStorage.getItem("Stratiesid");

    //   setCustomerId(customer_id); // Set customerId in state

      const formData = new FormData();
      formData.append("smalladmins_id", broker_id);
      formData.append("id", customer_id);
      formData.append("status", "1");

      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}small-admins-view-defaultsize`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      };

      const response = await axios(config);
      const data = response.data.data;
      setFormData(data[0]);
      setViewData(data[0]);
      const dataurl = response.data.url;
      setBaseUrl(dataurl);
    } catch (error) {
      console.error("Error fetching user broker:", error);
    }
  };




  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const requiredFields = [
    ];

    let validationErrors = {};
    let hasError = false;

    for (const { field, name } of requiredFields) {
      if (!formData[field]) {
        validationErrors[field] = `Please fill in the ${name}.`;
        hasError = true;
      }
    }

    setErrors(validationErrors);

    if (hasError) {
      setLoading(false);
      return;
    }

    try {
      const token = localStorage.getItem("bear_token");
      const customer_id = localStorage.getItem("detailsedit_id");

      const updatedFormData = new FormData();
      updatedFormData.append("smalladmins_id", broker_id);
      // updatedFormData.append("id", customer_id);
      // updatedFormData.append("status", "1");

     
      Object.entries(formData).forEach(([key, value]) => {
        if (value !== null && value !== "" && value !== "null") {
          
            updatedFormData.append(key, value);
      
        }
      });

      if (newImage) {
        updatedFormData.append("profile_image", newImage);
      }
      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}small-admins-update-defaultsize`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
        data: updatedFormData,
      };

      const response = await axios(config);

      if (response.data.status === 1) {
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: response.data.message,
          // timer: 1500
        });
    
        setTimeout(() => {
          navigate("/Defaultlotsize");
        }, 2000);
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: response.data.message,
          // timer: 2000
        });
      
      }
    } catch (error) {
      console.warn("Error add-small-update-default size:", error);
   
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: "Failed to update default size",
          // timer: 2000
        });
      
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <div className="card mb-3">
        <div
          className="bg-holder d-none d-lg-block bg-card"
          style={{
            backgroundImage:
              "url(../../assets/img/icons/spot-illustrations/corner-4.png)",
          }}
        />
        <div className="card-body position-relative">
          <div className="row ">
            <div className="col-lg-8">
              <h3 className="mb-0">Update Add Default Lot Size </h3>
            </div>
            <div className="col-lg-4 text-end">
              <Link to="/Defaultlotsize" className="">
                <button className="btn btn-success">Back</button>
              </Link>
            </div>
          </div>
        </div>
      </div>



      <div className="card mb-6">
        <form className="card-body" onSubmit={handleSubmit}>
          {/* <h5>Customer Details</h5> */}
          <div className="row g-3 mt-2">
      
            <div className="col-md-6 form_details mt-3">
              <div className="form-group local-forms">
                <label className="form-label" htmlFor="strategies_name">
                strateigs <span className="login-danger"></span>
                </label>
                <input
                  className={`form-control`}
                  id="name"
                  name="strategies_name"
                  type="text"
                  value={formData.strategies_name}
                  readOnly
                />
              </div>
            </div>
    
            <div className="col-md-6 form_details mt-3">
              <div className="form-group local-forms">
                <label className="form-label" htmlFor="symbol_name">
               Symbol <span className="login-danger"></span>
                </label>
                <input
                  className={`form-control`}
                  id="name"
                   name="symbol_name"
                  type="text"
                  value={formData.symbol_name}
                  readOnly
                />
              </div>
            </div>
       
            <div className="col-md-6 form_details mt-3">
              <div className="form-group local-forms">
                <label className="form-label" htmlFor="default_lot_size">
                Default Lot Size  <span className="login-danger"></span>
                </label>
                <input
                  className={`form-control`}
                  id="name"
                  name="default_lot_size"
                  type="text"
                  value={formData.default_lot_size}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="col-md-6 form_details mt-3">
              <div className="form-group local-forms">
                <label className="form-label" htmlFor="naked_default_lot_size">
             Naked Default Lot Size <span className="login-danger"></span>
                </label>
                <input
                  className={`form-control`}
                  id="name"
                  name="naked_default_lot_size"
                  type="text"
                  value={formData.naked_default_lot_size}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            
            <div className="col-md-6 form_details mt-3">
              <div className="form-group local-forms">
                <label className="form-label" htmlFor="hedge_default_lot_size">
                Hedge Default Lot Size <span className="login-danger"></span>
                </label>
                <input
                  className={`form-control`}
                  id="name"
                  name="hedge_default_lot_size"
                  type="text"
                  value={formData.hedge_default_lot_size}
                  onChange={handleInputChange}
                />
              </div>
            </div>
          

            {/* <div className="col-md-6 form_details mt-3">
              <div className="form-group local-forms">
                <label className="form-label" htmlFor="strategies">
                Small Admins Name <span className="login-danger"></span>
                </label>
                <input
                  className={`form-control`}
                  id="smalladmins_name"
                  name="smalladmins_name"
                  type="text"
                  value={formData.smalladmins_name}
                  onChange={handleInputChange}
                />
              </div>
            </div> */}
            
            </div>



          <div className="row g-3 mt-2">
          

            
            
           
            
           
            
            {/* <div className="col-md-6 form_details">
              <div className="form-group local-forms">
           
                <input
                  className="form-control"
                  id="profile_image"
                  type="file"
          
                  name="profile_image"
                  onChange={handleImageChange}
                />
                {fileimage ? (
                  <img
                    src={fileimage}
                    alt="Profile Preview"
                    style={{
                      width: "100px",
                      height: "100px",
                      marginTop: "10px",
                    }}
                  />
                ):formData.profile_image !== null ?(
               
                <img src={`${baseurl}/${formData.profile_image}`} height={100} width={100} alt="Profile" className="img-fluid" />
                ):null}
              </div>
            </div> */}
          </div> 
          <div className="pt-4 w-100 d-flex justify-content-end">
            <button
              type="submit"
              className="btn btn-primary me-sm-3 me-1 waves-effect waves-light"
              disabled={loading}
            >
              {loading ? <Spinner animation="border" size="sm" /> : "Submit"}
            </button>
          </div>
        </form>
      </div>

    </div>
  );
}
